import { Space } from "antd";
import { EmbeddedBlackoutDates } from "./EmbeddedBlackoutDates";
import { EmbeddedCheckoutType } from "./EmbeddedCheckoutType";
import { EmbeddedCheckoutQuestions } from "./EmbeddedCheckoutQuestions";
import { EmbeddedTaxRate } from "./EmbeddedTaxRate";
import { EmbeddedDaysOfTheWeek } from "./EmbeddedDaysOfTheWeek";
import { EmbeddedEmailSettings } from "./EmbeddedEmailSettings";

export const EmbeddedSettings = () => {
  return (
    <Space
      size={12}
      direction={"vertical"}
      style={{ display: "inline-block", width: "100%" }}
    >
      <EmbeddedCheckoutType />
      <EmbeddedTaxRate />
      <EmbeddedBlackoutDates />
      <EmbeddedDaysOfTheWeek />
      <EmbeddedCheckoutQuestions />
      <EmbeddedEmailSettings />
    </Space>
  );
};
