import { Typography } from "@mui/material";
import React, { ReactNode } from "react";

interface LogTextProps {
  children?: ReactNode;
}

const LogText: React.FC<LogTextProps> = ({ children }) => {
  return (
    <Typography
      sx={{ mt: 2 }}
      variant="body2"
      style={{ whiteSpace: "pre-wrap" }}
    >
      {children}
    </Typography>
  );
};

export default LogText;
