import React, { Dispatch, FC, SetStateAction, useMemo } from "react";

import { usePersistentDataGrid } from "../../../../app/services/hooks/usePersistentDataGrid";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  DataGridPro,
  GridColDef,
  GridColumnHeaderParams,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";

import { Typography } from 'antd';

import { COLOR_PURPLE_0 } from "../../../../app/ui/colorConstants";
import TextBody from "../../../../app/system-components/typography/text/TextBody";
import { CategoryTag } from "../../../../app/system-components/tags/Tags";
import { getTagFilterOperator } from "../dataGridComponents/CategoryFilterComponent";
import { ImageCell } from "../dataGridComponents/ImageCell";
import { InventoryCategory } from "../../../../app/interfaces/inventoryCategory";
import { FormatToLocalCurrency } from "../../../../app/utils/currency/formatToLocalCurrency";
import { useSelector } from "react-redux";
import { authSelector } from "../../../auth/authSlice";
import { Part } from "../../../../app/interfaces/part";
import { PartCell } from "../dataGridComponents/PartCell";
import { useAdminPartsContextMenu } from "../contextMenu/useAdminPartsContextMenu";
import PartsContextMenu from "./PartsContextMenu";

const { Text } = Typography;

export const PartsDataGrid: FC<{
  setPartDrawer: Dispatch<
    SetStateAction<{
      visible: boolean;
      item?: Part;
    }>
  >;
  onDuplicateComplete: () => Promise<void>;
  onDeleteComplete: () => Promise<void>;
  data: Part[];
  loadingParts: boolean;
  loadingCategories: boolean;
  categories: InventoryCategory[];
}> = ({
  setPartDrawer,
  onDuplicateComplete,
  onDeleteComplete,
  data,
  loadingParts,
  loadingCategories,
  categories,
}) => {
  const persistentDataGrid = usePersistentDataGrid(
    "ADMIN_PARTS_TABLE_STATE"
  );
  const contextMenu = useAdminPartsContextMenu({
    onDuplicateComplete,
    onDeleteComplete,
    setPartDrawer,
  });

  const tagFilterOperator = getTagFilterOperator(categories);
  const { userData, orgData, fsOrgPrefix } = useSelector(authSelector);

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton style={{ color: COLOR_PURPLE_0 }} />
        <GridToolbarDensitySelector style={{ color: COLOR_PURPLE_0 }} />
        <GridToolbarExport style={{ color: COLOR_PURPLE_0 }} />
        <GridToolbarFilterButton style={{ color: COLOR_PURPLE_0 }} />
      </GridToolbarContainer>
    );
  };

  const columns = useMemo(getColumns, [setPartDrawer, categories, data]);

  return (
    <div
      style={{
        width: "100%",
        padding: "8px",
        backgroundColor: "white",
      }}
    >
      <DataGridPro<Part>
        autoHeight={true}
        apiRef={persistentDataGrid.apiRef}
        columns={columns}
        loading={loadingParts || loadingCategories}
        rows={data.filter((d) => !!d.id)}
        pagination={true}
        slots={{
          toolbar: CustomToolbar,
        }}
        initialState={{
          pagination: { paginationModel: { pageSize: 25 } },
          sorting: {
            sortModel: [{ field: "name", sort: "asc" }],
          },
        }}
        onRowDoubleClick={(params, event) => {
          event.stopPropagation();
          event.preventDefault();
          event.nativeEvent.preventDefault();
          // contextMenu.configureContextMenu(event, params.row);
        }}
        slotProps={{
          row: {
            onContextMenu: (event) => {
              event.stopPropagation();
              event.preventDefault();
              event.nativeEvent.preventDefault();
              const rowId = event.currentTarget.getAttribute("data-id");
              const part = data.find((c) => c.id === rowId);
              if (part)
                contextMenu.configureContextMenu(event, part);
            },
            style: { cursor: "context-menu", minHeight: "56px" },
          },
        }}
        onColumnOrderChange={persistentDataGrid.storeGridState}
        onColumnWidthChange={persistentDataGrid.storeGridState}
        onColumnVisibilityModelChange={persistentDataGrid.storeGridState}
        onPinnedColumnsChange={persistentDataGrid.storeGridState}
      />
      <PartsContextMenu control={contextMenu} />
    </div>
  );

  function getColumns(): GridColDef<Part>[] {
    const cols: GridColDef<Part>[] = [
      {
        headerName: "",
        field: "image",
        width: 30,
        valueFormatter: ({ value }) => `${value && value.downloadURL}`,
        renderCell: ImageCell,
      },
      {
        headerName: "Part",
        field: "name",
        width: 200,
        renderCell: (params) => (
          <PartCell setItemDrawer={setPartDrawer} {...params} />
        ),
      },
      { headerName: "Stock", field: "stock", width: 200 },
      {
        headerName: "Vendor", field: "vendor", width: 200,
        renderCell: ({row}) => (
          `${row.vendor?.companyName ?? ""}`
        ),
      },
      {
        headerName: "Categories",
        sortable: false,
        field: "categories",
        width: 200,
        valueFormatter: ({ value }) => `${value.map((obj: any) => obj.name)}`,
        renderCell: ({ row }) => (
          <>
            {row.categories.map((c) => (
              <CategoryTag text={c.name} key={c.id} />
            ))}
          </>
        ),
        filterable: true,
        // filterOperators: [tagFilterOperator],
      },
      {
        headerName: "Notes",
        field: "notes",
        width: 200,
        renderCell: ({ value }) => (
          <div
            className="InvNotesCell"
            style={{
              maxHeight: "68px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <TextBody text={value} />
          </div>
        ),
      },
      {
        headerName: "Price",
        field: "price",
        width: 200,
        sortable: false,
        valueFormatter: ({ value }) =>
          `${value / 100}`,
        renderCell: ({ value }) => {
          return (
            <TextBody
              text={value ? FormatToLocalCurrency(value) : "--"}
            />
          );
        },
      },
    ];

    const weightColumn = { headerName: "Weight", field: "weight", width: 150 };
    if (orgData && orgData.trackInventoryWeight) {
      cols.push(weightColumn);
    }

    return cols;
  }
};
export default PartsDataGrid;
