import { useContext } from "react";
import { AdminEmbeddedContext } from "../adminEmbeddedContext";
import { Card, Checkbox, InputNumber, Radio, Space, Switch, Tooltip } from "antd";
import TextSubtitle from "../../../app/system-components/typography/text/TextSubtitle";
import { InfoCircleOutlined } from "@ant-design/icons";
import { authSelector } from "../../auth/authSlice";
import { useSelector } from "react-redux";
import { checkSubscriptionLevel } from "../../../app/interfaces/features-set-permissions";

export const EmbeddedCheckoutType = () => {

  const { actions, state } = useContext(AdminEmbeddedContext);
  const { orgData } = useSelector(authSelector);

  return (
    <Card size={"small"} title={"Checkout"} style={{ marginTop: "24px" }}>
      <Space direction={"horizontal"} size={8}>
        <Radio.Group
          buttonStyle="solid"
          value={state.checkoutSettings.type}
          onChange={(v) => actions.checkoutSettings.changeType(v.target.value)}
        >
          <Tooltip title={!checkSubscriptionLevel("embeddedCheckout.wishlist", orgData) ? "Upgrade your subscription to enable this feature." : ""}>
            <Radio.Button value={"wishlist"} disabled={!checkSubscriptionLevel("embeddedCheckout.wishlist", orgData)}>Wishlist</Radio.Button>
          </Tooltip>
          <Radio.Button value={"order"}>Order</Radio.Button>
        </Radio.Group>
        <Space>
          <Checkbox
            checked={state.checkoutSettings.allowOverbooked}
            onChange={(v) =>
              actions.checkoutSettings.toggleOverbooked(v.target.checked)
            }
          />
          Allow overbooked items
        </Space>
      </Space>
      <br />
      <br />
      *Wishlist items get processed as estimates. Orders will be processed as
      invoices.
      <br />
      <br />
      <TextSubtitle text={"MAX CHECKOUT DAYS"} />
      <br />
      <TextSubtitle text={"SET ZERO FOR ONE DAY RENTAL"} />
      <br />
      <InputNumber
        min={0}
        placeholder={"0"}
        style={{ width: "120px" }}
        value={state.maxCheckoutDays}
        onChange={(v) => {
          actions.setMaxCheckoutDays(v ?? 0);
        }}
      />
      <br />
      <br />
      <div>
        <Switch
          checked={state.displayInventoryAvailability}
          onChange={(value) => actions.setDisplayInventory(value)}
        />{" "}
        <TextSubtitle text={"DISPLAY INVENTORY AVAILABILITY"} />
      </div>
      <br />
      <br />
      <Space>
        <Checkbox
          checked={state.allowDeliveryPickup}
          onChange={(v) => actions.setAllowDeliveryPickup(v.target.checked)}
        />
        Allow Delivery/Pickup
        <Tooltip
          title={`Show options for delivery. Your company makes delivery/pickup to/from customers address.`}
        >
          <InfoCircleOutlined />
        </Tooltip>
      </Space>
      <br />
      <Space>
        <Checkbox
          checked={state.allowWillCall}
          onChange={(v) => actions.setAllowWillCall(v.target.checked)}
        />
        {`Allow Customer Pickup/Drop Off (Will Call)`}
        <Tooltip title="Show options for will call. Let customers come to your warehouse to pick up/drop off orders.">
          <InfoCircleOutlined />
        </Tooltip>
      </Space>
      <br />
      <br />
      <div>
        <Space direction="vertical" style={{marginTop: "8px"}}>
          <TextSubtitle text={"NUMBER OF ITEMS TO DISPLAY PER PAGE (RECOMMEND MULTIPLE OF 3)"} />
          <InputNumber
            min={6}
            placeholder={"6"}
            style={{ width: "120px" }}
            value={state.pageCount}
            onChange={(v) => {
              actions.setPageCount(v ?? 6);
            }}
          />
        </Space>
      </div>
    </Card>
  );
};
