import { ApexOptions } from 'apexcharts';
// @mui
import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import Card, { CardProps } from '@mui/material/Card';
// utils
import { fCurrency, fNumber } from './format-number';
// components
import Chart, { useChart } from './chart';

// ----------------------------------------------------------------------

interface Props extends CardProps {
  title?: string;
  subheader?: string;
  chart: {
    colors?: string[];
    series: {
      label: string;
      value: number;
      qty: number;
    }[];
    options?: ApexOptions;
  };
}

export default function SalesReportChart({ title, subheader, chart, ...other }: Props) {
  const { colors, series, options } = chart;
  const chartSeries = series.map((i) => i.value);

  const chartOptions = useChart({
    colors,
    tooltip: {
      marker: { show: false },
      y: {
        formatter: (
          value: number,
          {
            dataPointIndex,
          }: { seriesIndex: number; dataPointIndex: number }
        ) => {
          const item = series[dataPointIndex];
          return `${fCurrency(value)}<br>Qty: ${item.qty}`;
        },
        title: {
          formatter: () => "",
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "28%",
        borderRadius: 2,
      },
    },
    xaxis: {
      categories: series.map((i) => i.label),
      labels: {
        formatter: (value) => fCurrency(value),
      },
    },
    ...options,
  });

  return (
      <Box sx={{ mx: 3 }}>
        <Chart
          type="bar"
          dir="ltr"
          series={[{ data: chartSeries }]}
          options={chartOptions}
          height={(chartSeries.length + 1) * 50}
        />
      </Box>
  );
}
