// @mui
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import "firebase/firestore";

import { Card, Link, Stack } from "@mui/material";

export const V2_1_4 = () => {
  return (
    <Card>
    <Box sx={{ p: 2, display: "flex" }}>
      <Stack spacing={0.5}>
        <Typography fontWeight={700}>
          V2.1.4 - Released 10-13-2023
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          style={{ whiteSpace: "pre-wrap" }}
        >
          {`
Whats new?

• A new feature to expire estimate payment links! In the general setting, you can now set how many days you want an estimate payment link to be live. This can save you from old estimates sent out suddenly being paid for and you are left with overbooked items 😩
• If you have the Adelie shopping cart integrated, you can now add rich formatted text for the description to better market your products.

New YouTube Trainings On Adelie Shopping Cart 📺
`}

          <Link
            href="https://youtu.be/qbzuwZsNnec?si=bo95V6KD4ZSN93KH"
            underline="always"
            target="_blank"
            rel="noopener"
          >
            Online Booking for Rental Products
          </Link>

          <br />

          <Link
            href="https://youtu.be/5TS_965p2VU?si=pxB7ehs8jjx0-5AW"
            underline="always"
            target="_blank"
            rel="noopener"
          >
            BW Party Supply + Adelie Logistics Embedded Checkout
          </Link>
          <br />
          <Link
            href="https://youtu.be/R4dq5k-VYMY?si=kkio60aQDqVKId4t"
            underline="always"
            target="_blank"
            rel="noopener"
          >
            Embedded Shopping Cart Product Settings
          </Link>
          <br />

          {`
Bug Fixes!

• Bug fix for default security deposit not editing properly. 🐜

`}
        </Typography>
      </Stack>
    </Box>
  </Card>
  );
};
