import React, { useEffect } from "react";

import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import {
  Button,
  Stack,
  MenuItem,
  IconButton,
  TextField,
  Divider,
  Tooltip,
} from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Fade from "@mui/material/Fade";
import Popper from "@mui/material/Popper";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';

import { COLOR_PURPLE_0 } from "./../../app/ui/colorConstants";

import SaveIcon from "@mui/icons-material/Save";

import { GridToolbarFilterButton } from "@mui/x-data-grid-pro";
import { addDays, addYears, endOfDay, endOfMonth, endOfWeek, endOfYear, startOfDay, startOfMonth, startOfWeek, startOfYear, subDays, subYears } from "date-fns";
import { firelikeId } from "../../app/utils/models/checkers/firelikeid";
import { checkSubscriptionLevel } from "../../app/interfaces/features-set-permissions";

function ViewListItem(props) {

  const { view, viewId, selected, onDelete, onSelect, ...other } = props;

  return (
    <MenuItem selected={selected} onClick={() => onSelect(viewId)} {...other}>
      {view.label}
      <IconButton
        edge="end"
        aria-label="delete"
        size="small"
        onClick={(event) => {
          event.stopPropagation();
          onDelete(viewId);
        }}
      >
        <DeleteIcon />
      </IconButton>
    </MenuItem>
  );
}

function NewViewListButton(props) {
  const { label, onLabelChange, onSubmit, isValid, canUseFilters } = props;
  const [isAddingView, setIsAddingView] = React.useState(false);

  console.log(canUseFilters)

  const handleSubmitForm = (e) => {
    onSubmit();
    setIsAddingView(false);
    e.preventDefault();
  };

  return (
    <React.Fragment>
      <Tooltip
        title={
          !canUseFilters
            ? "You do not have the required plan for saving table templates. Requires Premium Plan, you can change this in Admin/Billing"
            : ""
        }
      >
        <span>
          <Button
            disabled={!canUseFilters}
            style={{ color: COLOR_PURPLE_0 }}
            startIcon={<AddIcon />}
            size="small"
            onClick={() => setIsAddingView(true)}
          >
            Save New Filter
          </Button>
        </span>
      </Tooltip>

      <Dialog onClose={() => setIsAddingView(false)} open={isAddingView}>
        <form onSubmit={handleSubmitForm}>
          <DialogTitle>New custom filter.</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              value={label}
              onChange={onLabelChange}
              margin="dense"
              size="small"
              label="Custom view label"
              variant="standard"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button type="button" onClick={() => setIsAddingView(false)}>
              Cancel
            </Button>
            <Button type="submit" disabled={!isValid}>
              Create Filter
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}

const gridStateReducer = (state, action) => {
  switch (action.type) {
    case "createView": {
      const id = firelikeId();

      const updatedViews = {
        ...state.views,
        [id]: { label: state.newViewLabel, value: action.value },
      };

      return {
        ...state,
        activeViewId: id,
        newViewLabel: "",
        views: updatedViews,
      };
    }

    case "deleteView": {
      const views = Object.fromEntries(
        Object.entries(state.views).filter(([id]) => id !== action.id)
      );

      let activeViewId;
      if (state.activeViewId !== action.id) {
        activeViewId = state.activeViewId;
      } else {
        const viewIds = Object.keys(state.views);

        if (viewIds.length === 0) {
          activeViewId = null;
        } else {
          activeViewId = viewIds[0];
        }
      }

      return {
        ...state,
        views,
        activeViewId,
      };
    }

    case "setActiveView": {
      return {
        ...state,
        activeViewId: action.id,
        isMenuOpened: false,
      };
    }

    case "setNewViewLabel": {
      return {
        ...state,
        newViewLabel: action.label,
      };
    }

    case "setViews": {
      return {
        ...state,
        views: action.views,
      };
    }

    case "togglePopper": {
      return {
        ...state,
        isMenuOpened: !state.isMenuOpened,
        menuAnchorEl: action.element,
      };
    }

    case "closePopper": {
      return {
        ...state,
        isMenuOpened: false,
      };
    }

    case "setLastSelectedView": {
      return {
        ...state,
        activeViewId: action.id,
      };
    }

    default: {
      return state;
    }
  }
};

const INITIAL_GRID_STATE = {
  views: JSON.parse(localStorage.getItem("GRID_PROFILES")) || {},
  newViewLabel: "",
  isMenuOpened: false,
  menuAnchorEl: null,
  activeViewId: null,
};

export const CustomToolbar = (apiRef, currentDates, setCurrentDates, orgData) => {

  const canUseFilters = checkSubscriptionLevel("makeTheSale.savedFilters", orgData)

  const [state, dispatch] = React.useReducer(
    gridStateReducer,
    INITIAL_GRID_STATE
  );

  const createNewView = () => {
    dispatch({
      type: "createView",
      value: apiRef.current.exportState(),
    });
  };

  const handleNewViewLabelChange = (event) => {
    dispatch({ type: "setNewViewLabel", label: event.target.value });
  };

  const handleDeleteView = React.useCallback((viewId) => {
    dispatch({ type: "deleteView", id: viewId });
  }, []);

  const handleSetActiveView = (viewId) => {
    apiRef.current.restoreState(state.views[viewId].value);
    dispatch({ type: "setActiveView", id: viewId });
  };

  const handlePopperAnchorClick = (event) => {
    dispatch({ type: "togglePopper", element: event.currentTarget });
    event.stopPropagation();
  };

  const handleClosePopper = () => {
    dispatch({ type: "closePopper" });
  };

  const handleUpdateView = () => {
    // Get the updated view label and value from the form inputs
    const updatedViewValue = apiRef.current.exportState();

    const updatedViews = {
      ...state.views,
      [state.activeViewId]: {
        ...state.views[state.activeViewId], // Retain existing properties of the view
        label: state.views[state.activeViewId].label, // Update the label
        value: updatedViewValue, // Update the value
      },
    };

    // Update the state with the updated views
    dispatch({ type: "setViews", views: updatedViews });

    // Save the updated views to localStorage
    localStorage.setItem("GRID_VIEWS", JSON.stringify(updatedViews));
  };

  const isNewViewLabelValid = React.useMemo(() => {
    if (state.newViewLabel.length === 0) {
      return false;
    }

    return Object.values(state.views).every(
      (view) => view.label !== state.newViewLabel
    );
  }, [state.views, state.newViewLabel]);

  const canBeMenuOpened = state.isMenuOpened && Boolean(state.menuAnchorEl);
  const popperId = canBeMenuOpened ? "transition-popper" : undefined;

  const handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      dispatch({ type: "closePopper" });
    } else if (event.key === "Escape") {
      dispatch({ type: "closePopper" });
    }
  };

  // Add a useEffect to set the last selected view on load
  useEffect(() => {
    const lastSelectedView = localStorage.getItem("LAST_SELECTED_VIEW");

    const currentGridProfile = JSON.parse(localStorage.getItem("CURRENT_GRID_SETTINGS"));
    let orderedFields = currentGridProfile.columns.orderedFields; // array of strings.
    // check if it contains "__check__" and if it does not, or it is not first, add it and make it first.
    if (!orderedFields.includes("__check__") || orderedFields[0] !== "__check__") {
      orderedFields = ["__check__", ...orderedFields.filter((field) => field !== "__check__")];
    }
    currentGridProfile.columns.orderedFields = orderedFields;

    if (currentGridProfile) {
      apiRef.current.restoreState(currentGridProfile);
      if (lastSelectedView && state.views[lastSelectedView]) {
        state.activeViewId = lastSelectedView;
      }
      return;
    }

    if (lastSelectedView && state.views[lastSelectedView]) {
      handleSetActiveView(lastSelectedView);
    }
  }, []);

  // Add a useEffect to save the last selected view
  useEffect(() => {
    if (state.activeViewId) {
      localStorage.setItem("LAST_SELECTED_VIEW", state.activeViewId);
    }
  }, [state.activeViewId]);

  useEffect(() => {
    localStorage.setItem("GRID_PROFILES", JSON.stringify(state.views));
  }, [state.views]);

  const handleDateRangeChange = (newDates) => {
    if (newDates[0] && newDates[1]) {
      // Serialize the date range object to a JSON string

      const dateRangeToStore = {
        startDate: newDates[0].toISOString(), // Convert Date object to string
        endDate: newDates[1].toISOString(),
      };

      const serializedDateRange = JSON.stringify(dateRangeToStore);

      // Store the serialized date range in session storage
      sessionStorage.setItem("selectedDateRange", serializedDateRange);
      setCurrentDates(newDates);
    }
  };

  return (
    <Stack direction={"column"}>
      <GridToolbarContainer
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <GridToolbarColumnsButton style={{ color: COLOR_PURPLE_0 }} />
          <GridToolbarDensitySelector style={{ color: COLOR_PURPLE_0 }} />
          <GridToolbarExport style={{ color: COLOR_PURPLE_0 }} />
          <GridToolbarFilterButton style={{ color: COLOR_PURPLE_0 }} />
        </div>

        <div
          style={{
            border: "1px solid #ced4da",
            padding: " 0px 20px 0px 20px",
            borderRadius: 22,
            translate: "0px 5px",
          }}
        >
          <GridToolbarQuickFilter
            sx={{
              color: COLOR_PURPLE_0,
              "& .MuiInputBase-input": { fontWeight: "medium" },
              translate: "0px 5px",
            }}
          />
        </div>

        <div>
          {
            canUseFilters && (
              <>
              <Button
              aria-describedby={popperId}
              type="button"
              size="small"
              id="custom-view-button"
              aria-controls={state.isMenuOpened ? "custom-view-menu" : undefined}
              aria-expanded={state.isMenuOpened ? "true" : undefined}
              aria-haspopup="true"
              onClick={handlePopperAnchorClick}
              style={{ color: COLOR_PURPLE_0 }}
            >
              SAVED FILTERS ({Object.keys(state.views).length})
            </Button>
            <ClickAwayListener onClickAway={handleClosePopper}>
              <Popper
                id={popperId}
                open={state.isMenuOpened}
                anchorEl={state.menuAnchorEl}
                role={undefined}
                transition
                placement="bottom-start"
                sx={{ zIndex: "modal" }}
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper>
                      <MenuList
                        autoFocusItem={state.isMenuOpened}
                        id="custom-view-menu"
                        aria-labelledby="custom-view-button"
                        onKeyDown={handleListKeyDown}
                      >
                        {Object.entries(state.views).map(([viewId, view]) => (
                          <ViewListItem
                            key={viewId}
                            view={view}
                            viewId={viewId}
                            selected={viewId === state.activeViewId}
                            onDelete={handleDeleteView}
                            onSelect={handleSetActiveView}
                          />
                        ))}
                      </MenuList>
                    </Paper>
                  </Fade>
                )}
              </Popper>
            </ClickAwayListener>

            <Button
              startIcon={<SaveIcon />}
              size="small"
              onClick={handleUpdateView}
              disabled={!state.activeViewId || !canUseFilters}
              style={{ color: COLOR_PURPLE_0 }}
            >
              Update Current Filter
            </Button>
            </>
            )
          }

          


          <NewViewListButton
            label={state.newViewLabel}
            onLabelChange={handleNewViewLabelChange}
            onSubmit={createNewView}
            isValid={isNewViewLabelValid}
            canUseFilters={canUseFilters}
          />
        </div>
      </GridToolbarContainer>

      <GridToolbarContainer
        sx={{
          mt: 1,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Stack alignItems={"center"} direction={"row"} gap={2}>
          <DateRangePicker
            value={currentDates}
            onAccept={(newValue) => handleDateRangeChange(newValue)}
            slotProps={{
              textField: { size: "small" },
              shortcuts: {
                items: shortcutsItems,
              },
              actionBar: {
                actions: ["today", "tomorrow"],
              },
            }}
          />
        </Stack>
      </GridToolbarContainer>

      <Divider sx={{ mt: 1 }} />
    </Stack>
  );
};

const shortcutsItems = [
  {
    label: 'All Time',
    getValue: () => {
      const seven = addYears(new Date(), 7);
      const minusFive = subYears(new Date(), 5)
      return [startOfYear(minusFive), endOfYear(seven)];
    },
  },
  {
    label: 'Yesterday',
    getValue: () => {
      const yesterday = subDays(new Date(), 1);
      return [startOfDay(yesterday), endOfDay(yesterday)];
    },
  },
  {
    label: 'Today',
    getValue: () => {
      const today = new Date();
      return [startOfDay(today), endOfDay(today)];
    },
  },
  {
    label: 'Tomorrow',
    getValue: () => {
      const tomorrow = addDays(new Date(), 1);
      return [startOfDay(tomorrow), endOfDay(tomorrow)];
    },
  },
  {
    label: 'Last Week',
    getValue: () => {
      const lastWeek = subDays(new Date(), 7);
      return [startOfWeek(lastWeek), endOfWeek(lastWeek)];
    },
  },
  {
    label: 'This Week',
    getValue: () => {
      const today = new Date();
      return [startOfWeek(today), endOfWeek(today)];
    },
  },
  {
    label: 'Next Week',
    getValue: () => {
      const nextWeek = addDays(new Date(), 7);
      return [startOfWeek(nextWeek), endOfWeek(nextWeek)];
    },
  },
  {
    label: 'This Month',
    getValue: () => {
      const today = new Date();
      return [startOfMonth(today), endOfMonth(today)];
    },
  },
  {
    label: 'Next Month',
    getValue: () => {
      const nextMonth = addDays(endOfMonth(new Date()), 1);
      return [startOfDay(nextMonth), endOfMonth(nextMonth)];
    },
  },
  {
    label: 'Last Year',
    getValue: () => {
      const lastYear = subYears(new Date(), 1);
      return [startOfYear(lastYear), endOfYear(lastYear)];
    },
  },
  {
    label: 'This Year',
    getValue: () => {
      const today = new Date();
      return [startOfYear(today), endOfYear(today)];
    },
  },
  {
    label: 'Next Year',
    getValue: () => {
      const nextYear = addYears(new Date(), 1);
      return [startOfYear(nextYear), endOfYear(nextYear)];
    },
  },
  { label: 'Reset', getValue: () => [null, null] },
];