import { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Modal,
  Row,
  Col,
  Divider,
} from "antd";

import TextSubtitle from "../../../../app/system-components/typography/text/TextSubtitle";
import { configureCustomer } from "../../../../app/utils/models/configure/configureCustomer";
import { useSelector } from "react-redux";
import { authSelector } from "../../../auth/authSlice";
import { useFirestore } from "react-redux-firebase";
import notificationConfirm from "../../../../app/system-components/toasters/notificationConfirm";
import notificationError from "../../../../app/system-components/toasters/notificationError";
import { vendorWrites } from "../../../../app/services/firestore/writes/vendorWrites";
import { configureVendor } from "../../../../app/utils/models/configure/configureVendor";

const AddVendorModal = ({ visible, onCancel, onComplete }) => {
  const { orgData, userData, fsOrgPrefix } = useSelector(authSelector);

  const [billingShippingSame, setBillingShippingSame] = useState(false);
  const [saving, setSaving] = useState(false);

  const [form] = Form.useForm();
  const firestore = useFirestore();

  const handleFinish = () => {
    form.validateFields().then(() => {
      setSaving(true);
      const values = form.getFieldsValue();
    
      const data = {
        ...values,
      };

      const payload = configureVendor().newVendor(
        data,
        userData.id,
        orgData
      );
      vendorWrites({ firestore }, fsOrgPrefix)
        .createVendor(payload)
        .then((res) => {
          clearBeforeClose();
          onComplete(res);
          notificationConfirm("Vendor created", "");
        })
        .catch((err) => {
          clearBeforeClose();
          console.log(err);
          notificationError(
            "Something went wrong",
            "Vendor could not be created"
          );
        });
    });
  };

  const clearBeforeClose = () => {
    setSaving(false);
    setBillingShippingSame(false);
    form.resetFields();
  };

  useEffect(() => {
    const values = form.getFieldsValue();
    form.setFieldsValue({
      billingAddress: values.billingAddress,
      billingCity: values.billingCity,
      billingState: values.billingState,
      billingZip: values.billingZip,
    });
  }, [form]);

  return (
    <Modal
      destroyOnClose
      width={1000}
      open={visible}
      closeIcon={<></>}
      title="Add Vendor"
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            form.resetFields();
            onCancel();
          }}
          disabled={saving}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => handleFinish()}
          disabled={saving}
          loading={saving}
        >
          Add Vendor
        </Button>,
      ]}
    >
      <>
        <Form form={form}>

          <Row gutter={24} wrap={false}>
            <Col span={12}>
              <div style={{ marginBottom: "12px" }}>
                <TextSubtitle text={"VENDOR INFO"} />
              </div>

              <Form.Item
                name={'companyName'}
                initialValue=""
                style={{ marginBottom: "12px" }}
                rules={[
                  {
                    required: true,
                    message: "Company name required",
                  },
                ]}
              >
                <Input placeholder="Company name" />
              </Form.Item>

              <Form.Item
                initialValue=""
                name="email"
                style={{ marginBottom: "12px" }}
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "Company email required",
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>

              <Form.Item
                initialValue=""
                name="phone"
                style={{ marginBottom: "12px" }}
              >
                <Input placeholder="Phone" />
              </Form.Item>

            </Col>
            <Col span={12}>
              <div style={{ marginBottom: "12px" }}>
                <TextSubtitle text={"MAIN CONTACT INFO"} />
              </div>

                <Form.Item
                  initialValue=""
                  name="firstName"
                  style={{ marginBottom: "12px" }}
                >
                  <Input placeholder="First Name" />
                </Form.Item>
              
                <Form.Item
                  initialValue=""
                  name="lastName"
                  style={{ marginBottom: "12px" }}
                >
                  <Input placeholder="Last Name" />
                </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row wrap={false} justify="space-between" gutter={24}>
            <Col>
              <div style={{ marginBottom: "12px" }}>
                <TextSubtitle text={"BILLING ADDRESS"} />
              </div>
              <Form.Item
                initialValue=""
                name={'billingAddress'}
                style={{ marginBottom: "12px" }}
              >
                <Input
                  placeholder="Street"
                />
              </Form.Item>
              <Row wrap={false} justify="space-between">
                <Col span={9}>
                  <Form.Item
                    initialValue=""
                    name={'billingCity'}
                    style={{ marginBottom: "0px" }}
                  >
                    <Input
                      placeholder="City"
                    />
                  </Form.Item>
                </Col>
                <Col span={9}>
                  <Form.Item
                    initialValue=""
                    name={'billingState'}
                    style={{ marginBottom: "0px" }}
                  >
                    <Input
                      placeholder="State"
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    initialValue=""
                    name={'billingZip'}
                    style={{ marginBottom: "0px" }}
                  >
                    <Input
                      placeholder="ZIP"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </>
    </Modal>
  );
};

export default AddVendorModal;
