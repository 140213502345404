import { AppBar, Container } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { authSelector } from '../auth/authSlice';
import SubscriptionInfoCard from './subscription-card';
import { OrganizationData } from '../../app/interfaces/organizationData';
import PricingTable from './pricing-table';
import { DB } from '../../app/config/firebase';

const BillingPage = () => {

    const { orgData, userData } = useSelector(authSelector);

    useEffect(() => {

      const handleEvent = (data: any) => {
        if (orgData.subscription !== data.subscription) {
          console.log('updating subscription');
          window.location.reload();
        }
      };

      const fetchData = async () => {
        if (orgData) {
  
          const unsubscribe = DB.collection("orgs")
            .doc(orgData.id)
            .onSnapshot((snapshot) => {
              const data = snapshot.data();
              handleEvent(data);
            });

          return () => {
            unsubscribe();
          };
        }
      };
      
      if (orgData) {
        fetchData();
      }
    }, [orgData]);

  return (
    <>
   
    <Container sx={{mt:2}}>

        {
            orgData && <SubscriptionInfoCard orgData={orgData as OrganizationData} />
        }

    {/* WIP */}
    {/* {
      orgData && ((orgData as OrganizationData).subscriptionStatus === 'inactive' || (orgData as OrganizationData).subscriptionStatus === 'trial') && !orgData.grandfathered && (
        <PricingTable orgData={orgData} />
      )
    } */}

    </Container>
    </>
  )
}

export default BillingPage
