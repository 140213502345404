import { useState, MouseEvent } from "react";
import { useSelector } from "react-redux";
import { authSelector } from "../../../auth/authSlice";

import { useFirestore } from "react-redux-firebase";
import notificationConfirm from "../../../../app/system-components/toasters/notificationConfirm";
import { Modal } from "antd";
import { WarningFilled } from "@ant-design/icons";
import { COLOR_RED_0 } from "../../../../app/ui/colorConstants";
import notificationError from "../../../../app/system-components/toasters/notificationError";
import { Part } from "../../../../app/interfaces/part";
import { partWrites } from "../../../../app/services/firestore/writes/inventoryWrites";
import { configurePart } from "../../../../app/utils/models/configure/configurePart";

export interface ContextConfig {
  selectedPart?: Part;
  visible: boolean;
  x: number;
  y: number;
}
export interface AdminPartsContextMenuControl {
  handleDuplicate: () => void;
  handleDelete: () => void;
  handleEdit: () => void;
  contextConfig: ContextConfig;
  configureContextMenu: (
    event: MouseEvent<HTMLElement>,
    record: Part
  ) => void;
}

export const useAdminPartsContextMenu = ({
  setPartDrawer,
  onDuplicateComplete,
  onDeleteComplete,
}: {
  setPartDrawer: React.Dispatch<
    React.SetStateAction<{
      visible: boolean;
      item?: Part;
    }>
  >;
  onDuplicateComplete: () => Promise<void>;
  onDeleteComplete: () => Promise<void>;
}): AdminPartsContextMenuControl => {
  const { userData, orgData, fsOrgPrefix } = useSelector(authSelector);
  const firestore = useFirestore();
  const [contextConfig, setContextMenuConfig] = useState<ContextConfig>({
    selectedPart: undefined,
    visible: false,
    x: 0,
    y: 0,
  });

  const handleEdit = () => {
    setPartDrawer({
      visible: true,
      item: contextConfig.selectedPart,
    });
  };

  const handleDuplicate = () => {
    // create duplicate
    if (contextConfig.selectedPart) {
      const payload = configurePart().duplicatePartItem(
        contextConfig.selectedPart,
        userData.id,
        orgData
      );
      const writer = partWrites({ firestore }, fsOrgPrefix);
      if (writer) {
        writer.createPart(payload).then(() => {
          onDuplicateComplete().then(() => {
            notificationConfirm("Item duplicated");
          });
        });
      }
    }

    // open drawer
  };
  const handleDelete = () => {
    Modal.confirm({
      icon: <WarningFilled style={{ color: COLOR_RED_0 }} />,
      title: "Delete part?",
      content: "This cannot be undone",
      okType: "danger",
      onOk: async () => {
        try {
          if (!fsOrgPrefix)
            return notificationError(
              "Something went wrong",
              "Please try again later"
            );

          const writer = partWrites({ firestore }, fsOrgPrefix);
          if (writer && contextConfig.selectedPart) {
            await writer.deletePart(
              contextConfig.selectedPart.id
            );
            await onDeleteComplete();
            notificationConfirm("Item deleted", "");
          }
        } catch (err) {
          console.log(err);
          notificationError("Something went wrong", "Please try again later");
        }
      },
      onCancel() {},
    });
  };
  const configureContextMenu = (
    event: MouseEvent<HTMLElement>,
    record: Part
  ) => {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    if (!contextConfig.visible) {
      document.addEventListener(`click`, function onClickOutside() {
        setContextMenuConfig({
          selectedPart: undefined,
          visible: false,
          x: 0,
          y: 0,
        });
        document.removeEventListener(`click`, onClickOutside);
      });
    }
    setContextMenuConfig({
      selectedPart: record,
      visible: true,
      x: event.clientX,
      y: event.clientY,
    });
  };
  return {
    handleDuplicate,
    handleDelete,
    handleEdit,
    contextConfig,
    configureContextMenu,
  };
};
