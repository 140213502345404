import notificationError from "../../../system-components/toasters/notificationError";
import { dollarToCents } from "./configureCurrencyToCents";
import onlyUnique from "../../filters/filterOnlyUnique";
import { checkUndefinedOrNull } from "../checkers/checkUndefined";
import { INV_BUNDLE_ITEM } from "../modelConstants/modelConstants";
import {
  Part,
  PartFormData,
} from "../../../interfaces/part";

export const configurePart = () => {
 
  function newPartItem(
    values: Partial<PartFormData>,
    createdById: string,
    orgData: any
  ) {
    if (!values || !createdById || !orgData)
      return notificationError("Could not create item", "");

    const newItem: Partial<Part> = {
      orgId: orgData.id,
      type: values.type ? values.type : "rental",
      name: values.name ?? "",
      description: values.description ?? "",
      stock: values.stock ? values.stock : null,
      categories: values.categories ? values.categories : [],
      categoriesQueryHook: values.categoriesQueryHook
        ? values.categoriesQueryHook
        : [],
      vendorQueryHook: values.vendorQueryHook ?? null,
      vendor: values.vendor,
      price: values.price ? values.price : 0,
      weight: values.weight ? values.weight : null,
      notes: values.notes ? values.notes : "",
      image: values.image ? values.image : null,
      isAvailable: values.isAvailable ?? false,
      isActive: values.isActive ?? false,
      isTaxable: values.isTaxable ?? false,
      createdOn: new Date(),
      createdBy: createdById,
      updatedOn: new Date(),
      updatedBy: createdById,
      updateSource: "adelie",
      createdOnPlatform: "adelie",
    };
    return newItem;
  }
  function editPartItem(
    values: PartFormData,
    editedById: string,
    orgData: any
  ): Partial<Part> | undefined {
    if (!values || !editedById || !orgData) {
      notificationError("Could not edit item", ""); // could we throw an exception here?
      return undefined;
    }

    const result: Partial<Part> = {
      orgId: orgData.id,
      type: values.type ? values.type : "rental",
      name: values.name,
      description: values.description,
      stock: values.stock ? values.stock : null,
      categories: values.categories ? values.categories : [],
      categoriesQueryHook: values.categoriesQueryHook
        ? values.categoriesQueryHook
        : [], // for referencing categories
      vendorQueryHook: values.vendorQueryHook ?? null,
      vendor: values.vendor ?? null,
      weight: values.weight ? values.weight : null,
      price: values.price ? values.price : 0,
      notes: values.notes ? values.notes : "",
      image: values.image ? values.image : null, // object with url and name
      isAvailable: values.isAvailable, // populates in searching available inventory
      isActive: values.isActive, // denotes temporary holds (ie servicing etc)
      isTaxable: values.isTaxable,
      updatedOn: new Date(),
      updatedBy: editedById,
      updateSource: "adelie",
    };
    return result;
  }

  function duplicatePartItem(
    values: any,
    createdById: string,
    orgData: any
  ) {
    if (!values || !createdById || !orgData)
      return notificationError("Could not edit item", "");
    const {
      id,
      name,
      createdOn,
      createdBy,
      updatedOn,
      updatedBy,
      rates,

      /**
       * Remove from configuring
       */
      qbId,
      updateSource,
      createdOnPlatform,
      /**
       *
       */
      ...rest
    } = values;
    return {
      name: `${name} Copy`,
      createdOn: new Date(),
      createdBy: createdById,
      updatedOn: new Date(),
      updatedBy: createdById,
      updateSource: "adelie",
      createdOnPlatform: "adelie",
      ...rest,
    };
  }

  return {
    newPartItem, // configure new part item
    editPartItem, // edit part item
    duplicatePartItem, // duplicate part item
  };
};
