// @mui
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import "firebase/firestore";

import { Card, Link, Stack } from "@mui/material";

export const V2_1_6 = () => {
  return (
    <Card>
      <Box sx={{ p: 2, display: "flex" }}>
        <Stack spacing={0.5}>
          <Typography fontWeight={700}>V2.1.6 - Released 10-23-2023</Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            style={{ whiteSpace: "pre-wrap" }}
          >
            {`
Whats new?

• Dashboard update - you can now set invoice statuses (loaded, received, returned) directly from the dashboard via the edit button on each row.

📱 Mobile Updates! 
Updates to both iOS and Android apps that allow users to view load only routes. This feature allows the route maker to assign a load date that is different from the route delivery date in the event that orders need to be prepped and loaded before hand.
Users are also able to view just their routes now as well.

There is also a NEW menu item that has more information about the mobile apps. Check it out! There will be more tutorials and documentation coming to that page soon.

And if you haven't downloaded the apps yet, get them at the links below! Just sign in with your Adelie email and password. If you want to get trainings on the mobile apps, email jonny@adelielogistics.com
`}

            <Link
              href="https://apps.apple.com/us/app/adelie/id1636241259"
              underline="always"
              target="_blank"
              rel="noopener"
            >
              Get it on iOS 📱
            </Link>

            <br />

            <Link
              href="https://play.google.com/store/apps/details?id=com.adelie.adelie&pcampaignid=web_share"
              underline="always"
              target="_blank"
              rel="noopener"
            >
              Get it on Android 🤖
            </Link>

            <br />

            {`
New YouTube Training On Adelie YouTube Channel 📺 (SUBSCRIBE! 😀)
`}

            <Link
              href="https://youtu.be/qbzuwZsNnec?si=bo95V6KD4ZSN93KH"
              underline="always"
              target="_blank"
              rel="noopener"
            >
              Right Click Functionality - Useful Shortcuts
            </Link>

            {`
Bug Fixes!

• Fix for embedded shopping cart rates being applied as a single unity vs per item.


`}
          </Typography>
        </Stack>
      </Box>
    </Card>
  );
};
