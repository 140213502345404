// @mui
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import "firebase/firestore";

import { Card, Stack } from "@mui/material";
import { WhatsNew } from "../components/WhatsNew";
import LogImage from "../components/LogImage";
import { BugFixes } from "../components/BugFixes";
import LogText from "../components/LogText";
import LogSectionHeader from "../components/LogSectionHeader";

export const V2_2_3 = () => {
  return (
    <Card sx={{ width: "100%", padding: 4 }}>
      <Box sx={{ p: 2, display: "flex", width: "100%" }}>
        <Stack spacing={1} sx={{ width: "100%" }}>
          <Typography fontWeight={700}>V2.2.3 - Released 3-3-2024</Typography>

          <br />
          <LogSectionHeader>We got a lot to catch up on!</LogSectionHeader>
          <LogText>
            We've released several new features and bug fixes over the last month, so let's see what's new!
          </LogText>
          <br />
          <WhatsNew />
          <LogSectionHeader>Email Templates</LogSectionHeader>

          <LogText>
          We are super excited to launch email templates. This new feature allows you to create email templates for any scenario you can think of, then quickly send them for one or multiple orders.
          </LogText>

          <LogText>
            {`First, go to your admin settings > email.`}
          </LogText>

          <LogText>
          You will see a button in the top right for "New Template". Give your new template a name, and subject line. Then just write out the body of your email, or you can use AI to generate an email template for you!
          </LogText>

          <LogText>
          If you want to include dynamic properties like the customer name and invoice number, utilize the shortcode buttons below the content editor. If you find yourself needing shortcodes that aren't included, send us a feature request from the Dashboard to let us know.
          </LogText>

          <LogText>
            In the left menu, you can add attachments, include payment links, and whether to include the invoice pdf as attachment.
          </LogText>

          <LogText>
            Once you are happy with your template, save it, and it is ready to use.
          </LogText>

          <LogText>
          To send emails using the template, go to your "Make The Sale" tab. Select any orders using the new checkboxes in the first column. Then you will see a button in the top right appear that says "Send Emails". Pick the email template you want to send, then click "Send".
          </LogText>

          <LogText >
            This can be extremely useful for cases like bulk emailing customers with outstanding balances for orders this week, as an example. You can use the powerful filtering capabilities of the Make The Sale data grid to select all invoices this week, then add another filter for balance greater than 0. Then select all those orders, and send them a "Balance Due" email template!
          </LogText>

          <LogText>
            And finally, the results of the email send will be sent as a Dashboard notification indicating how many emails were sent and if any failed.         
          </LogText>

          <LogText>
            We're very excited about this feature and hope it saves you a lot of time.
          </LogText>

          <br />
          <LogSectionHeader>Search and Filter Persistence</LogSectionHeader>

          <LogText>
            {`
            Next, we made some highly requested (and frankly long overdue) updates to the Make The Sale tab. Previously, any filtering or search parameters were not persisted when you left the page. Well, I am happy to report that is now no longer the case. Any filtering, searching, column changes, etc will be saved even after reloading the page. The search has also been improved and can now return results on more invoice properties. Give it a try and let us know what you think!
            `.trim()}
          </LogText>

          <br />
          <LogSectionHeader>Dashboard Enhancements.</LogSectionHeader>

          <LogText>
            Next up, we have a new Dashboard widget, the Zoom Support Widget. If you find yourself confused or lost using our software, never fear, just click on that support button and you will be connected with a support specialist over Zoom to get fast support.           
          </LogText>


          <LogText>
          Lastly, we got some bug fixes and other small changes. You will notice in the general settings, the settings are now split into three tabs: General, Invoice, and Tags. The contents are the same but it made more sense to move the invoice settings to their own section.
          </LogText>

          <LogText>
          There are two new settings in the general settings, for those using the embedded checkout system or Shopify/WooCommerce integrations, you can now specify your delivery base rate and range rate ($ per mile).
          </LogText>

          <LogText>
          You may also notice that we are enforcing customer display name uniqueness. This is to align with QuickBooks Online requirements that no customer have the same display name. This will reduce sync errors when it comes to customers.
          </LogText>

          <br />
          <LogText>
            We hope these updates help you stay organized and efficient!
          </LogText>
        </Stack>
      </Box>
    </Card>
  );
};
