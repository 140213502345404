import React, { useContext } from "react";
import { useStopCell } from "../../../../../app/services/hooks/useStopCell";
import { Card, Collapse } from "antd";
import StopCellHeader from "../shared/StopCellHeader";
import { ItemTag } from "../../../../../app/system-components/tags/Tags";
import InvoiceNotes from "../shared/InvoiceNotes";
import { RouteContext } from "../../../NewRoute";
import { COLOR_ORANGE_HIGHLIGHT } from "../../../../../app/ui/colorConstants";

const { Panel } = Collapse;
const StopCellAssigned = ({ stop, routes, currentRouteId }) => {
  const { state } = useContext(RouteContext);
  
  const {
    invoiceNumber,
    items,
    notes,
    inRoutes,
    typeLabel,
    timeWindow,
    currentlySelected,
  } = useStopCell({
    stop,
    routes,
    currentRouteId,
    selectedStop: state.selectedStop,
  });

  return (
    <div
      style={{
        background: currentlySelected ? COLOR_ORANGE_HIGHLIGHT : "#FFF",
      }}
    >
      <Collapse
        defaultActiveKey={null}
        activeKey={null}
        bordered={true}
        style={{ padding: 0 }}
        ghost={true}
      >
        <Panel
          showArrow={false}
          className={"Stop-Header"}
          header={
            <StopCellHeader
              hideEdit={false}
              hideDots={true}
              stop={stop}
              invoiceNumber={invoiceNumber}
              inRoutes={inRoutes}
              typeLabel={typeLabel}
              timeWindow={timeWindow}
            />
          }
          key={`${invoiceNumber}`}
        ></Panel>
      </Collapse>
      <InvoiceNotes notes={notes} />
    </div>
  );
};

export default StopCellAssigned;
