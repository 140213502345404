import React, { useState, useRef, useEffect } from "react";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import * as DOMPurify from "dompurify";

import { useSelector } from "react-redux";
import { Button } from "antd";
import { authSelector, refreshOrgState } from "../../auth/authSlice";
import { formatDateOnRead } from "../../../app/utils/time/formatTimezoneOffset";
import { FormatToLocalCurrency } from "../../../app/utils/currency/formatToLocalCurrency";
import { checkUndefinedOrNull } from "../../../app/utils/models/checkers/checkUndefined";
import { NumberToPercentStr } from "../../../app/utils/Nifty/nifty";
import { i18n } from "../../../i18n";
import { invoices } from "../../../app/services/firestore/queries/invoiceQueries";
import { useFirestore } from "react-redux-firebase";
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';


const InvoicePdfViewer = ((props) => {

  // Create styles
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#E4E4E4'
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1
    },
    image: {
      width: 200,
    },
  });

  const invoice = props.invoice;
  const invoiceType = props.invoice.type;
  const emailSettings = props.emailSettings;
  const venues = props.venues;
  const receiveVenueID = props.invoice.receiveVenueQueryHook;
  const returnVenueID = props.invoice.returnVenueQueryHook;

  const [receiveVenue, setReceiveVenue] = useState(null);
  const [returnVenue, setReturnVenue] = useState(null);

  const { orgData } = useSelector(authSelector);
  const [invoiceHeaders, setInvoiceHeaders] = useState(null);
  const [subtotalSpacers, setSubtotalSpacers] = useState([]);

  useEffect(() => {
    const receiveVenue = venues.find((venue) => venue.id === receiveVenueID);
    const returnVenue = venues.find((venue) => venue.id === returnVenueID);
    setReceiveVenue(receiveVenue);
    setReturnVenue(returnVenue);

    const showItemImage = orgData.showInvoiceItemImg ?? false;
    const showItemName = orgData.showItemName ?? true;
    const showDescription = orgData.showDescription ?? true;
    const showRate = orgData.showRate ?? true;
    const showDuration = orgData.showDuration ?? true;
    const showTotal = orgData.showTotal ?? true;

    let descriptionPercent = 35;
    let itemNamePercent = 25;

    if (!showItemName) descriptionPercent += 25;
    if (!showDescription) itemNamePercent += 35;
    if (!showDuration && !showItemName) descriptionPercent += 10;
    if (!showDuration && showItemName) itemNamePercent += 10;
    if (!showItemImage && !showItemName) descriptionPercent += 10;
    if (!showItemImage && showItemName) itemNamePercent += 10;

    const headers = () => {
      const tds = [];
      var numberOfHeaders = 0;

      if (showItemImage) {
        tds.push(<td style={{ textAlign: "left", width: "10%" }}></td>);
        numberOfHeaders++;
      }

      if (showItemName) {
        tds.push(
          <td
            style={{
              textAlign: "left",
              width: itemNamePercent + "%",
              fontWeight: "bold",
            }}
          >
            Item
          </td>
        );
        numberOfHeaders++;
      }
      if (showDescription) {
        tds.push(
          <td
            style={{
              textAlign: "left",
              width: descriptionPercent + "%",
              fontWeight: "bold",
            }}
          >
            Description
          </td>
        );
        numberOfHeaders++;
      }

      tds.push(
        <td style={{ textAlign: "center", width: "5%", fontWeight: "bold" }}>
          Qty
        </td>
      );
      numberOfHeaders++;
      if (showRate) {
        tds.push(
          <td style={{ textAlign: "center", width: "15%", fontWeight: "bold" }}>
            Rate
          </td>
        );
        numberOfHeaders++;
      }
      if (showDuration && !invoice?.isSalesInvoice) {
        tds.push(
          <td style={{ textAlign: "center", width: "10%", fontWeight: "bold" }}>
            Duration
          </td>
        );
        numberOfHeaders++;
      }
      if (showTotal) {
        tds.push(
          <td style={{ textAlign: "right", width: "10%", fontWeight: "bold" }}>
            Total
          </td>
        );
        numberOfHeaders++;
      }

      const spacers = [];
      for (let i = 0; i < numberOfHeaders - 2; i++) {
        spacers.push(<td style={{ textAlign: "center" }}></td>);
      }
      setSubtotalSpacers(spacers);
      return <tr style={{ background: "#eee" }}>{tds.map((x) => x)}</tr>;
    };

    setInvoiceHeaders(headers);
  }, [orgData, invoice]);

  const formattedDate = (date) => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return date?.toLocaleDateString(undefined, options);
  };

  const hasTotalDiscount = invoice?.hasTotalDiscount;
  const totalDiscountType = invoice?.totalDiscountType;
  const totalDiscountPercent = invoice?.totalDiscountPercent; // Percentage of discount
  const totalDiscountNumber = invoice?.totalDiscountNumber; // Amount of discount
  const discountString = () => {
    if (hasTotalDiscount && hasTotalDiscount) {
      if (totalDiscountType === "number") {
        return FormatToLocalCurrency(
          checkUndefinedOrNull(totalDiscountNumber, 0),
          orgData.countryCode,
          orgData.languageCode,
          orgData.currencyCode
        );
      } else if (totalDiscountType === "percent") {
        return NumberToPercentStr(totalDiscountPercent, 2);
      }
      return "$0.00";
    } else {
      return "$0.00";
    }
  };

  const lineItems = invoice?.selectedItems.items.map((item) => {
    const showItemImage = orgData.showInvoiceItemImg ?? false;
    const showItemName = orgData.showItemName ?? true;
    const showDescription = orgData.showDescription ?? true;
    const showRate = orgData.showRate ?? true;
    const showDuration = orgData.showDuration ?? true;
    const showTotal = orgData.showTotal ?? true;

    const tds = [];

    if (item.rowType === "subtotal") {
      return (
        <tr style={{ borderBottom: "1px solid #eee" }}>
          <td style={{ textAlign: "left" }}>Subtotal</td>
          {subtotalSpacers && subtotalSpacers.map((s) => s)}
          <td style={{ textAlign: "right" }}>
            {FormatToLocalCurrency(
              item.rowTotal,
              orgData.countryCode,
              orgData.languageCode,
              orgData.currencyCode
            )}
          </td>
        </tr>
      );
    } else {
      if (showItemImage) {
        tds.push(
          <td className="title">
            {item.image && (
              <img
                id={item.id}
                src={item.image.downloadURL}
                style={{ width: "50px", height: "50px" }}
                alt="inventoryImage"
              />
            )}
          </td>
        );
      }

      if (showItemName) {
        tds.push(<td style={{ textAlign: "left" }}>{item.name}</td>);
      }
      if (showDescription) {
        tds.push(
          <td style={{ textAlign: "left" }}>{item.selectedDescription}</td>
        );
      }

      tds.push(<td style={{ textAlign: "center" }}>{item.selectedQty}</td>);

      if (showRate) {
        tds.push(
          <td style={{ textAlign: "center" }}>
            {FormatToLocalCurrency(
              item.selectedRateMeta.rate,
              orgData.countryCode,
              orgData.languageCode,
              orgData.currencyCode
            )}
          </td>
        );
      }
      if (showDuration && invoice && !invoice.isSalesInvoice) {
        tds.push(
          <td style={{ textAlign: "center" }}>
            {item.selectedDuration}{" "}
            {item.selectedDuration === 1
              ? item.selectedRateMeta.rateRange
              : `${item.selectedRateMeta.rateRange}s`}
          </td>
        );
      }
      if (showTotal) {
        tds.push(
          <td style={{ textAlign: "right" }}>
            {FormatToLocalCurrency(
              item.rowTotal,
              orgData.countryCode,
              orgData.languageCode,
              orgData.currencyCode
            )}
          </td>
        );
      }

      return (
        <tr style={{ borderBottom: "1px solid #eee" }}>{tds.map((x) => x)}</tr>
      );
    }
  });

  return (
    <Document>
    <Page size="A4" style={styles.page}>
        <table
          cellPadding={0}
          cellSpacing={0}
          style={{ width: "100%", lineHeight: "inherit", textAlign: "left" }}
        >
          {/* Company Logo and Invoice Data */}
          <tbody>
            <tr className="top">
              <td colSpan={2}>
                <table
                  style={{
                    width: "100%",
                    lineHeight: "inherit",
                    textAlign: "left",
                  }}
                >
                  <tbody>
                    <tr>
                      <td className="title" style={{ width: "30%" }}>
                        <img
                          id="logo"
                          src={orgData.logoUrl}
                          style={{ width: "100%", maxWidth: 200 }}
                          alt="logo"
                        />
                      </td>
                      <td style={{ width: "25%" }}></td>
                      <td style={{ textAlign: "-webkit-right", width: "45%" }}>
                        <tr>
                          <td style={{ textAlign: "left", width: "150px" }}>
                            {invoiceType.charAt(0).toUpperCase() +
                              invoiceType.slice(1).toLowerCase()}{" "}
                            #:
                          </td>
                          <td style={{ textAlign: "right", width: "255px" }}>
                            {invoice?.invoiceNumber}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ textAlign: "left", width: "150px" }}>
                            Updated:
                          </td>
                          <td style={{ textAlign: "right", width: "255px" }}>
                            {formattedDate(invoice?.updatedOn?.toDate())}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ textAlign: "left", width: "150px" }}>
                            Due:
                          </td>
                          <td style={{ textAlign: "right", width: "255px" }}>
                            {formattedDate(
                              invoice?.invoicePaymentDueDate?.toDate()
                            )}
                          </td>
                        </tr>
                        {invoice && !invoice.isSalesInvoice && (
                          <>
                            <tr>
                              <td style={{ textAlign: "left", width: "150px" }}>
                                Rental Start:
                              </td>
                              <td
                                style={{ textAlign: "right", width: "255px" }}
                              >
                                {formattedDate(
                                  invoice?.rentalDateStart?.toDate()
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td style={{ textAlign: "left", width: "150px" }}>
                                Rental End:
                              </td>
                              <td
                                style={{ textAlign: "right", width: "255px" }}
                              >
                                {formattedDate(
                                  invoice?.rentalDateEnd?.toDate()
                                )}
                              </td>
                            </tr>
                          </>
                        )}

                        {invoice?.eventDate && (
                          <tr>
                            <td style={{ textAlign: "left", width: "150px" }}>
                              Event Date:
                            </td>
                            <td style={{ textAlign: "right", width: "255px" }}>
                              {formattedDate(invoice?.eventDate?.toDate())}
                            </td>
                          </tr>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr className="information">
              <td colSpan={2}>
                <table
                  style={{
                    width: "100%",
                    lineHeight: "inherit",
                    textAlign: "left",
                  }}
                >
                  <br />
                  <tbody>
                    <tr>
                      <td>
                        {orgData.companyName}
                        <br />
                        {orgData.companyAddress}
                        <br />
                        {orgData.companyPhone}
                        <br />
                        {orgData.companyWebsite}
                        <br />
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {invoice?.customer?.customerDisplayName}
                        <br />
                        {invoice?.customer?.customerBillingAddressStr}
                        <br />
                        {invoice?.customer?.phone}
                        <br />
                        {invoice?.customer?.email}
                      </td>
                    </tr>
                    <br />
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>

        <table
          cellPadding={5}
          cellSpacing={0}
          borderSpacing={0}
          style={{ width: "100%", lineHeight: "inherit", textAlign: "left" }}
        >
          <tbody>{invoiceHeaders}</tbody>

          {lineItems}
        </table>
        <table
          cellPadding={0}
          cellSpacing={0}
          style={{ width: "100%", lineHeight: "inherit", textAlign: "left" }}
        >
          <tbody>
            {invoice && invoice.deliveryFee > 0 && (
              <tr className="deliveryFee">
                <td style={{ width: "55%" }}></td>
                <td style={{ paddingTop: 10, textAlign: "left" }}>
                  Delivery Fee:
                </td>
                <td style={{ paddingTop: 10, textAlign: "right" }}>
                  {FormatToLocalCurrency(
                    invoice?.deliveryFee,
                    orgData.countryCode,
                    orgData.languageCode,
                    orgData.currencyCode
                  )}
                </td>
              </tr>
            )}

            { hasTotalDiscount && (
              <tr className="discount">
                <td style={{ width: "55%" }}></td>
                <td style={{ textAlign: "left" }}>Discount:</td>
                <td style={{ textAlign: "right" }}>-{discountString()}</td>
              </tr>
            )}

            <tr className="subtotal">
              <td style={{ width: "50%" }}></td>
              <td style={{ textAlign: "left" }}>Subtotal:</td>
              <td style={{ textAlign: "right" }}>
                {FormatToLocalCurrency(
                  invoice?.subtotal,
                  orgData.countryCode,
                  orgData.languageCode,
                  orgData.currencyCode
                )}
              </td>
            </tr>
            <tr className="tax">
              <td style={{ width: "50%" }}></td>
              <td style={{ textAlign: "left" }}>Taxes:</td>
              <td style={{ textAlign: "right" }}>
                {FormatToLocalCurrency(
                  invoice?.taxAmt,
                  orgData.countryCode,
                  orgData.languageCode,
                  orgData.currencyCode
                )}
              </td>
            </tr>
            <tr className="total">
              <td style={{ width: "50%" }}></td>
              <td style={{ textAlign: "left" }}>Total:</td>
              <td style={{ textAlign: "right" }}>
                {FormatToLocalCurrency(
                  invoice?.total,
                  orgData.countryCode,
                  orgData.languageCode,
                  orgData.currencyCode
                )}
              </td>
            </tr>
            <tr className="paid" style={{}}>
              <td style={{ width: "50%" }}></td>
              <td style={{ textAlign: "left" }}>Amount Paid:</td>
              <td style={{ textAlign: "right" }}>
                {FormatToLocalCurrency(
                  invoice?.balanceFulfilled,
                  orgData.countryCode,
                  orgData.languageCode,
                  orgData.currencyCode
                )}
              </td>
            </tr>
            <tr className="balanceRemaining">
              <td style={{ width: "50%" }}></td>
              <td
                style={{
                  textAlign: "left",
                  borderTop: "1px solid #eee",
                }}
              >
                <strong>Remaining Balance:</strong>
              </td>
              <td
                style={{
                  textAlign: "right",
                  borderTop: "1px solid #eee",
                }}
              >
                <strong>
                  {FormatToLocalCurrency(
                    invoice?.balanceRemaining,
                    orgData.countryCode,
                    orgData.languageCode,
                    orgData.currencyCode
                  )}
                </strong>
              </td>
            </tr>
          </tbody>
        </table>
        <table
          cellPadding={0}
          cellSpacing={0}
          style={{
            marginTop: 50,
            width: "100%",
            lineHeight: "inherit",
            textAlign: "left",
          }}
        >
          {invoice && !invoice.isSalesInvoice && (
            <tbody>
              <tr className="heading">
                <td>Receive and Return Details:</td>
                <td />
              </tr>
              <br />
              <tr>
                <td>
                  <strong>Customer Receives Items Via:</strong>
                  &nbsp;
                  {invoice?.receiveMethod === "delivery"
                    ? "Delivery"
                    : "Customer Pickup"}
                </td>
                <td style={{ textAlign: "left" }}>
                  <strong>Items Returned Via:</strong>
                  &nbsp;{" "}
                  {invoice?.returnMethod === "onSite"
                    ? "Pickup"
                    : "Customer Dropoff"}
                </td>
              </tr>
              <br />
              <tr>
                <td>
                  <strong>Address:</strong>
                  <br />{" "}
                  {invoice?.receiveMethod === "delivery"
                    ? invoice?.deliveryCustomerShippingAddressStr
                    : orgData.companyAddress}
                </td>
                <td style={{ textAlign: "left" }}>
                  <strong>Address:</strong>
                  <br />{" "}
                  {invoice?.returnMethod === "onSite"
                    ? invoice?.onSiteCustomerShippingAddressStr
                    : orgData.companyAddress}
                </td>
              </tr>
              <br />
              <tr>
                <td>
                  {receiveVenueID && receiveVenue && (
                    <div>
                      <strong>Venue:</strong>
                      <br /> {receiveVenue.venueName}
                      <br /> Contact: {invoice?.deliveryCustomerDisplayName}
                    </div>
                  )}
                </td>
                <td style={{ textAlign: "left" }}>
                  {returnVenue && returnVenueID && (
                    <div>
                      <strong>Venue:</strong>
                      <br /> {returnVenue.venueName}
                      <br /> Contact: {invoice?.onSiteCustomerDisplayName}
                    </div>
                  )}
                </td>
              </tr>
              <br />
              <tr>
                <td>
                  <strong>Date/Time(Range):</strong>
                  <br />
                  {formattedDate(invoice?.rentalDateStart?.toDate())}
                  <br />
                  {formatReceiveTimeRange(invoice, orgData)}
                </td>
                <td style={{ textAlign: "left" }}>
                  <strong>Date/Time(Range):</strong>
                  <br />
                  {formattedDate(invoice?.rentalDateEnd?.toDate())}
                  <br />
                  {formatReturnTimeRange(invoice, orgData)}
                </td>
              </tr>
            </tbody>
          )}
        </table>
        <br />
        <p>NOTES:&nbsp;{invoice?.notes}</p>
      
      <p
        style={{
          maxWidth: "800px",
          margin: "auto",
          padding: "30px",
        }}
      >
        {
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                emailSettings?.termsConditions
                  .replaceAll("<p><br></p>", "<br>")
                  .replaceAll("<p>", "<br>")
                  .replaceAll("</p>", "")
              ),
            }}
          />
        }
      </p>

    </Page>
  </Document>

  );
});

function formatReceiveTimeRange(invoice, org) {
  const options = { hour: "numeric", minute: "numeric" };

  let timeRange = "";
  if (invoice?.hasSpecifiedReceiveTime) {
    if (invoice?.specifiedReceiveTime?.toDate()) {
      const startTime = invoice?.specifiedReceiveTime
        ?.toDate()
        ?.toLocaleTimeString(undefined, options);
      timeRange = startTime;

      if (invoice?.specifiedReceiveTimeEnd) {
        const endTime = invoice?.specifiedReceiveTimeEnd
          ?.toDate()
          ?.toLocaleTimeString(undefined, options);
        timeRange = timeRange + ` - ${endTime}`;
      }
    }

    return timeRange;
  } else {
    return i18n('common.noneSpecified');
  }
}

function formatReturnTimeRange(invoice, org) {
  const options = { hour: "numeric", minute: "numeric" };

  let timeRange = "";
  if (invoice?.hasSpecifiedReturnTime) {
    const startTime = invoice.specifiedReturnTime
      ?.toDate()
      ?.toLocaleTimeString(undefined, options);
    timeRange = startTime;

    if (invoice?.specifiedReturnTimeEnd) {
      const endTime = invoice?.specifiedReturnTimeEnd
        ?.toDate()
        ?.toLocaleTimeString(undefined, options);
      timeRange = timeRange + ` - ${endTime}`;
    }

    return timeRange;
  } else {
    return i18n('common.noneSpecified');
  }
}

export default InvoicePdfViewer;

const InvoiceBOM = React.forwardRef((props, ref) => {
  const invoice = props.invoice;
  const { orgData } = useSelector(authSelector);

  const formattedDate = (date) => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return date?.toLocaleDateString(undefined, options);
  };

  const lineItems = () => {
    const lines = [];
    const showItemName = orgData.showItemName ?? true;

    invoice?.selectedItems?.items.forEach((item) => {
      if (item?.bundleItems && item?.bundleItems?.length === 0) {
        lines.push(
          <tr style={{ borderBottom: "1px solid #eee" }}>
            <td style={{ textAlign: "left" }}>
              {showItemName ? item.name : item.selectedDescription}
            </td>
            <td style={{ textAlign: "right", paddingRight: "30px" }}>
              {item?.selectedQty}
            </td>
          </tr>
        );
      } else if (item.bundleItems) {
        const bundleItems = item.bundleItems;
        lines.push(
          <tr style={{ borderBottom: "1px solid #eee" }}>
            <td style={{ textAlign: "left" }}>
              {showItemName ? item.name : item.selectedDescription}
            </td>
            <td style={{ textAlign: "right", paddingRight: "30px" }}>
              {item.selectedQty}
            </td>
          </tr>
        );
        bundleItems.forEach((bi) => {
          lines.push(
            <tr style={{ borderBottom: "1px solid #eee" }}>
              <td
                style={{ textAlign: "left", paddingLeft: "25px" }}
              >{`- ${bi.bundleItemName}`}</td>
              <td
                style={{ textAlign: "right", paddingRight: "30px" }}
              >{`Total ${bi.bundleItemQty * item.selectedQty}    (${bi.bundleItemQty
                } per bundle)`}</td>
            </tr>
          );
        });
      }
    });

    return lines;
  };

  return (
    <div ref={ref}>
      <div
        style={{
          maxWidth: "800px",
          margin: "auto",
          padding: "30px",
          fontSize: "16px",
          lineHeight: "24px",
        }}
      >
        <table
          cellPadding={0}
          cellSpacing={0}
          style={{ width: "100%", lineHeight: "inherit", textAlign: "left" }}
        >
          {/* Company Logo and Invoice Data */}
          <tbody>
            <tr className="top">
              <td className="title" style={{ width: "30%" }}>
                <img
                  id="logo"
                  src={orgData.logoUrl}
                  style={{ width: "100%", maxWidth: 150 }}
                  alt="logo"
                />
              </td>
              <td colSpan={2}>
                <table
                  style={{
                    width: "100%",
                    lineHeight: "inherit",
                    textAlign: "left",
                  }}
                >
                  <tbody>
                    <tr>
                      <td style={{ textAlign: "right" }}>
                        {i18n("pdf.invoiceString")} #: {invoice?.invoiceNumber}
                        <br />
                        {i18n("invoices.rentalStart")}:{" "}
                        {formattedDate(invoice?.rentalDateStart?.toDate())}
                        <br />
                        {i18n("invoices.rentalEnd")}:{" "}
                        {formattedDate(invoice?.rentalDateEnd?.toDate())}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr className="information">
              <td colSpan={2}>
                <table
                  style={{
                    width: "100%",
                    lineHeight: "inherit",
                    textAlign: "left",
                  }}
                >
                  <br />
                  <tbody>
                    <tr>
                      <td style={{ textAlign: "right" }}>
                        {invoice?.customer?.customerDisplayName}
                        <br />
                        {invoice?.customer?.email}
                        <br />
                      </td>
                    </tr>
                    <br />
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>

        <table
          cellPadding={1}
          cellSpacing={1}
          style={{ width: "100%", lineHeight: "inherit", textAlign: "left" }}
        >
          <tbody>
            <tr className="heading" style={{ borderBottom: "1px solid #eee" }}>
              <td style={{ textAlign: "left", width: "55%" }}>
                {i18n("pdf.columnItem")}
              </td>
              <td
                style={{
                  textAlign: "right",
                  width: "45%",
                  paddingRight: "30px",
                }}
              >
                {i18n("pdf.colQty")}
              </td>
            </tr>
          </tbody>

          {lineItems()}
        </table>

        <table
          cellPadding={0}
          cellSpacing={0}
          style={{
            marginTop: 15,
            width: "100%",
            lineHeight: "inherit",
            textAlign: "left",
          }}
        >
          <tbody>
            <tr className="heading">
              <td>
                {/* Receive and Return Details: */}
                {i18n("pdf.receiveReturnDetails")}:
              </td>
              <td />
            </tr>
            <br />
            <tr>
              <td>
                <strong>
                  {/* Customer Receives Items Via: */}
                  {i18n("pdf.customerReceivesVia")}:
                </strong>
                &nbsp;
                {invoice?.receiveMethod === "delivery"
                  ? i18n("pdf.delivery")
                  : i18n("pdf.customerPickup")}
              </td>
              <td style={{ textAlign: "left" }}>
                {/* <strong>Items Returned Via:</strong> */}
                <strong>{i18n("pdf.returnedVia")}:</strong>
                &nbsp;{" "}
                {invoice?.returnMethod === "onSite"
                  ? i18n("pdf.pickup")
                  : i18n("pdf.customerDropOff")}
              </td>
            </tr>
            <br />
            <tr>
              <td>
                <strong>{i18n("pdf.address")}:</strong>
                <br />{" "}
                {invoice?.receiveMethod === "delivery"
                  ? invoice?.deliveryCustomerShippingAddressStr
                  : orgData.companyAddress}
              </td>
              <td style={{ textAlign: "left" }}>
                <strong>{i18n("pdf.address")}:</strong>
                <br />{" "}
                {invoice?.returnMethod === "onSite"
                  ? invoice?.onSiteCustomerShippingAddressStr
                  : orgData.companyAddress}
              </td>
            </tr>
            <br />
            <tr>
              <td>
                {/* <strong>Date/Time(Range):</strong> */}
                <strong>{i18n("pdf.timeRange")}:</strong>
                <br />
                {formattedDate(invoice?.rentalDateStart?.toDate())}
                <br />
                {formatReceiveTimeRange(invoice, orgData)}
              </td>
              <td style={{ textAlign: "left" }}>
                {/* <strong>Date/Time(Range):</strong> */}
                <strong>{i18n("pdf.timeRange")}:</strong>

                <br />
                {formattedDate(invoice?.rentalDateEnd?.toDate())}
                <br />
                {formatReturnTimeRange(invoice, orgData)}
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <p>
          {i18n("pdf.additionalNotes")}:&nbsp;{invoice?.notes}
        </p>
      </div>
    </div>
  );
});

export const PrintInvoiceComponent = ({ invoice }) => {
  let componentRef = useRef();

  const getPageMargins = () => {
    return `@page { margin: 50px 30px 30px 30px !important; }`;
  };

  return (
    <>
      <div>
        <style>{getPageMargins()}</style>

        <ReactToPrint
          trigger={() => <Button>Print Invoice</Button>}
          content={() => componentRef}
        />

        <div style={{ display: "none" }}>
          {invoice && (
            <InvoicePdfViewer
              invoice={invoice}
              ref={(el) => (componentRef = el)}
            />
          )}
        </div>
      </div>
    </>
  );
};

export const PrintInvoiceBOMComponent = ({ invoice, handleComplete }) => {

  const [invoiceToPrint, setInvoiceToPrint] = useState(null)
  const { orgData, fsOrgPrefix } = useSelector(authSelector);

  const firestore = useFirestore();

  let componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: invoice.invoiceNumber,
    onBeforeGetContent: 
      async () => {
        const res = await handleComplete({
          type: invoice.type,
          redirect: null,
        });
        const refreshedInvoice = await invoices(
          orgData?.orgTimezone
        ).fetchInvoice({ firestore }, fsOrgPrefix, {
          id: res?.invoiceId,
        });

        setInvoiceToPrint(refreshedInvoice);

        return;
      }
  })

  return (
    <>
      <div>

        <Button onClick={handlePrint}>Print Pull Sheet</Button>

        {/* component to be printed */}
        <div style={{ display: "none" }}>
          <InvoiceBOM invoice={invoiceToPrint} ref={componentRef} />
        </div>
      </div>
    </>
  );
};
