// @mui
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import "firebase/firestore";

import { useState } from "react";
import { useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";
import {
  Button,
  Card,
  TextField,
} from "@mui/material";

import { message } from "antd";
import { authSelector } from "../auth/authSlice";
import { V2_0_9, V2_1_0, V2_1_1, V2_1_2, V2_1_3, V2_1_4, V2_1_5, V2_1_6, V2_1_7, V2_1_8, V2_1_9, V2_2_0, V2_2_1, V2_2_2, V2_2_3, V2_2_4 } from "./logs";
import { V2_2_5 } from "./logs/v-2-2-5";


export default function ChangeLogView() {
  const { orgData, userData } = useSelector(authSelector);

  const firestore = useFirestore();

  const [feedbackText, setFeedbackText] = useState("");

  const handleFeedbackSubmit = () => {
    // Add the feedback text to the 'feedback' Firestore collection
    firestore
      .collection("feedback")
      .add({
        text: feedbackText,
        feature: "general",
        company: orgData.companyName,
        userEmail: userData.email,
        userName: userData.firstName,
        timestamp: firestore.FieldValue.serverTimestamp(),
      })
      .then(() => {
        message.success("Thank you for your feedback!");
        setFeedbackText("");
      })
      .catch((error) => {
        console.error("Error submitting feedback:", error);
      });
  };

  return (
    <Container maxWidth={"xl"} style={{ marginTop: "50px" }}>
      <Typography
        variant="h4"
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      >
        Change Log
      </Typography>

      <Card sx={{ padding: "12px" }}>
        <Typography variant="h4" component="h2">
          Have ideas for how to make the software even better?
        </Typography>
        <br />
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { width: "100%" },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="outlined-multiline-flexible"
            label="Tell us what would make your life easier!"
            multiline
            maxRows={4}
            value={feedbackText}
            onChange={(e) => setFeedbackText(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{ mt: 2 }}
            onClick={handleFeedbackSubmit} // Call the submit function when the button is clicked
          >
            Submit
          </Button>
        </Box>
      </Card>

      <br />
      <Grid xs={12} sm={12} md={4} lg={4}>
      <V2_2_5 />
      <br />
      <V2_2_4 />
        <br />
        <V2_2_3 />
        <br />
        <V2_2_2 />
        <br />
        <V2_2_1 />
        <br />
        <V2_2_0 />
        <br />
        <V2_1_9 />
        <br />
        <V2_1_8 />
        <br />
        <V2_1_7 />
        <br />
        <V2_1_6 />
        <br />
        <V2_1_5 />
        <br />
        <V2_1_4 />
        <br />
        <V2_1_3 />
        <br />
        <V2_1_2 />
        <br />
        <V2_1_1 />
        <br />
        <V2_1_0 />
        <br />
        <V2_0_9 />
      </Grid>

      <br />
    </Container>
  );
}
