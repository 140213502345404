import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, CircularProgress, Container, Divider, Stack, Table, Typography } from '@mui/material';
import { useFirestore } from 'react-redux-firebase';
import { OrganizationData } from '../../app/interfaces/organizationData';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { functions } from '../../app/config/firebase';
import { Payment } from '../../app/interfaces/payment';
import { FormatToLocalCurrency } from '../../app/utils/currency/formatToLocalCurrency';
// Define the prop type for orgData
interface SyncReportProps {
  orgData: OrganizationData;
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function PaymentsSyncReport({ orgData }: SyncReportProps) {
  const classes = useStyles();

  const firestore = useFirestore();

  const [badPayments, setBadPayments] = useState<Payment[] | null>(null);
  const [loadingInitial, setLoadingInitial] = useState(false);
  const [loadingInitialComplete, setLoadingInitialComplete] = useState(false);

  interface PaymentStatus {
    processing: boolean;
    success: boolean;
    error: {
      Message: string;
      Detail: string;
      Code: string;
    } | null;
  }

  const [processingStatus, setProcessingStatus] = useState<{
    [paymentId: string]: PaymentStatus;
  }>({});

  useEffect(() => {
    if (orgData) {
      setLoadingInitial(true);

      const loadPayments = async () => {
        const paymentsQuery = await firestore.collection('orgs')
        .doc(orgData.id)
        .collection('payments')
        .where('status', '==', 'complete')
        .where('qbId', '==', null).get();

        const payments = paymentsQuery.docs.map((snap) => snap.data() as Payment);

        setBadPayments(payments);
        setLoadingInitial(false);
        setLoadingInitialComplete(true);
      };

      loadPayments();
    }
  }, [orgData]);

  // Function to trigger cloud function for a specific payments
  const syncPaymentToQuickBooks = async (index: number) => {
    const payment = badPayments && badPayments[index];
    if (!payment) {
      return;
    }

    // Update the processing status for this payments to "Processing"
    setProcessingStatus((prevProcessingStatus) => ({
      ...prevProcessingStatus,
      [payment.id]: {
        processing: true,
        success: false,
        error: null,
      },
    }));

    // Call your cloud function here for the payments at the given index
    try {
      // Perform the cloud function call
      const call = functions.httpsCallable('manualPaymentSync');

      return call({
        orgId: orgData.id,
        paymentId: payment.id,
      })
        .then(async (res: any) => {
          console.log(res);
          const status = res.data.status;
          if (status === 200) {
            setProcessingStatus((prevProcessingStatus) => ({
              ...prevProcessingStatus,
              [payment.id]: {
                processing: false,
                success: true,
                error: null,
              },
            }));
          } else if (status === 500) {
            const errorDataArray = res.data.error.Fault.Error;
            const errorData = errorDataArray[0];

            setProcessingStatus((prevProcessingStatus) => ({
              ...prevProcessingStatus,
              [payment.id]: {
                processing: false,
                success: false,
                error: errorData || {
                  Message: 'Sync Failed',
                  Detail: 'Sorry, we do not know why it failed. Please contact support.',
                  Code: 'ERROR_CODE',
                },
              },
            }));
          }
        })
        .catch((err: any) => {
          console.log(err);
        });

      // If the cloud function call is successful, update the status to "Success"
    } catch (error) {
      // If there is an error, update the status to "Failure"
    }
  };

  // Sync all payments.
  const syncAllPayments = async () => {
    if (!badPayments || badPayments?.length === 0) {
      return;
    }

    const updatedProcessingStatus = { ...processingStatus }; // Create a copy to update

    for (let index = 0; index < badPayments.length; index++) {
      const payment = badPayments[index];

      // Update the processing status for this payment to indicate processing.
      updatedProcessingStatus[payment.id] = {
        processing: true,
        success: false,
        error: null,
      };
      setProcessingStatus(updatedProcessingStatus);
    }

    for (let index = 0; index < badPayments.length; index++) {
      await syncPaymentToQuickBooks(index);
    }
  };

  return (
    <>
      {loadingInitial && <CircularProgress />}

      {loadingInitialComplete && badPayments && (
        <>
          <Divider />
          <br />

          <Stack spacing={2} alignItems={'center'} direction={'row'}>
            <Typography color={'darkred'}>You have {badPayments?.length} payments that are not synced to your QuickBooks account.</Typography>

            <Button variant='contained' color='primary' onClick={() => syncAllPayments()}>
              Sync All
            </Button>
          </Stack>

          <br />

          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell>Payment Method</TableCell>
                  <TableCell align='right'>Amount</TableCell>
                  <TableCell align='right'>Customer</TableCell>
                  <TableCell align='right'>Sync</TableCell>
                  <TableCell align='right' sx={{ width: '50%' }}>Results</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {badPayments.map((row, index) => (
                  <TableRow key={row.id}>
                    <TableCell component='th' scope='row'>
                      {row.paymentMethod.toUpperCase()}
                    </TableCell>
                    <TableCell align='right'>{FormatToLocalCurrency(row.total / 100, orgData.countryCode, orgData.languageCode, orgData.currencyCode)}</TableCell>
                    <TableCell align='right'>{row.customer.customerDisplayName}</TableCell>

                    <TableCell align='right'>
                      {processingStatus[row.id]?.processing ? (
                        <Button disabled>Processing...</Button>
                      ) : processingStatus[row.id]?.success ? (
                        <Button disabled>Synced</Button>
                      ) : (
                        <Button variant='contained' color='primary' onClick={() => syncPaymentToQuickBooks(index)}>
                          Sync to QuickBooks
                        </Button>
                      )}
                    </TableCell>

                    <TableCell align='right'>
                      {processingStatus[row.id]?.error ? (
                        <Stack direction={'column'}>
                          <Typography color={'darkred'}>{processingStatus[row.id]?.error?.Message}</Typography>
                          <Typography color={'darkred'}>{processingStatus[row.id]?.error?.Detail}</Typography>
                        </Stack>
                      ) : processingStatus[row.id]?.success ? (
                        <Typography color={'darkgreen'}>SUCCESS!</Typography>
                      ) : (
                        <>--</>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
}
