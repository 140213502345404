import { checkUndefined } from "./checkUndefined";
import { firelikeId } from "./firelikeid";

export const CheckSelectedItem = (item) => {
  const checkBundleItem = (bundleItems) => {
    const items = checkUndefined(bundleItems, []);
    return items.map((i) => {
      return {
        bundleItemDesc: i?.bundleItemDesc ?? "",
        bundleItemId: i?.bundleItemId ?? "",
        bundleItemName: i?.bundleItemName ?? "",
        bundleItemQty: i?.bundleItemQty ?? 0,
        bundleItemTurnaround: i?.bundleItemTurnaround ?? null,
      };
    });
  };
  return {
    bundleItems: checkBundleItem(item?.bundleItems),
    categories: item?.categories ?? [],
    categoriesQueryHook: item?.categoriesQueryHook ?? [],
    id: item?.id ?? "",
    description: item?.description ?? "",
    image: item?.image ?? "",
    inheritParentTurnaround: item?.inheritParentTurnaround ?? false,
    isActive: item?.isActive ?? false,
    isAvailable: item?.isAvailable ?? false,
    isTaxable: item?.isTaxable ?? false,
    key: item?.key ?? 0,
    name: item?.name ?? "",
    notes: item?.notes ?? "",
    orgId: item?.orgId ?? "",
    qbId: item?.qbId ?? "",
    rates: item?.rates ?? [],
    rowTotal: item?.rowTotal ?? 0,
    rowType: item?.rowType ?? "",
    selectedDescription: item?.selectedDescription ?? "",
    uuid: item?.uuid ?? firelikeId(),
    selectedDuration: item?.selectedDuration ?? 0,
    selectedItemId: item?.selectedItemId ?? "",
    selectedQty: item?.selectedQty ?? 0,
    selectedRate: item?.selectedRate ?? "",
    selectedTaxable: item?.selectedTaxable ?? false,
    selectedRateMeta: checkRateMeta(item?.selectedRateMeta),
    weight: item?.weight,
  };
};

const checkRateMeta = (rate) => {
  return {
    rate: rate?.rate ?? 0,
    rateName: rate?.rateName ?? "Unknown Rate",
    rateRange: rate?.rateRange ?? null,
  };
};
