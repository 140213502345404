import AddInvoice from "./InvoiceDetail";

const CartInvoice = ({ history }) => {
  return (
    <div>
      <AddInvoice history={history} cartItems={history.location.state.detail} />
    </div>
  );
};

export default CartInvoice;
