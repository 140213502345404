import { Autocomplete, Button, CircularProgress, Stack, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { InventoryItem } from '../../../../app/interfaces/inventoryItem';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { functions } from '../../../../app/config/firebase';

const ItemAccountsSelector = ({ item, orgId }: { item: InventoryItem, orgId: string }) => {

  const firebase = useFirebase();
  const db = useFirestore();

  const [loading, setLoading] = useState(false);

  const [accounts, setAccounts] = useState([]);
  const [incomeAccount, setIncomeAccount] = useState(item.incomeAccount || null);
  const [expenseAccount, setExpenseAccount] = useState(item.expenseAccount || null);
  const [assetAccount, setAssetAccount] = useState(item.assetAccount || null);

  const [availableIncomeAccounts, setAvailableIncomeAccounts] = useState([]);
  //  For an inventory product, the expense account must have Account Type: 'Cost of Goods Sold' and Detail Type: 'Supplies and Materials.'
  const [availableExpenseAccounts, setAvailableExpenseAccounts] = useState([]);

  // The inventory asset account must have Account Type: 'Other Current Asset' and Detail Type: 'Inventory.'
  const [availableAssetAccounts, setAvailableAssetAccounts] = useState([]);

  const updateItem = async () => {
    console.log('updating item');
    const itemRef = db.doc(`orgs/${orgId}/inventory/${item.id}`);
    itemRef.update({
      incomeAccount: incomeAccount,
      expenseAccount: expenseAccount,
      assetAccount: assetAccount,
    });
  }


  // callable function to getch accounts
  useEffect(() => {

    setLoading(true);
    const fetchOptions = {
      orgId: orgId,
      objectType: "account",
      options: {
        fetchAll: true,
      },
    };
    const getAccounts = functions.httpsCallable("fetchQBOObjectsCallable");
    

    getAccounts(fetchOptions).then((res) => {

      if (res.data) {
        console.log(res.data);

        const assetAccounts = res.data.filter(
          (account: any) => (account.AccountType === "Other Current Asset" && account.AccountSubType === "Inventory")
        ).map((account: any) => {
          return {
            name: account.Name,
            id: account.Id,
          };
        });

        setAvailableAssetAccounts(assetAccounts);

        const expenseAccounts = res.data.filter(
          (account: any) => (account.AccountType === "Cost of Goods Sold" && account.AccountSubType === "SuppliesMaterialsCogs")
        ).map((account: any) => {
          return {
            name: account.Name,
            id: account.Id,
          };
        });

        setAvailableExpenseAccounts(expenseAccounts);

        // For an inventory product, the income account must have Account Type: 'Income' and Detail Type: 'Sales of Product Income.'
        if (item.type === "sale") {
          const incomeAccounts = res.data
            .filter(
              (account: any) =>
                account.AccountType === "Income" &&
                account.AccountSubType === "SalesOfProductIncome"
            )
            .map((account: any) => {
              return {
                name: account.Name,
                id: account.Id,
              };
            });

          setAvailableIncomeAccounts(incomeAccounts);
        } else {
          const incomeAccounts = res.data
            .filter((account: any) => account.AccountType === "Income")
            .map((account: any) => {
              return {
                name: account.Name,
                id: account.Id,
              };
            });

          setAvailableIncomeAccounts(incomeAccounts);
        }
        


        const accounts = res.data.map((account: any) => {
          return {
            name: account.Name,
            id: account.Id,
          };
        });
        setAccounts(accounts);
        setLoading(false);
      } else {
        console.log("no accounts found");
        setLoading(false);
      }
    });
  }, [firebase]);

  return (

    // need a spinner until accounts are fetched
    loading ? (
      <CircularProgress />
    ) : (
    <Stack gap={2}>
        {item.type === "sale" && (
          <React.Fragment>
            <Autocomplete
              options={availableExpenseAccounts}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} label="Expense Account" />
              )}
              value={expenseAccount}
              onChange={(event, newValue) => {
                if (newValue) {
                  setExpenseAccount(newValue);
                  item.expenseAccount = newValue;
                } else {
                  setExpenseAccount(null);
                  item.expenseAccount = null;
                }
              }}
            />

            <Autocomplete
              options={availableAssetAccounts}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} label="Asset Account" />
              )}
              value={assetAccount}
              onChange={(event, newValue) => {
                if (newValue) {
                  setAssetAccount(newValue);
                  item.assetAccount = newValue;
                } else {
                  setAssetAccount(null);
                  item.assetAccount = null;
                }
              }}
            />
          </React.Fragment>
        )}

        <Autocomplete
          options={availableIncomeAccounts}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField {...params} label="Income Account" />
          )}
          value={incomeAccount}
          onChange={(event, newValue) => {
            if (newValue) {
              setIncomeAccount(newValue);
              item.incomeAccount = newValue;
            } else {
              setIncomeAccount(null);
              item.incomeAccount = null;
            }
          }}
        />

        <Button variant="contained" onClick={updateItem}>Update Inventory Accounts</Button>
      </Stack>
    )
  )
}

export default ItemAccountsSelector