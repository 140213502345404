import { Typography } from "@mui/material";

export const MobileUpdates = () => {
  return (
    <Typography
      variant="body2"
      gutterBottom
      style={{ whiteSpace: "pre-wrap", fontWeight: "bold" }}
    >
      📱 Mobile App Updates
    </Typography>
  );
};
