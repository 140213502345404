import { Moment } from "moment";
import { createContext } from "react";

export interface EmbeddedCheckoutQuestion {
  question: string;
  isRequired: boolean;
  index: number;
}
export type EmbeddedCheckoutOrderTypes = "wishlist" | "order";
export interface EmbeddedCheckoutBlackoutDate {
  blackoutStart: Moment;
  blackoutEnd: Moment;
  willCallAllowed: boolean;
}

export interface EmbeddedSettingsState {
  orgId: string;
  checkoutQuestions: EmbeddedCheckoutQuestion[];
  primaryColor: null | string;
  currencySymbol: string;
  closedDateSelectedMessage: string;
  checkoutCompleteMessage: string;
  checkoutCompleteEmailMessage: string;

  checkoutSettings: {
    type: "wishlist" | "order";
    allowOverbooked: boolean;
  };
  blackoutDates: EmbeddedCheckoutBlackoutDate[];
  taxRateId: string | null;
  daysOfOperation: {
    Sunday: boolean;
    Monday: boolean;
    Tuesday: boolean;
    Wednesday: boolean;
    Thursday: boolean;
    Friday: boolean;
    Saturday: boolean;
  };
  maxCheckoutDays: number;
  displayInventoryAvailability: boolean;
  allowDeliveryPickup: boolean;
  allowWillCall: boolean;
  pageCount: number;
}

export interface EmbeddedSettingsActions {
  loadInitialState: (data: EmbeddedSettingsState) => void;
  state: EmbeddedSettingsState;
  checkoutQuestions: {
    create: () => void;
    remove: (props: any) => void;
    toggleRequired: (props: any) => void;
    moveUp: (props: any) => void;
    moveDown: (props: any) => void;
    update: (props: any) => void;
  };
  checkoutSettings: {
    changeType: (props: any) => void;
    toggleOverbooked: (props: any) => void;
  };
  blackoutDates: {
    onDateChange: (props: any) => void;
    addBlackoutDate: (props: any) => void;
    removeBlackoutDate: (props: any) => void;
  };
  changePrimaryColor: (newColor: string) => void;
  changeCurrencySymbol: (newSymbol: string) => void;
  taxRate: {
    setRate: (rateId: string) => void;
  };
  setDayOfOperation: (
    dayOfWeek:
      | "Sunday"
      | "Monday"
      | "Tuesday"
      | "Wednesday"
      | "Thursday"
      | "Friday"
      | "Saturday",
    enabled: boolean
  ) => void;
  setMaxCheckoutDays: (d: number) => void;
  setClosedDateSelectedMessage: (newMessage: string) => void;
  setCheckoutCompleteEmailMessage: (newMessage: string) => void;
  setCheckoutCompleteMessage: (newMessage: string) => void;
  setDisplayInventory: (display: boolean) => void;
  setAllowWillCall: (display: boolean) => void;
  setAllowDeliveryPickup: (display: boolean) => void;
  setPageCount: (d: number) => void;

}

export interface EmbeddedSettingsContextInterface {
  state: EmbeddedSettingsState;
  actions: EmbeddedSettingsActions;
  save: () => Promise<void>;
  busy: boolean;
}

export const defaultSettingsState: EmbeddedSettingsState = {
  orgId: "",
  checkoutQuestions: [],
  primaryColor: "000000",
  checkoutSettings: {
    type: "order",
    allowOverbooked: false,
  },
  blackoutDates: [],
  taxRateId: null,
  daysOfOperation: {
    Sunday: true,
    Monday: true,
    Tuesday: true,
    Wednesday: true,
    Thursday: true,
    Friday: true,
    Saturday: true,
  },
  maxCheckoutDays: 0,
  currencySymbol: "$",
  closedDateSelectedMessage: "",
  checkoutCompleteMessage: "",
  checkoutCompleteEmailMessage: "",
  displayInventoryAvailability: true,
  allowDeliveryPickup: true,
  allowWillCall: true,
  pageCount: 6,
};

const uselessDefaultContextValue: EmbeddedSettingsContextInterface = {
  state: defaultSettingsState,
  actions: {
    loadInitialState: function (_data: any): void {
      throw new Error("Function not implemented.");
    },
    state: defaultSettingsState,
    checkoutQuestions: {
      create: function (): void {
        throw new Error("Function not implemented.");
      },
      remove: function (_props: any): void {
        throw new Error("Function not implemented.");
      },
      toggleRequired: function (_props: any): void {
        throw new Error("Function not implemented.");
      },
      moveUp: function (_props: any): void {
        throw new Error("Function not implemented.");
      },
      moveDown: function (_props: any): void {
        throw new Error("Function not implemented.");
      },
      update: function (_props: any): void {
        throw new Error("Function not implemented.");
      },
    },
    checkoutSettings: {
      changeType: function (_props: any): void {
        throw new Error("Function not implemented.");
      },
      toggleOverbooked: function (_props: any): void {
        throw new Error("Function not implemented.");
      },
    },
    blackoutDates: {
      onDateChange: function (_props: any): void {
        throw new Error("Function not implemented.");
      },
      addBlackoutDate: function (_props: any): void {
        throw new Error("Function not implemented.");
      },
      removeBlackoutDate: function (_props: any): void {
        throw new Error("Function not implemented.");
      },
    },
    taxRate: {
      setRate: function (_rateId: string): void {
        throw new Error("Function not implemented.");
      },
    },
    setDayOfOperation: function (
      _dayOfWeek: "Sunday" |
        "Monday" |
        "Tuesday" |
        "Wednesday" |
        "Thursday" |
        "Friday" |
        "Saturday",
      _enabled: boolean
    ): void {
      throw new Error("Function not implemented.");
    },
    setMaxCheckoutDays: function (_d: number): void {
      throw new Error("Function not implemented.");
    },
    setPageCount: function (_d: number): void {
      throw new Error("Function not implemented.");
    },
    changePrimaryColor: function (_newColor: string): void {
      throw new Error("Function not implemented.");
    },
    changeCurrencySymbol: function (_newSymbol: string): void {
      throw new Error("Function not implemented.");
    },
    setClosedDateSelectedMessage: function (_newMessage: string): void {
      throw new Error("Function not implemented.");
    },
    setCheckoutCompleteEmailMessage: function (_newMessage: string): void {
      throw new Error("Function not implemented.");
    }, 
    setCheckoutCompleteMessage: function (_newMessage: string): void {
      throw new Error("Function not implemented.");
    },
    setDisplayInventory: function (_display: boolean): void {
      throw new Error("Function not implemented.");
    },
    setAllowWillCall: function (_display: boolean): void {
      throw new Error("Function not implemented.");
    },
    setAllowDeliveryPickup: function (_display: boolean): void {
      throw new Error("Function not implemented.");
    },
  },
  save: function (): Promise<void> {
    throw new Error("Function not implemented.");
  },
  busy: false,
};

export const AdminEmbeddedContext = createContext<EmbeddedSettingsContextInterface>(uselessDefaultContextValue);
