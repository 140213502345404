// @mui
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import "firebase/firestore";

import { Card, Link, Stack } from "@mui/material";

export const V2_1_2 = () => {
  return (
    <Card>
    <Box sx={{ p: 2, display: "flex" }}>
      <Stack spacing={0.5}>
        <Typography fontWeight={700}>
          V2.1.2 - Released 10-11-2023
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          style={{ whiteSpace: "pre-wrap" }}
        >
          {`
Whats new?

• This change log!
We hope this page will help you keep up with all the updates we have going on at Adelie. And inspire you to think of more ways we can make this the best software for rental companies!

Bug fixes
• A couple updates ago we changed the behavior of the invoice saving. We heard from a lot of you it was annoying that saving the invoice always kicked you back to the main invoices list. So we changed that.
However, in doing so we introduced a couple little bugs... Oops. People were experiencing some bugs when saving (especially brand new orders that had not been saved yet).
This update should fix those bugs.

NOTE: Some additional changes were made to the order and saving flow. We think this is an improvement but please let us know what you think. Now, you can only perform email actions once it has been saved once.
You can only go to the payment page once an order has been saved as an Invoice.

`}
        </Typography>
      </Stack>
    </Box>
  </Card>
  );
};
