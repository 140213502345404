import React from 'react';
import { Card, List, ListItem, ListItemText, Chip, Box, Typography, Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { ROUTE_EDIT_INVOICE } from '../../../../app/routes';
import { COLOR_BLUE_0, COLOR_LIGHT_GRAY, COLOR_DARK_BG_0 } from '../../../../app/ui/colorConstants';
import { ROW_TYPE_SUBTOTAL } from '../../../../app/utils/models/modelConstants/modelConstants';

const SalesInvoices = ({ state, actions }) => {
  return (
    <Card variant="outlined" sx={{ p: 2, mb: 2 }}>
      <List subheader={<Typography variant="h6">Invoices</Typography>}>
        {state.filteredInvoices.map((item) => (
          <ListItem
            key={item.id}
            secondaryAction={
              <Box display="flex" alignItems="center">
                <Typography variant="body2" sx={{ fontWeight: 'bold', mr: 1 }}>
                  Invoice Total:
                </Typography>
                <NumberFormat
                  value={item?.total?.toFixed(2)}
                  displayType={"text"}
                  sx={{fontWeight: "bold"}}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </Box>
            }
          >
            <ListItemText
              primary={
                <MuiLink
                  component={Link}
                  to={`${ROUTE_EDIT_INVOICE}${item?.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ fontWeight: 'bold' }}
                >
                  #{item?.invoiceNumber}: {item?.customer?.customerDisplayName}
                </MuiLink>
              }
              secondary={
                <Box sx={{ display: 'flex', flexDirection: "column", flexWrap: 'wrap' }}>
                  {item?.selectedItems?.items?.map((i) => {
                    if (i?.rowType === ROW_TYPE_SUBTOTAL) return null;
                    const isSelected = state.selectedInvItem === i?.id;
                    return (
                      <Chip
                        key={i.id}
                        label={`(${i?.selectedQty}) ${i?.name}`}
                        sx={{
                          m: 0.25,
                          maxWidth: 'fit-content',
                          bgcolor: isSelected ? COLOR_BLUE_0 : COLOR_LIGHT_GRAY,
                          color: isSelected ? '#FFF' : COLOR_DARK_BG_0,
                          fontWeight: 'bold',
                        }}
                      />
                    );
                  })}
                </Box>
              }
            />
          </ListItem>
        ))}
      </List>
    </Card>
  );
};

export default SalesInvoices;
