import React, { useEffect, useState } from "react";
import { Modal, Button, Radio, Form, Input, Spin } from "antd";
import { MailFilled, PhoneFilled } from "@ant-design/icons";
import {
  USERTYPE_ADMIN,
  USERTYPE_OFFICE,
  USERTYPE_WAREHOUSE,
} from "../../../app/utils/models/modelConstants";
import TextSubtitle from "../../../app/system-components/typography/text/TextSubtitle";
import { Spinner } from "../../../app/ui/spinner";
import { useFirebase } from "react-redux-firebase";
import { configureNewUser } from "../../../app/utils/models/configure/configureNewUser";
import { useSelector } from "react-redux";
import { authSelector } from "../../auth/authSlice";
import { createNewOrgMember } from "../../../app/services/cloud-functions/createNewOrgMember";
import notificationConfirm from "../../../app/system-components/toasters/notificationConfirm";
import notificationError from "../../../app/system-components/toasters/notificationError";

const CreateUserModal = ({ visible, onCancel, onComplete }) => {
  const [saving, setSaving] = useState(false);
  const [form] = Form.useForm();

  const { userData, orgData } = useSelector(authSelector);
  const firebase = useFirebase();

  useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  }, [visible]);
  const handleFinish = () => {
    if (!userData || !orgData) return;

    form.validateFields().then(() => {
      setSaving(true);
      const payload = configureNewUser(
        form.getFieldsValue(),
        userData.id,
        orgData.id
      );
      try {
        createNewOrgMember({ firebase }, payload).then(() => {
          setSaving(false);
          onComplete();
          notificationConfirm("User Created", "");
        });
      } catch (err) {
        console.log(err);
        setSaving(false);
        //err toast
        notificationError("Something went wrong", "Please try again later");
      }
    });
  };

  return (
    <Modal
      destroyOnClose
      width={600}
       open={visible}
      closeIcon={<></>}
      title="Create User"
      footer={[
        <Button key="cancel" onClick={() => onCancel()} disabled={saving}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => handleFinish()}
          disabled={saving}
        >
          Create User
        </Button>,
      ]}
    >
      <div>
        <Spin spinning={saving} indicator={Spinner}>
          <Form
            form={form}
            onFinish={handleFinish}
            layout="vertical"
            requiredMark={false}
          >
            <Form.Item
              name="permissions"
              label={<TextSubtitle text={"USER PERMISSIONS"} />}
              initialValue={"admin"}
            >
              <Radio.Group buttonStyle="solid">
                <Radio.Button value={USERTYPE_ADMIN}>Admin</Radio.Button>
                <Radio.Button value={USERTYPE_OFFICE}>Office</Radio.Button>
                <Radio.Button value={USERTYPE_WAREHOUSE}>
                  Warehouse
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
            <br />
            <div style={{ paddingBottom: "8px" }}>
              <TextSubtitle text={"USER DETAILS"} />
            </div>
            <Form.Item
              name="firstName"
              rules={[
                {
                  required: true,
                  message: "First name required",
                },
              ]}
            >
              <Input placeholder="First Name" />
            </Form.Item>
            <Form.Item
              name="lastName"
              rules={[
                {
                  required: true,
                  message: "Last name required",
                },
              ]}
            >
              <Input placeholder="Last Name" />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message: "Enter a valid email",
                },
                {
                  required: true,
                  message: "Email required",
                },
              ]}
            >
              <Input
                placeholder="Email"
                suffix={
                  <MailFilled style={{ opacity: "0.2", marginRight: "2px" }} />
                }
              />
            </Form.Item>
            <Form.Item
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Phone number required",
                },
              ]}
            >
              <Input
                placeholder="Phone"
                suffix={
                  <PhoneFilled style={{ opacity: "0.2", marginRight: "2px" }} />
                }
              />
            </Form.Item>
            <br />
            <Form.Item
              name="tempPassword"
              label={<TextSubtitle text={"TEMPORARY PASSWORD"} />}
              rules={[
                {
                  required: true,
                  message: "Temporary password required",
                },
              ]}
            >
              <Input placeholder="Must be 6 characters" />
            </Form.Item>
          </Form>
        </Spin>
      </div>
    </Modal>
  );
};

export default CreateUserModal;
