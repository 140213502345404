import React, { useEffect, useState } from 'react';
import { Affix, Button, Form, Spin, Card, PageHeader } from 'antd';
import { Spinner } from '../../../../app/ui/spinner';
import NewItemDetails from '../InventoryItemForm/NewItemDetails';

import { useSelector } from 'react-redux';
import { authSelector } from '../../../auth/authSlice';
import { useFirestore } from 'react-redux-firebase';
import { categoryQueries } from '../../../../app/services/firestore/queries/categoryQueries';
import { sortBy } from '../../../../app/utils/sorters/sortBy';
import { configureInventory } from '../../../../app/utils/models/configure/configureInventory';
import { inventoryWrites } from '../../../../app/services/firestore/writes/inventoryWrites';
import notificationConfirm from '../../../../app/system-components/toasters/notificationConfirm';
import notificationError from '../../../../app/system-components/toasters/notificationError';
import TextBody from '../../../../app/system-components/typography/text/TextBody';
import {
  INV_BUNDLE_ITEM_DESC,
  INV_BUNDLE_ITEM_ID,
  INV_BUNDLE_ITEM_NAME,
  INV_BUNDLE_ITEM_QTY,
  INV_BUNDLE_ITEM_TURNAROUND,
  INV_ITEM_EMBEDDED_SHOP_RATE_ID,
} from '../../../../app/utils/models/modelConstants/modelConstants';
import { checkUndefinedOrNull } from '../../../../app/utils/models/checkers/checkUndefined';
import { InventoryItemFormData } from '../../../../app/interfaces/inventoryItem';
import { InventoryCategory } from '../../../../app/interfaces/inventoryCategory';
import MaintenanceDetails from '../InventoryItemForm/MaintenanceDetails';
import NewItemAdditional from '../InventoryItemForm/NewItemAdditional';

const AddServiceItem = () => {
  const { orgData, userData, fsOrgPrefix } = useSelector(authSelector);
  const [saving, setSaving] = useState(false);
  const [itemImg, setItemImg] = useState<{
    downloadURL: string;
    fileName: string;
  } | null>(null);
  const [categories, setCategories] = useState<InventoryCategory[]>([]);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [duplicated, setDuplicated] = useState(false);
  const [storedBundleSelections, setStoredBundleSelections] = useState<any[]>([]);

  const [showMaintenanceDetails, setShowMaintenanceDetails] = useState(false);

  const toggleMaintenanceSwitch = (e: boolean) => {
    console.log(e);
    setShowMaintenanceDetails(e);
  };

  const [form] = Form.useForm();
  const firestore = useFirestore();

  useEffect(() => {
    fsOrgPrefix && loadCategories();
  }, [fsOrgPrefix]);

  const loadCategories = async () => {
    setLoadingCategories(true);
    const querier = categoryQueries({ firestore }, fsOrgPrefix);
    if (querier) {
      const cats = await querier.fetchCategories();
      setCategories(sortBy(cats, 'name'));
    }
    setLoadingCategories(false);
  };

  const onComplete = () => {
    console.log('complete');
  };

  const handleFinish = ({ isDuplicate }: { isDuplicate: boolean }) => {
    // TODO: handle writing logic
    console.log(form.getFieldValue(INV_ITEM_EMBEDDED_SHOP_RATE_ID));

    form.validateFields().then(() => {
      setSaving(true);
      const formValues: Partial<InventoryItemFormData> = form.getFieldsValue();
      const {
        // imageUploader,
        categoriesQueryHook,
        initialDate,
        bundleItems,
        ...rest
      } = formValues;

      // get other category data to save
      const selectedCategories =
        categoriesQueryHook &&
        categoriesQueryHook.map((h) => {
          const cat = categories.find((c) => c.id === h);
          const { id, orgId, name, description } = cat!; // could this ever be null?
          return {
            id: id,
            orgId: orgId,
            name: name,
            description: description,
          };
        });
      // map storedBundleSelections
      const configureBundles = () => {
        let items: any[] = [];
        let bundleItemsQueryHook: string[] = [];
        if (bundleItems) {
          bundleItems.map((i) => {
            const invItem = storedBundleSelections.find((s) => s.id === i[INV_BUNDLE_ITEM_ID]);
            const { name, id, description, turnaround } = invItem;
            bundleItemsQueryHook.push(id);
            items.push({
              [INV_BUNDLE_ITEM_ID]: id,
              [INV_BUNDLE_ITEM_DESC]: description,
              [INV_BUNDLE_ITEM_NAME]: name,
              [INV_BUNDLE_ITEM_QTY]: i[INV_BUNDLE_ITEM_QTY],
              [INV_BUNDLE_ITEM_TURNAROUND]: checkUndefinedOrNull(turnaround, 0),
            });
          });
        }
        return {
          bundleItems: items,
          bundleItemsQueryHook: bundleItemsQueryHook,
        };
      };

      const values: Partial<InventoryItemFormData> = {
        categories: selectedCategories, // add categories
        categoriesQueryHook: categoriesQueryHook,
        image: itemImg,
        initialDate: initialDate ? initialDate.toDate() : null,
        bundleItems: configureBundles().bundleItems,
        bundleItemsQueryHook: configureBundles().bundleItemsQueryHook,
        embeddedShopRateId: form.getFieldValue(INV_ITEM_EMBEDDED_SHOP_RATE_ID),
        ...rest,
      };

      const payload = configureInventory().newInventoryItem(values, userData.id, orgData);
      if (payload) {
        const writer = inventoryWrites({ firestore }, fsOrgPrefix);
        if (writer) {
          writer
            .createInventory(payload)
            .then(() => {
              setSaving(false);
              notificationConfirm('Inventory item created');
              if (isDuplicate) {
                form.setFieldsValue({
                  name: `${payload.name} Copy`,
                });
              } else {
                setStoredBundleSelections([]);
                form.setFieldsValue({ rates: [] });
                form.resetFields();
                setItemImg(null);
                onComplete();
              }
            })
            .catch((err) => {
              console.log(err);
              setSaving(false);
              notificationError('Something went wrong', 'Please try again later');
            });
        }
      }
    });
  };

  const onImageUrl = (downloadURL: string, fileName: string) => {
    setItemImg({
      downloadURL: downloadURL,
      fileName: fileName,
    });
  };
  return (
    <div>
      <Affix>
        <PageHeader
          onBack={() => window.history.back()}
          className='PageHeader'
          title='Add New Inventory Item'
          extra={[
            <Button
              onClick={() => {
                setDuplicated(true);
                handleFinish({ isDuplicate: true });
              }}
            >
              Add & Create Duplicate
            </Button>,
            <Button
              type='primary'
              onClick={() => {
                setDuplicated(false);
                handleFinish({ isDuplicate: false });
              }}
            >
              Add Item
            </Button>,
          ]}
        />
      </Affix>
      <div className='list-margin-top' style={{ margin: '68px 12px' }}>
        <Spin spinning={saving || loadingCategories} indicator={Spinner}>
          <Form form={form} layout='vertical' requiredMark={false}>
            <div className='add-inventory' style={{ width: '800px' }}>
              <Card size='small' title={<TextBody style={{ fontWeight: 'bold' }} text={'General'} />}>
                <NewItemDetails
                  categories={categories}
                  form={form}
                  duplicated={duplicated}
                  storedSelections={storedBundleSelections}
                  setStoredSelections={setStoredBundleSelections}
                  onImageUrl={onImageUrl}
                  removeImg={() => setItemImg(null)}
                  defaultImg={itemImg}
                  saving={saving}
                />
              </Card>

              <br />
              <NewItemAdditional
                form={form}
                showMaintenanceSwitch={true}
                showWeightInput={orgData && orgData.trackInventoryWeight}
                showMaintenanceSwitchToggled={toggleMaintenanceSwitch}
              />

              <br />
              {orgData && orgData.allowInventoryMaintenance && showMaintenanceDetails && <MaintenanceDetails form={form} />}
            </div>
          </Form>
        </Spin>
      </div>
    </div>
  );
};

export default AddServiceItem;
