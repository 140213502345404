import React, { useContext } from "react";
import { Card, Input, Space, Switch } from "antd";
import { AdminEmbeddedContext } from "../adminEmbeddedContext";
import TextSubtitle from "../../../app/system-components/typography/text/TextSubtitle";
import TextArea from "antd/lib/input/TextArea";

export const EmbeddedEmailSettings = () => {
  const { actions, state } = useContext(AdminEmbeddedContext);

  return (
    <Card
      size={"small"}
      title={"After Checkout Settings (Only Applicable To Wishlist Orders)"}
      style={{ marginTop: "24px" }}
    >
      <Space direction="vertical">
        <div>
          <TextSubtitle text={"MESSAGE TO DISPLAY AFTER CHECKOUT"} />
          <br />
          <TextArea
            value={state.checkoutCompleteMessage}
            style={{ width: 400 }}
            autoSize={{ minRows: 2, maxRows: 10 }}
            onChange={(v) => actions.setCheckoutCompleteMessage(v.target.value)}
          />
        </div>

        <br />

        <div>
          <TextSubtitle text={"MESSAGE TO SEND IN EMAIL AFTER CHECKOUT"} />
          <br />
          <TextSubtitle text={"Use shortcode {{COMPANY_NAME}} where you want your company name to display."} />

          <br />
          <TextArea
            value={state.checkoutCompleteEmailMessage}
            autoSize={{ minRows: 2, maxRows: 10 }}
            style={{ width: 400 }}
            onChange={(v) =>
              actions.setCheckoutCompleteEmailMessage(v.target.value)
            }
          />
        </div>

        <br />
        <br />
        <br />
        <br />
        <br />
      </Space>
    </Card>
  );
};
