import { Box } from '@mui/material'
import React from 'react'

interface LogImageProps {
    src: string;
    alt?: string;
    height?: number;
  }

const LogImage: React.FC<LogImageProps> = ({ src, alt = "change log image", height = 400 }) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
    <img
      alt={alt}
      src={src}
      style={{
        height: height,
        width: "auto",
      }}
    />
  </Box>
  )
}

export default LogImage