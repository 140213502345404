import Box from '@mui/material/Box';
import { alpha } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useSettingsContext } from '../../app/settings';
import { Button, Dialog, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import { useState } from 'react';
import CustomerSyncReport from './CustomersSyncReport';
import InventorySyncReport from './InventorySyncReport';
import InvoicesSyncReport from './InvoicesSyncReport';
import { functions } from '../../app/config/firebase';
import { LoadingButton } from '@mui/lab';
import PaymentsSyncReport from './PaymentsSyncReport';
import { useSelector } from 'react-redux';
import { authSelector } from '../auth/authSlice';

// ----------------------------------------------------------------------

export default function QboSyncTools() {
  const settings = useSettingsContext();
  const { orgData } = useSelector(authSelector);

  const [openCustomerSyncDialog, setOpenCustomerSyncDialog] = useState(false);
  const [openInventorySyncDialog, setOpenInventorySyncDialog] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [runningReport, setRunningReport] = useState(false);
  const [syncingCustomersFromQbo, setSyncingCustomersFromQbo] = useState(false);
  const [syncingInventoryFromQbo, setSyncingInventoryFromQbo] = useState(false);
  const [qboCustomerSyncResult, setQboCustomerSyncResult]: any = useState(null)
  const [qboInventorySyncResult, setQboInventorySyncResult]: any = useState(null)

  // CUSTOMER SYNC
  const handleOpenCustomerSyncDialog = () => {
    setOpenCustomerSyncDialog(true);
  };
  const handleConfirmCustomerSyncDialog = async () => {
    const call = functions.httpsCallable("syncCustomersFromQBO");

    setSyncingCustomersFromQbo(true);
    setQboCustomerSyncResult(null)
    setOpenCustomerSyncDialog(false);

    try {
      const res = await call({
        orgId: orgData.id,
      });

      setQboCustomerSyncResult(res.data)
    } finally {
      setSyncingCustomersFromQbo(false);
    }
  };

  const handleCloseCustomerSyncDialog =  () => {
    setOpenCustomerSyncDialog(false);
  };


  // INVENTORY SYNC
  const handleOpenInventorySyncDialog = () => {
    setOpenInventorySyncDialog(true);
  };
  const handleConfirmInventorySyncDialog = async () => {

    const call = functions.httpsCallable("syncInventoryFromQBO");

    setSyncingInventoryFromQbo(true);
    setQboInventorySyncResult(null)
    setOpenInventorySyncDialog(false);

    try {
      const res = await call({
        orgId: orgData.id,
      });

      setQboInventorySyncResult(res.data)
    }
    catch (error) {
      console.log(error)
    } finally {
      setSyncingInventoryFromQbo(false);
    }
  };
  const handleCloseInventorySyncDialog = () => {
    setOpenInventorySyncDialog(false);
  };

  const beginReport = () => {
    setRunningReport(true);
  };
  const clearReport = () => {
    setRunningReport(false);
  };

  return (
    <Container maxWidth={settings.themeStretch ? false : "xl"}>
      <Typography variant="h4"> QuickBooks Sync Tools </Typography>

      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <Box
              sx={{
                mt: 5,
                p: 2,
                width: "100%", // Make the box fill horizontal space
                borderRadius: 2,
                bgcolor: (theme) => alpha(theme.palette.grey[500], 0.04),
                border: (theme) => `dashed 1px ${theme.palette.divider}`,
              }}
            >
           
              <Typography
                sx={{ typography: { xs: "body2", xl: "body1" } }}
                variant="body1"
                gutterBottom
              >
                Easily sync your QuickBooks Inventory with Adelie Logistics by
                clicking the button below. This integration allows for the
                effortless transfer of inventory data from QuickBooks to Adelie
                Logistics, ensuring that you can efficiently manage your
                inventory within your Adelie account.
              </Typography>
              <br />
              <LoadingButton
                loading={syncingInventoryFromQbo}
                disabled={syncingInventoryFromQbo}
                variant="contained"
                color="primary"
                onClick={handleOpenInventorySyncDialog}
              >
                Sync Inventory From QuickBooks
              </LoadingButton>

              {qboInventorySyncResult && (
                <Box
                  sx={{
                    mt: 2,
                    p: 2,
                    width: "100%", // Make the box fill horizontal space
                    borderRadius: 2,
                    bgcolor: (theme) =>
                      qboInventorySyncResult.status === 200
                        ? alpha(theme.palette.success.light, 0.25)
                        : alpha(theme.palette.error.light, 0.25),
                    border: (theme) => `dashed 1px ${theme.palette.divider}`,
                  }}
                >
                  <Typography
                    sx={{ typography: { xs: "body2", xl: "body1" } }}
                    variant="body1"
                    gutterBottom
                  >
                    {qboInventorySyncResult.msg}
                  </Typography>
                </Box>
              )}

              <Dialog
                open={openInventorySyncDialog}
                onClose={handleCloseInventorySyncDialog}
                aria-labelledby="confirmation-dialog-title"
              >
                <DialogTitle id="confirmation-dialog-title">
                  Confirm Sync
                </DialogTitle>
                <div style={{ padding: "16px" }}>
                  <Typography variant="body1">
                    Are you sure you want to sync your QuickBooks Inventory with
                    Adelie Logistics? This may take a few minutes.
                  </Typography>
                  <br />
                  <LoadingButton
                    loading={syncingInventoryFromQbo}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      handleConfirmInventorySyncDialog();
                    }}
                  >
                    Confirm
                  </LoadingButton>
                </div>
              </Dialog>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <Box
              sx={{
                mt: 5,
                p: 2,
                width: "100%", // Make the box fill horizontal space
                borderRadius: 2,
                bgcolor: (theme) => alpha(theme.palette.grey[500], 0.04),
                border: (theme) => `dashed 1px ${theme.palette.divider}`,
              }}
            >
              <Typography
                sx={{ typography: { xs: "body2", xl: "body1" } }}
                variant="body1"
                gutterBottom
              >
                Easily sync your QuickBooks Customers with Adelie Logistics by
                clicking the button below. This integration allows for the
                effortless transfer of customers data from QuickBooks to Adelie
                Logistics, ensuring that you can efficiently manage your
                customers within your Adelie account.
              </Typography>
              <br />
              <LoadingButton
                loading={syncingCustomersFromQbo}
                disabled={syncingCustomersFromQbo}
                variant="contained"
                color="primary"
                onClick={handleOpenCustomerSyncDialog}
              >
                Sync Customers From QuickBooks
              </LoadingButton>

              {qboCustomerSyncResult && (
                <Box
                  sx={{
                    mt: 2,
                    p: 2,
                    width: "100%", // Make the box fill horizontal space
                    borderRadius: 2,
                    bgcolor: (theme) =>
                      qboCustomerSyncResult.status === 200
                        ? alpha(theme.palette.success.light, 0.25)
                        : alpha(theme.palette.error.light, 0.25),
                    border: (theme) => `dashed 1px ${theme.palette.divider}`,
                  }}
                >
                  <Typography
                    sx={{ typography: { xs: "body2", xl: "body1" } }}
                    variant="body1"
                    gutterBottom
                  >
                    {qboCustomerSyncResult.msg}
                  </Typography>
                </Box>
              )}

              <Dialog
                open={openCustomerSyncDialog}
                onClose={handleCloseCustomerSyncDialog}
                aria-labelledby="confirmation-dialog-title"
              >
                <DialogTitle id="confirmation-dialog-title">
                  Confirm Sync
                </DialogTitle>
                <div style={{ padding: "16px" }}>
                  <Typography variant="body2">
                    Are you sure you want to sync your QuickBooks Customers with
                    Adelie Logistics? This may take a few minutes.
                  </Typography>
                  <br />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      handleConfirmCustomerSyncDialog();
                    }}
                  >
                    Confirm
                  </Button>
                </div>
              </Dialog>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box
              sx={{
                p: 2,
                width: "100%",
                borderRadius: 2,
                bgcolor: (theme) => alpha(theme.palette.grey[500], 0.04),
                border: (theme) => `dashed 1px ${theme.palette.divider}`,
              }}
            >
              <Typography
                sx={{ typography: { xs: "body2", xl: "body1" } }}
                variant="body1"
                gutterBottom
              >
                Sometimes syncing objects from Adelie to QuickBooks fails.
                Sometimes it happens because of duplicate naming, or a space at
                the end of a name. We know this can be super frustrating and
                this tool is meant to help resolve those issues and provide you
                with better transparency and action items to remedy any sync
                issues. You can select to run a report on your Adelie Customers,
                Inventory, and Invoices.
              </Typography>
              <Typography
                sx={{ typography: { xs: "body2", xl: "body1" } }}
                variant="body1"
                gutterBottom
              >
                *Note that customers and inventory directly affect invoices, so
                we recommend running reports on customers and inventory first.
              </Typography>
              <br />

              <Stack
                direction="row"
                spacing={2}
                justifyContent={"space-between"}
              >
                <Stack direction="row" spacing={2}>
                  <FormControl>
                    <InputLabel>Select Option</InputLabel>
                    <Select
                      disabled={runningReport}
                      style={{ width: "200px" }}
                      value={selectedOption}
                      onChange={(event) =>
                        setSelectedOption(event.target.value)
                      }
                    >
                      <MenuItem value="customer">Customers</MenuItem>
                      <MenuItem value="inventory">Inventory</MenuItem>
                      <MenuItem value="invoice">Invoices</MenuItem>
                      <MenuItem value="payments">Payments/Refunds</MenuItem>

                    </Select>
                  </FormControl>

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={beginReport}
                    disabled={runningReport || !selectedOption}
                  >
                    Run Report
                  </Button>
                </Stack>

                <Button variant="contained" color="error" onClick={clearReport}>
                  {runningReport ? "Clear Report" : "Clear Report"}
                </Button>
              </Stack>

              <br />
              {orgData && runningReport && selectedOption === "customer" && (
                <CustomerSyncReport orgData={orgData} />
              )}
              {orgData && runningReport && selectedOption === "inventory" && (
                <InventorySyncReport orgData={orgData} />
              )}
              {orgData && runningReport && selectedOption === "invoice" && (
                <InvoicesSyncReport orgData={orgData} />
              )}
              {orgData && runningReport && selectedOption === "payments" && (
                <PaymentsSyncReport orgData={orgData} />
              )}
            </Box>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
