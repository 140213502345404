import { createContext } from "react";
import { Affix, Button, PageHeader, Space } from "antd";
import { useModalMgr } from "../../app/services/hooks/useModalMgr";
import AdminTruckModal from "./AdminTruckModal";
import TruckContextMenuWrapper from "./TruckContextMenuWrapper";
import { useGetTrucksQuery } from "../../app/services/api";
import { useFirestore } from "react-redux-firebase";
import { authSelector } from "../auth/authSlice";
import { useSelector } from "react-redux";

export const TrucksContext = createContext();
const AdminTrucks = () => {
  const ModalMgr = useModalMgr();
  const { fsOrgPrefix } = useSelector(authSelector);
  const firestore = useFirestore();
  const { data, isFetching, refetch } = useGetTrucksQuery({
    orgPrefix: fsOrgPrefix,
    db: { firestore },
  });
  return (
    <div>
      <TrucksContext.Provider value={ModalMgr}>
        <Affix>
          <PageHeader
            className="PageHeader"
            backIcon={false}
            title="Trucks"
            extra={
              <Space size={12}>
                <Button type="primary" onClick={() => ModalMgr.show()}>
                  Add Truck
                </Button>
              </Space>
            }
          />
        </Affix>
        <div className="list-margin-top" style={{ marginTop: "68px" }}>
          <TruckContextMenuWrapper
            data={data}
            loading={isFetching}
            refreshTable={() => {
              refetch();
            }}
          />
          <AdminTruckModal
            refreshTable={() => {
              refetch();
            }}
          />
        </div>
      </TrucksContext.Provider>
    </div>
  );
};

export default AdminTrucks;
