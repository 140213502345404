import { FC, useEffect, useState } from "react";
import {
    List,
    Space,
    Divider,
    Typography,
    Col,
    Button,
    Row
} from "antd"; import NumberFormat from "react-number-format";
import TextSubtitle from "../../../../app/system-components/typography/text/TextSubtitle";
import TextBody from "../../../../app/system-components/typography/text/TextBody";
import { useFirestore } from "react-redux-firebase";
import { InventoryItem } from "../../../../app/interfaces/inventoryItem";
import { MaintenanceRecord } from "../../../../app/interfaces/maintenanceRecord";
import { FilePdfOutlined } from "@ant-design/icons";
import { InternalFile } from "../../../../app/interfaces/invoice";
import AddEditMaintenanceRecord from "./AddEditMaintenanceRecord";

const { Text } = Typography;

const MaintenanceScheduled: FC<{
  item?: InventoryItem;
  fetching: boolean;
  data: MaintenanceRecord[];
  users: any[];
}> = ({ fetching, data, item, users }) => {
 
  const [recordToEdit, setRecordToEdit] = useState<MaintenanceRecord | null>(
    null
  );

  const handleFileClick = (file: InternalFile) => {
    window.open(file.downloadUrl, "_blank");
  };

  const formattedDate = (date: Date) => {
    const options: Intl.DateTimeFormatOptions = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return date?.toLocaleDateString(undefined, options);
  };

  const handleEditClick = (record: MaintenanceRecord) => {
    console.log("lets edit this record", record);
    setRecordToEdit(record);
  };

  const finishEditing = () => {
    setRecordToEdit(null);
  }

  return (
    <>
      {recordToEdit === null ? (
      <List
        loading={fetching}
        locale={{ emptyText: "No records" }}
        size="small"
        bordered={false}
        dataSource={data}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button onClick={() => handleEditClick(item)}>
                Edit / Review
              </Button>,
            ]}
          >
            <List.Item.Meta
              title={
                <Space>
                  <TextBody
                    text={"Scheduled for: "}
                    style={{ fontWeight: "bold" }}
                  />
                  {formattedDate(item.servicedOn)}
                </Space>
              }
              description={
                <Space direction={"vertical"} size={0}>
                  <Space>
                    <TextBody text={"Cost of Parts: "} />
                    <NumberFormat
                      style={{ fontWeight: "semi-bold" }}
                      value={item?.costOfParts.toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />

                    <TextBody text={"Cost of Labor: "} />
                    <NumberFormat
                      style={{ fontWeight: "semi-bold" }}
                      value={item?.costOfLabor.toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                  </Space>
                  <TextBody text={`Serviced By: ${item.servicedBy}`} />
                  <TextBody text={`Notes: ${item.notes}`} />

                  {item &&
                    item.internalFiles &&
                    item.internalFiles.length > 0 && (
                      <>
                        <br />

                        <TextBody
                          text={"Attachments"}
                          style={{ fontWeight: "bold" }}
                        />

                        <List
                          dataSource={(item && item.internalFiles) || []}
                          renderItem={(file) => (
                            <List.Item
                              key={file.name}
                              onClick={() => handleFileClick(file)}
                              style={{ cursor: "pointer" }}
                            >
                              <List.Item.Meta
                                avatar={
                                  <FilePdfOutlined style={{ color: "red" }} />
                                }
                                title={file.name}
                              />
                            </List.Item>
                          )}
                        />
                      </>
                    )}
                </Space>
              }
            />
          </List.Item>
        )}
      />
      ) : (
        // Display this when recordToEdit is not null
    
        <AddEditMaintenanceRecord maintenanceRecord={recordToEdit} item={item!} finishEditing={finishEditing} users={users}/>
      )}
    </>
  );
};

export default MaintenanceScheduled;
