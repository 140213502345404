import React, { createContext, useContext, useEffect, useState, useCallback, useMemo } from 'react';
import 'firebase/firestore';
import { Invoice } from '../interfaces/invoice';
import { checkInvoices } from '../utils/models/checkers/checkInvoices';
import { useSelector } from 'react-redux';
import { authSelector } from '../../domains/auth/authSlice';
import { FirestoreInvoicesContext } from './FirestoreContext';
import { useFirebase, useFirestore } from "react-redux-firebase";
import { formatDateOnRead } from '../utils/time/formatTimezoneOffset';
import { inventoryQueries } from '../services/firestore/queries/inventoryQueries';
import { sortBy } from '../utils/sorters/sortBy';
import { emailSettingsQuery } from '../services/firestore/queries/orgQueries';

export function FirestoreProvider({ children }: { children: React.ReactNode }) {

  const firestore = useFirestore();
  const firebase = useFirebase();
  const { orgData, userData, fsOrgPrefix } = useSelector(authSelector);

  const [loadingInventory, setLoadingInventory] = useState(true);
  const [inventory, setInventory] = useState([]);
  const [initialInventoryLoaded, setInitialInventoryLoaded] = useState(false);

  const [users, setUsers] = useState<any[]>([]);
  const [tags, setTags] = useState<any[]>([]);
  const [invoiceTextData, setInvoiceTextData] = useState<any>(null);
  const [emailInvoiceSettings, setEmailInvoiceSettings] = useState<any>(null);
  const [emailEstimateSettings, setEmailEstimateSettings] = useState<any>(null);

  const isoToday = useCallback(() => {
    const formattedDate = formatDateOnRead({
      date: new Date(),
      orgTimezone: orgData.orgTimezone,
    })?.toDate();

    const year = formattedDate?.getFullYear();
    const month = ((formattedDate?.getMonth() ?? 0) + 1).toString().padStart(2, '0');
    const day = formattedDate?.getDate().toString().padStart(2, '0');
    const mobileFriendly = `${year}-${month}-${day}`;
    return mobileFriendly;
  }, [orgData]);

  const [invoicesData, setInvoicesData] = useState<{
    todaysInvoices: Invoice[];
    yesterdayInvoices: Invoice[];
    tomorrowInvoices: Invoice[];
    lastWeekInvoices: Invoice[];
    nextWeekInvoices: Invoice[];
  }>({
    todaysInvoices: [],
    yesterdayInvoices: [],
    tomorrowInvoices: [],
    lastWeekInvoices: [],
    nextWeekInvoices: [],
  });

  const [invoices, setInvoices] = useState<Invoice[]>([]);

  useEffect(() => {
    if (orgData) {
      loadUsers();
      loadTags();
      fetchInvoiceTextCustomization();
      fetchEmailSettings();
    }
  }, [firestore, orgData, initialInventoryLoaded]);

  const loadInventory = useCallback(async () => {
    setLoadingInventory(true);
    const data = await inventoryQueries(
      { firestore },
      fsOrgPrefix
    ).fetchInventory();

    setInventory(sortBy(data, "name"));
    setLoadingInventory(false);
    setInitialInventoryLoaded(true);
  }, [firestore, fsOrgPrefix]);

  const loadUsers = useCallback(async () => {
    const usersQuery = await firestore
      .collection("orgs")
      .doc(orgData.id)
      .collection("users")
      .where("permissions", "!=", "warehouse")
      .get();

    const users = usersQuery.docs
      .map((snap) => snap.data())
      .map((data) => {
        return { 
          ...data,
          name: `${data.firstName} ${data.lastName}`, 
          id: data.id 
        };
      });

    setUsers(sortBy(users, "name"));
  }, [firestore, orgData]);

  const loadTags = useCallback(async () => {
    if (!orgData) return;

    const snapshot = await firestore
      .collection(`orgs/${orgData.id}/tags`)
      .get();
      
    const fetchedTags = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    setTags(fetchedTags);
  }, [firestore, orgData]);

  const fetchInvoiceTextCustomization = useCallback(async () => {
    const textSnap = await firestore
      .collection("orgs")
      .doc(orgData.id)
      .collection("textCustomization")
      .doc("invoice")
      .get();

    if (textSnap.exists) {
      const textData = textSnap.data();
      setInvoiceTextData(textData);
    }
  }, [firestore, orgData]);

  const fetchEmailSettings = useCallback(async () => {
    try {
      const invoiceSettings = await emailSettingsQuery({ firestore }, fsOrgPrefix)
        .fetchEmailSettings(orgData.id, "invoiceEmailSettings");
      if (invoiceSettings) {
        setEmailInvoiceSettings(invoiceSettings);
      }

      const estimateSettings = await emailSettingsQuery({ firestore }, fsOrgPrefix)
        .fetchEmailSettings(orgData.id, "estimateEmailSettings");
      if (estimateSettings) {
        setEmailEstimateSettings(estimateSettings);
      }
    } catch (err) {
      console.log(err);
    }
  }, [firestore, fsOrgPrefix, orgData]);

  return (
    <FirestoreInvoicesContext.Provider
      value={{
        invoicesData,
        inventory,
        loadingInventory,
        users,
        tags,
        invoiceTextData,
        emailInvoiceSettings,
        emailEstimateSettings,
      }}
    >
      {children}
    </FirestoreInvoicesContext.Provider>
  );
}


/*
 let unsubscribeInvoices: () => void;

    if (orgData) {

    const todayDateISO = isoToday();
    const todayDate = new Date(todayDateISO);

    // Calculate the date ranges for yesterday, tomorrow, last week, and next week
    const yesterday = new Date(todayDate);
    yesterday.setDate(yesterday.getDate() - 1);
    const tomorrow = new Date(todayDate);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const lastWeek = new Date(todayDate);
    lastWeek.setDate(lastWeek.getDate() - 7);
    const nextWeek = new Date(todayDate);
    nextWeek.setDate(nextWeek.getDate() + 7);

    unsubscribeInvoices = firestore
      .collection("orgs")
      .doc(orgData.id)
      .collection("invoices")
      .where("rentalDateStart", ">", lastWeek)
      .onSnapshot((snapshot) => {

        const allInvoices: Invoice[] = [];
        const invoicesToday: Invoice[] = [];
        const invoicesYesterday: Invoice[] = [];
        const invoicesTomorrow: Invoice[] = [];
        const invoicesLastWeek: Invoice[] = [];
        const invoicesNextWeek: Invoice[] = [];

        snapshot.docs.forEach((doc) => {
          const i = checkInvoices(doc, orgData.orgTimezone) as Invoice;
          allInvoices.push(i);

          if (i.mobileStartDate === todayDateISO || i.mobileEndDate === todayDateISO) {
            invoicesToday.push(i);
          }

          // Check if rentalStartDate or rentalEndDate falls within the desired ranges
          if (
            (i.rentalDateStart.toDate() >= yesterday && i.rentalDateStart.toDate() <= tomorrow) ||
            (i.rentalDateEnd.toDate() >= yesterday && i.rentalDateEnd.toDate() <= tomorrow)
          ) {
            invoicesYesterday.push(i);
          }

          if (
            (i.rentalDateStart.toDate() >= yesterday && i.rentalDateStart.toDate() <= tomorrow) ||
            (i.rentalDateEnd.toDate() >= yesterday && i.rentalDateEnd.toDate() <= tomorrow)
          ) {
            invoicesTomorrow.push(i);
          }

          if (
            (i.rentalDateStart.toDate() >= lastWeek && i.rentalDateStart.toDate() <= todayDate) ||
            (i.rentalDateEnd.toDate() >= lastWeek && i.rentalDateEnd.toDate() <= todayDate)
          ) {
            invoicesLastWeek.push(i);
          }

          if (
            (i.rentalDateStart.toDate() >= todayDate && i.rentalDateStart.toDate() <= nextWeek) ||
            (i.rentalDateEnd.toDate() >= todayDate && i.rentalDateEnd.toDate() <= nextWeek)
          ) {
            invoicesNextWeek.push(i);
          }
        });

        setInvoices(allInvoices);

        setInvoicesData((prevData) => ({
          ...prevData,
          todaysInvoices: invoicesToday,
          yesterdayInvoices: invoicesYesterday,
          tomorrowInvoices: invoicesTomorrow,
          lastWeekInvoices: invoicesLastWeek,
          nextWeekInvoices: invoicesNextWeek,
        }));
      });
    }
  
    // Clean up the listener when the component unmounts
    return () => {
      if (unsubscribeInvoices) unsubscribeInvoices();
    };
    */