import { useSelector } from "react-redux";
import {
    ROUTE_ADMIN_CREATE_INVENTORY,
    ROUTE_ADMIN_CUSTOMERS,
    ROUTE_ADMIN_CUSTOMERS_PAYMENT,
    ROUTE_ADMIN_INTEGRATIONS,
    ROUTE_ADMIN_BILLING,
    ROUTE_ADMIN_INVENTORY,
    ROUTE_ADMIN_SETTINGS_EMAIL,
    ROUTE_ADMIN_SETTINGS_WORDS,
    ROUTE_ADMIN_SETTINGS_GENERAL,
    ROUTE_ADMIN_TRUCKS,
    ROUTE_ADMIN_FILE_MANAGER,
    ROUTE_ADMIN_USERS,
    ROUTE_CREATE_INVOICE,
    ROUTE_CREATE_PURCHASE_ORDER,
    ROUTE_CREATE_INVOICE_FROM_CART,
    ROUTE_FORGOT_PASSWORD,
    ROUTE_INVENTORY,
    ROUTE_INVOICES,
    ROUTE_PURCHASE_ORDERS,
    ROUTE_LOGIN,
    ROUTE_QUICK_REPORT,
    ROUTE_RECEIVE_INVOICE_PAYMENT,
    ROUTE_REPORTS,
    ROUTE_REPORTS_DEPRECIATION,
    ROUTE_REPORTS_SALES,
    ROUTE_REPORTS_USAGE,
    ROUTE_ROUTES,
    ROUTE_VIEW_EDIT_INVOICE,
    ROUTE_ADMIN_VENUES,
    ROUTE_REPORTS_TAX,
    ROUTE_ADMIN_EMBEDDED_CHECKOUT,
    ROUTE_ADMIN_CREATE_PART,
    ROUTE_ADMIN_CREATE_SERVICE,
    ROUTE_ADMIN_VENDORS,
    ROUTE_DASHBOARD,
    ROUTE_CHANGE_LOG,
    ROUTE_MOBILE_APPS,
    ROUTE_ADMIN_SETTINGS_PERMISSIONS
  } from "../../app/routes";
import { Permissions } from "./permissions.type";
import { authSelector } from "../auth/authSlice";

/*
super - 99
admin - 2
office - 1
warehouse - 0 
*/

export const minimumDefaultRoutePermissions: Permissions = {
    [ROUTE_DASHBOARD]: ["office", "admin", "warehouse"],
    [ROUTE_ADMIN_CREATE_INVENTORY]: ["office", "admin"],
    [ROUTE_ADMIN_CUSTOMERS]: ["office", "admin"],
    [ROUTE_ADMIN_USERS]: ["admin"],
    [ROUTE_ADMIN_INTEGRATIONS]: ["admin"],
    [ROUTE_ADMIN_BILLING]: ["admin"],
    [ROUTE_ADMIN_SETTINGS_GENERAL]: ["admin"],
    [ROUTE_ADMIN_SETTINGS_EMAIL]: ["admin"],
    [ROUTE_ADMIN_SETTINGS_WORDS]: ["admin"],
    [ROUTE_ADMIN_EMBEDDED_CHECKOUT]: ["admin"],
    [ROUTE_ADMIN_INVENTORY]: ["office", "admin"],
    [ROUTE_ADMIN_CUSTOMERS_PAYMENT]: ["office", "admin"],
    [ROUTE_ADMIN_CREATE_PART]: ["office", "admin"],
    [ROUTE_ADMIN_CREATE_SERVICE]: ["office", "admin"],
    [ROUTE_ADMIN_VENDORS]: ["office", "admin"],
    [ROUTE_ADMIN_TRUCKS]: ["office", "admin"],
    [ROUTE_ADMIN_FILE_MANAGER]: ["office", "admin"],
    [ROUTE_CREATE_INVOICE]: ["office", "admin"],
    [ROUTE_CREATE_PURCHASE_ORDER]: ["office", "admin"],
    [ROUTE_CREATE_INVOICE_FROM_CART]: ["office", "admin"],
    [ROUTE_FORGOT_PASSWORD]: ["office", "admin"],
    [ROUTE_INVENTORY]: ["office", "admin"],
    [ROUTE_INVOICES]: ["office", "admin"],
    [ROUTE_PURCHASE_ORDERS]: ["office", "admin"],
    [ROUTE_LOGIN]: ["office", "admin"],
    [ROUTE_QUICK_REPORT]: ["warehouse", "office", "admin"],
    [ROUTE_RECEIVE_INVOICE_PAYMENT]: ["office", "admin"],
    [ROUTE_REPORTS]: ["admin"],
    [ROUTE_REPORTS_DEPRECIATION]: ["admin"],
    [ROUTE_REPORTS_SALES]: ["admin"],
    [ROUTE_REPORTS_USAGE]: ["admin"],
    [ROUTE_ROUTES]: ["office", "admin"],
    [ROUTE_VIEW_EDIT_INVOICE]: ["office", "admin"],
    [ROUTE_ADMIN_VENUES]: ["office", "admin"],
    [ROUTE_REPORTS_TAX]: ["office", "admin"],
    [ROUTE_CHANGE_LOG]: ["warehouse", "office", "admin"],
    [ROUTE_MOBILE_APPS]: ["warehouse", "office", "admin"],
    [ROUTE_ADMIN_SETTINGS_PERMISSIONS]: ["admin"],
};

export const hasPermission = (route: string, userData: any, permissionsData: Permissions) => {

    // Check if userData and permissionsData are loaded
    if (!userData || !permissionsData) {
        // Data not loaded yet, can return false or a loading state
        return false; // or return a loading state indication if needed
    }

    const userType = userData?.permissions ?? "office";

    return permissionsData[route as keyof Permissions].includes(userType)
}

export const hasPermissionForMenu = (submenu: string, userData: any, permissionsData: Permissions) => {

    // Check if userData and permissionsData are loaded
    if (!userData || !permissionsData) {
        // Data not loaded yet, can return false or a loading state
        return false; // or return a loading state indication if needed
    }

    const userType = userData?.permissions ?? "office";

    if (submenu === "admin") {
        if (userType === "warehouse") {
            return false
        } else {
            return true
        }
    }
    if (submenu === "reports") {
        if (userType === "warehouse" || userType === "office") {
            return false
        } else {
            return true
        }
    }
    if (submenu === "settings") {
        if (userType === "warehouse" || userType === "office") {
            return false
        } else {
            return true
        }
    }
}

export function usePermissions() {

    const { orgData, userData, permissionsData } = useSelector(authSelector);

    const hasPlanAccess = () => {
        // Check if userData and permissionsData are loaded
        if (!userData || !permissionsData) {
            // Data not loaded yet, can return false or a loading state
            return false; // or return a loading state indication if needed
        }

        // essentials, plus, premium
    }

    return {
        hasPermission,
        hasPlanAccess,
        hasPermissionForMenu
    }
}

export const decideUserPermissionsLevel = (userType: string) => {

    if (userType === "warehouse") {
        return 0
    } else if (userType === "office") {
        return 1
    } else if (userType === "admin") {
        return 2
    } else if (userType === "super") {
        return 99
    } else {
        return 1
    }

}