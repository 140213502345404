import React, { useEffect, useState } from "react";
import "../../App.less";
import { Affix, Button, Dropdown, Input, Menu, PageHeader, Space } from "antd";
import CreateCategoryModal from "./components/CreateCategoryModal";
import { useFirestore } from "react-redux-firebase";
import { categoryQueries } from "../../app/services/firestore/queries/categoryQueries";
import { authSelector } from "../auth/authSlice";
import { useSelector } from "react-redux";
import EditCategoryModal from "./components/EditCategoryModal";
import notificationConfirm from "../../app/system-components/toasters/notificationConfirm";
import { inventoryQueries } from "../../app/services/firestore/queries/inventoryQueries";
import { sortBy } from "../../app/utils/sorters/sortBy";
import { ROUTE_ADMIN_CREATE_INVENTORY } from "../../app/routes";
import { PlusCircleOutlined } from "@ant-design/icons";
import TextBody from "../../app/system-components/typography/text/TextBody";
import { COLOR_PURPLE_0 } from "../../app/ui/colorConstants";
import TextSubtitle from "../../app/system-components/typography/text/TextSubtitle";
import InventoryDataGrid from "./components/InventoryDataGrid";
import ItemDrawer from "./components/ItemDrawer";

const { Search } = Input;
const AdminInventory = ({ history }) => {
  const { userData, orgData, fsOrgPrefix } = useSelector(authSelector);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [loadingInventory, setLoadingInventory] = useState(false);
  const [manageCategoriesModal, setManageCategoriesModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [inventory, setInventory] = useState([]);
  const [filteredInventory, setFilteredInventory] = useState([]);
  const [viewEditCategoryModal, setViewEditCategoryModal] = useState({
    visible: false,
    category: null,
  });
  const [keywords, setKeywords] = useState(null);
  const [itemDrawer, setItemDrawer] = useState({
    visible: false,
    item: null,
  });

  const firestore = useFirestore();
  useEffect(() => {
    // load categories
    fsOrgPrefix && loadCategories();
    fsOrgPrefix && loadInventory();
  }, [fsOrgPrefix]);

  const loadCategories = async () => {
    setLoadingCategories(true);
    const cats = await categoryQueries(
      { firestore },
      fsOrgPrefix
    ).fetchCategories();
    setCategories(sortBy(cats, "name"));
    setLoadingCategories(false);
  };

  const loadInventory = async () => {
    setLoadingInventory(true);
    const data = await inventoryQueries(
      { firestore },
      fsOrgPrefix
    ).fetchInventory();
    setInventory(sortBy(data, "name"));
    setLoadingInventory(false);
  };

  useEffect(() => {
    if (!keywords) return;
    const filtered = inventory.filter((i) => {
      const invName = `${i.name.toLowerCase()}`;
      return invName.includes(keywords.toLowerCase());
    });
    setFilteredInventory([...filtered]);
  }, [keywords]);

  const categoryOverlay = () => {
    return (
      <Menu>
        <Menu.Item
          key="1"
          onClick={() => setManageCategoriesModal(true)}
          style={{ height: "40px" }}
        >
          <TextBody
            text={
              <div>
                Create Category{" "}
                <PlusCircleOutlined style={{ margin: "0px 8px" }} />
              </div>
            }
            style={{ color: COLOR_PURPLE_0, fontWeight: "bold" }}
          />
        </Menu.Item>
        {categories.map((c) => (
          <Menu.Item
            key={c.id}
            onClick={() => {
              setViewEditCategoryModal({
                visible: true,
                category: c,
              });
            }}
          >
            <span style={{ fontWeight: "bold" }}>{c.name}</span>
            <br />
            <TextSubtitle text={c.description} />
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  return (
    <div>
      <Affix>
        <PageHeader
          className="PageHeader"
          backIcon={false}
          title="Inventory"
          extra={
            <Space size={12}>
              <Search
                allowClear={true}
                key="Searchbar"
                placeholder="Search"
                onSearch={(value) => setKeywords(value)}
                style={{
                  width: 280,
                  fontWeight: "bold",
                }}
              />
              <Dropdown overlay={categoryOverlay} trigger="click">
                <Button>Categories</Button>
              </Dropdown>
              <Button
                type="primary"
                onClick={() => history.push(ROUTE_ADMIN_CREATE_INVENTORY)}
              >
                Add Inventory Item
              </Button>
            </Space>
          }
        />
      </Affix>
      <div className="admin-inventory-list" style={{ marginTop: "0px" }}>
        {userData && orgData && (
          <InventoryDataGrid
            setItemDrawer={setItemDrawer}
            onDuplicateComplete={loadInventory}
            onDeleteComplete={loadInventory}
            data={!keywords ? inventory : filteredInventory}
            onEditComplete={loadInventory}
            loadingInventory={loadingInventory}
            loadingCategories={loadingCategories}
            categories={categories}
          />
        )}
        <ItemDrawer
          // drawerView
          visible={itemDrawer.visible}
          handleCloseDrawer={setItemDrawer}
          item={itemDrawer}
          categories={categories}
          onComplete={() => {
            setItemDrawer({
              visible: false,
              item: null,
            });
            loadInventory();
          }}
        />
      </div>
      <CreateCategoryModal
        visible={manageCategoriesModal}
        onCancel={() => setManageCategoriesModal(false)}
        onComplete={() => {
          setManageCategoriesModal(false);
          loadCategories().then(() => {
            notificationConfirm("Category created", "");
          });
        }}
      />
      <EditCategoryModal
        visible={viewEditCategoryModal.visible}
        category={viewEditCategoryModal.category}
        onComplete={() => {
          setViewEditCategoryModal({
            visible: false,
            category: null,
          });
          loadCategories();
        }}
        onCancel={() => {
          setViewEditCategoryModal({
            visible: false,
            category: null,
          });
        }}
      />
    </div>
  );
};

export default AdminInventory;
