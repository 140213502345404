import { Vendor } from "./vendor";

export interface Part {
    id: string;
    qbId: string;
    orgId: string;
    type: string;
    name: string;
    description: string;
    stock: number | null;
    categories: {
      description: string;
      id: string;
      name: string;
      orgId: string;
    }[];
    categoriesQueryHook: string[];
    vendorQueryHook: string | null;
    vendor: Vendor | Partial<Vendor> | null;
    price: number;
    notes: string;
    image: {
      downloadURL: string;
      fileName: string;
    } | null;
    isAvailable: boolean;
    isActive: boolean;
    isTaxable: boolean;
    createdOn: Date | null;
    createdBy: string;
    updatedOn: Date | null;
    updatedBy: string;
    updateSource: string;
    createdOnPlatform: string;
    weight: number | null;
  }
  
  export interface PartFormData {
    categories: {
      id: string;
      orgId: string;
      name: string;
      description: string;
    }[];
    categoriesQueryHook: string[];
    vendorQueryHook: string | null;
    vendor: Vendor | null;
    image: {
      downloadURL: string;
      fileName: string;
    } | null;
    type: string;
    name: string;
    description: string;
    price: number;
    stock: number;
    notes: string;
    isAvailable: boolean;
    isActive: boolean;
    isTaxable: boolean;
    weight: number | null;
  }
  
  export interface SelectedPart {
    id: string;
    qbId: string;
    orgId: string;
    type: string;
    name: string;
    qty: number;
    description: string;
    stock: number | null;
    categories: {
      description: string;
      id: string;
      name: string;
      orgId: string;
    }[];
    categoriesQueryHook: string[];
    vendorQueryHook: string | null;
    vendor: Vendor | Partial<Vendor> | null;
    price: number;
    notes: string;
    image: {
      downloadURL: string;
      fileName: string;
    } | null;
    isAvailable: boolean;
    isActive: boolean;
    isTaxable: boolean;
    weight: number | null;
  }

  export function convertPartToSelectedPart(part: Part): SelectedPart {
    // Extract only the common properties between Part and SelectedPart
    const { id, qbId, orgId, type, name, description, stock, categories, vendor, price, notes, image, isAvailable, isActive, isTaxable, weight } = part;
  
    // Create a SelectedPart object with the extracted properties and a default qty value of 0
    const selectedPart: SelectedPart = {
      id,
      qbId,
      orgId,
      type,
      name,
      qty: 0,
      description,
      stock,
      categories,
      vendor,
      price,
      notes,
      image,
      isAvailable,
      isActive,
      isTaxable,
      weight,
      categoriesQueryHook: part.categoriesQueryHook,
      vendorQueryHook: part.vendorQueryHook
    };
  
    return selectedPart;
  }