import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import { functionsBaseUrl } from "../../../app/config/firebase";
import { JustifiPaymentDetail } from "./JustifiPaymentDetail";
import { useSelector } from "react-redux";
import { authSelector } from "../../auth/authSlice";


type Props = {};

export function JustifiPaymentsList({}: Props) {
  const { orgData } = useSelector(authSelector);
  const [open, setOpen] = React.useState(false);
  const [selectedPaymentId, setSelectedPaymentId] = React.useState<
    string | null
  >(null);

  const handleClose = () => {
    setSelectedPaymentId(null);
  };

  const formRef = useRef<HTMLFormElement>(null);
  const [webComponentToken, setWebComponentToken] = React.useState<
    string | null
  >(null);

  useEffect(() => {
    if (!orgData) return;

    const getWebcomponentToken = async () => {
      const url = functionsBaseUrl + "/justifi";
      const data = {
        orgId: orgData.id,
        action: "webComponentToken",
        payload: {},
      };
      // needs a body passed to the fetch
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const json = await response.json();
      setWebComponentToken(json.access_token);
    };

    getWebcomponentToken();
  }, [orgData]);

  useEffect(() => {
    const form = formRef.current;
    if (form && webComponentToken) {
      const handleClick = (event: Event) => {
        setSelectedPaymentId((event as CustomEvent).detail.id);
        setOpen(true);
      };

      form.addEventListener("payment-row-clicked", handleClick);

      return () => form.removeEventListener("payment-row-clicked", handleClick);
    }
  }, [webComponentToken]);

  return (
    <Card sx={{ p: 3, m: 1 }}>
      <Typography variant="body2">Payments</Typography>

      {orgData.justifiSubAccount && webComponentToken && (
        <justifi-payments-list
          ref={formRef}
          account-id={orgData.justifiSubAccount}
          auth-token={webComponentToken}
        ></justifi-payments-list>
      )}

      <Dialog
        maxWidth={"xl"}
        onClose={handleClose}
        open={selectedPaymentId !== null}
      >
        <DialogTitle>Payment Details</DialogTitle>
        <DialogContent>
          <JustifiPaymentDetail paymentId={selectedPaymentId ?? ""} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}
