import React, { createContext, useEffect, useState } from "react";
import { Affix, Button, Input, PageHeader, Space } from "antd";
import { authSelector } from "../auth/authSlice";
import { useSelector } from "react-redux";
import VendorsDataGrid from "./components/VendorsDataGrid";
import { vendorQueries } from "../../app/services/firestore/queries/vendorQueries";
import { useFirestore } from "react-redux-firebase";
import AddVendorModal from "./components/modals/AddVendorModal";
import EditVendorModal from "./components/modals/EditVendorModal";

const { Search } = Input;

export const VendorTableContext = createContext();

const AdminVendors = () => {
  const { fsOrgPrefix, userData, orgData } = useSelector(authSelector);
  const [vendorModal, setVendorModal] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(false);

  const [edit, setEdit] = useState({
    visible: false,
    selected: null,
  });
  
  const firestore = useFirestore();

  useEffect(() => {
    loadVendors();
  }, [fsOrgPrefix]);

  const loadVendors = () => {
    setLoading(true);
    fsOrgPrefix &&
      vendorQueries({ firestore }, fsOrgPrefix)
        .fetchVendors()
        .then((res) => {
          setVendors([...res]);
          setLoading(false);
        });
  };

  const handleClose = () => {
    setVendorModal(false);
  };
  const handleEdit = (c) => {
    setEdit({
      visible: true,
      selected: c,
    });
  };
  const handleCloseEdit = () => {
    setEdit({
      visible: false,
      selected: null,
    });
  };
  return (
    <div>
        <Affix>
          <PageHeader
            className="PageHeader"
            backIcon={false}
            title="Vendors"
            extra={
              <Space size={12}>
                <Search
                  allowClear={true}
                  key="Searchbar"
                  placeholder="Search"
                  onChange={(e) => vendors.onSearch(e.target.value)}
                  style={{
                    width: 280,
                    fontWeight: "bold",
                  }}
                />
                <Button
                  type="primary"
                  onClick={() => {
                    setVendorModal(true);
                  }}
                >
                  Add Vendor
                </Button>
              </Space>
            }
          />
        </Affix>
        <div className="list-margin-top" style={{ marginTop: "68px" }}>
          <VendorsDataGrid
            data={vendors}
            loading={loading}
            handleEdit={handleEdit}
            onDeleteComplete={() => {
              handleClose();
              loadVendors();
            }}
          />
        </div>
        <AddVendorModal
          visible={vendorModal}
          onCancel={handleClose}
          onComplete={() => {
            handleClose();
            loadVendors()
          }}
        />
        <EditVendorModal
          showPaymentOptions
          visible={edit.visible}
          selected={edit.selected}
          onCancel={handleCloseEdit}
          onContactAdded={() => {
            handleCloseEdit();
            loadVendors();
          }}
          onComplete={() => {
            handleCloseEdit();
            loadVendors();
          }}
        />
    </div>
  );
};

export default AdminVendors;
