import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

import { ChangeEvent, useEffect, useState } from "react";

import { useFirestore } from "react-redux-firebase";

import notificationConfirm from "../../../app/system-components/toasters/notificationConfirm";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { authSelector } from "../../auth/authSlice";

// ----------------------------------------------------------------------
export interface InvoiceTextData {
  estimate: string;
  invoice: string;
  invoiceUpdated: string;
  invoiceDue: string;
  invoiceRentalStart: string;
  invoiceRentalEnd: string;
  invoiceEventDate: string;

  receiveReturnDetails: string;
  customerReceivesItems: string;
  itemsReturnedBy: string;
  receiveAddress: string;
  returnAddress: string;
  receiveDateTime: string;
  returnDateTime: string;
  venue: string;
  deliveryFee: string;

  logoSize: string;
  logoDimension: string;
  itemImageSize: string;

  preferredDateStyle: string;
  notesPosition: string | "above" | "below";
  receiveDetailsPosition: string | "above" | "below";
}

export default function InvoiceWords() {
  const dateFormats = [
    // Formats Starting with Day of the Week (E)
    {
      code: "EEEE, dd MMM yyyy",
      example: format(new Date(), "EEEE, dd MMM yyyy"),
    },
    {
      code: "EEE, MMM dd, yyyy",
      example: format(new Date(), "EEE, MMM dd, yyyy"),
    },
    {
      code: "EEE, dd MMM yyyy",
      example: format(new Date(), "EEE, dd MMM yyyy"),
    },

    // Standard Date Formats (Year, Month, Day)
    { code: "yyyy-MM-dd", example: format(new Date(), "yyyy-MM-dd") },
    { code: "yyyy/MM/dd", example: format(new Date(), "yyyy/MM/dd") },

    // European Style (Day, Month, Year)
    { code: "dd-MM-yyyy", example: format(new Date(), "dd-MM-yyyy") },
    { code: "dd/MM/yyyy", example: format(new Date(), "dd/MM/yyyy") },
    { code: "dd MMMM yyyy", example: format(new Date(), "dd MMMM yyyy") },

    // American Style (Month, Day, Year)
    { code: "MM/dd/yyyy", example: format(new Date(), "MM/dd/yyyy") },
    { code: "MM-dd-yyyy", example: format(new Date(), "MM-dd-yyyy") },
  ];

  const tooltipContent = (
    <div>
      <p>Choose how dates will appear on your invoices.</p>

      <p>
        <strong>Day of the Week First:</strong>
      </p>
      <p>EEEE, dd MMM yyyy: Full Weekday, numeric day, short month, year.</p>
      <p>EEE, dd MMM yyyy: Weekday, numeric day, short month, year.</p>
      <p>EEE, MMM dd, yyyy: Weekday, short month, numeric day, year.</p>

      <p>
        <strong>Standard Formats:</strong>
      </p>
      <p>yyyy-MM-dd: ISO standard format, year first.</p>
      <p>yyyy/MM/dd: Year first, slashes as separators.</p>

      <p>
        <strong>European Style:</strong>
      </p>
      <p>dd-MM-yyyy: Numeric day first, dashes as separators.</p>
      <p>dd/MM/yyyy: Numeric day first, slashes as separators.</p>
      <p>dd MMMM yyyy: Full month name, day first.</p>

      <p>
        <strong>American Style:</strong>
      </p>
      <p>MM/dd/yyyy: Month first, slashes as separators.</p>
      <p>MM-dd-yyyy: Month first, dashes as separators.</p>
    </div>
  );

  const { orgData } = useSelector(authSelector);
  const firestore = useFirestore();

  const [inputData, setInputData] = useState<InvoiceTextData>({
    estimate: "Estimate",
    invoice: "Invoice",
    invoiceUpdated: "Updated",
    invoiceDue: "Due",
    invoiceRentalStart: "Rental Start",
    invoiceRentalEnd: "Rental End",
    invoiceEventDate: "Event Date",

    receiveReturnDetails: "Receive and Return Details",
    customerReceivesItems: "Customer Receives Items Via",
    itemsReturnedBy: "Items Returned Via",
    receiveAddress: "Address",
    returnAddress: "Address",
    receiveDateTime: "Date/Time(Range)",
    returnDateTime: "Date/Time(Range)",
    venue: "Venue",

    deliveryFee: "Delivery Fee",

    logoSize: "large", // small / large
    logoDimension: "rectangle", // square / rectangle
    itemImageSize: "small", // small / large

    preferredDateStyle: "EEE, MMM dd, yyyy",
    notesPosition: "below",
    receiveDetailsPosition: "below",
  });
  const [selectedLogoSize, setSelectedLogoSize] = useState("small");
  const [selectedItemImageSize, setSelectedItemImageSize] = useState("small");
  const [selectedDimension, setSelectedDimension] = useState("rectangle");
  const [notesPosition, setNotesPosition] = useState('below');
  const [receiveDetailsPosition, setReceiveDetailsPosition] = useState('below');

  const handleNotesPositionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotesPosition((event.target as HTMLInputElement).value);
    setInputData((prevState) => ({
      ...prevState,
      notesPosition: event.target.value,
    }));
  };

  const handleReceiveDetailsPositionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReceiveDetailsPosition((event.target as HTMLInputElement).value);
    setInputData((prevState) => ({
      ...prevState,
      receiveDetailsPosition: event.target.value,
    }));
  };

  const fetchInvoiceTextCustomization = async () => {
    const textSnap = await firestore
      .collection("orgs")
      .doc(orgData.id)
      .collection("textCustomization")
      .doc("invoice")
      .get();
    if (textSnap.exists) {
      const textData = textSnap.data() as InvoiceTextData;
      setInputData(textData);
      setSelectedFormat(textData.preferredDateStyle);
      setSelectedLogoSize(textData.logoSize);
      setSelectedItemImageSize(textData.itemImageSize);
      setSelectedDimension(textData.logoDimension);
      setNotesPosition(textData.notesPosition);
      setReceiveDetailsPosition(textData.receiveDetailsPosition);
    }
  };
  useEffect(() => {
    if (orgData) {
      fetchInvoiceTextCustomization();
    }
  }, [orgData]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInputData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      console.log(inputData);
      await firestore
        .collection("orgs")
        .doc(orgData.id)
        .collection("textCustomization")
        .doc("invoice")
        .set(inputData, { merge: true });
      notificationConfirm("Saved");
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const [selectedFormat, setSelectedFormat] = useState(
    inputData.preferredDateStyle || dateFormats[1].code
  );

  const handleDateFormatChange = (event: SelectChangeEvent<string>) => {
    setSelectedFormat(event.target.value);
    setInputData((prevState) => ({
      ...prevState,
      preferredDateStyle: event.target.value,
    }));
  };

  const handleItemImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedItemImageSize(event.target.value);

    setInputData((prevState) => ({
      ...prevState,
      itemImageSize: event.target.value,
    }));
  };
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedLogoSize(event.target.value);
    setInputData((prevState) => ({
      ...prevState,
      logoSize: event.target.value,
    }));
  };
  const handleDimensionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedDimension(event.target.value);
    setInputData((prevState) => ({
      ...prevState,
      logoDimension: event.target.value,
    }));
  };

  const itemSizeBoxStyle = (size: string) => ({
    width: size === "small" ? 50 : 75,
    height: size === "small" ? 50 : 75,
    border: "2px dashed grey",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: selectedItemImageSize === size ? "lightblue" : "",
  });
  const logoSizeBoxStyle = (size: string) => ({
    width: size === "small" ? 100 : 200,
    height: size === "small" ? 100 : 200,
    border: "2px dashed grey",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: selectedLogoSize === size ? "lightblue" : "",
  });
  const logoDimensionsBoxStyle = (dimension: string) => ({
    width: dimension === "square" ? 100 : 200,
    height: dimension === "square" ? 100 : 100,
    border: "2px dashed grey",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: selectedDimension === dimension ? "lightblue" : "",
  });

  return (
    <Container>
      <Grid container direction="row" alignItems="flex-start">
        <Grid item xs>
          <Typography sx={{ mb: 2 }} variant="h4">
            Invoice Text Customization
          </Typography>
        </Grid>

        <Grid item>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Save
          </Button>
        </Grid>
      </Grid>

      <div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              size="small"
              label="Estimate"
              name="estimate"
              value={inputData.estimate}
              onChange={handleInputChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              size="small"
              label="Invoice"
              name="invoice"
              value={inputData.invoice}
              onChange={handleInputChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              size="small"
              label="Updated"
              name="invoiceUpdated"
              value={inputData.invoiceUpdated}
              onChange={handleInputChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              size="small"
              label="Due"
              name="invoiceDue"
              value={inputData.invoiceDue}
              onChange={handleInputChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              size="small"
              label="Rental Start"
              name="invoiceRentalStart"
              value={inputData.invoiceRentalStart}
              onChange={handleInputChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              size="small"
              label="Rental End"
              name="invoiceRentalEnd"
              value={inputData.invoiceRentalEnd}
              onChange={handleInputChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              size="small"
              label="Event Date"
              name="invoiceEventDate"
              value={inputData.invoiceEventDate}
              onChange={handleInputChange}
              variant="outlined"
            />
            <br />
            <br />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              size="small"
              label="Receive and Return Details"
              name="receiveReturnDetails"
              value={inputData.receiveReturnDetails}
              onChange={handleInputChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              size="small"
              label="Customer Receives Items Via"
              name="customerReceivesItems"
              value={inputData.customerReceivesItems}
              onChange={handleInputChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              size="small"
              label="Items Returned Via"
              name="itemsReturnedBy"
              value={inputData.itemsReturnedBy}
              onChange={handleInputChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              size="small"
              label="Address"
              name="receiveAddress"
              value={inputData.receiveAddress}
              onChange={handleInputChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              size="small"
              label="Venue"
              name="venue"
              value={inputData.venue}
              onChange={handleInputChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              size="small"
              label="Delivery Fee"
              name="deliveryFee"
              value={inputData.deliveryFee}
              onChange={handleInputChange}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12}>
            <Stack gap={1} alignItems={"center"} direction={"row"}>
              <FormControl>
                <InputLabel id="date-format-select-label">
                  Date Format
                </InputLabel>
                <Select
                  labelId="date-format-select-label"
                  id="date-format-select"
                  value={selectedFormat}
                  label="Date Format"
                  onChange={handleDateFormatChange}
                >
                  {dateFormats.map((format, index) => (
                    <MenuItem key={index} value={format.code}>
                      {format.example}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Tooltip title={tooltipContent} placement="right">
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          </Grid>

          <Stack>
            <Box sx={{ mt: 3 }}>
              <Typography gutterBottom sx={{ pb: 1, fontWeight: "semiBold" }}>
                Invoice Items Image Size
              </Typography>
              <RadioGroup
                row
                value={selectedItemImageSize}
                onChange={handleItemImageChange}
              >
                <FormControlLabel
                  value="large"
                  control={<Radio />}
                  label={<Box style={itemSizeBoxStyle("large")}>Large</Box>}
                />
                <FormControlLabel
                  value="small"
                  control={<Radio />}
                  label={<Box style={itemSizeBoxStyle("small")}>Small</Box>}
                />
              </RadioGroup>
            </Box>

            <Box sx={{ mt: 3 }}>
              <Typography gutterBottom sx={{ pb: 1, fontWeight: "semiBold" }}>
                Invoice Company Logo Size
              </Typography>
              <RadioGroup row value={selectedLogoSize} onChange={handleChange}>
                <FormControlLabel
                  value="large"
                  control={<Radio />}
                  label={
                    <Box style={logoSizeBoxStyle("large")}>Large Logo</Box>
                  }
                />
                <FormControlLabel
                  value="small"
                  control={<Radio />}
                  label={
                    <Box style={logoSizeBoxStyle("small")}>Small Logo</Box>
                  }
                />
              </RadioGroup>
            </Box>

            <Box sx={{ mt: 3 }}>
              <Typography sx={{ pb: 1, fontWeight: "semiBold" }}>
                Invoice Company Logo Dimensions
              </Typography>
              <Typography variant="body2" gutterBottom sx={{ pb: 1 }}>
                Is your company logo more square or rectangular?
              </Typography>
              <RadioGroup
                row
                value={selectedDimension}
                onChange={handleDimensionChange}
              >
                <FormControlLabel
                  value="square"
                  control={<Radio />}
                  label={
                    <Box style={logoDimensionsBoxStyle("square")}>Square</Box>
                  }
                />
                <FormControlLabel
                  value="rectangle"
                  control={<Radio />}
                  label={
                    <Box style={logoDimensionsBoxStyle("rectangle")}>
                      Rectangular
                    </Box>
                  }
                />
              </RadioGroup>
            </Box>
          </Stack>

          <Grid item xs={12}>
            <Stack sx={{ mt: 2 }}>
              <FormControl component="fieldset">
              <Typography  sx={{ fontWeight: "semiBold" }}>

                  Notes Position: Place the additional notes above or below the
                  order line items.
                </Typography>
                <RadioGroup
                  aria-label="notesPosition"
                  name="notesPosition"
                  value={notesPosition}
                  onChange={handleNotesPositionChange}
                >
                  <FormControlLabel
                    value="above"
                    control={<Radio />}
                    label="Above"
                  />
                  <FormControlLabel
                    value="below"
                    control={<Radio />}
                    label="Below"
                  />
                </RadioGroup>
              </FormControl>

              <FormControl component="fieldset">
              <Typography sx={{ pt:1, fontWeight: "semiBold" }}>
                  Receive Details Position: Place the Receive Details above or
                  below the order line items. 
                </Typography>
                <RadioGroup
                  aria-label="receiveDetailsPosition"
                  name="receiveDetailsPosition"
                  value={receiveDetailsPosition}
                  onChange={handleReceiveDetailsPositionChange}
                >
                  <FormControlLabel
                    value="above"
                    control={<Radio />}
                    label="Above"
                  />
                  <FormControlLabel
                    value="below"
                    control={<Radio />}
                    label="Below"
                  />
                </RadioGroup>
              </FormControl>
            </Stack>
          </Grid>
        </Grid>
      </div>
      <br />
      <br />
    </Container>
  );
}
