// @mui
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import "firebase/firestore";

import { Card, Link, Stack, alpha } from "@mui/material";
import { WhatsNew } from "../components/WhatsNew";
import LogImage from "../components/LogImage";
import { BugFixes } from "../components/BugFixes";
import LogText from "../components/LogText";
import LogSectionHeader from "../components/LogSectionHeader";
import TextCusomizationImg from "./assets/text-customization.jpg";
import WelcomeEmailImg from "./assets/welcome-email.jpg";
import InvoiceImg from "./assets/customized-invoice.png";

export const V2_1_9 = () => {
  return (
    <Card sx={{ width: "100%", padding: 4 }}>
      <Box sx={{ p: 2, display: "flex", width: "100%" }}>
        <Stack spacing={1} sx={{ width: "100%" }}>
          <Typography fontWeight={700}>V2.1.9 - Released 12-01-2023</Typography>
          <LogText>We got a lot of updates to cover in this one!</LogText>
          <BugFixes />
          <LogText>
            Clicking on a send estimate/invoice action was taking a long time
            for some customers. This is now fixed.
          </LogText>
          <WhatsNew />
          <LogText>
            Invoice Text Customization! This is a big one and I will cover it
            more below. tl;dr there is a new page in settings where you can
            customize how certain text appears to customers on invoices.
          </LogText>
          <LogSectionHeader>New User Emails</LogSectionHeader>
          <LogText>
            When you add a user to your organization they will now receive a
            welcome email with login information.
          </LogText>
          <LogImage src={WelcomeEmailImg} />
          <br />
          <LogSectionHeader>
            Adelie now has integrations with Go High Level.
          </LogSectionHeader>
          <LogText>
            A Go High Level subscription can be a game-changer for an event
            rental company, especially in areas crucial for business growth and
            client satisfaction. Firstly, reputation management is streamlined,
            allowing the company to effectively monitor and respond to client
            feedback across various platforms, enhancing brand image and trust.
            With integrated tools for Facebook and Google ads, Go High Level
            simplifies digital marketing, enabling targeted advertising
            campaigns to reach potential clients more efficiently. This not only
            boosts online presence but also drives conversions. Moreover, Go
            High Level's robust customer relationship management system ensures
            that all client interactions are tracked and managed seamlessly,
            leading to improved customer service and retention. In essence, Go
            High Level equips an event rental company with essential tools to
            enhance its online reputation, maximize advertising efficiency, and
            foster strong customer relationships, all pivotal for thriving in a
            competitive market.
          </LogText>
          <LogText>
            Contact us at info@adelielogistics.com to learn more about
            integrating your Adelie account with Go High Level.
          </LogText>

          <br />
          <LogSectionHeader>
          Invoice Text Customization
          </LogSectionHeader>
          <LogText>
          Ok, back to the big one. We heard from a lot of you that you would like a way to customize the verbiage that appears on invoices/estimates sent to your customers.
          </LogText>
          <LogText>
            {`Now you can do so in the Settings => Text Customization view.`}
          </LogText>
          <LogText>
          Right now we have text customization for invoices/estimates, but our goal is to eventually let you customize just about everything you see in the app, as well as your customers experience. So if there is something you REALLY want customizable, shoot us the feedback and we'll prioritize the most requested ones. You can also pick your preferred date style!
          </LogText>
          <LogImage src={TextCusomizationImg} />

          <LogText>
          The highlighted portions show corresponding customizable options.
          </LogText>

          <Box
                  sx={{
                    p: { xs: "8px", md: "20px" },
                    width: "auto",
                    borderRadius: 2,
                    bgcolor: (theme) => alpha(theme.palette.grey[500], 0.06),
                    border: (theme) =>
                      `dashed 1px ${theme.palette.primary.main}`,
                  }}
                >
          <LogImage src={InvoiceImg} height={600}/>
          </Box>

         <LogText>
         During the process of adding the text customization we also completely revamped the way invoices were downloaded and emailed. This should increase the speed with which invoices are emailed and downloaded.
         </LogText>
         <LogText>
            The payments portal has also gotten a refresh.
         </LogText>

         <LogText>
         We hope these new features and bug fixes improve your daily experience with Adelie. As always we want to hear your feedback and thank those that have submitted feedback, rest assured we read every one and they are logged in our feature requests log.
         </LogText>
        </Stack>
      </Box>
    </Card>
  );
};
