import { Box, Divider, Typography } from '@mui/material';
import React, { DOMAttributes, useEffect, useRef } from 'react'
import { functionsBaseUrl } from '../../../app/config/firebase';
import { useSelector } from 'react-redux';
import { authSelector } from '../../auth/authSlice';

type Props = {
    
}

type CustomElement<T> = Partial<T & DOMAttributes<T> & { children?: React.ReactNode }>;

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'justifi-payment-form': CustomElement<any>; // Specify more specific types as needed
    }
  }
}

export function JustifiOnboarding({}: Props) {
  const { orgData } = useSelector(authSelector);

  const formRef = useRef<HTMLFormElement>(null);
  const [webComponentToken, setWebComponentToken] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);

  useEffect(() => {
    if (!orgData) return;

    const getWebcomponentToken = async () => {
      setLoading(true);
      const url = functionsBaseUrl + "justifi";
      const data = {
        orgId: orgData.id,
        action: "webComponentToken",
        payload: {},
      };
      // needs a body passed to the fetch
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const json = await response.json();
      setWebComponentToken(json.access_token);
      setLoading(false);
    };

    getWebcomponentToken();
  }, [orgData]);

  useEffect(() => {
    const form = formRef.current;
    if (form) {
      const handleSubmit = (event: Event) => {
        // Handle the submit event
        console.log("Form submitted:", event);
      };
      form.addEventListener("submitted", handleSubmit);

      // Cleanup
      return () => form.removeEventListener("submitted", handleSubmit);
    }
  }, []);

  return (
    <Box>
      <Typography sx={{ mb: 2 }} gutterBottom variant="h6">
        This below form is provided by Justifi. It allows you to begin the process of setting up your business account with Justifi. If you run into any issues
        or have questions on what any fields mean, please contact jonny@adelielogistics.com.
      </Typography>

      <Divider />

      {
        loading ? (
          <Typography>Loading...</Typography>
        ) : (
          orgData.justifiBizAccount && webComponentToken && (
            <justifi-payment-provisioning ref={formRef} business-id={orgData.justifiBizAccount} auth-token={webComponentToken}></justifi-payment-provisioning>
          )
        )
      }

    </Box>
  );
}