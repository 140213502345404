import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  InputNumber,
  Row,
  Space,
  Table,
  Tooltip,
} from "antd";
import { Button as MuiButton } from '@mui/material';
import getSymbolFromCurrency from "currency-symbol-map";
import { FormatToLocalCurrency } from "../../../../../app/utils/currency/formatToLocalCurrency";
import FinalSubtotal from "./FinalSubtotal";
import { TagFilled, WarningFilled, ExclamationCircleFilled } from "@ant-design/icons";
import TextBody from "../../../../../app/system-components/typography/text/TextBody";
import { ROW_TYPE_SUBTOTAL } from "../../../../../app/utils/models/modelConstants/modelConstants";
import { authSelector } from "../../../../auth/authSlice";
import { useSelector } from "react-redux";
import TaxModal from "./TaxModal";
import TextSubtitle from "../../../../../app/system-components/typography/text/TextSubtitle";
import {
  COLOR_ORANGE,
  COLOR_RED_0,
  COLOR_YELLOW_0,
} from "../../../../../app/ui/colorConstants";
import Nifty from "../../../../../app/utils/Nifty";

const InvoiceTableSummary = ({
  invoiceTotal,
  setInvoiceTotal,
  selectedItems,
  customer,
  currentInvoice,
  invoiceTaxRate,
  setInvoiceTaxRate,
  setDeliveryFee,
  deliveryFee,
  setDeliveryFeeTaxable,
  deliveryFeeTaxable,
  getShippingFee,
}) => {
  const { orgData } = useSelector(authSelector);
  // if current invoice exists
  const [initialCustomer, setInitialCustomer] = useState(null);
  const [currencySymbol, setCurrencySymbol] = useState("");

  const [taxModal, setTaxModal] = useState({
    visible: false,
  });

  useEffect(() => {
    /**
     * set default on load
     * - new invoices
     * - existing invoices
     */

    console.log('invoiceTaxRate', invoiceTaxRate)
    if (currentInvoice) {
      setInvoiceTaxRate({
        rate: currentInvoice.taxRate,
        taxDetails: currentInvoice.taxMetaData,
      });
      setInitialCustomer(
        currentInvoice.customerQueryHook
          ? currentInvoice.customerQueryHook
          : null
      );
    } else {
      // new invoice
      const orgTaxRate = orgData.taxRate ? orgData.taxRate.rate : 0;
      const orgRateDetails = orgData.taxRate ? orgData.taxRate : null;
      setInvoiceTaxRate({
        rate: orgTaxRate,
        taxDetails: orgRateDetails,
      });
    }

    setCurrencySymbol(getSymbolFromCurrency(orgData.currencyCode) ?? "$");
  }, [currentInvoice]);
  useEffect(() => {
    /**
     * set rate based on actions
     * - customer selected
     * - new customer created
     * - selected from modal
     */
    if (customer && initialCustomer !== customer.id) {
      const customerTaxDetails =
        customer && customer.customerTaxRateDetails
          ? customer.customerTaxRateDetails
          : null;
      const orgTaxRate = orgData.taxRate ? orgData.taxRate : null;
      setInvoiceTaxRate({
        rate: customerTaxDetails
          ? customerTaxDetails.rate
          : orgTaxRate
          ? orgTaxRate.rate
          : 0,
        taxDetails: customerTaxDetails
          ? customerTaxDetails
          : orgTaxRate
          ? orgTaxRate
          : null,
      });
    }
  }, [customer]);

  useEffect(() => {
    /**
     * Recalculate total on item change or tax change
     */
    configureTax(configureInvoiceSubtotal(), customer);
  }, [
    invoiceTotal,
    selectedItems,
    invoiceTaxRate,
    deliveryFee,
    deliveryFeeTaxable,
  ]);

  const updateFinalDiscount = (payload, subtotal) => {
    const {
      totalDiscountPercent,
      totalDiscountNumber,
      totalDiscountType,
      hasTotalDiscount,
    } = payload;
    setInvoiceTotal({
      ...invoiceTotal,
      totalDiscountPercent: totalDiscountPercent,
      totalDiscountNumber: totalDiscountNumber,
      totalDiscountType: totalDiscountType,
      hasTotalDiscount: hasTotalDiscount,
    });
  };

  const configureInvoiceSubtotal = () => {
    const items = [...selectedItems.items];
    const delivery = parseFloat(deliveryFee);
    let subtotal = 0;
    // delivery fee
    subtotal = subtotal + delivery;
    items.map((i) => {
      if (i.rowType !== ROW_TYPE_SUBTOTAL) {
        subtotal = subtotal + (i.rowTotal ?? 0);
      }
    });
    // apply discounts
    if (invoiceTotal.hasTotalDiscount) {
      if (invoiceTotal.totalDiscountType === "percent") {
        const calcSub = subtotal - invoiceTotal.totalDiscountPercent * subtotal;
        subtotal = calcSub > 0 ? calcSub : 0;
      } else if (invoiceTotal.totalDiscountType === "number") {
        const discount = subtotal - invoiceTotal.totalDiscountNumber;
        subtotal = discount >= 0 ? discount : 0;
      }
    }
    return subtotal;
  };

  const configureAmtOff = () => {
    if (invoiceTotal.totalDiscountType === "percent") {
      return (
        invoiceTotal.subtotal / (1 - invoiceTotal.totalDiscountPercent) -
        invoiceTotal.subtotal
      ).toFixed(2);
    } else if (invoiceTotal.totalDiscountType === "number") {
      return invoiceTotal.totalDiscountNumber.toFixed(2);
    }
  };

  const configureTax = (newSubtotal, customer) => {
    if (!orgData) return;
    const invItems = [...selectedItems.items];
    // start taxable amt with delivery fee
    const customerIsTaxExempt = customer?.isTaxExempt ?? false;
    const deliveryFeeTaxableAmt = deliveryFeeTaxable
      ? parseFloat(deliveryFee)
      : 0;

    let totalTaxableAmt = customerIsTaxExempt ? 0 : deliveryFeeTaxableAmt;
    let totalItemAmt = parseFloat(deliveryFee);

    invItems.map((i) => {
      if (i.rowType !== ROW_TYPE_SUBTOTAL) {
        totalItemAmt = totalItemAmt + (i?.rowTotal ?? 0);
        if (i.selectedTaxable) {
          totalTaxableAmt = totalTaxableAmt + (i?.rowTotal ?? 0);
        }
      }
    });

    const configureProRate = (taxableAmt, totalAmt) => {
      if (taxableAmt === 0 || totalAmt === 0) return 0;
      // if (totalAmt === 0 && taxableAmt !== 0) return taxableAmt;
      return taxableAmt / totalAmt;
    };

    const configureAdjustedTaxAmt = (
      taxableAmt,
      discount,
      prorate,
      adjSubtotal
    ) => {
      if (taxableAmt === 0) return 0;
      if (adjSubtotal === 0) return 0;
      const amountOff = adjSubtotal / (1 - discount) - adjSubtotal;
      const proRatedAmtOff = amountOff * prorate;

      return taxableAmt - proRatedAmtOff;
    };
    const {
      total,
      taxRate,
      taxAmt,
      subtotal,
      hasTotalDiscount,
      totalDiscountType,
      totalDiscountPercent,
      totalDiscountNumber,
    } = invoiceTotal;
    // configure final discount

    let finalTaxableAmt = 0;
    if (hasTotalDiscount) {
      if (totalDiscountType === "percent") {
        finalTaxableAmt = configureAdjustedTaxAmt(
          totalTaxableAmt,
          totalDiscountPercent,
          configureProRate(totalTaxableAmt, totalItemAmt),
          newSubtotal
        );
      } else {
        const convertedPercent =
          totalDiscountNumber / (totalDiscountNumber + newSubtotal);
        finalTaxableAmt = configureAdjustedTaxAmt(
          totalTaxableAmt,
          convertedPercent,
          configureProRate(totalTaxableAmt, totalItemAmt),
          newSubtotal
        );
      }
    } else {
      finalTaxableAmt = totalTaxableAmt;
    }
    const finalTaxRounded = Nifty.RoundToTwoDecimals(
      finalTaxableAmt !== 0 ? finalTaxableAmt * invoiceTaxRate.rate : 0
    );
    const roundedNewSubtotal = Nifty.RoundToTwoDecimals(newSubtotal);
    if (total === finalTaxRounded + roundedNewSubtotal) {
      return finalTaxRounded;
    } else {
      setInvoiceTotal({
        taxAmt: finalTaxRounded,
        taxRate: invoiceTaxRate.rate,
        total: finalTaxRounded + roundedNewSubtotal,
        subtotal: roundedNewSubtotal,
        hasTotalDiscount: hasTotalDiscount,
        totalDiscountType: totalDiscountType,
        totalDiscountPercent: totalDiscountPercent,
        totalDiscountNumber: totalDiscountNumber,
      });
      return finalTaxRounded;
    }
  };

  const handleDeliveryFee = (e) => {
    const value = parseFloat(e?.toFixed(2));
    setDeliveryFee(value ? value : 0);
  };

  const calculateDeliveryFee = async () => {
    const calculatedFee = await getShippingFee() / 100;
    const value = parseFloat(calculatedFee?.toFixed(2));
    setDeliveryFee(value ? value : 0);

  };

  return (
    <Table.Summary className={"invoice-table-summary"}>
      <Table.Summary.Row>
        <Table.Summary.Cell colSpan={5} index={0}>
          <Space size={6}>
            <TextBody style={{ fontWeight: "bold" }} text={`Delivery Fee`} />
            <Tooltip title={`Base Rate: ${FormatToLocalCurrency(orgData.baseRate / 100, orgData.countryCode, orgData.languageCode, orgData.currencyCode)} By Mile: ${FormatToLocalCurrency(orgData.rangeRate / 100, orgData.countryCode, orgData.languageCode, orgData.currencyCode)}`}>
            <MuiButton size="small" variant="contained" onClick={calculateDeliveryFee}>
              Apply by Distance
            </MuiButton>
            </Tooltip>
          </Space>
        </Table.Summary.Cell>
        <Table.Summary.Cell colSpan={1} index={1}>
          <Checkbox
            value={deliveryFeeTaxable}
            onChange={(e) => {
              setDeliveryFeeTaxable(e.target.checked);
            }}
            style={{ marginLeft: "1.1em" }}
            checked={deliveryFeeTaxable}
          />
        </Table.Summary.Cell>
        <Table.Summary.Cell colSpan={1} index={4}>
          <div style={{ textAlign: "right" }}>
            <InputNumber
              value={deliveryFee}
              onChange={handleDeliveryFee}
              min={0}
              placeholder={0}
              style={{ width: "104px" }}
              formatter={(value) =>
                `${currencySymbol} ${value}`.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ","
                )
              }
              parser={(value) => value.replace(currencySymbol, "")} // removes the currency symbol and replaces empty string
            />
          </div>
        </Table.Summary.Cell>
      </Table.Summary.Row>
      <Table.Summary.Row>
        <Table.Summary.Cell colSpan={6} index={0}>
          <FinalSubtotal
            invoiceTotal={invoiceTotal}
            updateFinalDiscount={updateFinalDiscount}
            orgData={orgData}
          />
        </Table.Summary.Cell>
        <Table.Summary.Cell colSpan={1} index={4}>
          <div style={{ width: "100%", textAlign: "right" }}>
            <Space style={{ alignItems: "center" }}>
              {invoiceTotal.hasTotalDiscount && (
                <Tooltip
                  title={FormatToLocalCurrency(
                    configureAmtOff(),
                    orgData.countryCode,
                    orgData.languageCode,
                    orgData.currencyCode
                  )}
                >
                  <TagFilled style={{ marginRight: "8px", color: "#ffb700" }} />
                </Tooltip>
              )}

              <div style={{ fontWeight: "bold" }}>
                {FormatToLocalCurrency(
                  invoiceTotal.subtotal,
                  orgData.countryCode,
                  orgData.languageCode,
                  orgData.currencyCode
                )}
              </div>
            </Space>
          </div>
        </Table.Summary.Cell>
      </Table.Summary.Row>
      <Table.Summary.Row>
        <Table.Summary.Cell colSpan={6} index={0}>
          <Row justify={"space-between"}>
            <Col>
              <Space size={6}>
                <TextBody
                  style={{ fontWeight: "bold" }}
                  text={`Tax (${(invoiceTaxRate.rate * 100).toFixed(2)}% - ${
                    invoiceTaxRate.taxDetails?.name
                  })`}
                />
                <Button
                  size={"small"}
                  type={"link"}
                  onClick={() => setTaxModal({ visible: true })}
                >
                  Edit
                </Button>

                {orgData &&
                  orgData.qboRealmId &&
                  orgData.qboRealmId !== "" &&
                  invoiceTaxRate.taxDetails &&
                  (invoiceTaxRate.taxDetails.qbId === "" ||
                    !invoiceTaxRate.taxDetails.qbId) && (
                    <Tooltip
                      title={
                        "This tax rate is not linked to a QuickBooks rate and will fail to sync to QuickBooks"
                      }
                    >
                      <WarningFilled
                        style={{ marginRight: "8px", color: COLOR_RED_0 }}
                      />
                    </Tooltip>
                  )}
              </Space>
            </Col>
            <Col>
              {customer?.isTaxExempt && (
                <div style={{ textAlign: "right" }}>
                  <TextSubtitle
                    styleProps={{ color: COLOR_ORANGE }}
                    text={"Customer is Tax Exempt"}
                  />
                </div>
              )}
            </Col>
          </Row>
        </Table.Summary.Cell>
        <Table.Summary.Cell colSpan={1} index={4}>
          <div style={{ textAlign: "right", fontWeight: "bold" }}>
            {FormatToLocalCurrency(
              invoiceTotal.taxAmt,
              orgData.countryCode,
              orgData.languageCode,
              orgData.currencyCode
            )}
          </div>
        </Table.Summary.Cell>
      </Table.Summary.Row>

      <Table.Summary.Row>
        <Table.Summary.Cell colSpan={6} index={0}>
          <TextBody style={{ fontWeight: "bold" }} text={"Total"} />
        </Table.Summary.Cell>
        <Table.Summary.Cell colSpan={1} index={4}>
          <div
            style={{ textAlign: "right", fontWeight: "bold", fontSize: "18px" }}
          >
            {FormatToLocalCurrency(
              invoiceTotal.total,
              orgData.countryCode,
              orgData.languageCode,
              orgData.currencyCode
            )}
          </div>
        </Table.Summary.Cell>
      </Table.Summary.Row>
      <TaxModal
        visible={taxModal.visible}
        handleCancel={() => setTaxModal({ visible: false })}
        handleOk={(rate) => {
          setInvoiceTaxRate({
            rate: rate.rate,
            taxDetails: rate,
          });
          setTaxModal({ visible: false });
        }}
      />
    </Table.Summary>
  );
};

export default InvoiceTableSummary;
