import { useEffect, useState } from "react";
import { makeStyles } from '@mui/styles';

import { Customer } from "../../app/interfaces/customer";
import { Button, CircularProgress, Container, Divider, Stack, Table, Typography } from "@mui/material";
import { getFirebase, useFirestore } from "react-redux-firebase";

import { OrganizationData } from "../../app/interfaces/organizationData";
import { checkCustomer } from "../../app/utils/models/checkers/checkCustomer";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { functions } from "../../app/config/firebase";
// Define the prop type for orgData
interface CustomerSyncReportProps {
    orgData: OrganizationData;
  }

  const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
  });

export default function CustomerSyncReport({ orgData }: CustomerSyncReportProps) {
  const classes = useStyles();

  const firestore = useFirestore();
  const firebase = getFirebase();

  const [badCustomers, setBadCustomers] = useState<Customer[] | null>(null);
  const [loadingInitial, setLoadingInitial] = useState(false);
  const [loadingInitialComplete, setLoadingInitialComplete] = useState(false);

  interface CustomerStatus {
    processing: boolean;
    success: boolean;
    error: {
      Message: string;
      Detail: string;
      Code: string;
    } | null;
  }

  const [processingStatus, setProcessingStatus] = useState<{
    [customerId: string]: CustomerStatus;
  }>({});

  useEffect(() => {
    if (orgData) {
      setLoadingInitial(true);

      const loadCustomers = async () => {
        const customersQuery = await firestore
          .collection("orgs")
          .doc(orgData.id)
          .collection("customers")
          .where("qbId", "==", null)
          .get();

        const customers = customersQuery.docs.map(
          (snap) => checkCustomer(snap) as Customer
        );

        setBadCustomers(customers);
        setLoadingInitial(false);
        setLoadingInitialComplete(true);
      };

      loadCustomers();
    }
  }, [orgData]);

  // Function to trigger cloud function for a specific customer
  const syncCustomerToQuickBooks = async (index: number) => {
    const customer = badCustomers && badCustomers[index];
    if (!customer) {
      return;
    }

    // Update the processing status for this customer to "Processing"
    setProcessingStatus((prevProcessingStatus) => ({
      ...prevProcessingStatus,
      [customer.id]: {
        processing: true,
        success: false,
        error: null,
      },
    }));

    // Call your cloud function here for the customer at the given index
    try {
      // Perform the cloud function call
      const call = functions.httpsCallable("manualCustomerSync");

      return call({
        orgId: orgData.id,
        customerId: customer.id,
      })
        .then(async (res: any) => {
          console.log(res);
          const status = res.data.status;
          if (status === 200) {
            setProcessingStatus((prevProcessingStatus) => ({
              ...prevProcessingStatus,
              [customer.id]: {
                processing: false,
                success: true,
                error: null,
              },
            }));
          } else if (status === 500) {
            const errorDataArray = res.data.error.Fault.Error;
            const errorData = errorDataArray[0];

            setProcessingStatus((prevProcessingStatus) => ({
              ...prevProcessingStatus,
              [customer.id]: {
                processing: false,
                success: false,
                error: errorData || {
                  Message: "Sync Failed",
                  Detail:
                    "Sorry, we do not know why it failed. Please contact support.",
                  Code: "ERROR_CODE",
                },
              },
            }));
          }
        })
        .catch((err: any) => {
          console.log(err);
        });

      // If the cloud function call is successful, update the status to "Success"
    } catch (error) {
      // If there is an error, update the status to "Failure"
    }
  };

  // Sync all customers.
  const syncAllCustomers = async () => {
    if (!badCustomers || badCustomers?.length === 0) {
      return;
    }

    const updatedProcessingStatus = { ...processingStatus }; // Create a copy to update

    for (let index = 0; index < badCustomers.length; index++) {
      const customer = badCustomers[index];

      // Update the processing status for this customer to indicate processing.
      updatedProcessingStatus[customer.id] = {
        processing: true,
        success: false,
        error: null,
      };
      setProcessingStatus(updatedProcessingStatus);
    }

    for (let index = 0; index < badCustomers.length; index++) {
      await syncCustomerToQuickBooks(index)
    }

  };

  return (
    <>
      {loadingInitial && <CircularProgress />}

      {loadingInitialComplete && badCustomers && (
        <>
          <Divider />
          <br />

          <Stack spacing={2} alignItems={"center"} direction={"row"}>
            <Typography color={"darkred"}>
              You have {badCustomers?.length} customers that are not synced to
              your QuickBooks account.
            </Typography>

            <Button
              variant="contained"
              color="primary"
              onClick={() => syncAllCustomers()}
            >
              Sync All
            </Button>
          </Stack>

          <br />

          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>First Name</TableCell>
                  <TableCell align="right">Last Name</TableCell>
                  <TableCell align="right">Display Name</TableCell>
                  <TableCell align="right">Email</TableCell>
                  <TableCell align="right">Phone</TableCell>
                  <TableCell align="right">Sync</TableCell>
                  <TableCell align="right">Result</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {badCustomers.map((row, index) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.firstName}
                    </TableCell>

                    <TableCell align="right">{row.lastName}</TableCell>

                    <TableCell align="right">
                      {row.customerDisplayName}
                    </TableCell>
                    <TableCell align="right">{row.email}</TableCell>
                    <TableCell align="right">{row.mobile}</TableCell>

                    <TableCell align="right">
                      {processingStatus[row.id]?.processing ? (
                        <Button disabled>Processing...</Button>
                      ) : processingStatus[row.id]?.success ? (
                        <Button disabled>Synced</Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => syncCustomerToQuickBooks(index)}
                        >
                          Sync to QuickBooks
                        </Button>
                      )}
                    </TableCell>

                    <TableCell align="right">
                      {processingStatus[row.id]?.error ? (
                        <Stack direction={"column"}>
                          <Typography color={"darkred"}>
                            {processingStatus[row.id]?.error?.Message}
                          </Typography>
                          <Typography color={"darkred"}>
                            {processingStatus[row.id]?.error?.Detail}
                          </Typography>
                        </Stack>
                      ) : processingStatus[row.id]?.success ? (
                        <Typography color={"darkgreen"}>SUCCESS!</Typography>
                      ) : (
                        <>--</>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
}