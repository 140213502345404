import { useFirestore } from "react-redux-firebase";
import { useEffect, useState } from "react";
import moment from "moment";
import { useGetInvoicesForRangeQuery } from "../../../app/services/api";
import Nifty from "../../../app/utils/Nifty";
import NiftyInvoice from "../../../app/utils/NiftyInvoice";
import { useSelector } from "react-redux";
import { authSelector } from "../../auth/authSlice";

export function useTaxReport() {
  const firestore = useFirestore();
  const { fsOrgPrefix, orgData } = useSelector(authSelector);
  const [dateRange, setDateRange] = useState({
    start: moment().subtract(7, "days").startOf("day"),
    end: moment().endOf("day"),
  });
  const [taxData, setTaxData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [statCardData, setStatCardData] = useState({
    isFiltered: false,
    filteredAmt: 0,
    totalAmt: 0,
    filteredPercentage: 0,
    filteredPercentageLabel: "",
    selectedRates: [],
  });
  const [taxType, setTaxType] = useState("accrual");
  const [isFiltered, setIsFiltered] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [loading, setLoading] = useState(false);

  const {
    data: invoices,
    isFetching: fetchingInvoices,
    refetch: refetchInvoices,
  } = useGetInvoicesForRangeQuery({
    db: { firestore },
    orgPrefix: fsOrgPrefix,
    dateStart: dateRange.start ?? null,
    dateEnd: dateRange.end ?? null,
    orgTimezone: orgData?.orgTimezone,
    invoiceOnly: true,
  });

  console.log("FILTERS", selectedFilters);

  useEffect(() => {
    refetchInvoices();
  }, [dateRange]);

  useEffect(() => {
    const data = getTaxDetailsFromInvoice(invoices);
    const stats = configureStatCardData({
      taxData: data,
      selectedRateIds: selectedFilters,
      isFiltered,
    });
    const cData = isFiltered ? filterTaxDataById(data, selectedFilters) : data;
    const invoicesForTable = selectedInvoices(
      selectedFilters,
      cData,
      isFiltered
    );

    setTaxData(data);
    setStatCardData(stats);
    setChartData(cData);
    setFilteredInvoices(invoicesForTable);
  }, [invoices, selectedFilters]);

  const changeDateRange = (dates) => {
    setDateRange({
      start: dates?.[0]?.startOf("day"),
      end: dates?.[1]?.endOf("day"),
    });
  };

  /**
   * isFiltered, filteredAmt, totalAmt, filteredPercentage, selectedRates
   */
  const changeItemFilters = (items) => {
    setIsFiltered(items?.length !== 0);
    setSelectedFilters(items);
  };
  const getTaxDetailsFromInvoice = (invoices) => {
    if (!invoices) return [];
    const groups = invoices?.reduce(
      (result, item) => ({
        ...result,
        [item["taxMetaData"]["id"]]: [
          ...(result[item["taxMetaData"]["id"]] || []),
          item,
        ],
      }),
      {}
    );
    let result = [];
    for (const [key, value] of Object.entries(groups)) {
      const rate = `${
        value[0]?.taxRate ? `${value[0]?.taxRate * 100}%` : "Unknown rate"
      }`;
      const taxData = NiftyInvoice.GetTaxData(value);
      result.push({
        taxId: key,
        total: Nifty.SumObjectValuesInArray(value, "taxAmt"),
        totalTaxAmt: Nifty.SumObjectValuesInArray(value, "taxAmt"),
        totalTaxableAmt: Nifty.SumObjectValuesInArray(
          taxData.data,
          "totalTaxableAmt"
        ),
        name: `(${rate}) ${value[0]?.taxMetaData?.name ?? "Tax Name Unknown"}`,
        invoiceIds: value?.map((v) => v?.id ?? ""),
        invoices: value,
        invoiceTaxData: taxData.data,
      });
    }
    return Nifty.SortArrByNumber(result, "total");
  };

  const selectedInvoices = (selectedRateIds, taxData, isFiltered) => {
    if (!isFiltered)
      return Nifty.SortArrByNumber(
        taxData.map((i) => i.invoiceTaxData)?.flat(),
        "taxAmt"
      );
    const condition = [
      { key: "taxId", condition: "in-array", value: selectedRateIds },
    ];
    const selected = Nifty.FindObjectsWithMatchingConditions(
      taxData,
      condition
    )?.map((s) => s.invoiceTaxData);
    return Nifty.SortArrByNumber(selected.flat(), "taxAmt");
  };

  const filterTaxDataById = (taxData, selectedRateIds) => {
    const condition = [
      { key: "taxId", condition: "in-array", value: selectedRateIds },
    ];
    return Nifty.FindObjectsWithMatchingConditions(taxData, condition);
  };

  const configureStatCardData = ({ taxData, selectedRateIds, isFiltered }) => {
    let filteredAmt = 0;
    let totalAmt = 0;
    let filteredTaxableAmt = 0;
    let totalTaxableAmt = 0;
    let selectedRates = [];
    taxData?.map((i) => {
      if (selectedRateIds?.includes(i.taxId)) {
        filteredAmt = filteredAmt + i.total;
        filteredTaxableAmt = filteredTaxableAmt + i.totalTaxableAmt;
        selectedRates.push(i.name);
      }
      totalAmt = totalAmt + i.total;
      totalTaxableAmt = totalTaxableAmt + i.totalTaxableAmt;
    });
    const filteredPercentage = (filteredAmt / totalAmt) * 100;
    const filteredTaxableAmtPercentage =
      (filteredTaxableAmt / totalTaxableAmt) * 100;
    return {
      isFiltered,
      filteredAmt,
      totalAmt,
      filteredTaxableAmt,
      totalTaxableAmt,
      filteredPercentage: isNaN(filteredPercentage) ? 0 : filteredPercentage,
      filteredPercentageLabel: Nifty.NumberToPercentStr(filteredPercentage, 0),
      filteredTaxableAmtPercentage: isNaN(filteredTaxableAmtPercentage)
        ? 0
        : filteredTaxableAmtPercentage,
      filteredTaxableAmtPercentageLabel: Nifty.NumberToPercentStr(
        filteredTaxableAmtPercentage,
        0
      ),
      selectedRates,
    };
  };

  return {
    state: {
      dateRange,
      loading: fetchingInvoices,
      statCardData,
      chartData,
      isFiltered,
      selectedFilters,
      filteredInvoices,
      taxData,
    },
    actions: { changeDateRange, setDateRange, changeItemFilters },
  };
}
