import React, { useContext } from "react";
import { SalesReportContext } from "../../sales/SalesReport";
import { Card, List, Space, Tag } from "antd";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import { ROUTE_EDIT_INVOICE } from "../../../../app/routes";
import TextBody from "../../../../app/system-components/typography/text/TextBody";
import { ROW_TYPE_SUBTOTAL } from "../../../../app/utils/models/modelConstants/modelConstants";
import {
  COLOR_BLUE_0,
  COLOR_DARK_BG_0,
  COLOR_LIGHT_GRAY,
} from "../../../../app/ui/colorConstants";
import { UsageReportContext } from "../UsageReport";

const UsageInvoices = () => {
  const { state, actions } = useContext(UsageReportContext);
  return (
    <Card size={"small"}>
      <List
        header={"Invoices"}
        itemLayout="horizontal"
        dataSource={state.filteredInvoices}
        renderItem={(item) => (
          <List.Item
            extra={
              <Space>
                Invoice Total:{" "}
                <NumberFormat
                  value={item?.total?.toFixed(2)}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </Space>
            }
            // onClick={() => setSelectedInvoice(item)}
          >
            <List.Item.Meta
              title={
                <Link
                  to={`${ROUTE_EDIT_INVOICE}${item?.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TextBody
                    text={`#${item?.invoiceNumber}: ${item?.customer?.customerDisplayName}`}
                  />
                </Link>
              }
              description={
                <Space>
                  {item?.selectedItems?.items?.map((i) => {
                    if (i?.rowType === ROW_TYPE_SUBTOTAL) return null;
                    const isSelected = state.selectedInvItem === i?.id;
                    return (
                      <div>
                        <Tag
                          style={{
                            borderColor: "transparent",
                            margin: "4px",
                            background: isSelected
                              ? COLOR_BLUE_0
                              : COLOR_LIGHT_GRAY,
                            color: isSelected ? "#FFF" : COLOR_DARK_BG_0,
                          }}
                        >
                          <span
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            ({i?.selectedQty})
                          </span>{" "}
                          {i?.name}
                        </Tag>
                      </div>
                    );
                  })}
                </Space>
              }
            />
          </List.Item>
        )}
      />
    </Card>
  );
};

export default UsageInvoices;
