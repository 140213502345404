import React from 'react';
import { Card, CardHeader, CardContent, Typography } from '@mui/material';
import SelectReportItems from "./SelectReportItems";
import SalesReportChart from "./sales-bar-chart";

// Assuming EmptyTable is a React component that accepts a text prop
const EmptyTable: React.FC<{ text: string }> = ({ text }) => (
  <Typography variant="subtitle1" component="div">
    {text}
  </Typography>
);

const SalesChart = ({state, actions}: {state: any, actions: any}) => {

  console.log("STATE CHART DATA", JSON.stringify(state.chartData));

  return (
    <Card sx={{ minHeight: 150 }}>
      <CardHeader
        sx={{ display: 'flex', alignItems: 'start' }}
        title="Sales by Inventory"
        action={<SelectReportItems state={state} actions={actions}/>}
        titleTypographyProps={{ variant: 'h6' }}
      />
      <CardContent>
        {state.chartData && state.chartData.length > 0 ? (
          <SalesReportChart
            title=""
            subheader=""
            chart={{
              series: state.chartData
                .map((e: any) => {
                  return {
                    label: e.name,
                    value: e.filteredTotal,
                    qty: e.qty,
                  };
                })
                .filter((e: any) => e.value > 0),
            }}
          />
        ) : (
          <EmptyTable text="No sales data" />
        )}
      </CardContent>
    </Card>
  );
};

export default SalesChart;
