// @mui
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import "firebase/firestore";

import { Card, Link, Stack, alpha } from "@mui/material";
import { WhatsNew } from "../components/WhatsNew";
import LogImage from "../components/LogImage";
import { BugFixes } from "../components/BugFixes";
import LogText from "../components/LogText";
import LogSectionHeader from "../components/LogSectionHeader";
import ImagePreferences from "./assets/image-preferences.jpg";
import UserPermissions from "./assets/user-permissions.jpg";
import { MobileUpdates } from "../components/MobileUpdates";

export const V2_2_0 = () => {
  return (
    <Card sx={{ width: "100%", padding: 4 }}>
      <Box sx={{ p: 2, display: "flex", width: "100%" }}>
        <Stack spacing={1} sx={{ width: "100%" }}>
          <Typography fontWeight={700}>V2.2.0 - Released 12-14-2023</Typography>

          <BugFixes />
          <LogText>
            We have been busy fixing a number of bugs introduced as part of the
            last update to invoices. Sorry about that! Inventory item images not
            showing up was a big one which should be fixed now. The customer
            email has also been added back to the customer invoice information.
          </LogText>
          <LogText>
            We have added some additional preferences to help further customize
            the invoice and help with some of these issues, which takes us to...
          </LogText>
          <WhatsNew />
          <LogText>
            We added additional invoice customization. You can now customize the
            text for "Delivery Fee", set a relative desired size for invoice
            line item images, and set company logo size and dimensions to
            improve how it appears on pdf based on your preferences.
          </LogText>
          <LogImage src={ImagePreferences} />
          <br />
          <LogSectionHeader>User Permissions</LogSectionHeader>
            <LogText>
                This is a brand new much requested feature! You can now set user permissions and restrict who in your organization can see what.
                Users have types "Warehouse", "Office", and "Admin". Not everyone needs (or should) see everything in your account. With this new feature (only visible to "Admin" users)
                you can specify exactly WHO can see WHAT. Simply toggle on/off the respective user type for the specified view/feature.
            </LogText>
            <LogImage src={UserPermissions} />
        
        <br />
        <MobileUpdates />
        <LogText>
            You can now view the Tags assigned to invoices in the web app in the iOS and Android apps.
        </LogText>
        <br />
          <LogText>
            We hope these new features and bug fixes improve your daily
            experience with Adelie. As always we want to hear your feedback and
            thank those that have submitted feedback, rest assured we read every
            one and they are logged in our feature requests log.
          </LogText>
        </Stack>
      </Box>
    </Card>
  );
};
