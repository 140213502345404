// @mui
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import "firebase/firestore";

import { Card, Link, Stack } from "@mui/material";

export const V2_1_7 = () => {
  return (
    <Card>
      <Box sx={{ p: 2, display: "flex" }}>
        <Stack spacing={0.5}>
          <Typography fontWeight={700}>V2.1.7 - Released 11-6-2023</Typography>

          <Typography
            variant="body2"
            color="text.secondary"
            style={{ whiteSpace: "pre-wrap" }}
          >
            {`
What's new?

Sync Tools

We are super excited to release a suite of QuickBooks Online sync tools for those of you integrated with QuickBooks. These tools will help you understand which customers, inventory, and invoices have not properly synced to your QuickBooks account.

As a little bit of context, a QuickBooks invoice is made up of multiple objects. A customer, items, tax code, and other meta data. Every one of these items must have all the correct meta data for an invoice to sync properly. If the customer has not previously synced, the invoice will not sync. If any of the inventory items are not synced, the invoice will not sync. If the tax code is not a valid QuickBooks tax code, the invoice will not sync. You get the point.

So our goal with this update is to provide you tools to know what is and is not properly synced, what is preventing it from syncing, and the ability to re-sync them on your own.

So let's get into the changes.

The Inventory and Customers table now shows what is and is not synced at a glance.
In the detail view of each, there is a new tab where you can attempt to sync individual items and receive feedback on why an  object will not sync. 

`}
          </Typography>

          <Box display="flex" justifyContent="center" alignItems="center">
            <img
              alt="Dashboard"
              src="https://firebasestorage.googleapis.com/v0/b/adelie-logistics-production.appspot.com/o/public-images%2Fchange-log%2Fv2.1.7%2FXnapper-2023-11-06-14.14.55.png?alt=media&token=529913d8-573e-435a-9982-1af9f347b08d"
              style={{
                height: 400,
                width: "auto",
              }}
            />
          </Box>

          <Box display="flex" justifyContent="center" alignItems="center">
            <img
              alt="Dashboard"
              src="https://firebasestorage.googleapis.com/v0/b/adelie-logistics-production.appspot.com/o/public-images%2Fchange-log%2Fv2.1.7%2FXnapper-2023-11-06-14.42.17.png?alt=media&token=36c647d0-dd62-4b4c-984e-367a4856f55e"
              style={{
                height: 400,
                width: "auto",
              }}
            />
          </Box>

          <br />
          <Typography
            sx={{ mt: 2 }}
            variant="body2"
            color="text.secondary"
            style={{ whiteSpace: "pre-wrap" }}
          >
            {`In the general settings, if you are integrated with QuickBooks, you will not be able to create tax rates any more. You must create any new tax rate in your QuickBooks account, then in the Adelie general settings, click the sync tax codes button. This will sync any QuickBooks tax codes over to Adelie. If you have any tax codes already in Adelie that are NOT synced from QuickBooks, there will be a red warning area that lists out which tax codes are not synced, and would therefore cause an Invoice sync to fail.`}
          </Typography>

          <Box display="flex" justifyContent="center" alignItems="center">
            <img
              alt="Dashboard"
              src="https://firebasestorage.googleapis.com/v0/b/adelie-logistics-production.appspot.com/o/public-images%2Fchange-log%2Fv2.1.7%2Fgeneral-settings.png?alt=media&token=1a484610-08aa-40b8-91f1-b642c5e5e585"
              style={{
                height: 400,
                width: "auto",
              }}
            />
          </Box>

          <br />
          <Typography
            sx={{ mt: 2 }}
            variant="body2"
            color="text.secondary"
            style={{ whiteSpace: "pre-wrap" }}
          >
            {`In the Invoice view, if a customer or tax code is selected that does NOT have a QuickBooks ID, then you will see a small red warning next to it, indicating that if you proceed with that selection, the invoice sync will fail.`}
            <p />
            {`And finally, in the Integrations view, there is a new tab at the top called QuickBooks Sync. Here you can perform a number of tasks.

The first two sections are for syncing objects FROM QuickBooks TO Adelie. You can sync all inventory and all customers from QuickBooks to Adelie. This is recommended for new Adelie accounts that are getting set up and need to sync those objects over.`}
          </Typography>

          <Box display="flex" justifyContent="center" alignItems="center">
            <img
              alt="Dashboard"
              src="https://firebasestorage.googleapis.com/v0/b/adelie-logistics-production.appspot.com/o/public-images%2Fchange-log%2Fv2.1.7%2Fsync-tools.png?alt=media&token=c85c1524-59e1-4406-bdbd-0e60875399a2"
              style={{
                height: 600,
                width: "auto",
              }}
            />
          </Box>

          <Typography
            sx={{ mt: 2 }}
            variant="body2"
            color="text.secondary"
            style={{ whiteSpace: "pre-wrap" }}
          >
            <p></p>
            {`Below that, is the sync report.

You can choose to run a sync report on customers, inventory, and invoices. When you click "Run Report" it will display all objects that are not synced to QuickBooks. You can then choose to sync them individually, or to sync all.

You will receive feedback on whether the sync fails or succeeds. If it fails it will provide detailed error codes. These error codes come straight from the QuickBooks API, so if they are to difficult to understand, please send a copy of the error details and the object name to Adelie support for help analyzing: jonny@adelielogistics.com`}
          </Typography>

<br />
          <Box display="flex" justifyContent="center" alignItems="center">
            <img
              alt="Dashboard"
              src="https://firebasestorage.googleapis.com/v0/b/adelie-logistics-production.appspot.com/o/public-images%2Fchange-log%2Fv2.1.7%2FXnapper-2023-11-06-14.57.29.png?alt=media&token=f511bf34-cebc-49bd-9b6e-6ca9fbff6318"
              style={{
                height: 600,
                width: "auto",
              }}
            />
          </Box>
              <br />
          <Typography
            sx={{ mt: 2 }}
            variant="body2"
            color="text.secondary"
            style={{ whiteSpace: "pre-wrap" }}
          >
            {`We hope these new features help you eliminate the QuickBooks sync errors and streamline your workflow.`}
          </Typography>
        </Stack>
      </Box>
    </Card>
  );
};
