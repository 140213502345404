import React, { useContext } from "react";

import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent } from "@mui/material";

const SelectReportItems = ({state, actions}: {state: any, actions: any}) => {

  const [selectedItemIds, setSelectedItemIds] = React.useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof selectedItemIds>) => {
    const { target: { value } } = event;
    const idArray = typeof value === 'string' ? value.split(',') : value;

    actions.changeItemFilters(idArray);
    setSelectedItemIds(idArray);
  };

  return (
    <>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-checkbox-label">Filter</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={selectedItemIds}
          onChange={handleChange}
          input={<OutlinedInput label="Filter" />}
          renderValue={(selected: any[]) => selected.map((id: string) => (state.categories.find((cat: any) => id === cat.id).name)).join(", ")}
        >
          {state.categories?.map((i: any) => (
            <MenuItem key={i?.id} value={i.id}>
              <Checkbox checked={selectedItemIds.includes(i.id)} />
              <ListItemText primary={i.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default SelectReportItems;
