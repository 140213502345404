import React, { useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  Space,
  Tooltip,
  Progress,
  List,
} from "antd";
import TextBody from "../../app/system-components/typography/text/TextBody";
import { PictureFilled } from "@ant-design/icons";
import {
  COLOR_DARK_BG_0,
  COLOR_LIGHT_GRAY,
  COLOR_RED_0,
  COLOR_TEXT_GRAY_1,
  COLOR_TEXT_GRAY_2,
  COLOR_YELLOW_0,
} from "../../app/ui/colorConstants";
import TextSubtitle from "../../app/system-components/typography/text/TextSubtitle";
import QuickAdd from "./components/QuickAdd";
import InvMetaDataList from "./components/InvMetaDataList";
import TagTypeWithTooltip from "./components/TagTypeWithTooltip";
import { i18n } from "../../i18n";
import { useAvailableInventoryPaginated } from "../../app/services/hooks/useAvailableInventoryPaginated";

const AvailableInventory = ({
  rentalDates,
  inventory,
  searchStr,
  cart,
  setCart,
  setEmptyState,
  selectedTab,
}) => {

 

  useEffect(() => {
    let unmounted = false;
    !unmounted && refreshInvoices();
    return () => {
      unmounted = true;
    };
  }, [rentalDates, searchStr, inventory]);

  const {
    availableInventory,
    fetchingInvoices,
    refreshInvoices,
    fullInventory,
  } = useAvailableInventoryPaginated({
    startEndOpts: { useTurnaround: true, ...rentalDates },
    filterInventoryIds: inventory?.map((i) => i.id),
    invoiceItems: [],
    ignoreInvoicesForAvailability: [],
  });

  // const filterInventory = (inventory, selectedTab) => {
  //   switch (selectedTab) {
  //     case 'all':
  //       return inventory;
  //     case 'booked':
  //       return inventory.filter(item => item.availableStock < item.stock);
  //     case 'overbooked':
  //       return inventory.filter(item => item.availableStock < 0);
  //     default:
  //       return inventory;
  //   }
  // };

  // const filteredInventory = filterInventory(availableInventory, selectedTab);


  const configurePercent = (i) => {
    const { availableStock, stock, unavailableStock, unavailableEstStock } = i;
    return stock ? (availableStock / stock) * 100 : 0;
  };

  const configureSuccessPercent = (i) => {
    const { availableStock, stock, unavailableStock, unavailableEstStock } = i;
    return stock ? ((availableStock - unavailableEstStock) / stock) * 100 : 0;
  };

  const handleEmptyState = () => {
    setEmptyState(!hasRentalDates());
    return (
      <TextBody
        style={{ color: COLOR_TEXT_GRAY_1 }}
        text={i18n("availabilityPage.selectDates")}
      />
    );
  };

  const hasRentalDates = () => {
    return (
      rentalDates.rentalDateStart !== null && rentalDates.rentalDateEnd !== null
    );
  };

  const progressBarTextColor = (i) => {
    
    if (i.type === "bundle") {
      let backgroundColor;
      if (i.availableStock === 0) {
        backgroundColor = COLOR_YELLOW_0;

      } else if (i.availableStock < 0) {
        backgroundColor = COLOR_RED_0;

      } else {
        backgroundColor = COLOR_DARK_BG_0;

      }
      return backgroundColor
    } else {
      return i.availableStock < 0
      ? "#FF0000"
      : "#4A4A4D"
    }
  };

  const progressBarAvailabilityNumber = (i) => {
    
    if (i.type === "bundle") {
      let availabilityNumber;
      if (i.availableStock <= 0) {
        availabilityNumber = 0;
      } else {
        availabilityNumber = i.availableStock;
      }

      return availabilityNumber

    } else {
      return i.availableStock;
    } 
  };
  
  return (
    <div
      style={{
        width: "100%",
        height: "calc(100vh - 217px)",
        overflowY: "auto",
      }}
    >
      { hasRentalDates() ? (
        <List
          locale={{ emptyText: (fetchingInvoices || !availableInventory) ? "Loading..." : "No items" }}
          itemLayout="horizontal"
          dataSource={availableInventory}
          renderItem={(i) => (
            <List.Item>
              <Card style={{ width: "100%" }} size="small" key={i.id}>
                <Row align="middle" gutter={12} justify={"space-between"}>
                  <Col style={{ marginRight: "24px" }}>
                    <Space>
                      <div>
                        {i.image ? (
                          <img
                            src={i.image.downloadURL}
                            style={{
                              height: "76px",
                              width: "76px",
                              objectFit: "contain",
                            }}
                            alt={i.image.fileName}
                            onError={(e) => {
                              e.target.onerror = null; // Prevents looping
                              e.target.style.display = "none"; // Hide the img element
                              e.target.nextSibling.style.display = "inline-block"; // Show the PictureFilled
                            }}
                          />
                        ) : null}
                        <PictureFilled
                          style={{
                            color: COLOR_TEXT_GRAY_2,
                            fontSize: "76px",
                            display: i.image ? "none" : "inline-block",
                          }}
                        />
                      </div>
                      <div style={{ width: "100%" }}>
                        <Space>
                          <TextBody style={{ fontSize: "16px", fontWeight: "bold" }} text={i.name} />
                          <TextSubtitle style={{ fontSize: "14px" }} text={i.description} />
                          <TagTypeWithTooltip data={i} availableInventory={fullInventory} />
                        </Space>
                        <div style={{ width: "200px", marginTop: "4px" }}>
                          {i.stock ? (
                            <Tooltip
                              title={`Available: ${progressBarAvailabilityNumber(i)} | Pending: ${i.unavailableEstStock} | Total: ${i.stock ? i.stock : "NA"}`}
                            >
                              <Progress
                                strokeColor={"#BAE7CB"}
                                format={(p) => <TextSubtitle text={`${progressBarAvailabilityNumber(i)} Left`} color={progressBarTextColor(i)} />}
                                percent={configurePercent(i)}
                                success={{
                                  percent: configureSuccessPercent(i),
                                  strokeColor: "#38BB69",
                                }}
                              />
                            </Tooltip>
                          ) : (
                            <TextSubtitle text={`No stock info available`} />
                          )}
                        </div>
                      </div>
                    </Space>
                  </Col>
                  <Col>
                    <QuickAdd itemMax={i.availableStock} setCart={setCart} cart={cart} itemId={i.id} item={i} />
                  </Col>
                </Row>
                {i.invoices && <InvMetaDataList key={i.selectedItemId} invoices={i.invoices} itemId={i.id} />}
              </Card>
            </List.Item>
          )}
        />
      ) : (
        handleEmptyState()
      )}
    </div>
  );
};

export default AvailableInventory;

export const useAvailableInventoryDbWrapper = () => {};
