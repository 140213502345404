import { ExtendedFirestoreInstance } from 'react-redux-firebase';
import notificationError from '../../../system-components/toasters/notificationError';
import { VENDOR_COLLECTION } from '../../../utils/models/collections/collectionConstants';
import { Vendor } from '../../../interfaces/vendor';

export function vendorWrites({ firestore }: { firestore: ExtendedFirestoreInstance }, orgPrefix: string) {
  if (!orgPrefix) return notificationError('Organization error', 'Cannot determine organization');
  const ref = firestore.collection(`${orgPrefix}${VENDOR_COLLECTION}`);

  async function createVendor(payload: any) {
    const newRef = firestore.collection(`${orgPrefix}${VENDOR_COLLECTION}`).doc();
    return await newRef
      .set({
        id: newRef.id,
        ...payload,
      })
      .then(() => {
        return newRef.id;
      });
  }
  async function editVendor(id: string, payload: Partial<Vendor>) {
    delete payload.additionalContacts;
    return await ref.doc(id).set({ ...payload }, { merge: true });
  }
  async function deleteVendor(id: string) {
    return await ref.doc(id).delete();
  }

  return {
    createVendor: createVendor, // create vendor
    editVendor: editVendor, // edit vendor
    deleteVendor: deleteVendor, // delete vendor
  };
}
