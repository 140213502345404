import { centsToDollars } from "./checkCurrencyToDollars";
import { checkUndefined, checkUndefinedOrNull } from "./checkUndefined";
import {
  DocumentData,
  DocumentSnapshot,
  QueryDocumentSnapshot,
} from "@firebase/firestore-types";
import { Part } from "../../../interfaces/part";
import { Vendor } from "../../../interfaces/vendor";

export const checkPart = (
  snap: QueryDocumentSnapshot<DocumentData> | DocumentSnapshot<DocumentData>,
  alreadyConverted?: boolean
) => {

  const data: Partial<Part> = alreadyConverted
    ? snap
    : snap.data() ?? {};

  const item: Part = {
    id: data && data.id ? data.id : "",
    qbId: checkUndefined(data.qbId, null),
    orgId: checkUndefined(data.orgId, ""),
    type: checkUndefined(data.type, "rental"),
    name: checkUndefined(data.name, ""),
    description: checkUndefined(data.description, ""),
    stock: checkUndefined(data.stock, null),
    categories: checkUndefined(data.categories, []),
    categoriesQueryHook: checkUndefined(data.categoriesQueryHook, []),
    vendor: data.vendor ?? null,
    vendorQueryHook: checkUndefinedOrNull(data.vendorQueryHook, null),
    price: checkUndefined(data.price, 0),
    notes: checkUndefined(data.notes, ""),
    image: data.image
      ? {
        downloadURL: data.image.downloadURL ? data.image.downloadURL : "",
        fileName: data.image.fileName ? data.image.fileName : "",
      }
      : null,
    isAvailable: data.isAvailable ? data.isAvailable : false,
    isActive: data.isActive ? data.isActive : false,
    isTaxable: data.isTaxable ? data.isTaxable : false,
    createdOn: data.createdOn ? data.createdOn : null,
    createdBy: data.createdBy ? data.createdBy : "",
    updatedOn: data.updatedOn ? data.updatedOn : null,
    updatedBy: data.updatedBy ? data.updatedBy : "",
    createdOnPlatform: checkUndefined(data.createdOnPlatform, null),
    updateSource: "adelie",
    weight: data.weight ? data.weight : null,
  };
  return item;
};

export const checkPartForInvoice = () => {};
