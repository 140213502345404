// @mui
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import "firebase/firestore";

import { Card, Stack } from "@mui/material";
import { WhatsNew } from "../components/WhatsNew";
import LogImage from "../components/LogImage";
import { BugFixes } from "../components/BugFixes";
import LogText from "../components/LogText";
import LogSectionHeader from "../components/LogSectionHeader";

export const V2_2_2 = () => {
  return (
    <Card sx={{ width: "100%", padding: 4 }}>
      <Box sx={{ p: 2, display: "flex", width: "100%" }}>
        <Stack spacing={1} sx={{ width: "100%" }}>
          <Typography fontWeight={700}>V2.2.2 - Released 01-28-2024</Typography>

          <br />
          <WhatsNew />
          <LogSectionHeader>More invoice template customization.</LogSectionHeader>

          <LogText>
            You can now choose where to position the delivery details and the additional notes. They can be above, or below the invoice line items. This can be found at the bottom of the invoice template customization page.
          </LogText>

          <br />
          <LogSectionHeader>Dashboard Enhancements.</LogSectionHeader>

          <LogText>
          We are excited to release a big update to the Dashboard. You can now change what dates to view! Use the quick picker to quickly select pre defined ranges like this week, next week, tomorrow, and more.
          </LogText>

          <LogText>
          The table now shows much more data including invoice balance, address information, and more. Hover over the address or email for quick copy to clipboard actions.
                    </LogText>

          <LogText>
          We have added a weather widget and have plans to expand on weather functionality for things like receiving alerts when there are high winds (especially helpful for those of you setting up tents and inflatables) and precipitation.          </LogText>

          <LogText>
          Some other small enhancements is adding the "Show In Shop" column to inventory table and adding the signature image and date to invoice pdfs.          </LogText>

          <br />
          <LogText>
            We hope this new feature helps you stay organized and efficient!
          </LogText>
        </Stack>
      </Box>
    </Card>
  );
};
