import { _mock } from './_mock';
import { _tags } from './assets';

// ----------------------------------------------------------------------

const GB = 1000000000 * 24;

export const FILE_TYPE_OPTIONS = [
  'txt',
  'image',
  'pdf',
];

// ----------------------------------------------------------------------


