import { ROW_TYPE_SUBTOTAL } from "../../../../utils/models/modelConstants/modelConstants";
import { sortByNum, sortByNumberValue } from "../../../../utils/sorters/sortBy";
import { CheckSelectedItem } from "../../../../utils/models/checkers/checkSelectedItem";
import { FormatForSalesChart } from "./formatForSalesChart";

export const GetSubtotalsByInventory = ({
  invoices,
  inventoryIds,
  filterItemIds,
  inventory,
}) => {
  if (!invoices || !inventoryIds || !inventory) return [];
  let inventoryListIds = inventoryIds.map((id) => {
    const inv = inventory?.find((c) => c?.id === id);
    return {
      inventoryId: id,
      name: inv?.name ?? "",
      total: 0,
      filteredTotal: 0,
      qty: 0,
    };
  });

  invoices?.forEach((invoice) => {
    const selectedItems = invoice?.selectedItems?.items ?? null;
    selectedItems &&
      selectedItems.map((item) => {
        const safeItem = CheckSelectedItem(item);
        inventoryListIds = inventoryListIds?.map((i) => {
          return FormatForSalesChart({
            item: safeItem,
            categoryFilterIds: filterItemIds,
            inventoryListItem: i,
          });
        });
      });
  });
  const list = inventoryListIds?.filter((item) => {
    return item?.total !== 0 || item?.filteredTotal !== 0;
  });
  return sortByNumberValue(list, "filteredTotal");
};
