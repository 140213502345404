// @mui
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import "firebase/firestore";

import { Card, Link, Stack } from "@mui/material";

export const V2_1_5 = () => {
  return (
    <Card>
    <Box sx={{ p: 2, display: "flex" }}>
      <Stack spacing={0.5}>
        <Typography fontWeight={700}>
          V2.1.5 - Released 10-19-2023
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          style={{ whiteSpace: "pre-wrap" }}
        >
          {`
Bug Fixes!

• Bug fix for overbooked icon on Prepare The Items report.
• Bug fixes for mobile inventory loading feature. NOTE: All users will need to update to the latest versions on iOS (version 1.4.4) and Android (version 1.2.3)

`}
        </Typography>
      </Stack>
    </Box>
  </Card>
  );
};
