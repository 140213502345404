import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { createFirestoreInstance } from "redux-firestore";
import firebase from "./app/config/firebase";
import { store } from "./app/store";
import { getAntdLanguage } from './i18n';
import { ConfigProvider } from 'antd';
import { SettingsProvider } from "./app/settings";
import ThemeProvider from "./app/theme";
import { FirestoreProvider } from "./app/global-context/FirestoreProvider";
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns'
import { WeatherProvider } from "./domains/sharedComponents/weather/context/weather-context";
import { SnackbarProvider } from "./domains/file-manager/snackbar";

const rrfConfig = {
  // userProfile: "orgs/users",
  attachAuthIsReady: true,
  useFirestoreForProfile: true,
  updateProfileOnLogin: false,
};

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

ReactDOM.render(
  <ConfigProvider locale={getAntdLanguage()}>
    <SettingsProvider
      defaultSettings={{
        themeMode: "light", // 'light' | 'dark'
        themeDirection: "ltr", //  'rtl' | 'ltr'
        themeContrast: "default", // 'default' | 'bold'
        themeLayout: "vertical", // 'vertical' | 'horizontal' | 'mini'
        themeColorPresets: "purple", // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
        themeStretch: true,
      }}
    >
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Provider store={store}>
            <ReactReduxFirebaseProvider {...rrfProps}>
              <BrowserRouter>
              <WeatherProvider>
              <SnackbarProvider>
                <FirestoreProvider>
                  <App />
                </FirestoreProvider>
              </SnackbarProvider>
                </WeatherProvider>
              </BrowserRouter>
            </ReactReduxFirebaseProvider>
          </Provider>
        </LocalizationProvider>
      </ThemeProvider>
    </SettingsProvider>
  </ConfigProvider>,

  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
