// @mui
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import "firebase/firestore";

import { Card, Link, Stack } from "@mui/material";

export const V2_1_1 = () => {
  return (
    <Card>
    <Box sx={{ p: 2, display: "flex" }}>
      <Stack spacing={0.5}>
        <Typography fontWeight={700}>
          V2.1.1 - Released 10-10-2023
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          style={{ whiteSpace: "pre-wrap" }}
        >
          {`
Whats new?

• Updates to the embedded checkout options. Users with Adelie shopping carts can now customize the end of checkout messages.
• If you have sent emails to customers, and gotten a response, then had trouble finding the invoice, this feature is for you. In the general settings, there is now a checkbox 
called "Automatically Add Invoice Number To Email Subject Line". If you check this box, future emails sent will automatically have the invoice number appended in the email subject line.
Thanks to Belmont Party Rentals for championing this feature.
`}
        </Typography>
      </Stack>
    </Box>
  </Card>
  );
};
