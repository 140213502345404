import React, { useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import firebase from "firebase/app";


import { Box, Card, Button, TextField, Typography, Stack } from '@mui/material';
import { OrganizationData } from '../../../app/interfaces/organizationData';
import { useFirestore } from 'react-redux-firebase';
import { Link } from '@mui/material';
import { Link as RouterLink} from 'react-router-dom';
import { ROUTE_CHANGE_LOG } from '../../../app/routes';
import AttentionTag from '../../../app/ui/AttentionTag/attentionTag';

const WhatsNewWidget = ({
  orgData,
  userData,
}: {
  orgData: OrganizationData;
  userData: any;
}) => {
    
  const db = useFirestore();

  return (
    <Card sx={{ padding: "12px", height: "100%" }}>
      <Stack direction={"row"} gap={2} mb={1}>
      <Typography variant="h6" component="h6">
        What's New In Adelie?
      </Typography>

      <AttentionTag arrowDirection={null} />
      </Stack>
      
      <Typography variant="body1">
        Email Automations!
      </Typography>

      <RouterLink to={ROUTE_CHANGE_LOG}>Learn more in latest Change Log</RouterLink>

      <br />
      <br />

      <Link
        href="https://youtu.be/OTzvfpj_TNI?si=3WwX51XP7SMnobp2"
        target="_blank"
        rel="noopener noreferrer"
      >
        Latest YouTube Training: Email Automations
      </Link>
      <br />
    </Card>
  );
};

export default WhatsNewWidget;
