import React, { useEffect, useState } from "react";
import InvoiceDetail from "./InvoiceDetail";
import { invoices } from "../../app/services/firestore/queries/invoiceQueries";
import { Spin } from "antd";
import { Spinner } from "../../app/ui/spinner";
import { useSelector } from "react-redux";
import { authSelector } from "../auth/authSlice";
import { useFirestore } from "react-redux-firebase";

const EditInvoice = ({ match }: { match: any; history: any }) => {
  const id: string = match.params.id;
  const { fsOrgPrefix, orgData } = useSelector(authSelector);
  const firestore = useFirestore();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    invoices(orgData?.orgTimezone)
      .fetchInvoice({ firestore }, fsOrgPrefix, {
        id: id,
      })
      .then((invoice) => {
        setData(invoice);
        setLoading(false);
      });
      
  }, [id, fsOrgPrefix, match]);

  return (
    <div>
      <Spin indicator={Spinner} spinning={loading}>
        {data && <InvoiceDetail id={id} initialInvoice={data && data} cartItems={undefined}/>}
      </Spin>
    </div>
  );
};

export default EditInvoice;
