import React, { FC, useEffect, useState } from "react";
import {
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Space,
  Switch,
  Tooltip,
} from "antd";
import TextSubtitle from "../../../../app/system-components/typography/text/TextSubtitle";
import TextBody from "../../../../app/system-components/typography/text/TextBody";
import { InventoryItem } from "../../../../app/interfaces/inventoryItem";
import { Part } from "../../../../app/interfaces/part";

const NewPartAdditional: FC<{
  form: any;
  item?: Part;
  isEdit?: any;
  visible?: any;
  drawerView?: any;
  showWeightInput?: boolean;

}> = ({ form, item, isEdit, visible, drawerView, showWeightInput }) => {
  const [defaultToggle, setDefaultToggle] = useState<{
    isAvailable: boolean;
    isActive: boolean;
    isTaxable: boolean;
  }>({
    isAvailable: true,
    isActive: true,
    isTaxable: true,
  });

  useEffect(() => {
    if (isEdit && visible) {
      const toggleData = {
        isAvailable: !!item && item.isAvailable,
        isActive: !!item && item.isActive,
        isTaxable: !!item && item.isTaxable,
        weight: !!item && item.weight,
      };
      form.setFieldsValue(toggleData);
      setDefaultToggle(toggleData);
    }
  }, [visible]);

  return (
    <div>
        <Card
        size="small"
        title={
          <TextBody style={{ fontWeight: "bold" }} text={"Additional Info"} />
        }
        bordered={!drawerView}
      >
        <Form.Item name="notes">
          <Input.TextArea placeholder="Additional Notes" rows={2} />
        </Form.Item>

        {showWeightInput && (
          <Form.Item name="weight" label={<TextSubtitle text={"WEIGHT (lb/kg)"} />}>
            <InputNumber min={0} placeholder={"0"} style={{ width: "120px" }} />
          </Form.Item>
        )

        }
        <Form.Item
          name="isAvailable"
          style={{ marginBottom: 0 }}
          initialValue={isEdit ? defaultToggle.isAvailable : true}
          valuePropName="checked"
        >
          <Switch
            size="small"
            defaultChecked={isEdit ? item?.isAvailable : true}
            onChange={(e) => form.setFieldsValue({ isAvailable: e })}
          />{" "}
          Is Available
        </Form.Item>
        <Form.Item
          name="isActive"
          style={{ marginBottom: 0 }}
          initialValue={isEdit ? defaultToggle.isActive : true}
          valuePropName="checked"
        >
          <Switch
            size="small"
            defaultChecked={isEdit ? item?.isActive : true}
            onChange={(e) => form.setFieldsValue({ isActive: e })}
          />{" "}
          Is Active
        </Form.Item>
        <Form.Item
          name="isTaxable"
          style={{ marginBottom: 0 }}
          initialValue={isEdit ? defaultToggle.isTaxable : true}
          valuePropName="checked"
        >
          <Switch
            size="small"
            defaultChecked={isEdit ? item?.isTaxable : true}
            onChange={(e) => form.setFieldsValue({ isTaxable: e })}
          />{" "}
          Taxable
        </Form.Item>

      </Card>
    </div>
  );
};

export default NewPartAdditional;
