import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import "../../App.less";
import { Affix, Button, Form, Modal, PageHeader, Tabs } from "antd";
import TabPane from "antd/lib/tabs/TabPane";

import AdminInventory from "./AdminInventory";
import { CalendarView } from "./components/calendar/view";
import AdminParts from "./AdminParts";

import { authSelector } from "../auth/authSlice";

const InventoryContainer = ({history}) => {

    const [tabType, setTabType] = useState("inventory"); // inventory , parts, services
    const { orgData } = useSelector(authSelector);

    return (
      <div>
        <Affix>
          <PageHeader
            className="PageHeader"
            backIcon={false}
            title="Settings"
          />
        </Affix>
        <div style={{ paddingTop: "68px" }}>
          <div style={{ width: "100%", marginLeft: "14px" }}>
            <Tabs
              defaultActiveKey="inventory"
              activeKey={tabType}
              onChange={setTabType}
            >
              <TabPane tab="Inventory" key="inventory" />

              { orgData && orgData.allowInventoryMaintenance && (
                <>
                  <TabPane tab="Maintenance Parts" key="parts" />
                  {/* <TabPane tab="Service Items" key="services" /> */}
                  <TabPane tab="Maintenance Calendar" key="calendar" />
                </>
              )}

            </Tabs>
          </div>

          {tabType === "inventory" && <AdminInventory history={history} />}
          {tabType === "parts" && <AdminParts history={history} />}
          {tabType === "calendar" && <CalendarView />}

          {/* {tabType === "services" && (< AdminServiceItems history={history}/>)} */}
        </div>
      </div>
    );

}

export default InventoryContainer;
