import notificationError from "../../../system-components/toasters/notificationError";
import { VENDOR_COLLECTION } from "../../../utils/models/collections/collectionConstants";
import { checkVendor } from "../../../utils/models/checkers/checkVendor";
import { sortBy } from "../../../utils/sorters/sortBy";

export function vendorQueries({ firestore }, orgPrefix) {
  if (!orgPrefix)
    return notificationError(
      "Organization error",
      "Cannot determine organization"
    );
  const ref = firestore.collection(`${orgPrefix}${VENDOR_COLLECTION}`);

  async function fetchVendors() {
    const snap = await ref.get();
    let list = [];
    snap.forEach((s) => {
      list.push(checkVendor(s));
    });
    return sortBy(list, 'companyName');
  }
  async function fetchActiveVendors() {
    const snap = await ref.where("isActive", "==", true).get();
    let list = [];
    snap.forEach((s) => {
      list.push(checkVendor(s));
    });
    return sortBy(list, 'companyName');
  }
  async function fetchVendor(id) {
    const snap = await ref.doc(id).get();
    return checkVendor(snap);
  }
  return {
    fetchVendors: fetchVendors, // fetch vendors
    fetchVendor: fetchVendor, // fetch vendor
    fetchActiveVendors: fetchActiveVendors, // fetch active vendors only
  };
}
