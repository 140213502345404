import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import {
  GridFilterInputValueProps,
  GridFilterOperator,
} from "@mui/x-data-grid-pro";
import { FC, useState } from "react";
import { COLOR_PURPLE_0 } from "../../../../app/ui/colorConstants";
import { InventoryCategory } from "../../../../app/interfaces/inventoryCategory";
import { InventoryItem } from "../../../../app/interfaces/inventoryItem";
import { Part } from "../../../../app/interfaces/part";

export const getTagFilterOperator = (
  categories: InventoryCategory[]
): GridFilterOperator<InventoryItem | Part, any, any> => ({
  // https://mui.com/x/react-data-grid/filtering/
  label: "Category Select",
  value: "Categories",
  getApplyFilterFn(filterItem, column) {
    if (!filterItem.field || !filterItem.value || !filterItem.operator) {
      return null;
    }
    const selectedCategories: string[] = filterItem.value;
    return (params) => {
      const currentCategoryIds = params.row.categories.map((c) => c.id);
      return selectedCategories.some((id) => currentCategoryIds.includes(id));
    };
  },
  InputComponent: (props) => (
    <CategoryFilterComponent categories={categories} {...props} />
  ),
});

export const CategoryFilterComponent: FC<
  GridFilterInputValueProps & { categories?: InventoryCategory[] }
> = (props) => {
  const [checkedCategoryIds, setCheckedCategoryIds] = useState<string[]>(
    props.item.value ?? []
  );
  return (
    <FormGroup sx={{ marginX: "8px" }}>
      {props.categories?.map((category) => (
        <FormControlLabel
          key={category.id}
          control={
            <Checkbox
              sx={{
                paddingY: 0,
                marginY: 0,
                color: COLOR_PURPLE_0,
                "&.Mui-checked": {
                  color: COLOR_PURPLE_0,
                },
              }}
              checked={checkedCategoryIds.includes(category.id)}
              onChange={() =>
                setCheckedCategoryIds((categories) => {
                  if (categories.includes(category.id))
                    return categories.filter((c) => c !== category.id);
                  else return categories.concat([category.id]);
                })
              }
            />
          }
          label={category.name}
        />
      ))}

      <button
        className="ant-btn ant-btn-primary ant-btn-sm"
        onClick={() => {
          props.applyValue({ ...props.item, value: checkedCategoryIds });
        }}
      >
        Save
      </button>
    </FormGroup>
  );
};
