// @mui
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import "firebase/firestore";

import { Card, Link, Stack } from "@mui/material";
import { WhatsNew } from "../components/WhatsNew";
import LogImage from "../components/LogImage";
import { BugFixes } from "../components/BugFixes";
import LogText from "../components/LogText";
import LogSectionHeader from "../components/LogSectionHeader";

export const V2_2_5 = () => {
  return (
    <Card sx={{ width: "100%", padding: 4 }}>
      <Box sx={{ p: 2, display: "flex", width: "100%" }}>
        <Stack spacing={1} sx={{ width: "100%" }}>
          <Typography fontWeight={700}>V2.2.5 - Released 7-31-2024</Typography>

          <LogText>
            Hello everyone! 👋 It has been some time since our last change log. We have been super busy! And while it's been a while since the last change log, we have been steadily adding new features over the last few months. So, let's recap!
          </LogText>

          <WhatsNew />
          
          <LogSectionHeader>Customer Email Receipt 📧</LogSectionHeader>
          <LogText>
            At long last, we have built in customer payment receipt emails! You can customize and turn them off or on in the {`Settings > Email`} section. This will send out a receipt email to the customer upon payment (whether that is through the payment portal or through the Adelie Web App). The admin email will also receive an email notifying a payment was made.
          </LogText>
          <LogText>
            If you are using Stripe and are happy with those emailed receipts, you can simply turn off these emails by clicking on the "Turn Off Receipts" button.
          </LogText>

          <LogSectionHeader>New Pick List 📋</LogSectionHeader>
          <LogText>
            In the dashboard, in the top right hand next to the bell icon, is a new button for making a pick list. Based on the date range selected, and applicable invoices, this will generate a report for all items going in or out during that time range. You can specify by receive/return type and by category. You can download it as a csv or print it out as shown in that view.
          </LogText>

          <LogSectionHeader>Tiers and Billing 💳</LogSectionHeader>
          <LogText>
            You are now able to view your current plan in the {`Admin > Billing`} section. Right now you can only view it, soon you will be able to manage your subscription as well as add any additional features like Go High Level integrations.
          </LogText>

          <LogSectionHeader>QuickBooks Sales Accounts 📊</LogSectionHeader>
          <LogText>
            For those of you integrated with QuickBooks you can now assign specific income accounts for your inventory items. This can be done in the Edit Item view, then click on the QuickBooks Sync tab.
          </LogText>

          <LogSectionHeader>Prepare The Items 🧳</LogSectionHeader>
          <LogText>
            In the prepare the items view, if the invoice has any notes, those will be available to view there.
          </LogText>

          <LogSectionHeader>New Payment Processor Option 💰</LogSectionHeader>
          <LogText>
            We have integrated with a new payment processing partner, Justifi. If you are interested in learning more about how Justifi compares with Stripe, please email joe@adelielogistics.com or jonny@adelielogistics.com for a quick zoom call to discuss. 
          </LogText>

          <LogSectionHeader>Sales Report 📈</LogSectionHeader>
          <LogText>
            The {`Sales Report > Payments`} got some updates. The data is now displayed in an easier to digest format with the taxes broken out separately. The csv download now properly includes the tax amounts.
          </LogText>

          <BugFixes />
          <LogText>
            {`- When you delete an email automation, it no longer will show up in the queue.
- Automated emails were failing, this is now fixed.
- When you add a venue to an order, it will not override the notes text.
- Check availability cart to new order functionality was broken for a bit, now it is fixed.`}
          </LogText>

          <LogSectionHeader>Check Availability View 🔍</LogSectionHeader>
          <LogText>
            {`- Event date now shows in the invoices list.
- Broken image links don't mess up the layout.
- Improved (hopefully) display of stock availability.`}
          </LogText>

          <LogText>
            In the routes view, assigned trucks now load correctly on subsequent views.
          </LogText>

          <br />
          <LogText>
            We hope these updates help you stay organized and efficient! As always, we appreciate your feedback and support. 🙏
          </LogText>
        </Stack>
      </Box>
    </Card>
  );
};