import Calendar from '@fullcalendar/react'; // => request placed at the top
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import timelinePlugin from '@fullcalendar/timeline';
//
import { useState, useEffect, useCallback } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { DateSelectArg, EventClickArg, EventDropArg } from '@fullcalendar/core';
import { palette as themePalette } from '../../../../../app/theme/palette'

import Dialog from '@mui/material/Dialog';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
// utils
import { fTimestamp } from '../hooks/format-time';
// hooks
// import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from '../hooks/use-responsive';
// _mock
// import { CALENDAR_COLOR_OPTIONS } from 'src/_mock/_calendar';
// api
// import { useGetEvents, updateEvent } from 'src/api/calendar';
// components
import Iconify from '../../../../../app/iconify/iconify';
// import { useSettingsContext } from 'src/components/settings';
// types
import { ICalendarEvent, ICalendarDate, ICalendarFilters, ICalendarFilterValue } from '../calendar';
//
import { useCalendar } from '../hooks';
import { StyledCalendar } from '../styles';
import CalendarToolbar from '../calendar-toolbar';
import { MaintenanceRecord } from '../../../../../app/interfaces/maintenanceRecord';
import { CheckMaintenanceRecord } from '../../../../../app/services/firestore/queries/maintenanceRecordQueries';
import { authSelector } from '../../../../auth/authSlice';
import { useSelector } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';
import { palette } from '../../../../../app/theme/palette';

// ----------------------------------------------------------------------

const defaultFilters: ICalendarFilters = {
  colors: [],
  startDate: null,
  endDate: null,
};

// ----------------------------------------------------------------------

export default function CalendarView() {
  const theme = useTheme();

  // const settings = useSettingsContext();
  const firestore = useFirestore();

  const smUp = useResponsive('up', 'sm');

  // const openFilters = useBoolean();

  const [filters, setFilters] = useState(defaultFilters);
  const { orgData, userData, fsOrgPrefix } = useSelector(authSelector);
  const [selectEventId, setSelectEventId] = useState('');

  // const { events, eventsLoading } = useGetEvents();

  const [events, setEvents] = useState<ICalendarEvent[]>([])
  const [scheduledMaintenance, setScheduledMaintenance] = useState<any[]>([]);
  const [fetchingMaintenanceRecords, setFetchingMaintenanceRecords] = useState(false);
  const palette = themePalette('light');


  useEffect(() => {
    const fetchData = async () => {
      if (orgData) {
        setFetchingMaintenanceRecords(true);

        console.log(orgData)

        const unsubscribe = firestore
          .collectionGroup("maintenanceRecords")
          .where('orgId', '==', orgData.id)
          .where('status', '==', 'scheduled')
          .onSnapshot((snapshot) => {

            console.log('Getting any results?', snapshot.docs)

            let data: MaintenanceRecord[] = [];
            snapshot.docs.forEach((s) => {
              const record = CheckMaintenanceRecord(s, orgData.orgTimezone)
              if (record) {
                data.push(record);
              }
            });

            setScheduledMaintenance(data);

            // convert to ICalendar Events
            let convertedEvents: ICalendarEvent[] = []

            data.forEach((record) => {
              const newEvent: ICalendarEvent = {
                id: record.id,
                color: palette.primary.dark,
                title: record.itemName,
                allDay: true,
                description: record.notes,
                end: record.servicedOn.getTime(),
                start: record.servicedOn.getTime()
              }

              convertedEvents.push(newEvent)

              console.log(newEvent)
            })

            setEvents(convertedEvents);

            setFetchingMaintenanceRecords(false);
          });

        return () => {
          unsubscribe();
        };
      }
    };

    fetchData();
  }, [firestore, orgData]);

  const dateError =
    filters.startDate && filters.endDate
      ? filters.startDate.getTime() > filters.endDate.getTime()
      : false;

  const {
    calendarRef,
    //
    view,
    date,
    //
    onDatePrev,
    onDateNext,
    onDateToday,
    onDropEvent,
    onChangeView,
    onSelectRange,
    onClickEvent,
    onResizeEvent,
    onInitialView,
    //
    openForm,
    onOpenForm,
    onCloseForm,
    //
    // selectEventId,
    selectedRange,
    //
    onClickEventInFilters,
  } = useCalendar();

  // const currentEvent = useEvent(events, selectEventId, selectedRange, openForm);

  useEffect(() => {
    onInitialView();
  }, [onInitialView]);

  const onRecordClick = useCallback(
    (arg: EventClickArg) => {
      const { event } = arg;

      console.log(event.id)
      onOpenForm();
      setSelectEventId(event.id);
    },
    [onOpenForm]
  );

  const handleFilters = useCallback((name: string, value: ICalendarFilterValue) => {
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  // const handleResetFilters = useCallback(() => {
  //   setFilters(defaultFilters);
  // }, []);

  // const canReset = !!filters.colors.length || (!!filters.startDate && !!filters.endDate);

  // const dataFiltered = applyFilter({
  //   inputData: events,
  //   filters,
  //   dateError,
  // });

  // const renderResults = (
  //   <CalendarFiltersResult
  //     filters={filters}
  //     onFilters={handleFilters}
  //     //
  //     canReset={canReset}
  //     onResetFilters={handleResetFilters}
  //     //
  //     results={dataFiltered.length}
  //     sx={{ mb: { xs: 3, md: 5 } }}
  //   />
  // );

  return (
    <>
      <Container maxWidth={'xl'}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        >
          <Typography variant="h4">Maintenance Calendar</Typography>
          {/* <Button
            variant="contained"
            startIcon={<Iconify icon="mingcute:add-line" />}
            onClick={onOpenForm}
          >
            New Event
          </Button> */}
        </Stack>

        {/* {canReset && renderResults} */}

        <Card>
          <StyledCalendar>
            <CalendarToolbar
              date={date}
              view={view}
              loading={fetchingMaintenanceRecords}
              onNextDate={onDateNext}
              onPrevDate={onDatePrev}
              onToday={onDateToday}
              onChangeView={onChangeView}
              // onOpenFilters={openFilters.onTrue}
            />

            <Calendar
              weekends
              editable
              droppable
              selectable
              rerenderDelay={10}
              allDayMaintainDuration
              eventResizableFromStart
              ref={calendarRef}
              initialDate={date}
              initialView={view}
              dayMaxEventRows={3}
              eventDisplay="block"
              events={events}
              headerToolbar={false}
              select={onSelectRange}
              eventClick={onRecordClick}
              height={'auto'}
              eventDrop={(arg) => {
                // onDropEvent(arg, updateEvent);
              }}
              eventResize={(arg) => {
                // onResizeEvent(arg, updateEvent);
              }}
              plugins={[
                listPlugin,
                dayGridPlugin,
                timelinePlugin,
                timeGridPlugin,
                interactionPlugin,
              ]}
            />
          </StyledCalendar>
        </Card>
      </Container>

      {/* <Dialog
        fullWidth
        maxWidth="xs"
        open={openForm}
        onClose={onCloseForm}
        transitionDuration={{
          enter: theme.transitions.duration.shortest,
          exit: theme.transitions.duration.shortest - 80,
        }}
      >
        <DialogTitle sx={{ minHeight: 76 }}>
          {openForm && <> {currentEvent?.id ? 'Edit Event' : 'Add Event'}</>}
        </DialogTitle> */}

        {/* <CalendarForm
          currentEvent={currentEvent}
          colorOptions={CALENDAR_COLOR_OPTIONS}
          onClose={onCloseForm}
        /> */}
      {/* </Dialog> */}

      {/* <CalendarFilters
        open={openFilters.value}
        onClose={openFilters.onFalse}
        //
        filters={filters}
        onFilters={handleFilters}
        //
        canReset={canReset}
        onResetFilters={handleResetFilters}
        //
        dateError={dateError}
        //
        events={events}
        colorOptions={CALENDAR_COLOR_OPTIONS}
        onClickEvent={onClickEventInFilters}
      /> */}
    </>
  );
}

