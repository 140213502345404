import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { authSelector } from "../../../auth/authSlice";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Tabs,
} from "antd";
import TabPane from "antd/lib/tabs/TabPane";

import { useFirestore } from "react-redux-firebase";
import notificationConfirm from "../../../../app/system-components/toasters/notificationConfirm";
import notificationError from "../../../../app/system-components/toasters/notificationError";

import TextSubtitle from "../../../../app/system-components/typography/text/TextSubtitle";
import { WarningFilled } from "@ant-design/icons";
import { COLOR_RED_0 } from "../../../../app/ui/colorConstants";
import { CategoryTag } from "../../../../app/system-components/tags/Tags";
import { useHistory } from "react-router-dom";
import {
  ROUTE_ADMIN_CUSTOMERS_PAYMENT_ROOT,
} from "../../../../app/routes";
import AdditionalContacts from "./AdditionalContacts";
import { configureVendor } from "../../../../app/utils/models/configure/configureVendor";
import { vendorWrites } from "../../../../app/services/firestore/writes/vendorWrites";
import VendorPoHistory from "./VendorPoHistory";

const EditVendorModal = ({
  onCancel,
  onComplete,
  onContactAdded,
  visible,
  selected,
  hideDeactivate,
}) => {
  const { orgData, userData, fsOrgPrefix } = useSelector(authSelector);

  const [tabType, setTabType] = useState('vendor');  // vendor , pos , contacts

  const [saving, setSaving] = useState(false);
  const [form] = Form.useForm();
  const firestore = useFirestore();
  const history = useHistory();

  const handleFinish = () => {
    form.validateFields().then(() => {
      setSaving(true);
      const values = form.getFieldsValue();
      const data = {
        ...values,
      };
      const payload = configureVendor().editVendor(
        data,
        userData.id,
        orgData
      );
      vendorWrites({ firestore }, fsOrgPrefix)
        .editVendor(selected.id, payload)
        .then(() => {
          clearBeforeClose();
          onComplete();
          notificationConfirm("Vendor saved", "");
        })
        .catch((err) => {
          clearBeforeClose();
          console.log(err);
          notificationError(
            "Something went wrong",
            "Vendor could not be saved"
          );
        });
    });
  };
  const clearBeforeClose = () => {
    setSaving(false);
    form.resetFields();
  };

  useEffect(() => {
    if (!visible) return;
    
    const { ...rest } = selected;
    form.setFieldsValue({
      ...rest,
    });
  }, [visible]);

  useEffect(() => {
    const values = form.getFieldsValue();
    form.setFieldsValue({
      billingAddress: values.billingAddress,
      billingCity: values.billingCity,
      billingState: values.billingState,
      billingZip: values.billingZip,
    });
  }, [form]);

  const handleDeactivate = () => {
    Modal.confirm({
      title: "Make Inactive?",
      icon: <WarningFilled style={{ color: COLOR_RED_0 }} />,
      content:
        "Confirm.",
      maskClosable: true,
      okType: "danger",
      cancelButtonProps: { disabled: saving },
      onOk() {
        return new Promise((resolve, reject) => {
          setSaving(true);
          vendorWrites({ firestore }, fsOrgPrefix)
            .editVendor(selected && selected.id, { isActive: false })
            .then(() => {
              resolve();
              clearBeforeClose();
              onComplete();
              notificationConfirm("Vendor made inactive", "");
            })
            .catch((err) => {
              console.log(err);
              notificationError(
                "Something went wrong",
                "Vendor could not be made inactive"
              );
            });
        });
      },
      onCancel() { },
    });
  };

  const handleReactivate = () => {
    Modal.confirm({
      title: "Make Active?",
      icon: <WarningFilled style={{ color: COLOR_RED_0 }} />,
      content:
        "Confirm.",
      maskClosable: true,
      okType: "danger",
      cancelButtonProps: { disabled: saving },
      onOk() {
        return new Promise((resolve, reject) => {
          setSaving(true);
          vendorWrites({ firestore }, fsOrgPrefix)
            .editVendor(selected && selected.id, { isActive: true })
            .then(() => {
              resolve();
              clearBeforeClose();
              onComplete();
              notificationConfirm("Vendor made active", "");
            })
            .catch((err) => {
              console.log(err);
              notificationError(
                "Something went wrong",
                "Vendor could not be made active"
              );
            });
        });
      },
      onCancel() { },
    });
  };

  const configureDeactivateBtns = (selected) => {
    if (!selected) return null;
    if (hideDeactivate) return null;
    return (
      <Button
        style={{ float: "left" }}
        type={selected.isActive ? "danger" : ""}
        key="inactive"
        onClick={selected.isActive ? handleDeactivate : handleReactivate}
        disabled={saving}
      >
        {selected.isActive ? "Deactivate" : "Reactivate"}
      </Button>
    );
  };

  const configureDisabled = (selected) => {
    if (!selected) return false;
    return !selected.isActive;
  };

  return (
    <Modal
      destroyOnClose
      width={1000}
      open={visible}
      closeIcon={<></>}
      title={
        <div>
          <Space>
            Edit Vendor
            {selected && !selected.isActive && (
              <CategoryTag text={"Deactivated"} />
            )}
          </Space>
        </div>
      }
      footer={
        tabType === 'vendor' ? (
          [
            selected && configureDeactivateBtns(selected),
            <Button
              key="cancel"
              onClick={() => {
                form.resetFields();
                onCancel();
              }}
              disabled={saving}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={() => handleFinish()}
              disabled={saving || configureDisabled(selected)}
              loading={saving}
            >
              Save
            </Button>,
          ]
        ) : (
          <Button
            key="close"
            onClick={() => {
              onContactAdded();
            }}
            disabled={saving}
          >
            Close
          </Button>
        )

      }
    >
      <div style={{ width: "100%" }}>
        <Tabs
          defaultActiveKey="vendor"
          activeKey={tabType}
          onChange={setTabType}
        >
          <TabPane tab="Vendor" key="vendor" />
          <TabPane tab="Purchase Orders" key="pos" />
          <TabPane tab="Additional Contacts" key="contacts" />

        </Tabs>
      </div>

      {tabType === "vendor" && (
        <div>
          <Form form={form}>
            <div style={{ marginBottom: "12px" }}>
              <TextSubtitle text={"COMPANY INFO"} />
            </div>
            <Row gutter={24} wrap={false}>
              <Col span={12}>
                <Row wrap={false} gutter={8}>
                  <Col>
                    <Form.Item
                      initialValue=""
                      name="firstName"
                      style={{ marginBottom: "12px" }}
                    >
                      <Input
                        placeholder="First Name"
                        disabled={configureDisabled(selected)}
                      />
                    </Form.Item>
                  </Col>
                
                  <Col>
                    <Form.Item
                      initialValue=""
                      name="lastName"
                      style={{ marginBottom: "12px" }}
                    >
                      <Input
                        placeholder="Last Name"
                        disabled={configureDisabled(selected)}
                      />
                    </Form.Item>
                  </Col>
                  
                </Row>
                <Form.Item
                  name={'companyName'}
                  initialValue=""
                  style={{ marginBottom: "12px" }}
                >
                  <Input
                    placeholder="Company name"
                    disabled={configureDisabled(selected)}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  initialValue=""
                  name="email"
                  style={{ marginBottom: "12px" }}
                  rules={[
                    {
                      type: "email",
                    },
                  ]}
                >
                  <Input
                    placeholder="Email"
                    disabled={configureDisabled(selected)}
                  />
                </Form.Item>
                <Row wrap={false} gutter={8}>
                  <Col span={12}>
                    <Form.Item
                      initialValue=""
                      name="phone"
                      style={{ marginBottom: "12px" }}
                    >
                      <Input
                        placeholder="Phone"
                        disabled={configureDisabled(selected)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Divider />
            <Row wrap={false} justify="space-between" gutter={24}>
              <Col>
                <div style={{ marginBottom: "12px" }}>
                  <TextSubtitle text={"BILLING ADDRESS"} />
                </div>
                <Form.Item
                  initialValue=""
                  name={'billingAddress'}
                  style={{ marginBottom: "12px" }}
                >
                  <Input
                    disabled={configureDisabled(selected)}
                    placeholder="Street"
                  />
                </Form.Item>
                <Row wrap={false} justify="space-between">
                  <Col span={9}>
                    <Form.Item
                      initialValue=""
                      name={'billingCity'}
                      style={{ marginBottom: "0px" }}
                    >
                      <Input
                        disabled={configureDisabled(selected)}
                        placeholder="City"

                      />
                    </Form.Item>
                  </Col>
                  <Col span={9}>
                    <Form.Item
                      initialValue=""
                      name={'billingState'}
                      style={{ marginBottom: "0px" }}
                    >
                      <Input
                        disabled={configureDisabled(selected)}
                        placeholder="State"

                      />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      initialValue=""
                      name={'billingZip'}
                      style={{ marginBottom: "0px" }}
                    >
                      <Input
                        disabled={configureDisabled(selected)}
                        placeholder="ZIP"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      )}

      {tabType === "invoices" && (
        <VendorPoHistory customerId={selected && selected.id} />
      )}

      {tabType === "contacts" && (
        <div>
          <AdditionalContacts customer={selected && selected} />
        </div>
      )}

    </Modal>
  );
};

export default EditVendorModal;
