// @mui
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import "firebase/firestore";

import { Card, Link, Stack } from "@mui/material";
import { WhatsNew } from "../components/WhatsNew";
import LogImage from "../components/LogImage";
import { BugFixes } from "../components/BugFixes";
import LogText from "../components/LogText";
import LogSectionHeader from "../components/LogSectionHeader";

export const V2_2_4 = () => {
  return (
    <Card sx={{ width: "100%", padding: 4 }}>
      <Box sx={{ p: 2, display: "flex", width: "100%" }}>
        <Stack spacing={1} sx={{ width: "100%" }}>
          <Typography fontWeight={700}>V2.2.4 - Released 3-15-2024</Typography>

          <br />
          <WhatsNew />
          <LogSectionHeader>Email Automations</LogSectionHeader>

          <LogText>
            We are super excited to launch email automations. This new feature allows you to create email automations that can automatically schedule emails for the future.
          </LogText>

          <Link
            href="https://youtu.be/OTzvfpj_TNI?si=3WwX51XP7SMnobp2"
            underline="always"
            target="_blank"
            rel="noopener"
          >
            Watch the YouTube training video here
          </Link>

          <br />
          <LogText>
            We hope these updates help you stay organized and efficient!
          </LogText>
        </Stack>
      </Box>
    </Card>
  );
};
