// @mui
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import "firebase/firestore";

import { Card, Stack } from "@mui/material";
import { WhatsNew } from "../components/WhatsNew";
import LogImage from "../components/LogImage";
import { BugFixes } from "../components/BugFixes";
import LogText from "../components/LogText";
import LogSectionHeader from "../components/LogSectionHeader";

export const V2_2_1 = () => {
  return (
    <Card sx={{ width: "100%", padding: 4 }}>
      <Box sx={{ p: 2, display: "flex", width: "100%" }}>
        <Stack spacing={1} sx={{ width: "100%" }}>
          <Typography fontWeight={700}>V2.2.1 - Released 01-08-2024</Typography>

          <LogSectionHeader>
            Happy (belated) New Year and welcome to our first feature release of
            2024! 🥳
          </LogSectionHeader>

          <br />
          <BugFixes />
          <LogText>
            We fixed a bug in the additional contacts view for customers.
            Previously when adding additional contacts, then clicking on a
            different view, and coming back it would appear the contacts were
            not saved even though they were. This is now fixed.
          </LogText>
          <LogText>
            Added an "All Time" quick option in the Make The Sale date picker to
            easily load ALL your orders.
          </LogText>

          <br />
          <WhatsNew />
          <LogSectionHeader>📁 File Manager </LogSectionHeader>

          <LogText>
            We're excited to launch our new File Manager feature. This
            much-requested tool allows you to upload, store, and manage files
            like PDFs, images, and text documents. Use it to keep important
            information like equipment instructions and checklists handy for
            internal use, or easily attach them to emails for customers. This
            straightforward, practical feature is designed to make your document
            handling more efficient and organized.
          </LogText>

          <LogText>
            To get started, simply click on the new "File Manager" tab in the
            left-hand navigation.
          </LogText>
          <br />
          <LogImage
            src={
              "https://firebasestorage.googleapis.com/v0/b/adelie-logistics-production.appspot.com/o/public-images%2Fchange-log%2Ffile-manager.png?alt=media&token=4ecf2e4d-0f23-49cb-97ba-ca8f10209ba1"
            }
          />

          <br />
          <LogText>
            Then when sending emails, you can select a file from already
            uploaded files, or upload a new file.
          </LogText>
          <br />
          <LogImage
            src={
              "https://firebasestorage.googleapis.com/v0/b/adelie-logistics-production.appspot.com/o/public-images%2Fchange-log%2Fattach-email.png?alt=media&token=421f3546-a7b3-470f-9410-f42f8e08b429"
            }
          />

          <br />
          <LogText>
            We hope this new feature helps you stay organized and efficient!
          </LogText>
        </Stack>
      </Box>
    </Card>
  );
};
