import { useEffect, useState } from "react";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { subDays } from "date-fns";

import moment from "moment";
import { DateRange, PickersShortcutsItem } from "@mui/x-date-pickers-pro";

const SalesDateRangePicker = ({
  state,
  actions,
}: {
  state: any;
  actions: any;
}) => {
  const [diff, setDiff] = useState(7);

  useEffect(() => {
    if (state.dateRange?.start && state.dateRange?.end) {
      const a = moment(state.dateRange.start);
      const b = moment(state.dateRange.end);
      const days = a.diff(b, "days");
      setDiff(days * -1);
    }
  }, [state.dateRange]);

  const handleDateRangeChange = (newDates: DateRange<any>) => {
    console.log(newDates);
    if (newDates[0] && newDates[1]) {
      const rangeStart = newDates[0];
      const rangeEnd = newDates[1];

      actions.setDateRange({
        start: moment(rangeStart).startOf("day"),
        end: moment(rangeEnd).endOf("day"),
      });
    }
  };
  const shortcutsItems: PickersShortcutsItem<DateRange<any>>[] = [
    {
      label: "Last 7 Days",
      getValue: () => {
        const today = new Date();
        const sevenDaysAgo = subDays(today, 7);
        return [sevenDaysAgo, today];
      },
    },

    {
      label: "Last 30 Days",
      getValue: () => {
        const today = new Date();
        const thirtyDays = subDays(today, 30);
        return [thirtyDays, today];
      },
    },
    {
      label: "Last 90 Days",
      getValue: () => {
        const today = new Date();
        const ninetyDays = subDays(today, 90);
        return [ninetyDays, today];
      },
    },
    { label: "Reset", getValue: () => [null, null] },
  ];

  return (
    <div style={{ textAlign: "left", width: "100%" }}>
      <DateRangePicker
        value={[state.dateRange?.start.toDate(), state.dateRange?.end.toDate()]}
        onAccept={(newValue) => handleDateRangeChange(newValue)}
        slotProps={{
          textField: { size: "small" },
          shortcuts: {
            items: shortcutsItems,
          },
          actionBar: {
            actions: ["today"],
          },
        }}
      />
      <br />
    </div>
  );
};

export default SalesDateRangePicker;
