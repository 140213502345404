// login
export const ROUTE_LOGIN = "/login";
export const ROUTE_LOGOUT = "/logout";

export const ROUTE_FORGOT_PASSWORD = "/forgot-password";

// app domains
export const ROUTE_DASHBOARD = "/dashboard";
export const ROUTE_INVENTORY = "/inventory";
export const ROUTE_INVOICES = "/invoices";
export const ROUTE_PURCHASE_ORDERS = "/purchase-orders";
export const ROUTE_CREATE_INVOICE = "/invoices/new";
export const ROUTE_CREATE_PURCHASE_ORDER = "/purchase-orders/new";

export const ROUTE_CREATE_INVOICE_FROM_CART = "/invoices/cart-new";
export const ROUTE_EDIT_INVOICE = "/invoices/edit/";
export const ROUTE_VIEW_EDIT_INVOICE = "/invoices/edit/:id";
export const ROUTE_RECEIVE_PAYMENT_ROOT = "/invoices/payment/";
export const ROUTE_RECEIVE_INVOICE_PAYMENT = "/invoices/payment/:id";
export const ROUTE_ROUTES = "/routes";
export const ROUTE_QUICK_REPORT = "/quick-report";
export const ROUTE_REPORTS = "/reports";
export const ROUTE_REPORTS_SALES = "/reports/sales";
export const ROUTE_REPORTS_TAX = "/reports/tax";

export const ROUTE_REPORTS_USAGE = "/reports/usage/";
export const ROUTE_REPORTS_DEPRECIATION = "/reports/depreciation/";
export const ROUTE_ADMIN_USERS = "/admin-users";
export const ROUTE_ADMIN_INVENTORY = "/admin-inventory";
export const ROUTE_ADMIN_CREATE_INVENTORY = "/admin-inventory/add";
export const ROUTE_ADMIN_CREATE_PART = "/admin-inventory/add-part";
export const ROUTE_ADMIN_CREATE_SERVICE = "/admin-inventory/add-service";

export const ROUTE_ADMIN_INTEGRATIONS = "/admin-integrations";
export const ROUTE_ADMIN_BILLING = "/admin-billing";
export const ROUTE_ADMIN_SETTINGS_GENERAL = "/admin-settings-general";
export const ROUTE_ADMIN_SETTINGS_EMAIL = "/admin-settings-email";
export const ROUTE_ADMIN_SETTINGS_WORDS = "/admin-settings-words";
export const ROUTE_ADMIN_EMBEDDED_CHECKOUT = "/admin-embedded-checkout";
export const ROUTE_ADMIN_SETTINGS_PERMISSIONS = "/settings-permissions";


export const ROUTE_ADMIN_CUSTOMERS = "/admin-customers";
export const ROUTE_ADMIN_VENDORS = "/admin-vendors";
export const ROUTE_ADMIN_VENUES = "/admin-venues";
export const ROUTE_ADMIN_TRUCKS = "/admin-trucks";
export const ROUTE_ADMIN_FILE_MANAGER = "/admin-file-manager";

export const ROUTE_ADMIN_CUSTOMERS_PAYMENT_ROOT = "/admin-customers/payment/";

export const ROUTE_ADMIN_CUSTOMERS_PAYMENT = "/admin-customers/payment/:id";
export const ROUTE_ADMIN_PAYMENT_SETUP_COMPLETE =
  "/admin-integrations/payment-connect-complete";
export const ROUTE_ADMIN_PAYMENT_SETUP_REFRESH =
  "/admin-integrations/payment-connect-refresh";

export const ROUTE_CREATE_ROUTE = "/routes/new";

export const ROUTE_CHANGE_LOG = "/change-log";
export const ROUTE_MOBILE_APPS = "/mobile-apps";

