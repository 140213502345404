// @mui
import { Link } from 'react-router-dom';


// components
import { Button, Chip, Stack, Tooltip, TooltipProps, tooltipClasses } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import { useTheme } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import MessageIcon from '@mui/icons-material/Message';


import DeliveryIcon from "../../assets/images/map-d.png";
import PickupIcon from "../../assets/images/map-p.png";
import DeliveryPickupIcon from "../../assets/images/map-dp.png";
import { Invoice } from '../../app/interfaces/invoice';
import { usePopover } from '../../app/system-components/custom-popover';
import { ROUTE_EDIT_INVOICE } from '../../app/routes';
import Iconify from '../../app/iconify';
import CustomPopover from '../../app/system-components/custom-popover/custom-popover';
import { useFirestore } from 'react-redux-firebase';
import { Typography } from 'antd';
import React, { useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { FormatToLocalCurrency } from '../../app/utils/currency/formatToLocalCurrency';
import { OrganizationData } from '../../app/interfaces/organizationData';
import { fDate } from '../../app/utils/format-time';
import { useCopyToClipboard } from '../../app/hooks/use-copy-to-clipboard';
import { useSnackbar } from '../file-manager/snackbar';
import { checkSubscriptionLevel } from '../../app/interfaces/features-set-permissions';



// ----------------------------------------------------------------------

type Props = {
  row: Invoice;
  orgData: OrganizationData;
  isoDates: string[];
  tab: string | "dropOff" | "delivery" | "all" | "onSite" | "pickup";
  handleChatClick: (invoice: Invoice) => void;
};

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

export default function InvoiceTableRow({ row, orgData, isoDates, tab, handleChatClick }: Props) {
  const popover = usePopover();
  const theme = useTheme();
  const db = useFirestore();
  
  const { enqueueSnackbar } = useSnackbar()
  const { copy } = useCopyToClipboard();

  const isoStartDate = isoDates[0];
  const isoEndDate = isoDates[1];

  const PRIMARY_MAIN = theme.palette.primary.main;

  const handleCopy = useCallback((text: string) => {
    enqueueSnackbar('Copied!');
    copy(text);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [copy, enqueueSnackbar, row]);

  const deliveryPickupIcon = (invoice: Invoice) => {

    let receiveIncluded = false;
    let returnIncluded = false;

    if (invoice.mobileStartDate >= isoStartDate) {
      receiveIncluded = true;
    }
    if (invoice.mobileEndDate <= isoEndDate) {
      returnIncluded = true;
    }

    const startIcon = () => {
      if (invoice.receiveMethod === "delivery") {
        return (
          <Tooltip title="Delivery">
              <img
                style={{
                  width: "auto", // Set the desired width
                  height: "25px", // Set the desired height
                }}
                src={DeliveryIcon}
                alt="Delivery"
              />
          </Tooltip>
        );
      } else if (invoice.receiveMethod === "pickup") {
        return (
          <Tooltip title="Customer Pick Up">
              <Iconify
                icon="fa-solid:warehouse"
                color={theme.palette.primary.main}
              />
          </Tooltip>
        );
      }
    };

    const endIcon = () => {
      if (invoice.returnMethod === "onSite") {
        return (
          <Tooltip title="Pick Up">
              <img
                style={{
                  width: "auto", // Set the desired width
                  height: "25px", // Set the desired height
                }}
                src={PickupIcon}
                alt="Pick Up"
              />
          </Tooltip>
        );
      } else if (invoice.returnMethod === "dropOff") {
        return (
          <Tooltip title="Customer Drop Off">
              <Iconify
                icon="fa-solid:warehouse"
                color={theme.palette.primary.main}
              />
          </Tooltip>
        );
      }
    };

    if (receiveIncluded && returnIncluded && tab === 'all') {
      // Now we need to cover the various scenarios.
      return (
        <Stack direction="row" gap={1} display="inline-flex">
          {startIcon()}
          {endIcon()}
        </Stack>
      );
    }
    if (receiveIncluded && tab === 'all') {
      return (
          startIcon()
      );
    }
    if (returnIncluded && tab === 'all') {
      return (
          endIcon()
      );
    }

    // RECEIVE BY
    if (tab === "delivery" || tab === "pickup") {
      return (
          startIcon()
      );
    }

    // RETURN BY
    if (tab === "onSite" || tab === "dropOff") {
      return (
        endIcon()
      );
    }
  };

  const invoiceStatusCell = (invoice: Invoice) => {

    const loadedStatusElement = () => {
      return (
      <Chip
              size="small"
              label="Loaded"
              color={invoice.isLoaded ? 'success' : 'default'}
            />
      )
    }

    const receivedStatusElement = () => {
      if (invoice.receiveMethod === "delivery") {
        return (
        <Chip
          size="small"
          label="Received"
          color={invoice.receivedByDelivery ? "success" : "default"}
        />
        )
      } else if (invoice.receiveMethod === "pickup") {
        return (
        <Chip
          size="small"
          label="Received"
          color={invoice.receivedByPickup ? "success" : "default"}
        />
        )
      }
    };

    const returnedStatusElement = () => {
      if (invoice.returnMethod === "onSite") {
        return (
        <Chip
          size="small"
          label="Returned"
          color={invoice.returnedByPickup ? "success" : "default"}
        />
        )
      } else if (invoice.returnMethod === "dropOff") {
        return (
        <Chip
          size="small"
          label="Returned"
          color={invoice.returnedByDropOff ? "success" : "default"}
        />
        )
      }
    };

    return (
      <TableCell align="right">
        <Stack
          display="inline-flex"
          gap={1}
          direction={{ md: "column", lg: "row" }}
        >
          {loadedStatusElement()}
          {receivedStatusElement()}
          {returnedStatusElement()}

        </Stack>
      </TableCell>
    );
  };

  const invoiceAddress = () => {

    if (row.receiveMethod === "pickup" || row.returnMethod === "dropOff") {
      return <span>--</span>;
    }

    const deliveryAddress = row.deliveryCustomerShippingAddressStr;
    const pickupAddress = row.onSiteCustomerShippingAddressStr;
    const [firstPartDelivery, ...restDelivery] = deliveryAddress.split(",");
    const formattedDeliveryAddress = `${firstPartDelivery}\n${restDelivery.join(
      ","
    )}`;

    const [firstPartPickup, ...restPickup] = pickupAddress.split(",");
    const formattedPickupAddress = `${firstPartPickup}\n${restPickup.join(
      ","
    )}`;

    if (pickupAddress === deliveryAddress) {
      return (
        <Tooltip title="Click to copy">
          <Button
            sx={{
              fontWeight: "regular",
              color: theme.palette.text.primary,
              whiteSpace: "pre-line", // Add this line
            }}
            variant="text"
            onClick={() => handleCopy(deliveryAddress)}
          >
            {formattedDeliveryAddress}
          </Button>
        </Tooltip>
      );
    } else {
      return (
        <>
          <div>Delivery: {formattedDeliveryAddress}</div>
          <div>Pickup: {formattedPickupAddress}</div>
        </>
      );
    }
  }


  return (
    <>
      <TableRow
        key={`${row.id}-${row.keyHelp}`}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <TableCell component="th" scope="row">
          <Stack direction="row" gap={1} display="inline-flex">
            <Link
              style={{ color: PRIMARY_MAIN }}
              to={`${ROUTE_EDIT_INVOICE}${row.id}`}
            >
              <span>{row.invoiceNumber}</span>
            </Link>
            {deliveryPickupIcon(row)}
          </Stack>
        </TableCell>

        <TableCell align="right">
          <Stack>
            <div
              style={{
                fontWeight:
                  tab === "delivery" || tab === "pickup" ? "bold" : "normal",
              }}
            >
              Start: {fDate(row.rentalDateStart.toDate())}
            </div>
            <div
              style={{
                fontWeight:
                  tab === "onSite" || tab === "dropOff" ? "bold" : "normal",
              }}
            >
              End: {fDate(row.rentalDateEnd.toDate())}
            </div>
          </Stack>
        </TableCell>

        <TableCell align="right">
          <Stack direction={"column"} alignItems={"end"}>
            {row.customer?.customerDisplayName ?? ""}
            <Tooltip title="Click to copy">
              <Button
                sx={{
                  fontWeight: "regular",
                  color: theme.palette.text.primary,
                }}
                variant="text"
                onClick={() => handleCopy(row.customer.email ?? "")}
              >
                {row.customer?.email ?? ""}
              </Button>
            </Tooltip>
          </Stack>
        </TableCell>

        <TableCell align="right">{invoiceAddress()}</TableCell>

        <TableCell align="right">
          <div>
            <Stack style={{ alignItems: "right" }}>
              {row.balanceRemaining <= 0 && row.total > 0 ? (
                <div style={{ display: "inline-block" }}>
                  <Chip size="small" label="Paid" color="success" />
                </div>
              ) : (
                FormatToLocalCurrency(
                  row.balanceRemaining,
                  orgData.countryCode,
                  orgData.languageCode,
                  orgData.currencyCode
                )
              )}
            </Stack>
          </div>
        </TableCell>

        <TableCell align="right">
          {row.selectedItems.count < 3 ? (
            <div>
              {row.selectedItems.items.map((item, index) => (
                <div>
                  {item.name}: {item.selectedQty}
                </div>
              ))}
            </div>
          ) : (
            <div style={{ display: "inline-block" }}>
              <LightTooltip
                arrow
                placement="bottom"
                title={
                  <React.Fragment>
                    {row.selectedItems.items.map((item, index) => (
                      <Typography key={index}>
                        {item.name}: {item.selectedQty}
                      </Typography>
                    ))}
                  </React.Fragment>
                }
              >
                <div style={{ textAlign: "right", width: "auto" }}>
                  {row.selectedItems.count} items
                </div>
              </LightTooltip>
            </div>
          )}
        </TableCell>

        {invoiceStatusCell(row)}

        <TableCell align="center">
          <div>
            <Tooltip
              title={
                !checkSubscriptionLevel("internalNotes", orgData)
                  ? "You do not have the required plan for internal notes. Requires Plus Plan, you can change this in Admin/Billing"
                  : ""
              }
            >
              <span>
                <IconButton
                  disabled={!checkSubscriptionLevel("internalNotes", orgData)}
                  onClick={() => handleChatClick(row)}
                >
                  <MessageIcon style={{ color: PRIMARY_MAIN }} />
                </IconButton>
              </span>
            </Tooltip>
          </div>
        </TableCell>

        <TableCell align="right" sx={{ px: 1 }}>
          <IconButton
            color={popover.open ? "inherit" : "default"}
            onClick={popover.onOpen}
          >
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 160 }}
      >
        <MenuItem
          onClick={() => {
            const invoiceDocRef = db
              .collection("orgs")
              .doc(row.orgId)
              .collection("invoices")
              .doc(row.id);
            invoiceDocRef.update({ isLoaded: !row.isLoaded });
            popover.onClose();
          }}
        >
          <Iconify icon="fa6-solid:truck" />
          Set Loaded
        </MenuItem>

        <MenuItem
          onClick={() => {
            const invoiceDocRef = db
              .collection("orgs")
              .doc(row.orgId)
              .collection("invoices")
              .doc(row.id);
            if (row.receiveMethod === "delivery") {
              invoiceDocRef.update({
                receivedByDelivery: !row.receivedByDelivery,
              });
            } else if (row.receiveMethod === "pickup") {
              invoiceDocRef.update({
                receivedByPickup: !row.receivedByPickup,
              });
            }
            popover.onClose();
          }}
        >
          <Iconify icon="icon-park-solid:up-two" />
          Set Received
        </MenuItem>

        <MenuItem
          onClick={() => {
            const invoiceDocRef = db
              .collection("orgs")
              .doc(row.orgId)
              .collection("invoices")
              .doc(row.id);
            if (row.returnMethod === "onSite") {
              invoiceDocRef.update({ returnedByPickup: !row.returnedByPickup });
            } else if (row.returnMethod === "dropOff") {
              invoiceDocRef.update({
                returnedByDropOff: !row.returnedByDropOff,
              });
            }
            popover.onClose();
          }}
        >
          <Iconify icon="icon-park-solid:down-two" />
          Set Returned
        </MenuItem>
      </CustomPopover>
    </>
  );
}
