import { Card, Box, Typography, Stack, useTheme } from '@mui/material';
import TextSubtitle from '../../../../app/system-components/typography/text/TextSubtitle';
import { COLOR_BLUE_0 } from '../../../../app/ui/colorConstants';
import SalesReportFilterTags from './SalesReportFilterTags';
import Chart, { useChart } from './chart';
import { fCurrency } from './format-number';

const SalesStatistic = ({ state, actions }) => {
  const calcPercent = () => {
    const amt = (state.selectedInvAmt / state.totalSales) * 100;
    return Math.round(amt);
  };

  const showProgressCircle = () => {
    return !!state.selectedInvItem || state.itemFilters?.length !== 0;
  };
  const theme = useTheme();

  const colors = [theme.palette.primary.light, theme.palette.primary.main]

  const chartOptions = useChart({
    chart: {
      toolbar: false,
      sparkline: {
        enabled: true,
      },
    },

    legend: {
      show: false,
    },
    fill: {
      type: 'gradient',
      gradient: {
        colorStops: [
          { offset: 0, color: theme.palette.primary.light, opacity: 1 },
          { offset: 100, color: theme.palette.primary.main, opacity: 1 },
        ],
      },
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '78%',
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: 6,
            fontSize: theme.typography.subtitle2.fontSize,
          },
        },
      },
    },
  });


  return (
    <Card sx={{ width: "100%", p: 2 }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Box>
          <Stack direction="row" spacing={1} alignItems="center">
            {state.selectedInvAmt ? (
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography color={COLOR_BLUE_0} variant="h6">
                  {fCurrency(state.selectedInvAmt.toFixed(2))}
                </Typography>
                <Typography variant="h5">/</Typography>
              </Stack>
            ) : (
              <></>
            )}
            <Typography variant="h6">{fCurrency(state.totalSales.toFixed(2))}</Typography>
          </Stack>
          <TextSubtitle
            text={`Sales from: ${state.dateRange?.start?.format(
              "MM/DD/yyyy"
            )} - ${state.dateRange?.end?.format("MM/DD/yyyy")}`}
          />
        </Box>
        <Box>
          {showProgressCircle() && (
            <>
              <Chart
                dir="ltr"
                type="radialBar"
                series={[calcPercent()]}
                options={chartOptions}
                width={100}
                height={100}
              />
            </>
          )}
        </Box>
      </Box>
      <SalesReportFilterTags state={state} actions={actions} />
    </Card>
  );
};

export default SalesStatistic;
