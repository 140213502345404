import {
  GridRenderCellParams,
  GridTreeNodeWithRender,
} from "@mui/x-data-grid-pro";
import { Tooltip } from "antd";
import { Dispatch, FC, SetStateAction } from "react";
import TextBody from "../../../../app/system-components/typography/text/TextBody";
import { ClickableHoverStyle } from "../../../../app/ui/ClickableHoverStyle";
import { COLOR_YELLOW_0 } from "../../../../app/ui/colorConstants";
import { WarningFilled } from "@ant-design/icons";
import { Part } from "../../../../app/interfaces/part";

export const PartCell: FC<
  GridRenderCellParams<Part, any, any, GridTreeNodeWithRender> & {
    setItemDrawer: Dispatch<
      SetStateAction<{
        visible: boolean;
        item?: Part;
      }>
    >;  
  }
> = ({ row, value, setItemDrawer }) => {
  const handleTitleClick = (i: Part) => {
    setItemDrawer({
      visible: true,
      item: i,
    });
  };
  if (!row.isAvailable && !row.isActive)
    return (
      <div onClick={() => handleTitleClick(row)} style={ClickableHoverStyle}>
        <TextBody style={{ opacity: "0.5" }} text={value} />
        <Tooltip title="This item is no longer in inventory">
          <WarningFilled style={{ color: COLOR_YELLOW_0, marginLeft: "4px" }} />
        </Tooltip>
      </div>
    );
  if (!row.isActive)
    return (
      <Tooltip title="This item is no longer in inventory">
        <TextBody
          onClick={() => handleTitleClick(row)}
          style={{
            opacity: "0.5",
            whiteSpace: "no-wrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            cursor: "pointer",
          }}
          text={value}
        />
      </Tooltip>
    );
  if (!row.isAvailable)
    return (
      <div onClick={() => handleTitleClick(row)} style={ClickableHoverStyle}>
        <TextBody text={value} />
        <Tooltip title="This item is unavailable and will not show up in available inventory">
          <WarningFilled style={{ color: COLOR_YELLOW_0, marginLeft: "4px" }} />
        </Tooltip>
      </div>
    );
  return (
    <TextBody
      onClick={() => handleTitleClick(row)}
      style={ClickableHoverStyle}
      text={value}
    />
  );
};
