import { Card } from '@mui/material';
import React, { useEffect, useRef } from 'react'
import { functionsBaseUrl } from '../../../app/config/firebase';
import { useSelector } from 'react-redux';
import { authSelector } from '../../auth/authSlice';

type Props = {
    
}

export function JustifiPaymentsGraph({}: Props) {
    
    const { orgData } = useSelector(authSelector);

    const formRef = useRef<HTMLFormElement>(null);
    const [webComponentToken, setWebComponentToken] = React.useState<string | null>(null);

    useEffect(() => {
      if (!orgData) return;

      const getWebcomponentToken = async () => {
        const url = functionsBaseUrl + "/justifi";
        const data = {
          orgId: orgData.id,
          action: "webComponentToken",
          payload: {},
        };
        // needs a body passed to the fetch
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });

        const json = await response.json();
        setWebComponentToken(json.access_token);
      };

      getWebcomponentToken();
    }, [orgData]);

    useEffect(() => {
      const form = formRef.current;
      if (form) {
        const handleSubmit = (event: Event) => {
          // Handle the submit event
          console.log("Form submitted:", event);
        };
        form.addEventListener("submitted", handleSubmit);

        // Cleanup
        return () => form.removeEventListener("submitted", handleSubmit);
      }
    }, []);

    return (
      <Card sx={{ p: 3, m: 1 }}>
        {orgData.justifiSubAccount && webComponentToken && (
           <justifi-gross-payment-chart
            ref={formRef}
            account-id={orgData.justifiSubAccount}
            auth-token={webComponentToken}
           >
         </justifi-gross-payment-chart>
        )}
      </Card>
    );
}