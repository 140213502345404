import notificationError from "../../../system-components/toasters/notificationError";

export const configureVendor = () => {
  const createBillingAddressStr = (values) => {
    if (
      values.billingAddress === "" ||
      values.billingCity === "" ||
      values.billingState === "" ||
      values.billingZip === ""
    )
      return "";
    return `${values.billingAddress}, ${values.billingCity}, ${values.billingState} ${values.billingZip}`;
  };

  function newVendorQuickAdd(displayName, createdById, orgData) {
    if (!displayName || !createdById || !orgData)
      return notificationError("Display name not found", "");
    return {
      orgId: orgData.id,
      companyName: "",
      billingAddress: "",
      billingCity: "",
      billingState: "",
      billingZip: "",
      billingAddressStr: "",
      email: "",
      firstName: "",
      lastName: "",
      phone: "",
      createdBy: createdById,
      createdOn: new Date(),
      updatedBy: createdById,
      updatedOn: new Date(),
      updateSource: "adelie",
      isActive: true,
    };
  }

  function newVendor(values, createdById, orgData) {
    if (!values || !createdById || !orgData)
      return notificationError("Could not create customer", "");
    return {
      orgId: orgData.id,
      companyName: values.companyName
        ? values.companyName
        : "",
      billingAddress: values.billingAddress
        ? values.billingAddress
        : "",
      billingCity: values.billingCity
        ? values.billingCity
        : "",
      billingState: values.billingState
        ? values.billingState
        : "",
      billingZip: values.billingZip
        ? values.billingZip
        : "",

      email: values.email ? values.email : "",
      additionalContacts: values.additionalContacts ? values.additionalContacts : [],
      firstName: values.firstName ? values.firstName.trim() : "",
      lastName: values.lastName ? values.lastName.trim() : "",
      phone: values.phone ? values.phone : "",
      billingAddressStr: createBillingAddressStr(values),
      createdBy: createdById,
      createdOn: new Date(),
      updatedBy: createdById,
      updatedOn: new Date(),
      updateSource: "adelie",
      isActive: true,
    };
  }

  function editVendor(values, createdById, orgData) {
    if (!values || !createdById || !orgData)
      return notificationError("Could not save customer", "");
    return {
      orgId: orgData.id,
      companyName: values.companyName
        ? values.companyName
        : "",
      billingAddress: values.billingAddress
        ? values.billingAddress
        : "",
      billingCity: values.billingCity
        ? values.billingCity
        : "",
      billingState: values.billingState
        ? values.billingState
        : "",
      billingZip: values.billingZip
        ? values.billingZip
        : "",
      email: values.email ? values.email : "",
      additionalContacts: values.additionalContacts ? values.additionalContacts : [],
      firstName: values.firstName ? values.firstName.trim() : "",
      lastName: values.lastName ? values.lastName.trim() : "",
      phone: values.phone ? values.phone : "",
      billingAddressStr: createBillingAddressStr(values),
      updatedBy: createdById,
      updatedOn: new Date(),
      updateSource: "adelie",
    };
  }

  return {
    newVendor: newVendor, // configure new Vendor
    newVendorQuickAdd: newVendorQuickAdd, // configure new Vendor just display name
    editVendor: editVendor, // configure edited vendor
  };
};
