import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { Button, Divider, Drawer, Form, Row, Col, Spin, Space, Card } from 'antd';

import { Spinner } from '../../../../app/ui/spinner';
import { useSelector } from 'react-redux';
import { authSelector } from '../../../auth/authSlice';
import { useFirestore } from 'react-redux-firebase';
import { partWrites } from '../../../../app/services/firestore/writes/inventoryWrites';
import notificationConfirm from '../../../../app/system-components/toasters/notificationConfirm';
import notificationError from '../../../../app/system-components/toasters/notificationError';
import TextSubtitle from '../../../../app/system-components/typography/text/TextSubtitle';
import convertTimeToString from '../../../../app/utils/time/convertTimeToString';
import TextBody from '../../../../app/system-components/typography/text/TextBody';
import { useInventoryManager } from '../../../../app/services/hooks/useInventoryManager';
import { InventoryCategory } from '../../../../app/interfaces/inventoryCategory';
import { Part, PartFormData } from '../../../../app/interfaces/part';
import { configurePart } from '../../../../app/utils/models/configure/configurePart';
import NewPartDetails from '../InventoryItemForm/NewPartDetails';
import { Vendor } from '../../../../app/interfaces/vendor';
import { vendorQueries } from '../../../../app/services/firestore/queries/vendorQueries';
import { sortBy } from '../../../../app/utils/sorters/sortBy';
import NewPartAdditional from '../InventoryItemForm/NewPartAdditional';

const PartsDrawer: FC<{
  visible: boolean;
  handleCloseDrawer: Dispatch<
    SetStateAction<{
      visible: boolean;
      item: Part | null;
    }>
  >;
  item: {
    visible: boolean;
    item: Part | null;
  };
  categories: InventoryCategory[];
  onComplete: () => void;
  drawerView: any;
}> = ({ visible, handleCloseDrawer, item, categories, onComplete, drawerView }) => {
  const firestore = useFirestore();

  const { orgData, userData, fsOrgPrefix } = useSelector(authSelector);
  const [vendors, setVendors] = useState<Vendor[]>([]);
  const [loadingVendors, setLoadingVendors] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    const loadVendors = async () => {
      setLoadingVendors(true);
      const querier = vendorQueries({ firestore }, fsOrgPrefix);
      if (querier) {
        const vendors = await querier.fetchVendors();
        setVendors(sortBy(vendors, 'companyName'));
      }
      setLoadingVendors(false);
    };

    loadVendors();
  }, [firestore, item, orgData]);

  const [saving, setSaving] = useState(false);
  const [itemImg, setItemImg] = useState<{
    downloadURL: string;
    fileName: string;
  } | null>(null);

  const { disableEdit } = useInventoryManager(item && item.item);

  useEffect(() => {
    const part = item.item;

    if (part) {
      form.setFieldsValue({
        name: part.name,
        type: part.type,
        description: part.description,
        categoriesQueryHook: part.categoriesQueryHook,
        stock: part.stock,
        price: part.price,
        notes: part.notes,
        isAvailable: part.isAvailable,
        isActive: part.isActive,
        vendorQueryHook: part.vendorQueryHook,
        imageUploader: part.image
          ? [
              {
                name: part.image.fileName,
                thumbUrl: part.image.downloadURL,
                url: part.image.downloadURL,
              },
            ]
          : null,
      });
      if (part.image) {
        setItemImg({
          downloadURL: part.image?.downloadURL,
          fileName: part.image.fileName,
        });
      }
    }
    return () => setItemImg(null);
  }, [item]);

  const handleFinish = () => {
    form.validateFields().then(async () => {
      setSaving(true);
      const formValues = form.getFieldsValue();

      const { categoriesQueryHook, vendorQueryHook, ...rest } = formValues;

      const selectedCategories =
        categoriesQueryHook &&
        categoriesQueryHook.map((h: string) => {
          const cat = categories && categories.find((c) => c.id === h);

          const { id, orgId, name, description } = cat!;
          return {
            id: id,
            orgId: orgId,
            name: name,
            description: description,
          };
        });

      let selectedVendor = null;
      selectedVendor = vendors.find((v) => v.id === vendorQueryHook);

      const values: PartFormData = {
        categories: selectedCategories, // add categories
        categoriesQueryHook: categoriesQueryHook,
        vendor: selectedVendor,
        vendorQueryHook: vendorQueryHook,
        image: itemImg,
        ...rest,
      };
      const payload = configurePart().editPartItem(values, userData.id, orgData);

      const writer = partWrites({ firestore }, fsOrgPrefix);
      if (writer && item.item && payload) {
        writer
          .updatePart(item.item.id, payload)
          .then(() => {
            setSaving(false);
            notificationConfirm('Part updated');
            form.resetFields();
            onComplete();
          })
          .catch((err) => {
            console.log(err);
            setSaving(false);
            notificationError('Something went wrong', 'Please try again later');
          });
      }
    });
  };

  const onImageUrl = (downloadURL: string, fileName: string) => {
    setItemImg({
      downloadURL: downloadURL,
      fileName: fileName,
    });
  };
  const onClose = () => {
    handleCloseDrawer({
      visible: false,
      item: null,
    });
    setItemImg(null);
    form.resetFields();
  };

  const configureBodyStyle = (item: { item: Part | null; visible: boolean }) => {
    const padding = { padding: '12px' };
    if (!item.item) return padding;
    return disableEdit ? { pointerEvents: 'none', opacity: '.5', ...padding } : {};
  };

  return (
    <Drawer
      className='item-drawer'
      bodyStyle={configureBodyStyle(item)}
      destroyOnClose
      width={650}
      title={
        <Row wrap={false} style={{ width: '100%' }} align='middle'>
          <Col flex={'auto'}>{item.item && item.item.name}</Col>

          <Col flex={'145px'} style={{ textAlign: 'right' }}>
            <Space>
              <Button onClick={onClose} disabled={saving}>
                Cancel
              </Button>
              <Button type='primary' disabled={saving || disableEdit} loading={saving} onClick={handleFinish}>
                Save
              </Button>
            </Space>
          </Col>
        </Row>
      }
      placement={'right'}
      closable={false}
      onClose={onClose}
      open={visible}
      key={'item'}
    >
      <Spin spinning={saving} indicator={Spinner}>
        <Form form={form} onFinish={handleFinish} layout='vertical' requiredMark={false}>
          {item.item && (
            <Card size='small' title={<TextBody style={{ fontWeight: 'bold' }} text={'General'} />} bordered={!drawerView}>
              <NewPartDetails
                duplicated={false}
                vendors={vendors}
                categories={categories}
                form={form}
                onImageUrl={onImageUrl}
                removeImg={() => setItemImg(null)}
                defaultImg={itemImg}
                saving={saving}
              />
            </Card>
          )}
          <br />
          <NewPartAdditional form={form} showWeightInput={orgData && orgData.trackInventoryWeight} />
        </Form>
        <Divider style={{ margin: '16px 0px' }} />
        {item.item && (
          <div>
            <TextSubtitle text={`Last updated: ${convertTimeToString(item.item.updatedOn)}`} />
            <br />
            <TextSubtitle text={`Created: ${convertTimeToString(item.item.createdOn)}`} />
          </div>
        )}
      </Spin>
    </Drawer>
  );
};

export default PartsDrawer;
