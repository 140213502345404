// @mui
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import "firebase/firestore";

import { Card, Link, Stack } from "@mui/material";

export const V2_1_3 = () => {
  return (
    <Card>
    <Box sx={{ p: 2, display: "flex" }}>
      <Stack spacing={0.5}>
        <Typography fontWeight={700}>
          V2.1.3 - Released 10-12-2023
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          style={{ whiteSpace: "pre-wrap" }}
        >
          {`
Bug Fixes!

• We fixed several bugs on the routing pages.
• Routes now hide as expected.
• The route marker info window does not cover the marker.
• Made route marker circles bigger to see better.

`}
        </Typography>
      </Stack>
    </Box>
  </Card>
  );
};
