import { useEffect, useState } from "react";
import {
  useGetCategoriesQuery,
  useGetInventoryQuery,
  useGetTrucksQuery,
} from "../../../app/services/api";
import { useSelector } from "react-redux";

import { useFirestore } from "react-redux-firebase";
import { IncludeInDepreciation } from "../../../app/services/hooks/helpers/reports/includeInDepreciation";
import { CalcDepreciation } from "../../../app/services/hooks/helpers/reports/calcDepreciation";
import { IncludeInTruckDepreciation } from "../../../app/services/hooks/helpers/reports/includeInTruckDepreciation";
import { getColorForPercentage } from "../../../app/ui/colorMap";
import { authSelector } from "../../auth/authSlice";

export function useDepreciationReport() {
  const firestore = useFirestore();
  const [loading, setLoading] = useState(false);
  const [inventoryValue, setInventoryValue] = useState({
    total: 0,
    current: 0,
  });
  const [trucksValue, setTrucksValue] = useState({
    total: 0,
    current: 0,
  });
  const [searchStr, setSearchStr] = useState("");
  const [currentTab, setCurrentTab] = useState("inventory");
  const [truckReportData, setTruckReportData] = useState([]);
  const [categorizedBreakout, setCategorizedBreakout] = useState([]);
  const [inventoryReportData, setInventoryReportData] = useState([]);
  const { fsOrgPrefix } = useSelector(authSelector);
  const { data: inventoryData, isFetching: fetchingInventory } =
    useGetInventoryQuery({
      db: { firestore },
      orgPrefix: fsOrgPrefix,
    });
  const { data: trucksData, isFetching: fetchingTrucks } = useGetTrucksQuery({
    orgPrefix: fsOrgPrefix,
    db: { firestore },
  });

  const { data: categories, isFetching: fetchingCategories } =
    useGetCategoriesQuery({
      db: { firestore },
      orgPrefix: fsOrgPrefix,
    });

  useEffect(() => {
    if (fetchingInventory || fetchingTrucks || fetchingCategories)
      return setLoading(true);
    setLoading(false);
    const data = configureReportData({
      inventoryData,
      trucksData,
    });
    const list = getCategorizedBreakdown({
      categories,
      depreciatedInventoryData: data?.inventory,
    });
    console.log("list", list);
    setCategorizedBreakout(list);
    setInventoryReportData(data?.inventory);
    setTruckReportData(data?.trucks);
    setInventoryValue({
      total: data?.currentInventoryValue?.total,
      current: data?.currentInventoryValue?.current,
    });
    setTrucksValue({
      total: data?.currentTruckValue?.total,
      current: data?.currentTruckValue?.current,
    });
  }, [inventoryData, trucksData, categories]);

  const handleSearch = (str) => {
    setSearchStr(str);
  };
  const onTabChange = (v) => {
    setSearchStr("");
    setCurrentTab(v);
  };
  return {
    state: {
      loading,
      inventoryReportData,
      truckReportData,
      inventoryValue,
      trucksValue,
      currentTab,
      searchStr,
      categories,
      categorizedBreakout,
    },
    actions: {
      onTabChange,
      handleSearch,
    },
  };
}

// functions
const configureReportData = ({ inventoryData, trucksData }) => {
  const filteredInventory = inventoryData
    ?.filter((item) => {
      return IncludeInDepreciation(item);
    })
    .map((i) => {
      const { depreciation, ...rest } = i;
      return {
        currentDepreciation: CalcDepreciation({
          initialValue: depreciation?.initialValue,
          initialDate: depreciation?.initialDate,
          depreciationYears: depreciation?.depreciationYears,
        }),
        ...i,
      };
    });
  const filteredTrucks = trucksData
    ?.filter((truck) => {
      return IncludeInTruckDepreciation(truck);
    })
    .map((t) => {
      const { initialValue, purchaseDate, depreciationYears } = t;
      return {
        currentDepreciation: CalcDepreciation({
          initialValue,
          initialDate: purchaseDate,
          depreciationYears,
        }),
        ...t,
      };
    });
  return {
    inventory: filteredInventory,
    currentInventoryValue: getTotalInventoryValue(filteredInventory),
    trucks: filteredTrucks,
    currentTruckValue: getTotalTruckValue(filteredTrucks),
  };
};
const getTotalInventoryValue = (inventoryData) => {
  let totalValue = 0;
  let currentValue = 0;
  inventoryData?.map((item) => {
    const add = item?.depreciation?.initialValue ?? 0;
    const currentAdd = item?.currentDepreciation?.depreciatedValue ?? 0;
    totalValue = totalValue + add;
    currentValue = currentValue + currentAdd;
  });
  return {
    total: totalValue,
    current: currentValue,
  };
};
const getTotalTruckValue = (truckData) => {
  let totalValue = 0;
  let currentValue = 0;
  truckData?.map((item) => {
    const add = item?.initialValue ?? 0;
    const currentAdd = item?.currentDepreciation?.depreciatedValue ?? 0;
    totalValue = totalValue + add;
    currentValue = currentValue + currentAdd;
  });
  return {
    total: totalValue,
    current: currentValue,
  };
};
const getCategorizedBreakdown = ({ categories, depreciatedInventoryData }) => {
  return categories?.map((c) => {
    const includeInventory = depreciatedInventoryData?.filter((item) =>
      item.categoriesQueryHook.includes(c?.id)
    );
    if (includeInventory?.length > 0) {
      let amt = 0;
      let totalAmt = 0;
      includeInventory?.map((i) => {
        amt = amt + i?.currentDepreciation?.depreciatedValue ?? 0;
        totalAmt = totalAmt + i?.currentDepreciation?.initialValue ?? 0;
      });
      const p = amt / totalAmt;
      return {
        name: c?.name,
        id: c?.id,
        currentValue: `$${amt.toLocaleString("us", {
          maximumFractionDigits: 2,
        })}`,
        total: `$${totalAmt.toLocaleString("us", {
          maximumFractionDigits: 2,
        })}`,
        percent: p,
        color: getColorForPercentage(p),
      };
    } else {
      return {
        name: c?.name,
        id: c?.id,
        currentValue: `$0`,
        total: `$0`,
        percent: 0,
        color: getColorForPercentage(0),
      };
    }
  });
};
