import { useEffect, useState } from "react";
import { List, Space, Divider, Typography, Col, Button, Row } from "antd";
import NumberFormat from "react-number-format";
import TextSubtitle from "../../../../app/system-components/typography/text/TextSubtitle";
import TextBody from "../../../../app/system-components/typography/text/TextBody";
import { useFirestore } from "react-redux-firebase";
import { FilePdfOutlined } from "@ant-design/icons";
import AddEditMaintenanceRecord from "./AddEditMaintenanceRecord"

const { Text } = Typography;

const MaintenanceHistory = ({ fetching, data, item, users }) => {
  const firestore = useFirestore();

  const [totalMaintenanceCost, setTotalMaintenanceCost] = useState(0);
  const [totalPartsCost, setTotalPartsCost] = useState(0);
  const [totalLaborCost, setTotalLaborCost] = useState(0);
  const [roi, setRoi] = useState(null);
  const [recordToEdit, setRecordToEdit] = useState(null);

  useEffect(() => {
    // Calculate total cost of maintenance for this item.
    const totalServiceCost = data.reduce((total, record) => {
      return total + (record.costOfParts || 0) + record.costOfLabor; // Add each serviceCost to the total
    }, 0);
    setTotalMaintenanceCost(totalServiceCost);

    const totalPartsSum = data.reduce((total, record) => {
      return total + (record.costOfParts || 0); // Add each serviceCost to the total
    }, 0);
    setTotalPartsCost(totalPartsSum);

    const totalLaborSum = data.reduce((total, record) => {
      return total + record.costOfLabor; // Add each serviceCost to the total
    }, 0);
    setTotalLaborCost(totalLaborSum);
  }, [data]);

  const formattedDate = (date) => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return date?.toLocaleDateString(undefined, options);
  };

  const handleReviewClick = (record) => {
    setRecordToEdit(record);
  };

  const finishEditing = () => {
    setRecordToEdit(null);
  };

  const handleFileClick = (file) => {
    window.open(file.downloadUrl, "_blank");
  };

  const calculateRoi = async () => {
    // start with lifetime ROI
    // fetcxh all invoices where this item is added.
    const invoicesQuery = await firestore
      .collection(`orgs/${item?.orgId}/invoices/`)
      .where("selectedItemsQueryHook", "array-contains", item.id)
      .get();
    console.log(invoicesQuery.docs.length);

    const invoices = invoicesQuery.docs.map((i) => i.data());
    let totalFromInvoices = 0;
    invoices.forEach((invoice) => {
      const selectedItems = invoice.selectedItems.items;
      if (selectedItems && selectedItems.length > 0) {
        selectedItems.forEach((selectedItem) => {
          if (selectedItem.id === item.id) {
            totalFromInvoices += selectedItem.rowTotal;
          }
        });
      }
    });

    setRoi(totalFromInvoices / 100);
  };

  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <div>
            <div>
              Total Parts Cost: { }
              <Text type="danger">
                $
                {totalPartsCost.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Text>
            </div>

            <div>
              Total Labors Cost: { }
              <Text type="danger">
                $
                {totalLaborCost.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Text>
            </div>

            <div>
              Overall Service Cost: { }
              <Text type="danger">
                $
                {totalMaintenanceCost.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Text>
            </div>
          </div>
        </Col>
        <Space direction="vertical">
          <Button onClick={calculateRoi}>Calculate ROI</Button>

          {roi && (
            <>
              <div>
                Total Revenue: { }
                <Text style={{ fontWeight: "bold" }}>
                  $
                  {roi.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Text>
              </div>
              {/* <br /> */}
              <div>
                ROI: { }
                <Text
                  type={roi - totalMaintenanceCost > 0 ? "success" : "danger"}
                  style={{ fontWeight: "bold" }}
                >
                  $
                  {(roi - totalMaintenanceCost).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Text>
              </div>
            </>
          )}
        </Space>
      </Row>

      <Divider />

      <>
        {recordToEdit === null ? (
          <List
            loading={fetching}
            locale={{ emptyText: "No records" }}
            size="small"
            bordered={false}
            dataSource={data}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <Button onClick={() => handleReviewClick(item)}>
                    Review
                  </Button>,
                ]}
              >
                <List.Item.Meta
                  title={
                    <Space>
                      <TextBody
                        text={"Scheduled for: "}
                        style={{ fontWeight: "bold" }}
                      />
                      {formattedDate(item.servicedOn)}
                    </Space>
                  }
                  description={
                    <Space direction={"vertical"} size={0}>
                      <Space>
                        <TextBody text={"Cost of Parts: "} />
                        <NumberFormat
                          style={{ fontWeight: "semi-bold" }}
                          value={item?.costOfParts.toFixed(2)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />

                        <TextBody text={"Cost of Labor: "} />
                        <NumberFormat
                          style={{ fontWeight: "semi-bold" }}
                          value={item?.costOfLabor.toFixed(2)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </Space>
                      <TextBody text={`Serviced By: ${item.servicedBy}`} />
                      <TextBody text={`Notes: ${item.notes}`} />

                      {item &&
                        item.internalFiles &&
                        item.internalFiles.length > 0 && (
                          <>
                            <br />

                            <TextBody
                              text={"Attachments"}
                              style={{ fontWeight: "bold" }}
                            />

                            <List
                              dataSource={(item && item.internalFiles) || []}
                              renderItem={(file) => (
                                <List.Item
                                  key={file.name}
                                  onClick={() => handleFileClick(file)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <List.Item.Meta
                                    avatar={
                                      <FilePdfOutlined
                                        style={{ color: "red" }}
                                      />
                                    }
                                    title={file.name}
                                  />
                                </List.Item>
                              )}
                            />
                          </>
                        )}
                    </Space>
                  }
                />
              </List.Item>
            )}
          />
        ) : (

          // Display this when recordToEdit is set

          <AddEditMaintenanceRecord
            maintenanceRecord={recordToEdit}
            item={item}
            finishEditing={finishEditing}
            users={users}
          />
        )}
      </>
    </>
  );
};

export default MaintenanceHistory;
