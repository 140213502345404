import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {
  Box,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  Switch,
  alpha,
} from "@mui/material";

import { ChangeEvent, FunctionComponent, useEffect, useState } from "react";

import { useFirestore } from "react-redux-firebase";
import { Permissions } from "./permissions.type";
import { minimumDefaultRoutePermissions } from "./usePermissions";
import notificationConfirm from "../../app/system-components/toasters/notificationConfirm";
import { LoadingButton } from "@mui/lab";
import { useSelector } from "react-redux";
import { authSelector } from "../auth/authSlice";

// ----------------------------------------------------------------------
export interface InvoiceTextData {
  estimate: string;
  invoice: string;
  invoiceUpdated: string;
  invoiceDue: string;
  invoiceRentalStart: string;
  invoiceRentalEnd: string;
  invoiceEventDate: string;

  receiveReturnDetails: string;
  customerReceivesItems: string;
  itemsReturnedBy: string;
  receiveAddress: string;
  returnAddress: string;
  receiveDateTime: string;
  returnDateTime: string;
  venue: string;
  preferredDateStyle: string;
}

export default function PermissionsView() {
  const { orgData } = useSelector(authSelector);
  const firestore = useFirestore();

  const [saving, setSaving] = useState(false);
  const [inputData, setInputData] = useState<Permissions>(
    minimumDefaultRoutePermissions
  );

  const fetchPermissions = async () => {
    const permissionsSnap = await firestore
      .collection("orgs")
      .doc(orgData.id)
      .collection("settings")
      .doc("permissions")
      .get();
    if (permissionsSnap.exists) {
      const data = permissionsSnap.data() as Permissions;
      setInputData(data);
    }
  };
  useEffect(() => {
    if (orgData) {
      fetchPermissions();
    }
  }, [orgData]);

  const handlePermissionChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    route: string
  ) => {
    const { name, checked } = e.target;

    // Clone inputData and the array for the specific route to ensure immutability
    let updatedInputData = {
      ...inputData,
      [route]: [...inputData[route as keyof Permissions]],
    };

    if (checked) {
      // If 'checked' is true and 'name' is not in the array, add it
      if (!updatedInputData[route as keyof Permissions].includes(name)) {
        updatedInputData[route as keyof Permissions] = [
          ...updatedInputData[route as keyof Permissions],
          name,
        ]; // Creating a new array
      }
    } else {
      // If 'checked' is false and 'name' is in the array, remove it
      updatedInputData[route as keyof Permissions] = updatedInputData[
        route as keyof Permissions
      ].filter((permissionName) => permissionName !== name);
    }

    // Update your state with the new inputData
    console.log(updatedInputData);
    setInputData(updatedInputData);
  };

  const handleSubmit = async () => {
    try {
      
      setSaving(true);
      await firestore
        .collection("orgs")
        .doc(orgData.id)
        .collection("settings")
        .doc("permissions")
        .set(inputData, { merge: true });
      notificationConfirm("Saved");
      setSaving(false)
    } catch (e) {
      console.error("Error adding document: ", e);
      setSaving(false)
    }
  };

  return (
    <Container>
      <Typography sx={{ mb: 2, mt: 2 }} variant="h4">
        Customize User Type Permissions
      </Typography>

      <Grid container direction="row" alignItems="flex-start">
        <Grid item xs>
          <Stack direction="column">
          <Typography sx={{ mb: 2, mt: 2 }} variant="body1">
            User permissions are determined by user type. We currently have
            three types: Admin, Office, and Warehouse.
          </Typography>
          <Typography sx={{ mb: 2, mt: 2 }} variant="body1">
            For each view/feature in the app, you can toggle on/off what user
            type can view.
          </Typography>
          </Stack>
        </Grid>

        <Grid item>
          <LoadingButton
            loading={saving}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Save
          </LoadingButton>
        </Grid>
      </Grid>

      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <PermissionGroup
              title={"Check Availability"}
              route={"/inventory"}
              handlePermissionChange={handlePermissionChange}
              inputData={inputData}
            />
            <PermissionGroup
              title={"Make The Sale"}
              route={"/invoices"}
              handlePermissionChange={handlePermissionChange}
              inputData={inputData}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <PermissionGroup
              title={"Prepare The Items"}
              route={"/quick-report"}
              handlePermissionChange={handlePermissionChange}
              inputData={inputData}
            />
            <PermissionGroup
              title={"Manage Delivery/Pickup"}
              route={"/routes"}
              handlePermissionChange={handlePermissionChange}
              inputData={inputData}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <PermissionGroup
              title={"See How You're Doing > Sales Report"}
              route={"/reports/sales"}
              handlePermissionChange={handlePermissionChange}
              inputData={inputData}
            />
            <PermissionGroup
              title={"See How You're Doing > Tax Report"}
              route={"/reports/tax"}
              handlePermissionChange={handlePermissionChange}
              inputData={inputData}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <PermissionGroup
              title={"See How You're Doing > Usage Report"}
              route={"/reports/usage/"}
              handlePermissionChange={handlePermissionChange}
              inputData={inputData}
            />
            <PermissionGroup
              title={"See How You're Doing > Depreciation Report"}
              route={"/reports/depreciation/"}
              handlePermissionChange={handlePermissionChange}
              inputData={inputData}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <PermissionGroup
              title={"Admin > Users"}
              route={"/admin-users"}
              handlePermissionChange={handlePermissionChange}
              inputData={inputData}
            />
            <PermissionGroup
              title={"Admin > Inventory"}
              route={"/admin-inventory"}
              handlePermissionChange={handlePermissionChange}
              inputData={inputData}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <PermissionGroup
              title={"Admin > Customers"}
              route={"/admin-customers"}
              handlePermissionChange={handlePermissionChange}
              inputData={inputData}
            />
            <PermissionGroup
              title={"Admin > Vendors"}
              route={"/admin-vendors"}
              handlePermissionChange={handlePermissionChange}
              inputData={inputData}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <PermissionGroup
              title={"Admin > Venues"}
              route={"/admin-venues"}
              handlePermissionChange={handlePermissionChange}
              inputData={inputData}
            />
            <PermissionGroup
              title={"Admin > Trucks"}
              route={"/admin-trucks"}
              handlePermissionChange={handlePermissionChange}
              inputData={inputData}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <PermissionGroup
              title={"Admin > Integrations"}
              route={"/admin-integrations"}
              handlePermissionChange={handlePermissionChange}
              inputData={inputData}
            />

            <PermissionGroup
              title={"Settings > General"}
              route={"/admin-settings-general"}
              handlePermissionChange={handlePermissionChange}
              inputData={inputData}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <PermissionGroup
              title={"Settings > Email"}
              route={"/admin-settings-email"}
              handlePermissionChange={handlePermissionChange}
              inputData={inputData}
            />
            <PermissionGroup
              title={"Settings > Text Customization"}
              route={"/admin-settings-words"}
              handlePermissionChange={handlePermissionChange}
              inputData={inputData}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <PermissionGroup
              title={"Settings > Embedded Checkout"}
              route={"/admin-embedded-checkout"}
              handlePermissionChange={handlePermissionChange}
              inputData={inputData}
            />
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}

interface PermissionGroupProps {
  title: string;
  route: string;
  handlePermissionChange: (
    e: ChangeEvent<HTMLInputElement>,
    route: string
  ) => void;
  inputData: Permissions;
}

const PermissionGroup: FunctionComponent<PermissionGroupProps> = ({
  title,
  route,
  handlePermissionChange,
  inputData,
}) => {
  return (
    <Box
      sx={{
        p: 2,
        mb: 2,
        width: "100%",
        borderRadius: 2,
        bgcolor: (theme) => alpha(theme.palette.background.paper, 0.25),
        border: (theme) => `dashed 1px ${theme.palette.divider}`,
      }}
    >
      <Stack>
        <Typography fontWeight={"bold"}>{title}</Typography>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                onChange={(e) => handlePermissionChange(e, route)}
                checked={inputData[route as keyof Permissions].includes(
                  "admin"
                )}
                name="admin"
              />
            }
            label="Admin"
          />
          <FormControlLabel
            control={
              <Switch
                onChange={(e) => handlePermissionChange(e, route)}
                checked={inputData[route as keyof Permissions].includes(
                  "office"
                )}
                name="office"
              />
            }
            label="Office"
          />
          <FormControlLabel
            control={
              <Switch
                onChange={(e) => handlePermissionChange(e, route)}
                checked={inputData[route as keyof Permissions].includes(
                  "warehouse"
                )}
                name="warehouse"
              />
            }
            label="Warehouse"
          />
        </FormGroup>
      </Stack>
    </Box>
  );
};
