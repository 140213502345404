import { useEffect, useState } from "react";
import moment from "moment";
import {
  useGetCategoriesQuery,
  useGetInventoryQuery,
  useGetInvoicesForRangeQuery,
} from "../api";
import { useFirestore } from "react-redux-firebase";
import { GetSubtotalsForInvoices } from "./helpers/reports/getSubtotalsForInvoices";
import { GetSubtotalsByInventory } from "./helpers/reports/getSubtotalsByInventory";
import { sortByNumberValue } from "../../utils/sorters/sortBy";
import { useSelector } from "react-redux";
import { authSelector } from "../../../domains/auth/authSlice";

export function useSalesReport() {

  const firestore = useFirestore();
  const { fsOrgPrefix, orgData } = useSelector(authSelector);

  const [dateRange, setDateRange] = useState({
    start: moment().subtract(7, "days").startOf("day"),
    end: moment().endOf("day"),
  });
  const [loading, setLoading] = useState(false);
  const [itemFilters, setItemFilters] = useState([]);
  const [totalSales, setTotalSales] = useState(0);
  const [chartData, setChartData] = useState([]);
  const [inventory, setInventory] = useState([]);
  const [selectedInvItem, setSelectedInvItem] = useState(null);
  const [selectedInvAmt, setSelectedInvAmt] = useState(0);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  
  const {
    data: invoices,
    isFetching: fetchingInvoices,
    refetch: refetchInvoices,
  } = useGetInvoicesForRangeQuery({
    db: { firestore },
    orgPrefix: fsOrgPrefix,
    dateStart: dateRange.start ?? null,
    dateEnd: dateRange.end ?? null,
    orgTimezone: orgData?.orgTimezone,
    invoiceOnly: true,
  });

  const { data: categories, isFetching: fetchingCategories } =
    useGetCategoriesQuery({
      db: { firestore },
      orgPrefix: fsOrgPrefix,
    });

  const { data: inventoryData, isFetching: fetchingInventory } =
    useGetInventoryQuery({
      db: { firestore },
      orgPrefix: fsOrgPrefix,
    });

  useEffect(() => {
    refetchInvoices();
  }, [dateRange]);

  useEffect(() => {
    if (fetchingInventory) return;
    setInventory(inventoryData);
  }, [inventoryData]);

  useEffect(() => {
    if (fetchingInvoices || fetchingCategories || fetchingInventory)
      return setLoading(true);
    setLoading(false);
    // format for sales data
    // run through filters
    setTotalSales(GetSubtotalsForInvoices(invoices));
    const invList = GetSubtotalsByInventory({
      invoices,
      inventoryIds: inventory?.map((i) => i?.id),
      filterItemIds: itemFilters,
      inventory,
    });
    setChartData(invList);
    const matchingInvIds = inventoryIdsInCatFilters({
      inventoryData,
      itemFilters,
    });
    const filteredInvIds = invList?.map((i) => i?.inventoryId);
    const invItemsToFilter =
      itemFilters?.length > 0 ? matchingInvIds : filteredInvIds;
    const filteredInvoices = filterInvoices({
      inventoryIds: invItemsToFilter,
      invoices,
    });

    const filteredSelected = filteredInvoices?.filter((inv) => {
      return !!inv?.selectedItemsQueryHook.includes(selectedInvItem);
    });
    setFilteredInvoices(
      selectedInvItem
        ? sortByNumberValue(filteredSelected, "invoiceNumber")
        : sortByNumberValue(filteredInvoices, "invoiceNumber")
    );
  }, [invoices, categories, inventory, itemFilters, selectedInvItem]);

  useEffect(() => {
    let amt = 0;
    if (itemFilters?.length > 0) {
      const matchingInvIds = inventoryIdsInCatFilters({
        inventoryData,
        itemFilters,
      });
      chartData?.map((i) => {
        if (matchingInvIds.includes(i?.inventoryId)) {
          amt = amt + i?.filteredTotal;
        }
      });
    }
    if (selectedInvItem) {
      const item = chartData?.find((i) => i?.inventoryId === selectedInvItem);
      amt = item?.filteredTotal;
    }
    // const amt = chartData?.find((i) => i?.inventoryId === selectedInvItem);
    setSelectedInvAmt(amt);
  }, [selectedInvItem, chartData, itemFilters]);

  const changeDateRange = (dates) => {
    setDateRange({
      start: dates?.[0]?.startOf("day"),
      end: dates?.[1]?.endOf("day"),
    });
  };

  const changeItemFilters = (filters) => {
        setSelectedInvItem(null);
    setItemFilters([...filters]);
  };

  const changeSelectedInvItem = (v) => {
    setSelectedInvItem(v);
  };

  return {
    state: {
      dateRange,
      totalSales,
      categories,
      fetchingInventory,
      fetchingCategories,
      fetchingInvoices,
      inventory,
      chartData,
      selectedInvItem,
      invoices,
      filteredInvoices,
      selectedInvAmt,
      itemFilters,
      loading,
    },
    actions: {
      changeDateRange,
      changeItemFilters,
      changeSelectedInvItem,
      setDateRange,
    },
  };
}

const inventoryIdsInCatFilters = ({ inventoryData, itemFilters }) => {
  return inventoryData
    ?.filter((i) => {
      const incl = itemFilters?.filter((f) => {
        return !!i?.categoriesQueryHook.includes(f);
      });
      return incl?.length > 0;
    })
    ?.map((item) => item?.id);
};
const filterInvoices = ({ inventoryIds, invoices }) => {
  return invoices?.filter((i) => {
    const res = i?.selectedItemsQueryHook?.filter((id) =>
      inventoryIds.includes(id)
    );
    return res.length > 0;
  });
};
