import React, { useEffect, useState } from 'react';
import { Autocomplete, Button, Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import ItemAccountsSelector from './item-accounts-selector';


export const QboItemSync = ({ item, orgId }) => {
  const firebase = useFirebase();
  const db = useFirestore();

  const [syncing, setSyncing] = useState(false);
  const [syncSucceeded, setSyncSucceeded] = useState(false);
  const [error, setError] = useState(null);


  const syncItem = async () => {
    setSyncing(true);

    const call = firebase.functions().httpsCallable('manualInventorySync');

    return call({
      orgId,
      itemId: item.id,
    })
      .then(async (res) => {
        console.log(res);
        const status = res.data.status;
        if (status === 200) {
          setSyncSucceeded(true);
        } else if (status === 500) {
          const errorDataArray = res.data.error.Fault.Error;
          const errorData = errorDataArray[0];
          const error = errorData || {
            Message: 'Sync Failed',
            Detail: 'Sorry, we do not know why it failed. Please contact support.',
            Code: 'ERROR_CODE',
          };

          setError(error);
        }

        setSyncing(false);
      })
      .catch((err) => {
        setSyncing(false);
        console.log(err);
      });
  };


  return (
    <>
      {item && item.qbId && item.qbId !== "" && (
        <>
          <Typography variant="body1" gutterBottom>
            This item has been synced to QuickBooks. You can also specify an
            income/expense account below.
          </Typography>
          <br />
        </>
      )}

      <ItemAccountsSelector item={item} orgId={orgId}/>

      {item && (!item.qbId || item.qbId === "") && (
        <>
          <Stack>
            {!syncSucceeded && (
              <Typography variant="body1" color={"darkred"}>
                This item has not been properly synced with your QuickBooks
                account.
              </Typography>
            )}

            {syncSucceeded && (
              <Typography variant="body1" color={"darkgreen"}>
                Sync successful!
              </Typography>
            )}
            <br />

            {error && (
              <Stack direction={"column"}>
                <Typography color={"darkred"}>
                  Sync Failed. Review the reasons below, correct the issue, save
                  item, and try again.
                </Typography>
                <br />
                <Typography color={"darkred"}>
                  {error?.Message ?? ""}
                </Typography>
                <Typography gutterBottom color={"darkred"}>
                  {error?.Detail ?? ""}
                </Typography>
              </Stack>
            )}

            {!syncSucceeded && (
              <LoadingButton
                style={{ width: "200px" }}
                variant="contained"
                color="primary"
                loading={syncing}
                loadingPosition="end"
                onClick={syncItem}
                disabled={syncing}
              >
                Sync Item
              </LoadingButton>
            )}
          </Stack>
        </>
      )}
    </>
  );
};
