import {
  Button,
  Container,
  Paper,
  Typography,
  capitalize,
} from "@mui/material";
import React from "react";
import {
  findFeatureTier,
  featuresPermissionSet,
} from "../../app/interfaces/features-set-permissions";
import PricingTable from "./pricing-table";
import SubscriptionInfoCard from "./subscription-card";
import { OrganizationData } from "../../app/interfaces/organizationData";
import { portalUrl } from "../../app/config/stripe";


const LockedView = ({
  orgData,
  userData,
  subscriptionFeaturePath,
}: {
  orgData: OrganizationData;
  userData: any;
  subscriptionFeaturePath: string;
}) => {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Paper
        sx={{
          padding: "20px",
          margin: "20px 0",
          width: "100%",
          height: "100%",
        }}
      >
        <Typography>
          {`Your current plan does not have access to this feature. You will need to upgrade to at least the ${capitalize(
            findFeatureTier(featuresPermissionSet, subscriptionFeaturePath)
          )} plan to access this feature. Contact info@adelielogistics.com to do so.`}
        </Typography>

        <Button
          size="small"
          sx={{ mb: 1,mt: 2, width: "fit-content" }}
          variant="contained"
          onClick={() => {
            // email to info@adelielogistics
            const email = "info@adelielogistics.com";
            const subject = encodeURIComponent(
              "Subscription Plan Change Request"
            );
            const body = encodeURIComponent(
              "Please change my subscription plan to the following: \n"
            );

            window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
          }}
        >
          Contact To Manage
        </Button>

        {/* WIP */}
        {/* {orgData &&
          (orgData.subscriptionStatus === "inactive" ||
            orgData.subscriptionStatus === "trial") &&
          userData &&
          userData.permissions === "admin" && (
            <PricingTable orgData={orgData} />
          )}

        {orgData &&
        orgData.grandfathered === false &&
        orgData.subscriptionStatus === "active" &&
        userData &&
        userData.permissions === "admin" ? (
          <Button
            sx={{ mt: 2 }}
            variant="contained"
            onClick={() => {
              window.open(portalUrl);
            }}
          >
            Change Subscription
          </Button>
        ) : (
          <Typography>
            Please contact your organization's administrator to upgrade your
            subscription.
          </Typography>
        )}
        */}
      </Paper>

      {orgData &&
        orgData.subscriptionStatus === "active" &&
        userData &&
        userData.permissions === "admin" && (
          <SubscriptionInfoCard orgData={orgData} />
        )}
    </Container>
  );
};

export default LockedView;
