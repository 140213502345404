import React, { useContext, useEffect, useState } from "react";
import {
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Switch,
} from "antd";
import { TrucksContext } from "./AdminTrucks";
import TextSubtitle from "../../app/system-components/typography/text/TextSubtitle";
import { ConfigureTruck } from "../../app/utils/models/configure/configureTruck";
import { useFirestoreWrite } from "../../app/services/hooks/fetch/useFirestoreWrite";
import { TRUCKS_COLLECTION } from "../../app/utils/models/collections/collectionConstants";
import notificationError from "../../app/system-components/toasters/notificationError";
import notificationConfirm from "../../app/system-components/toasters/notificationConfirm";
import moment from "moment";

const AdminTruckModal = ({ refreshTable }) => {
  const modal = useContext(TrucksContext);
  const [form] = Form.useForm();
  const [isEdit, setIsEdit] = useState(false);
  const { writing, newDocument, updateDocument } = useFirestoreWrite();
  const [defaultToggle, setDefaultToggle] = useState({
    isAvailable: true,
  });
  // console.log("modal data", modal.data);

  useEffect(() => {
    const toggleData = {
      isAvailable: modal.data?.isAvailable,
    };
    if (modal.data) {
      setIsEdit(true);
      // edit existing
      form.setFieldsValue({
        initialValue: modal.data?.initialValue ?? 0,
        purchaseDate: modal.data?.purchaseDate
          ? new moment(modal.data?.purchaseDate.toDate())
          : null,
        depreciationYears: modal.data?.depreciationYears ?? 0,
        serviceDate: modal.data?.serviceDate
          ? new moment(modal.data?.serviceDate.toDate())
          : null,
        description: modal.data?.description ?? "",
        name: modal.data?.name ?? "",
        truckNotes: modal.data?.truckNotes ?? "",
        isAvailable: modal.data?.isAvailable ?? true,
      });
      setDefaultToggle(toggleData);
    } else {
      // new truck
      setIsEdit(false);
    }
  }, [modal.data]);

  const createNewTruck = async (values) => {
    const payload = ConfigureTruck(values);
    try {
      await newDocument({
        data: {
          collection: TRUCKS_COLLECTION,
          payload,
        },
      });
      return notificationConfirm("Truck created");
    } catch (err) {
      console.log(err);
      return notificationError(
        "Unable to save",
        "Something went wrong. Please try again."
      );
    }
  };
  const saveExistingTruck = async (values) => {
    if (!modal?.data?.id)
      return notificationError(
        "Something went wrong",
        "Please try again later"
      );
    const payload = ConfigureTruck(values);
    const { createdOn, ...payloadRest } = payload;
    console.log(payload);
    try {
      await updateDocument({
        data: {
          collection: TRUCKS_COLLECTION,
          payload: payloadRest,
          id: modal?.data?.id,
        },
      });
      return notificationConfirm("Truck saved");
    } catch (err) {
      console.log(err);
      return notificationError(
        "Unable to save",
        "Something went wrong. Please try again."
      );
    }
  };

  const validateAndCreate = async () => {
    const values = await form.validateFields();
    await modal.ok(async () => {
      return isEdit
        ? await saveExistingTruck(values)
        : await createNewTruck(values);
    });
  };
  return (
    <Modal
      destroyOnClose={true}
      open={modal.visible}
      confirmLoading={modal.loading || writing}
      onCancel={() => {
        form.resetFields();
        modal.cancel();
      }}
      onOk={() =>
        validateAndCreate().then(() => {
          form.resetFields();
          refreshTable();
        })
      }
      title={"Add Truck"}
    >
      <Form form={form} layout={"vertical"}>
        <Form.Item name={"name"} label={"Name"} required={true}>
          <Input placeholder={"Truck name"} />
        </Form.Item>
        <Form.Item name={"description"} label={"Description"} required={true}>
          <Input placeholder={"Truck description"} />
        </Form.Item>
        <Row wrap={false} gutter={12}>
          <Col span={12}>
            <Form.Item name={"serviceDate"} label={"Next service date"}>
              <DatePicker
                format={(d) => d.format("MMM Do YYYY")}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={"purchaseDate"} label={"Date of purchase"}>
              <DatePicker
                format={(d) => d.format("MMM Do YYYY")}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row wrap={false} gutter={12}>
          <Col span={12}>
            <Form.Item name="initialValue" label={"Initial truck value"}>
              <InputNumber
                min={0}
                placeholder={0}
                style={{ width: "100%" }}
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={"depreciationYears"} label={"Depreciation Years"}>
              <InputNumber min={0} placeholder={0} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row wrap={false} gutter={12}>
          <Col span={24}>
            <Form.Item
              name={"truckNotes"}
              label={"Additional Info (Service Records, Mileage...etc.)"}
            >
              <Input.TextArea placeholder="Additional Info" rows={5} />
            </Form.Item>
          </Col>
        </Row>
        <Row wrap={false} gutter={12}>
          <Col span={24}>
            <Form.Item
              name="isAvailable"
              style={{ marginBottom: 0 }}
              initialValue={isEdit ? modal.data?.isAvailable : true}
              valuePropName="checked"
            >
              <Switch
                size="small"
                defaultChecked={isEdit ? modal.data?.isAvailable : true}
                onChange={(e) => {
                  form.setFieldsValue({ isAvailable: e });
                }}
              />{" "}
              Is Available
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AdminTruckModal;
