import React from "react";
import { Card, Col, Divider } from "antd";

import { COLOR_BLUE_0, COLOR_RED_0 } from "../../../app/ui/colorConstants";
import { LoadingButton } from "@mui/lab";
import { Button, Link } from "@mui/material";

const JustifiCard = ({ orgData, configureJustifi, manageJustifi, creatingBusiness  }) => {
  
  return (
    <Col
      xs={12}
      sm={10}
      md={8}
      lg={8}
      xl={8}
      style={{ minWidth: "360px", marginBottom: "16px" }}
    >
      <Card size="small">
        <img
          src="/images/justifi-logo.svg"
          alt="justifi_logo"
          style={{ height: "50px" }}
        />

        {
          orgData && orgData.justifiBizAccount && orgData.justifiBizAccount !== "" ? (
            <Button
            variant="contained"
            style={{
              float: "right",
            }}
            onClick={manageJustifi}
          >
            Manage
          </Button>
          ) : (
            <LoadingButton
            loading={creatingBusiness}
            variant="contained"
            style={{
              float: "right",
            }}
            onClick={configureJustifi}
          >
            Get Started
          </LoadingButton>
          )
        }
          
        <Divider style={{ margin: "20px 0 16px" }} />

        <Link
            href="https://justifi.tech"
            underline="always"
            target="_blank"
            rel="noopener"
          >
            Learn More About Justifi Fintech Solutions
          </Link>
        
      </Card>
    </Col>
  );
};

export default JustifiCard;
