// @mui
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { useTheme, alpha } from "@mui/material/styles";
import firebase from "firebase/app";
import "firebase/firestore";

import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFirebase, useFirestore } from "react-redux-firebase";
import {
  Button,
  Card,
  Chip,
  Divider,
  IconButton,
  Link,
  Stack,
  TextField,
} from "@mui/material";

import { message } from "antd";
import { authSelector } from "../auth/authSlice";
import AppInfo from "./app-info";

export default function MobileAppsView() {
  const theme = useTheme();
  const { orgData, userData } = useSelector(authSelector);

  const firestore = useFirestore();
  const firebase = useFirebase();

  const [feedbackText, setFeedbackText] = useState("");

  const handleFeedbackSubmit = () => {
    // Add the feedback text to the 'feedback' Firestore collection
    firestore
      .collection("feedback")
      .add({
        text: feedbackText,
        feature: "mobile",
        company: orgData.companyName,
        userEmail: userData.email,
        userName: userData.firstName,
        timestamp: firestore.FieldValue.serverTimestamp(),
      })
      .then(() => {
        // Feedback successfully added to Firestore
        console.log("Feedback submitted successfully");
        // You can also reset the text field here if needed
        message.success("Thank you for your feedback!");
        setFeedbackText("");
      })
      .catch((error) => {
        // Handle errors, e.g., display an error message
        console.error("Error submitting feedback:", error);
      });
  };

  return (
    <Container maxWidth={"xl"} style={{ marginTop: "50px" }}>
 
       <AppInfo />

      <Card sx={{ padding: "12px" }}>
        <Typography variant="h4" component="h2">
          Have ideas for how to make the Mobile Apps even better?
        </Typography>
        <br />
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { width: "100%" },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="outlined-multiline-flexible"
            label="Tell us what would make your life easier!"
            multiline
            maxRows={4}
            value={feedbackText}
            onChange={(e) => setFeedbackText(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{ mt: 2 }}
            onClick={handleFeedbackSubmit} // Call the submit function when the button is clicked
          >
            Submit
          </Button>
        </Box>
      </Card>

      <br />
    </Container>
  );
}
