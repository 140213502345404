import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Card,
  CardContent,
  Link,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Iconify from "../../app/iconify/iconify";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  feature: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
  },
  card: {
    width: "48%", // Adjust the width as needed
    padding: theme.spacing(2),
  },
  cardContent: {
    textAlign: "center",
  },
}));

function AppInfo() {
  const classes = useStyles();

  return (
    <div>
      {/* Add this row above the existing content */}
      <div className={classes.row}>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Typography variant="h6">Get It On iOS</Typography>
            <Link
              href="https://apps.apple.com/us/app/adelie/id1636241259"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Iconify icon={"mingcute:appstore-line"} width={40} />
            </Link>
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Typography variant="h6">Get It On Android</Typography>
            <Link
              href="https://play.google.com/store/apps/details?id=com.adelie.adelie&pcampaignid=web_share"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Iconify icon={"teenyicons:google-play-store-solid"} width={40} />
            </Link>
          </CardContent>
        </Card>
      </div>

        <br />

      <Card sx={{ padding: "12px" }}>
        <CardContent>
          <Typography variant="h4" gutterBottom>
            Streamline Your Event and Equipment Rental Business with Our Mobile
            Apps
          </Typography>

          <Typography variant="h6" gutterBottom>
            Features:
          </Typography>
          <List>
            <ListItem>
              <ListItemText
                primary="Real-time Access to Daily Orders"
                secondary="Instantly access and manage daily orders, ensuring your team is always up-to-date on what needs to be prepared and dispatched."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Efficient Routing"
                secondary="Our routing feature helps your warehouse workers plan the most efficient routes for deliveries and pickups, saving time and reducing fuel costs."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Inventory Loading Management"
                secondary="Keep track of your inventory as it's loaded onto trucks, ensuring accurate counts and preventing any shortages or overages."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Seamless Communication with Customers"
                secondary="Stay connected with your customers through the app, providing updates on order status, delivery times, and any changes or issues that may arise."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Digital Receipt of Service Signatures"
                secondary="Say goodbye to paper receipts and signatures. Our app allows you to digitally capture signatures from customers upon delivery or pickup, streamlining the invoicing process."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="User-Specific Route Views"
                secondary="Assign routes to specific users and enable them to view their assigned routes. This feature enhances accountability and helps your team plan their day more effectively."
              />
            </ListItem>
          </List>

          <Typography variant="h6" gutterBottom>
            Why Should You Utilize Our Mobile Apps?
          </Typography>
          <Typography variant="body1" paragraph>
            Enhanced Efficiency: Our mobile apps are designed to boost your
            warehouse workers' productivity, helping them accomplish more in
            less time. With real-time access to orders, efficient routing, and
            inventory management, you can streamline your operations and reduce
            costly errors.
          </Typography>
          <Typography variant="body1" paragraph>
            Improved Customer Relations: Effective communication with customers
            is crucial in the event and equipment rental industry. Our app makes
            it easy to keep customers informed about their orders, ensuring a
            positive experience and repeat business.
          </Typography>
          <Typography variant="body1" paragraph>
            Reduced Costs: Optimize your routes to minimize fuel consumption and
            reduce operational costs. With our app's routing feature, you'll
            save both time and money on each delivery or pickup.
          </Typography>
          <Typography variant="body1" paragraph>
            Paperless Transactions: Ditch the paperwork and go digital with our
            app's signature capture feature. This not only saves you time but
            also helps the environment by reducing paper waste.
          </Typography>
          <Typography variant="body1">
            Enhanced User Accountability: Assign routes to specific users and
            enable them to view their assignments. This feature fosters a sense
            of responsibility among your team members and helps them plan their
            day efficiently.
          </Typography>
        </CardContent>
      </Card>

      <br />

    </div>
  );
}

export default AppInfo;
