import { FC, useState } from "react";
import {
  Card,
  Col,
  Form,
  InputNumber,
  Row,
  Switch} from "antd";
import { LockOutlined, UnlockOutlined } from "@ant-design/icons"; // Import the icons

import TextSubtitle from "../../../../app/system-components/typography/text/TextSubtitle";
import TextBody from "../../../../app/system-components/typography/text/TextBody";
import { InventoryItem } from "../../../../app/interfaces/inventoryItem";


const MaintenanceDetails: FC<{
  form: any;
  item?: InventoryItem;
  isEdit?: any;
  visible?: any;
  drawerView?: any;
}> = ({ form, item, isEdit, visible, drawerView }) => {

  const [isHoursBetweenServicesLocked, setIsHoursBetweenServicesLocked] = useState(true);

  const handleLockChange = (checked: boolean) => {
    setIsHoursBetweenServicesLocked(checked);
    if (!checked) {
      form.setFieldsValue({
        hoursBetweenServices: undefined, // Reset the value when unlocking
      });
    }
  };


  return (
    <div>
      <Card
        size="small"
        title={
          <TextBody style={{ fontWeight: "bold" }} text={"Maintenance Schedule"} />
        }
        bordered={!drawerView}
      >
        <Col>
          <Col>
            <Form.Item
              name="activeHours"
              label={<TextSubtitle text={"CURRENT ACTIVE HOURS"} />}
            >
              <InputNumber
                min={0}
                placeholder={"0"}
                style={{ width: "120px" }}
              />
            </Form.Item>
          </Col>

          <Row>
            <Form.Item
              name="hoursBetweenServices"
              label={<TextSubtitle text={"TIME BETWEEN SERVICE (HOURS)"} />}
            >
              <InputNumber
                min={0}
                placeholder={"0"}
                style={{ width: "120px" }}
                disabled={isHoursBetweenServicesLocked} // Disable the input based on the lock state
              />
            </Form.Item>

            <Col>
              <Form.Item label={<span>&nbsp;</span>}>
                <Switch
                  checked={isHoursBetweenServicesLocked}
                  onChange={handleLockChange}
                />
                {isHoursBetweenServicesLocked ? (
                  <LockOutlined style={{ marginLeft: "8px" }} />
                ) : (
                  <UnlockOutlined style={{ marginLeft: "8px" }} />
                )}
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Card>
    </div>
  );
};

export default MaintenanceDetails;
