import React, { useEffect, useState } from 'react';
import { Affix, Button, Form, Spin, Card, PageHeader } from 'antd';
import { Spinner } from '../../../../app/ui/spinner';
import { useSelector } from 'react-redux';
import { authSelector } from '../../../auth/authSlice';
import { useFirestore } from 'react-redux-firebase';
import { categoryQueries } from '../../../../app/services/firestore/queries/categoryQueries';
import { sortBy } from '../../../../app/utils/sorters/sortBy';
import { partWrites } from '../../../../app/services/firestore/writes/inventoryWrites';
import notificationConfirm from '../../../../app/system-components/toasters/notificationConfirm';
import notificationError from '../../../../app/system-components/toasters/notificationError';
import TextBody from '../../../../app/system-components/typography/text/TextBody';
import { InventoryCategory } from '../../../../app/interfaces/inventoryCategory';

import { PartFormData } from '../../../../app/interfaces/part';
import { configurePart } from '../../../../app/utils/models/configure/configurePart';
import { vendorQueries } from '../../../../app/services/firestore/queries/vendorQueries';
import { Vendor } from '../../../../app/interfaces/vendor';
import NewPartDetails from '../InventoryItemForm/NewPartDetails';
import NewPartAdditional from '../InventoryItemForm/NewPartAdditional';

const AddPart = () => {
  const { orgData, userData, fsOrgPrefix } = useSelector(authSelector);
  const [saving, setSaving] = useState(false);
  const [itemImg, setItemImg] = useState<{
    downloadURL: string;
    fileName: string;
  } | null>(null);
  const [categories, setCategories] = useState<InventoryCategory[]>([]);
  const [vendors, setVendors] = useState<Vendor[]>([]);

  const [loadingCategories, setLoadingCategories] = useState(false);
  const [loadingVendors, setLoadingVendors] = useState(false);

  const [duplicated, setDuplicated] = useState(false);

  const [form] = Form.useForm();
  const firestore = useFirestore();

  useEffect(() => {
    fsOrgPrefix && loadCategories();
    fsOrgPrefix && loadVendors();
  }, [fsOrgPrefix]);

  const loadCategories = async () => {
    setLoadingCategories(true);
    const querier = categoryQueries({ firestore }, fsOrgPrefix);
    if (querier) {
      const cats = await querier.fetchCategories();
      setCategories(sortBy(cats, 'name'));
    }
    setLoadingCategories(false);
  };

  const loadVendors = async () => {
    setLoadingVendors(true);
    const querier = vendorQueries({ firestore }, fsOrgPrefix);
    if (querier) {
      const cats = await querier.fetchVendors();
      setVendors(sortBy(cats, 'companyName'));
    }
    setLoadingVendors(false);
  };

  const onComplete = () => {
    console.log('complete');
  };

  const handleFinish = ({ isDuplicate }: { isDuplicate: boolean }) => {
    form.validateFields().then(() => {
      setSaving(true);
      const formValues: Partial<PartFormData> = form.getFieldsValue();
      const { categoriesQueryHook, vendorQueryHook, ...rest } = formValues;

      // get other category data to save
      const selectedCategories =
        categoriesQueryHook &&
        categoriesQueryHook.map((h) => {
          const cat = categories.find((c) => c.id === h);
          const { id, orgId, name, description } = cat!; // could this ever be null?
          return {
            id: id,
            orgId: orgId,
            name: name,
            description: description,
          };
        });

      let selectedVendor = null;
      selectedVendor = vendors.find((v) => v.id === vendorQueryHook);

      const values: Partial<PartFormData> = {
        categories: selectedCategories, // add categories
        vendor: selectedVendor || null,
        vendorQueryHook: vendorQueryHook || null,
        categoriesQueryHook: categoriesQueryHook,
        image: itemImg,
        ...rest,
      };

      const payload = configurePart().newPartItem(values, userData.id, orgData);

      if (payload) {
        const writer = partWrites({ firestore }, fsOrgPrefix);
        if (writer) {
          writer
            .createPart(payload)
            .then(() => {
              setSaving(false);
              notificationConfirm('Inventory item created');
              if (isDuplicate) {
                form.setFieldsValue({
                  name: `${payload.name} Copy`,
                });
              } else {
                form.setFieldsValue({ rates: [] });
                form.resetFields();
                setItemImg(null);
                onComplete();
              }
            })
            .catch((err) => {
              console.log(err);
              setSaving(false);
              notificationError('Something went wrong', 'Please try again later');
            });
        }
      }
    });
  };

  const onImageUrl = (downloadURL: string, fileName: string) => {
    setItemImg({
      downloadURL: downloadURL,
      fileName: fileName,
    });
  };
  return (
    <div>
      <Affix>
        <PageHeader
          onBack={() => window.history.back()}
          className='PageHeader'
          title='Add New Part'
          extra={[
            <Button
              onClick={() => {
                setDuplicated(true);
                handleFinish({ isDuplicate: true });
              }}
            >
              Add & Create Duplicate
            </Button>,
            <Button
              type='primary'
              onClick={() => {
                setDuplicated(false);
                handleFinish({ isDuplicate: false });
              }}
            >
              Add Part
            </Button>,
          ]}
        />
      </Affix>
      <div className='list-margin-top' style={{ margin: '68px 12px' }}>
        <Spin spinning={saving || loadingCategories} indicator={Spinner}>
          <Form form={form} layout='vertical' requiredMark={false}>
            <div className='add-inventory' style={{ width: '800px' }}>
              <Card size='small' title={<TextBody style={{ fontWeight: 'bold' }} text={'General'} />}>
                <NewPartDetails
                  vendors={vendors}
                  categories={categories}
                  form={form}
                  duplicated={duplicated}
                  onImageUrl={onImageUrl}
                  removeImg={() => setItemImg(null)}
                  defaultImg={itemImg}
                  saving={saving}
                />
              </Card>

              <br />
              <NewPartAdditional form={form} showWeightInput={orgData && orgData.trackInventoryWeight} />
            </div>
          </Form>
        </Spin>
      </div>
    </div>
  );
};

export default AddPart;
