import { IconButton, IconButtonProps, Theme } from '@mui/material';
import { styled } from '@mui/system';

interface CustomIconButtonProps extends IconButtonProps {
  customColor?: string;
}

const CustomIconButton = styled(IconButton)<{ customColor?: string }>(({ theme, customColor }) => ({
  color: customColor,
})) as React.FC<CustomIconButtonProps>;

export default CustomIconButton;
