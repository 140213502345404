// @mui
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import "firebase/firestore";

import { Card, Link, Stack } from "@mui/material";

export const V2_0_9 = () => {
  return (
    <Card>
    <Box sx={{ p: 2, display: "flex" }}>
      <Stack spacing={0.5}>
        <Typography fontWeight={700}>
          V2.0.9 - Released 9-15-2023
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          style={{ whiteSpace: "pre-wrap" }}
        >
          {`
Whats new?

This week, we've release some really exciting features in your Adelie account. From sharable payment links, invoice tagging and equipment maintenance features, we know you're going to love all the new things you can do in Adelie. 

• Maintenance Scheduling and Item ROI
Schedule an item for repair, create replacement part items and assign them to vendors and calculate your total return on investment on a per item basis.

Learn more from this YouTube video on our channel:
`}

          <Link
            href="https://youtu.be/Ux5O_01RejY?feature=shared"
            underline="always"
            target="_blank"
            rel="noopener"
          >
            Maintenance Features Overview
          </Link>

          {`

• Invoice Tags

Now you can group like invoices together with the use of tags. Create a tag for anything you want and assign it to an invoice. Come up with your own tags or see some suggestions here 👉

Learn more from this YouTube video on our channel:
`}

          <Link
            href="https://youtu.be/QZhyEjjsWBA?si=3sp1XtlJ6BINz4Mk"
            underline="always"
            target="_blank"
            rel="noopener"
          >
            Invoice Tags Overview
          </Link>

          {`

•Invoice Payment Link
Copy and paste a sharable payment link from within the invoice view. Then share via text or email to your customer for even faster payment requests!
`}
        </Typography>
      </Stack>
    </Box>
  </Card>
  );
};
