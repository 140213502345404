// @mui
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import "firebase/firestore";

import { Card, Link, Stack } from "@mui/material";

export const V2_1_0 = () => {
  return (
    <Card>
    <Box sx={{ p: 2, display: "flex" }}>
      <Stack spacing={0.5}>
        <Typography fontWeight={700}>
          V2.1.0 - Released 9-18-2023
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          style={{ whiteSpace: "pre-wrap" }}
        >
          {`
Whats new?

• New Dashboard! 

We are excited to introduce our latest feature – the all-new Dashboard! This powerful addition is designed to streamline your daily operations, improve communication, and make your workflow even more seamless.

Stay connected with your team effortlessly. Communicate with your employees, share important updates, and foster collaboration like never before.

We value your feedback, and now it's easier than ever to share your thoughts. Use the integrated text field to submit feedback or request new features directly within the Dashboard.

`}
        </Typography>

        <Box display="flex" justifyContent="center" alignItems="center">
          <img
            alt="Dashboard"
            src="https://ci4.googleusercontent.com/proxy/H6kNMyaEi9NjPsfJUwf_8MQn20eckcKc3JgcNlDB04tzTMjoW_BJyr3Z4wpVYSeu0UBu6rcjf6krkMytehAEqs2vNJT1RlHVNdCdcPW7XyPLwgNtCOl_kh0iriU_DqSKpBu3rtz9APZjw66IyIVzJNk=s0-d-e1-ft#https://storage.googleapis.com/msgsndr/KnjlKxZIRNYzZEiIR6zu/media/6508cc4c88b77265879a6582.png"
            style={{
              height: 400,
              width: "auto",
            }}
          />
        </Box>
      </Stack>
    </Box>
  </Card>
  );
};
