
import { checkUndefined } from "./checkUndefined";
import notificationError from "../../../system-components/toasters/notificationError";
import {
  DocumentData,
  DocumentSnapshot,
  QueryDocumentSnapshot,
} from "@firebase/firestore-types";
import { Vendor } from "../../../interfaces/vendor";

export const checkVendor = (
  snap: QueryDocumentSnapshot<DocumentData> | DocumentSnapshot<DocumentData>,
) => {

  const data: Partial<Vendor> = snap.data() ?? {};
  const exists = checkUndefined(data, null);
  if (!exists)
    return notificationError(
      "Record could not be found",
      "Please contact support for assistance"
    );

  const vendor: Partial<Vendor> = {
    id: snap.id,
    orgId: checkUndefined(data && data.orgId, ""),
    companyName: checkUndefined(data.companyName, ""),
    billingAddress: checkUndefined(
      data.billingAddress,
      ""
    ),
    billingCity: checkUndefined(data.billingCity, ""),
    billingState: checkUndefined(data.billingState, ""),
    billingZip: checkUndefined(data.billingZip, ""),
    billingAddressStr: checkUndefined(
      data.billingAddressStr,
      ""
    ),
    email: checkUndefined(data.email, ""),
    firstName: checkUndefined(data.firstName, ""),
    lastName: checkUndefined(data.lastName, ""),
    phone: checkUndefined(data.phone, ""),
    createdBy: checkUndefined(data.createdBy, ""),
    createdOn: checkUndefined(data.createdOn, null),
    updatedBy: checkUndefined(data.updatedBy, ""),
    updatedOn: checkUndefined(data.updatedOn, null),
    isActive: checkUndefined(data.isActive, true),
    additionalContacts: checkUndefined(data.additionalContacts, [])
  };

  return vendor;
};
