import React, { FC, useMemo, MouseEvent, useContext } from "react";
import {
  DataGridPro,
  GridColDef,
  GridPaginationModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import {
  COLOR_PURPLE_0,
} from "../../../app/ui/colorConstants";
import { ConvertNumberToCurrencyStr } from "../../../app/utils/numbers/convertNumberToCurrencyStr";
import { gridClasses } from "@mui/x-data-grid";
import { VendorTableContext } from "../AdminVendors";
import { useVendorContextMenu } from "./contextMenu/useVendorContextMenu";
import { usePersistentDataGrid } from "../../../app/services/hooks/usePersistentDataGrid";
import { Vendor } from "../../../app/interfaces/vendor";
import VendorContextMenu from "./contextMenu/VendorContextMenu";

const VendorsDataGrid: FC<{
  data: Vendor[];
  loading: boolean;
  handleEdit: (c: Vendor) => void;
  onDeleteComplete: () => void;
}> = ({ data, loading, handleEdit, onDeleteComplete }) => {
  // const { actions } = useContext(VendorTableContext);

  const persistentDataGrid = usePersistentDataGrid(
    "ADMIN_VENDOR_TABLE_STATE"
  );

  const vendorContextMenuControl = useVendorContextMenu({
    handleEdit,
    onDeleteComplete,
  });

  const columnsMUI = useMemo(getColumns, [data]);

  const toggleContextMenu = (
    event: React.MouseEvent<HTMLElement, globalThis.MouseEvent>,
    vendor: Vendor
  ) => {
    if (!vendorContextMenuControl.contextConfig.selectedVendor)
    vendorContextMenuControl.setContextMenuConfig({
        x: event.clientX - 2,
        y: event.clientY - 4,
        visible: true,
        selectedVendor: vendor,
      });
    else vendorContextMenuControl.resetContext();
  };

  const handleDoubleClick = (
    event: MouseEvent<HTMLElement>,
    record: Vendor
  ) => {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    toggleContextMenu(event, record);
  };

  const handleRightClick = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const rowId = event.currentTarget.getAttribute("data-id");
    const vendor = data.find((v) => v.id === rowId);
    if (vendor) toggleContextMenu(event, vendor);
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton style={{ color: COLOR_PURPLE_0 }} />
        <GridToolbarDensitySelector style={{ color: COLOR_PURPLE_0 }} />
        <GridToolbarExport style={{ color: COLOR_PURPLE_0 }} />
        <GridToolbarFilterButton style={{ color: COLOR_PURPLE_0 }} />
      </GridToolbarContainer>
    );
  };

  const handleOnPaginationChange = (props: GridPaginationModel) => {
    const lastIndexOnPage = (props.page + 1) * props.pageSize;
    const lastIndexOnNextPage = (props.page + 2) * props.pageSize;
    const shouldLoadMore =
      data.length >= lastIndexOnPage && data.length <= lastIndexOnNextPage;
    // if (shouldLoadMore) actions.loadNext();
  };

  return (
    <div
      style={{
        width: "100%",
        paddingLeft: "8px",
        paddingRight: "8px",
        backgroundColor: "white",
      }}
    >
      <DataGridPro<Vendor>
        style={{ paddingLeft: "8px" }}
        autoHeight={true}
        apiRef={persistentDataGrid.apiRef}
        loading={loading}
        columns={columnsMUI}
        rows={data}
        pagination={true}
        onPaginationModelChange={handleOnPaginationChange}
        initialState={{
          pagination: { paginationModel: { pageSize: 25 } },
          sorting: {
            sortModel: [{ field: "companyName", sort: "asc" }],
          },
        }}
        sx={{
          [`& .${gridClasses.cell}`]: {
            py: 1,
          },
        }}
        slots={{
          toolbar: CustomToolbar,
        }}
        onRowDoubleClick={(params, event) =>
          handleDoubleClick(event, params.row)
        }
        slotProps={{
          row: {
            onContextMenu: handleRightClick,
            style: { cursor: "context-menu", minHeight: "56px" },
          },
        }}
        onColumnOrderChange={persistentDataGrid.storeGridState}
        onColumnWidthChange={persistentDataGrid.storeGridState}
        onColumnVisibilityModelChange={persistentDataGrid.storeGridState}
        onPinnedColumnsChange={persistentDataGrid.storeGridState}
      />
      <VendorContextMenu control={vendorContextMenuControl} />
    </div>
  );

  function getColumns() {
    const cols: GridColDef[] = [
      {
        headerName: "Company",
        field: "companyName",
        width: 200,
        renderCell: (params) => (
          <div
            onClick={(e) => {
              e.preventDefault();
              const vendor = data.find((v) => v.id === params.id);
              if (vendor) handleEdit(vendor);
            }}
            style={{
              cursor: "pointer",
            }}
          >
            {params.value}
          </div>
        ),
      },
      {
        headerName: "Address",
        field: "billingAddressStr",
        width: 250,
      },
      { headerName: "Email", field: "email", width: 250 },
      { headerName: "Phone", field: "phone", width: 150 },
    ];
    return cols;
  }
};

export default VendorsDataGrid;
