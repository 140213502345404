import React from "react";
import { Button, Card, Col, Divider } from "antd";
import { COLOR_BLUE_0, COLOR_RED_0 } from "../../../app/ui/colorConstants";
import { qboClient } from "../../../app/config/quickbooks";
import OAuthClient from "intuit-oauth";
import { CategoryTag } from "../../../app/system-components/tags/Tags";
import { checkSubscriptionLevel } from "../../../app/interfaces/features-set-permissions";
import { Chip } from "@mui/material";

const QuickbooksCard = ({ orgData }) => {
  const configureBundleSourceLabel = (orgData) => {
    if (!orgData) return;
    if (!orgData.qboRealmId) return;
    if (!orgData.bundleManagedBy)
      return <CategoryTag text={"Not Configured"} />;
    return (
      <CategoryTag
        text={`Bundles Managed By: 
        ${orgData.bundleManagedBy === "adelie" ? "Adelie" : "Quickbooks"}`}
      />
    );
  };
  return (
    <Col
      xs={12}
      sm={10}
      md={8}
      lg={8}
      xl={8}
      style={{ minWidth: "360px", marginBottom: "16px" }}
    >
      <Card size="small">
        <img
          src="/images/qb-logo.png"
          alt="quickbooks_logo"
          style={{ height: "50px" }}
        />
        {checkSubscriptionLevel("integrations.qbo", orgData) ? (
          <Button
            type="primary"
            style={{
              float: "right",
              backgroundColor:
                orgData && orgData.qboRealmId ? COLOR_RED_0 : COLOR_BLUE_0,
              borderColor:
                orgData && orgData.qboRealmId ? COLOR_RED_0 : COLOR_BLUE_0,
            }}
            onClick={() => {
              const authUri = qboClient.authorizeUri({
                scope: [
                  OAuthClient.scopes.Accounting,
                  OAuthClient.scopes.OpenId,
                ],
                state: process.env.REACT_APP_QBO_STATE,
              });
              window.open(authUri, "_self");
            }}
          >
            {orgData && orgData.qboRealmId ? (
              <span>Disconnect</span>
            ) : (
              "Connect"
            )}
          </Button>
        ) : (
          <Chip
            label={"Upgrade To Plus"}
            style={{
              float: "right",
            }}
          ></Chip>
        )}

        <Divider style={{ margin: "20px 0 16px" }} />
        {configureBundleSourceLabel(orgData)}
      </Card>
    </Col>
  );
};

export default QuickbooksCard;
