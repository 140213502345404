import React, { useEffect, useState } from "react";
import "../../App.less";
import { Affix, PageHeader, Row, Tabs } from "antd";
import {AppBar, Dialog, IconButton, Slide, Toolbar, Typography} from '@mui/material';

import { useFirestoreWrite } from "../../app/services/hooks/fetch/useFirestoreWrite";
import { useDispatch, useSelector } from "react-redux";

import { useFirestore } from "react-redux-firebase";
import CloseIcon from '@mui/icons-material/Close';

import PaymentSetupComplete from "./PaymentSetupComplete";
import {
  ROUTE_ADMIN_INTEGRATIONS,
  ROUTE_ADMIN_PAYMENT_SETUP_COMPLETE,
  ROUTE_ADMIN_PAYMENT_SETUP_REFRESH,
} from "../../app/routes";
import PaymentSetupRefresh from "./PaymentSetupRefresh";
import QuickbooksCard from "./components/QuickbooksCard";
import { qboEndpoint } from "./helpers/qboEndpoint";
import StripeCard from "./components/StripeCard";
import { useHistory } from "react-router-dom";
import TabPane from "antd/lib/tabs/TabPane";
import QboSyncTools from "./QboSyncTools";
import HighLevelIntegrations from "./high-level";
import { checkSubscriptionLevel } from "../../app/interfaces/features-set-permissions";
import JustifiCard from "./components/JustifiCard";
import { functionsBaseUrl } from '../../app/config/firebase';

import { JustifiPage } from "../sharedComponents/justifi/JustifiPage";
import { authSelector, refreshOrgState } from "../auth/authSlice";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AdminIntegrations = () => {

  const history = useHistory();
  const dispatch = useDispatch();
  const firestore = useFirestore();

  const { writing, updateOrgDocument } = useFirestoreWrite();
  const { orgData, authenticated } = useSelector(authSelector);
  const [qboAuth, setQboAuth] = useState(null);

  const [openJustifi, setOpenJustifi] = React.useState(false);
  const [creatingBusiness, setCreatingBusiness] = React.useState(false);

  const [connectingStripe, setConnectingStripe] = useState(false);
  const [paymentModalVisible, setPaymentModalVisible] = useState(false);
  const [paymentRefreshVisible, setPaymentRefreshVisible] = useState(false);
  const [tabType, setTabType] = useState("integrations"); // integrations , quickbooks

  useEffect(() => {
    /**
     * for handling return state for Qbo
     * @type {string}
     */
    const location = window.location.href;
    const hasCode = location.includes("?code=");
    if (hasCode && orgData && authenticated) {
      const parseObj = new URL(location);
      const code = parseObj.searchParams.get("code");
      const newUrl = location.split("?code=");
      const realmId = newUrl[1].split("realmId=");
      const endpoint = `${process.env.REACT_APP_BASE_URL}/qbo/callback?code=${newUrl[1]}&orgId=${orgData.id}`;
      const payloadData = {
        payload: { 
          qboRealmId: realmId[1] 
        },
        id: orgData.id,
      };
      updateOrgDocument({
        data: payloadData,
      }).then(() => {
        qboEndpoint(endpoint);
      });
    }
  }, [window.location.href, orgData, authenticated]);

  useEffect(() => {
    /**
     * For handling return state on Stripe connect
     * @type {string}
     */
    const location = window.location.href;
    const setupComplete = location.includes(ROUTE_ADMIN_PAYMENT_SETUP_COMPLETE);
    setupComplete && !paymentModalVisible && setPaymentModalVisible(true);

    const refreshSetup = location.includes(ROUTE_ADMIN_PAYMENT_SETUP_REFRESH);
    refreshSetup && !paymentRefreshVisible && setPaymentRefreshVisible(true);
  }, [window.location.href]);

  const closeAndRefreshComplete = () => {
    history.push(ROUTE_ADMIN_INTEGRATIONS);
    window.location.reload();
  };
  const closeAndRefreshIncomplete = () => {
    history.push(ROUTE_ADMIN_INTEGRATIONS);
    window.location.reload();
  };


  const manageJustifi = () => {
    setOpenJustifi(true);
  };

  const configureJustifi = async () => {

    // double check org does not have biz account
    if (orgData.justifiBizAccount && orgData.justifiBizAccount !== "") {
      return;
    }

    setCreatingBusiness(true);
    // Call cloud function to create a Justifi biz account.
    const url = functionsBaseUrl + "/justifi";
    const data = {
      orgId: orgData.id,
      action: "createBusiness",
      payload: {},
    };
    // needs a body passed to the fetch
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    dispatch(refreshOrgState({ firestore }, orgData));
    setCreatingBusiness(false);
    setOpenJustifi(true);
  };

  const handleClose = () => {
    setOpenJustifi(false);
  };

  return (
    <div>
      <Affix>
        <PageHeader
          className="PageHeader"
          backIcon={false}
          title="Integrations"
        />
      </Affix>
      <>
        <div style={{ width: "100%", marginTop: "60px", marginLeft: "20px" }}>
          <Tabs
            defaultActiveKey="integrations"
            activeKey={tabType}
            onChange={setTabType}
          >
            <TabPane tab="Integrations" key="integrations" />

            {orgData &&
              orgData.qboRealmId &&
              orgData.qboRealmId !== "" &&
              checkSubscriptionLevel("integrations.qbo", orgData) && (
                <TabPane tab={"QuickBooks Sync"} key="quickbooks" />
              )}

            {orgData && orgData.integrations.highLevel && (
              <TabPane tab={"HighLevel"} key="highLevel" />
            )}
          </Tabs>
        </div>
      </>
      {tabType === "integrations" && (
        <>
          <div className="list-margin-top" style={{ marginTop: "18px" }}>
            <Row
              justify="start"
              style={{ margin: "16px 16px 16px 0" }}
              gutter={16}
            >
              <QuickbooksCard orgData={orgData} />

              <JustifiCard orgData={orgData} configureJustifi={configureJustifi} manageJustifi={manageJustifi} creatingBusiness={creatingBusiness} />

              <StripeCard
                orgData={orgData}
                setConnectingStripe={setConnectingStripe}
                connectingStripe={connectingStripe}
              />
            </Row>
          </div>
          <PaymentSetupComplete
            visible={paymentModalVisible}
            closeAndRefresh={closeAndRefreshComplete}
            setPaymentModalVisible={setPaymentModalVisible}
          />
          <PaymentSetupRefresh
            visible={paymentRefreshVisible}
            closeAndRefresh={closeAndRefreshIncomplete}
            setPaymentRefreshVisible={setPaymentRefreshVisible}
          />
        </>
      )}

      {tabType === "quickbooks" && <QboSyncTools />}

      {tabType === "highLevel" && <HighLevelIntegrations />}

      <Dialog
        fullScreen
        open={openJustifi}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Justifi Integration
            </Typography>
            
          </Toolbar>
        </AppBar>
        <JustifiPage orgData={orgData}/>
      </Dialog>

    </div>
  );
};

export default AdminIntegrations;
