import notificationError from "../../../system-components/toasters/notificationError";
import { INVENTORY_COLLECTION, PARTS_COLLECTION, PO_COLLECTION } from "../../../utils/models/collections/collectionConstants";
import { checkInventory } from "../../../utils/models/checkers/checkInventory";
import { fsMultiArrayQuery } from "./fsMultiArrayQuery";
import { ExtendedFirestoreInstance } from "react-redux-firebase";
import { InventoryItem } from "../../../interfaces/inventoryItem";
import { Part } from "../../../interfaces/part";
import { checkPart } from "../../../utils/models/checkers/checkPart";


interface PartsQueries {
    fetchParts: () => Promise<Part[]>;
    partsByIdList: (ids: string[]) => Promise<any>; // Update this type if needed
    purchaseOrderParts: (po: string)  => Promise<Part[]>;
  }
  
  export function partsQueries(
    { firestore }: { firestore: ExtendedFirestoreInstance },
    orgPrefix: string
  ): PartsQueries {
    if (!orgPrefix) {
      return {
        fetchParts: async () => {
          throw new Error("Cannot determine organization");
        },
        partsByIdList: async (ids: string[]) => {
          throw new Error("Cannot determine organization");
        },
        purchaseOrderParts: async (po: string) => {
            throw new Error("Cannot determine organization");
          },
      };
    }
  
    const ref = firestore.collection(`${orgPrefix}${PARTS_COLLECTION}`);
  
    async function fetchParts(): Promise<Part[]> {
      const snap = await ref.get();
      return snap.docs.map((s) => checkPart(s));
    }
  
    async function fetchPartsListByIds(ids: string[]) {
      const ref = firestore
        .collection(`${orgPrefix}${PARTS_COLLECTION}`)
        .where("id", "in", ids);
      return await ref.get();
    }
  
    async function partsByIdList(ids: string[]) {
      return fsMultiArrayQuery(
        { firestore },
        ids,
        fetchPartsListByIds,
        checkPart
      );
    }

    async function purchaseOrderParts(po: string) {
        const query = await firestore
          .collection(`${orgPrefix}${PO_COLLECTION}`)
          .doc(po)
          .collection('selectedItems')
          .get()

          return query.docs.map((s) => checkPart(s));
      }
  
    return {
      fetchParts: fetchParts,
      partsByIdList: partsByIdList,
      purchaseOrderParts: purchaseOrderParts,
    };
  }
  