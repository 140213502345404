import { Typography } from "@mui/material";

export const WhatsNew = () => {
  return (
    <Typography
      variant="body2"
      gutterBottom
      style={{ whiteSpace: "pre-wrap", fontWeight: "bold" }}
    >
      ✨ What's new?
    </Typography>
  );
};
