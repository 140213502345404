import { useState } from "react";
import "../../App.less";
import { Affix, PageHeader, Tabs } from "antd";
import TabPane from "antd/lib/tabs/TabPane";
import InvoiceWords from "./components/words-invoice";

const AdminSettingsWords = () => {
  const [tabType, setTabType] = useState("invoice"); // invoice , 

  return (
    <div>
      <Affix>
        <PageHeader className="PageHeader" backIcon={false} title="Settings" />
      </Affix>
      <div style={{ paddingTop: "68px" }}>
        <div style={{ width: "100%", marginLeft: '14px' }}>
          <Tabs
            defaultActiveKey="invoice"
            activeKey={tabType}
            onChange={setTabType}
          >
            <TabPane tab="Invoice" key="invoice" />

          </Tabs>
        </div>

        {tabType === "invoice" && (<InvoiceWords />)}

      </div>
    </div>
  );
};

export default AdminSettingsWords;
