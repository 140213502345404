import React from "react";
import { OrganizationData } from "../../app/interfaces/organizationData";
import { portalUrl, subscriptionPlanDetails } from "../../app/config/stripe";
import { Button, Card, Chip, Grid, Stack, Tooltip, Typography } from "@mui/material";
import Iconify from "../../app/iconify/iconify";
import { COLOR_GREEN_0, COLOR_LIGHT_GREEN } from "../../app/ui/colorConstants";

const SubscriptionInfoCard = ({ orgData }: { orgData: OrganizationData }) => {

  let subscriptionPlan =
    subscriptionPlanDetails.find(
      (plan: any) => plan.stripe_id === orgData.subscriptionPlanId
    ) ?? subscriptionPlanDetails[0];

  if (orgData.grandfathered) {
    subscriptionPlan = subscriptionPlanDetails.find((plan: any) => plan.subName === 'premium')
  }

  if (orgData.subscriptionStatus === "trial") {
    subscriptionPlan = subscriptionPlanDetails.find((plan: any) => plan.subName === 'premium')
  }

  return (
    <Card sx={{ p: 2, minHeight: "300px", minWidth: { md: "600px" }, mb: 2 }}>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={6} container justifyContent="center" alignItems="center">
          <Stack spacing={2} alignItems="left">
            <Chip
              variant="outlined"
              sx={{ mb: 3, width: "fit-content", fontSize: "12px" }}
              size="small"
              color={"primary"}
              label={orgData.subscriptionStatus === "trial" ? "Current Plan - Trial" : "Current Plan"}
            />

            <Typography variant="h3" gutterBottom>
              {subscriptionPlan.name}
            </Typography>

            {/* WIP */}
            {/* {orgData.grandfathered ? null : (
              <Button
                size="small"
                sx={{ mb: 2, width: "fit-content" }}
                variant="contained"
                onClick={() => {
                  window.open(portalUrl);
                }}
              >
                Manage / Upgrade
              </Button>
            )} */}

            { orgData.grandfathered ? null : (
              <Tooltip title="Email info@adelielogistics.com">
              <Button
                size="small"
                sx={{width: "fit-content" }}
                variant="contained"
                onClick={() => {
                  // email to info@adelielogistics
                  const email = 'info@adelielogistics.com';
                  const subject = encodeURIComponent('Subscription Plan Change Request');
                  const body = encodeURIComponent('Please change my subscription plan to the following: \n');
                  
                  window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
                }}
              >
               {orgData.subscriptionStatus === 'trial' ? `Switch To Paid Plan` : `Contact To Manage`}
              </Button>
              </Tooltip>
            )}
          </Stack>
        </Grid>

        <Grid item xs={6}>
          <Stack gap={1} sx={{ p: 1 }}>
            {subscriptionPlan.features.map((feature: any) => (
              <Stack direction={"row"} gap={1}>
                <Iconify
                  color={COLOR_GREEN_0}
                  icon={"carbon:checkmark-filled"}
                />
                <Typography variant="body2">{feature}</Typography>
              </Stack>
            ))}
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
};

export default SubscriptionInfoCard;


