// @mui
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import "firebase/firestore";

import { Card, Link, Stack } from "@mui/material";

export const V2_1_8 = () => {
  return (
    <Card>
      <Box sx={{ p: 2, display: "flex" }}>
        <Stack spacing={0.5}>
          <Typography fontWeight={700}>V2.1.8 - Released 11-10-2023</Typography>

          <Typography
            variant="body2"
            color="text.secondary"
            style={{ whiteSpace: "pre-wrap" }}
          >
            {`
What's new?

You can now change the order number!! 🎉

In the order view, click on the invoice number in the top left corner, which will open a modal where you can enter a new number. Order numbers must be unique, so if you submit a number already used, you will be presented with an error message.

If you are integrated with QuickBooks and have gotten duplicate number errors while trying to sync, this tool will help remedy that issue.

`}
          </Typography>

          <Box display="flex" justifyContent="center" alignItems="center">
            <img
              alt="Dashboard"
              src="https://firebasestorage.googleapis.com/v0/b/adelie-logistics-production.appspot.com/o/public-images%2Fchange-log%2Fv2.1.8%2Fwhere-to-click.png?alt=media&token=78ec0fd9-4ebd-4dbe-903e-5739ab48c391"
              style={{
                height: 400,
                width: "auto",
              }}
            />
          </Box>

          <Box display="flex" justifyContent="center" alignItems="center">
            <img
              alt="Dashboard"
              src="https://firebasestorage.googleapis.com/v0/b/adelie-logistics-production.appspot.com/o/public-images%2Fchange-log%2Fv2.1.8%2Fchange-modal.png?alt=media&token=8c511645-3fd7-4d77-b17c-f03660265c9a"
              style={{
                height: 400,
                width: "auto",
              }}
            />
          </Box>

          <br />
          <Typography
            sx={{ mt: 2 }}
            variant="body2"
            color="text.secondary"
            style={{ whiteSpace: "pre-wrap" }}
          >
            {`
            What else?

            Some users on Safari have had issues with the copy payment link copying to their clipboard. So, we added an additional explicit text field that contains the same link that you can copy manually.`}
          </Typography>
            
            <br />
          <Box display="flex" justifyContent="center" alignItems="center">
            <img
              alt="Dashboard"
              src="https://firebasestorage.googleapis.com/v0/b/adelie-logistics-production.appspot.com/o/public-images%2Fchange-log%2Fv2.1.8%2Fcopy-pay-link.png?alt=media&token=86c80033-67be-49fb-aae6-85bbbd258efc"
              style={{
                height: 400,
                width: "auto",
              }}
            />
          </Box>

          <br />
          <Typography
            sx={{ mt: 2 }}
            variant="body2"
            color="text.secondary"
            style={{ whiteSpace: "pre-wrap" }}
          >
            {`
            Bug Fixes 🐛
            `}

            {`
            • Case insensitive search on invoices table.
            • Added back "Last Week" shortcut on date picker.
            `}
          </Typography>
              <br />
          <Typography
            sx={{ mt: 2 }}
            variant="body2"
            color="text.secondary"
            style={{ whiteSpace: "pre-wrap" }}
          >
            {`We hope these new features and bug fixes improve your daily experience with Adelie. As always we want to hear your feedback and thank those that have submitted feedback, rest assured we read every one and they are logged in our feature requests log.`}
          </Typography>
        </Stack>
      </Box>
    </Card>
  );
};
