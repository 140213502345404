import React, { Dispatch, FC, SetStateAction, useMemo } from "react";
import { useAdminInventoryContextMenu } from "./contextMenu/useAdminInventoryContextMenu";
import { usePersistentDataGrid } from "../../../app/services/hooks/usePersistentDataGrid";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  DataGridPro,
  GridColDef,
  GridColumnHeaderParams,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import Tooltip from "@mui/material/Tooltip";

import { Tag, Typography } from 'antd';

import { COLOR_PURPLE_0 } from "../../../app/ui/colorConstants";
import TextBody from "../../../app/system-components/typography/text/TextBody";
import { CategoryTag } from "../../../app/system-components/tags/Tags";
import { DepreciationCell } from "./dataGridComponents/DepreciationCell";
import { ItemCell } from "./dataGridComponents/ItemCell";
import { getTagFilterOperator } from "./dataGridComponents/CategoryFilterComponent";
import { ImageCell } from "./dataGridComponents/ImageCell";
import InventoryContextMenu from "./contextMenu/InventoryContextMenu";
import { InventoryCategory } from "../../../app/interfaces/inventoryCategory";
import { InventoryItem } from "../../../app/interfaces/inventoryItem";
import { FormatToLocalCurrency } from "../../../app/utils/currency/formatToLocalCurrency";
import { useSelector } from "react-redux";
import { authSelector } from "../../auth/authSlice";

const { Text } = Typography;

// Extend GridColDef to add tooltip
interface GridColDefWithTooltip extends GridValidRowModel {
  tooltip?: string;
}

export const InventoryDataGrid: FC<{
  setItemDrawer: Dispatch<
    SetStateAction<{
      visible: boolean;
      item?: InventoryItem;
    }>
  >;
  onDuplicateComplete: () => Promise<void>;
  onDeleteComplete: () => Promise<void>;
  data: InventoryItem[];
  loadingInventory: boolean;
  loadingCategories: boolean;
  categories: InventoryCategory[];
}> = ({
  setItemDrawer,
  onDuplicateComplete,
  onDeleteComplete,
  data,
  loadingInventory,
  loadingCategories,
  categories,
}) => {
  const persistentDataGrid = usePersistentDataGrid(
    "ADMIN_INVENTORY_TABLE_STATE"
  );
  const contextMenu = useAdminInventoryContextMenu({
    onDuplicateComplete,
    onDeleteComplete,
    setItemDrawer,
  });
  const tagFilterOperator = getTagFilterOperator(categories);
  const { userData, orgData, fsOrgPrefix } = useSelector(authSelector);


  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton style={{ color: COLOR_PURPLE_0 }} />
        <GridToolbarDensitySelector style={{ color: COLOR_PURPLE_0 }} />
        <GridToolbarExport style={{ color: COLOR_PURPLE_0 }} />
        <GridToolbarFilterButton style={{ color: COLOR_PURPLE_0 }} />
      </GridToolbarContainer>
    );
  };

  const columns = useMemo(getColumns, [setItemDrawer, categories, data]);

  return (
    <div
      style={{
        width: "100%",
        padding: "8px",
        backgroundColor: "white",
      }}
    >
      <DataGridPro<InventoryItem>
        autoHeight={true}
        apiRef={persistentDataGrid.apiRef}
        columns={columns}
        loading={loadingInventory || loadingCategories}
        rows={data.filter((d) => !!d.id)}
        pagination={true}
        slots={{
          toolbar: CustomToolbar,
        }}
        initialState={{
          pagination: { paginationModel: { pageSize: 25 } },
          sorting: {
            sortModel: [{ field: "name", sort: "asc" }],
          },
        }}
        onRowDoubleClick={(params, event) => {
          event.stopPropagation();
          event.preventDefault();
          event.nativeEvent.preventDefault();
          contextMenu.configureContextMenu(event, params.row);
        }}
        slotProps={{
          row: {
            onContextMenu: (event) => {
              event.stopPropagation();
              event.preventDefault();
              event.nativeEvent.preventDefault();
              const rowId = event.currentTarget.getAttribute("data-id");
              const inventoryItem = data.find((c) => c.id === rowId);
              if (inventoryItem)
                contextMenu.configureContextMenu(event, inventoryItem);
            },
            style: { cursor: "context-menu", minHeight: "56px" },
          },
        }}
        onColumnOrderChange={persistentDataGrid.storeGridState}
        onColumnWidthChange={persistentDataGrid.storeGridState}
        onColumnVisibilityModelChange={persistentDataGrid.storeGridState}
        onPinnedColumnsChange={persistentDataGrid.storeGridState}
      />
      <InventoryContextMenu control={contextMenu} />
    </div>
  );

  function getColumns(): GridColDef<InventoryItem>[] {
    const cols: GridColDef<InventoryItem>[] = [
      {
        headerName: "",
        field: "image",
        width: 30,
        valueFormatter: ({ value }) => `${value && value.downloadURL}`,
        renderCell: ImageCell,
      },
      {
        headerName: "Item",
        field: "name",
        width: 200,
        renderCell: (params) => (
          <ItemCell setItemDrawer={setItemDrawer} {...params} />
        ),
      },
      {
        headerName: "Description",
        field: "description",
        width: 200,
        renderCell: ({ value }) => (
          <div
            className="InvNotesCell"
            style={{
              maxHeight: "68px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <TextBody text={value} />
          </div>
        ),
      },
      { headerName: "Stock", field: "stock", width: 200 },
      { headerName: "Turnaround", field: "turnaround", width: 200 },
      {
        headerName: "Categories",
        sortable: false,
        field: "categories",
        width: 200,
        valueFormatter: ({ value }) => `${value.map((obj: any) => obj.name)}`,
        renderCell: ({ row }) => (
          <>
            {row.categories.map((c) => (
              <CategoryTag text={c.name} key={c.id} />
            ))}
          </>
        ),
        filterable: true,
        filterOperators: [tagFilterOperator],
      },
      {
        headerName: "Notes",
        field: "notes",
        width: 200,
        renderCell: ({ value }) => (
          <div
            className="InvNotesCell"
            style={{
              maxHeight: "68px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <TextBody text={value} />
          </div>
        ),
      },
      {
        headerName: "Depreciated Value",
        field: "depreciation",
        width: 200,
        valueFormatter: ({ value }) => `Check Depreciation Report`,
        renderCell: DepreciationCell,
      },
      {
        headerName: "Item Value",
        field: "depreciation.initialValue",
        width: 200,
        valueGetter: (params) => params.row.depreciation?.initialValue  ? FormatToLocalCurrency(params.row.depreciation?.initialValue) : "--",
        renderCell: ({ row }) => {
          return (
            <TextBody
              text={row.depreciation?.initialValue ? FormatToLocalCurrency(row.depreciation?.initialValue) : "--"}
            />
          );
        },
      },
      {
        headerName: "Rate",
        field: "rates",
        width: 200,
        sortable: false,
        valueFormatter: ({ value }) =>
          `${value.map((obj: any) => obj.rate / 100)}`,
        renderCell: ({ value }) => {
          const rate = value.find((rate: any) => rate.rateRange === "day");
          return (
            <TextBody
              text={rate ? FormatToLocalCurrency(rate.rate) + " / day" : "--"}
            />
          );
        },
        renderHeader: (params) => (
          <Tooltip title={"There may be more rates attached to this item."}>
            <div>{params.colDef.headerName}</div>
          </Tooltip>
        ),
      },
      {
        headerName: "Item Type",
        field: "type",
        width: 200,
        renderCell: ({ value }) => {
          return (
            <TextBody
              text={
                value === "rental"
                  ? "Rental"
                  : value === "sale"
                  ? "Sale"
                  : value === "bundle"
                  ? "Bundle"
                  : "Unknown"
              }
            />
          );
        },
      },
    ];

    const maintenanceHeaders: GridColDef<InventoryItem>[] = [
      {
        headerName: "Maintenance hrs",
        field: "maintenance",
        width: 200,
        renderCell: ({ row }) => {

          const ratio = (row.maintenanceDetails ? row.maintenanceDetails.activeHours : 0) / (row.maintenanceDetails ? row.maintenanceDetails.hoursBetweenServices : 0);
          const active = row.maintenanceDetails?.activeHours
          const between = row.maintenanceDetails?.hoursBetweenServices
        
          let color;

          if(ratio > 0.95) {
            color = 'red';
          } else if (ratio > 0.8) {
            color = 'orange';
          } else {
            color = 'green'
          }

          return (
            <>
              {row.hasMaintenanceDetails && active && between && (
                <Text style={{ color }}>
                  {active} / {between}
                </Text>
              )}
            </>
          );
        }
      },
      {
        headerName: "Remaining hrs",
        field: "remainingHours",
        width: 200,
        renderCell: ({ row }) => {

          const ratio = (row.maintenanceDetails ? row.maintenanceDetails.activeHours : 0) / (row.maintenanceDetails ? row.maintenanceDetails.hoursBetweenServices : 0);
          const active = row.maintenanceDetails?.activeHours 
          const between = row.maintenanceDetails?.hoursBetweenServices 
          const remaining = (between ?? 0) - (active ?? 0)

          let color;

          if(ratio > 0.95) {
            color = 'red';
          } else if (ratio > 0.8) {
            color = 'orange';
          } else {
            color = 'green'
          }

          return (
            <>
              { row.hasMaintenanceDetails && active && between &&(
                <Text style={{ color }}>
                  { `${remaining} hrs`  }
                </Text>
              )}
            </>
          );
        }
      },
    ]

    const weightColumn = { headerName: "Weight", field: "weight", width: 150 };
    if (orgData && orgData.trackInventoryWeight) {
      cols.push(weightColumn);
    }

    if (orgData && orgData.allowInventoryMaintenance) {
      cols.push(...maintenanceHeaders);
    }

    if (orgData && orgData.embeddedCheckoutEnabled) {
      cols.push({
        headerName: "Show In Shop",
        field: "showInShop",
        width: 100,
        renderCell: (params) =>
          params.row.showInShop ? (
            <div>
              <Tag color={"success"}>Yes</Tag>
            </div>
          ) : (
            <div>
              <Tag color={"error"}>No</Tag>
            </div>
          ),
      });
    }

    if (orgData && orgData.qboRealmId && orgData.qboRealmId !== "") {
      cols.push({
        headerName: "QBO",
        field: "qbId",
        width: 100,
        renderCell: (params) =>
          params.row.qbId ? (
            <div>
              <Tag color={"success"}>Synced</Tag>
            </div>
          ) : (
            <div>
              <Tag color={"error"}>Error</Tag>
            </div>
          ),
      });
    }

    return cols;
  }
};
export default InventoryDataGrid;
