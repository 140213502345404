import {
  EmbeddedCheckoutOrderTypes,
  EmbeddedSettingsState,
} from "../adminEmbeddedContext";
import { replace } from "./replace";
/**
 * CHECKOUT ACTIONS
 * ***
 * {
 *     orgId: "12345",
 *     checkoutQuestions: [],
 *     checkoutConfig: { snippetColor: null, snippetMarginTop: null },
 *     checkoutSettings: {
 *       type: "wishlist", // can also be 'order',
 *       allowOverbooked: false,
 *     },
 *     blackoutDates: [],
 *   }
 * **
 * *
 */

export const onTypeChange = (
  state: EmbeddedSettingsState,
  props: EmbeddedCheckoutOrderTypes
) => {
  const type = props ?? "order";
  const allowOverbooked = state?.checkoutSettings?.allowOverbooked ?? false;
 
  return replace(state, "checkoutSettings", () => {
    return { type, allowOverbooked };
  });
};

export const toggleOverbooked = (state: EmbeddedSettingsState, props: any) => {
  const type = state?.checkoutSettings?.type ?? "order";
  const allowOverbooked = props ?? false;
  return replace(state, "checkoutSettings", () => {
    return { type, allowOverbooked };
  });
};