import { useEffect, useState } from "react";
import { Button, Col, Input, List, Popconfirm, Row, Space } from "antd";
import { useFirestore } from "react-redux-firebase";

import TextSubtitle from "../../../../app/system-components/typography/text/TextSubtitle";
import { DeleteOutlined } from "@ant-design/icons";
import { firelikeId } from "../../../../app/utils/models/checkers/firelikeid";
import { authSelector } from "../../../auth/authSlice";
import { useSelector } from "react-redux";

const AdditionalContacts = ({ customer }) => {
  const { orgData } = useSelector(authSelector);
  const firestore = useFirestore();

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");

  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    setContacts(customer.additionalContacts);
  }, []);

  const addContact = async () => {
    const newContact = {
      name,
      email,
      phone,
      id: firelikeId()
    };
    const newContacts = [...contacts, newContact]
    setContacts(newContacts);

    setEmail("");
    setName("")
    setPhone("")

    await firestore.collection(`orgs/${orgData.id}/customers/`).doc(customer.id).update({ additionalContacts: newContacts });

  };

  const deleteContact = async (contact) => {
    const newContacts = contacts.filter((c) => c.email !== contact.email)
    setContacts(newContacts);
    await firestore.collection(`orgs/${orgData.id}/customers/`).doc(customer.id).update({ additionalContacts: newContacts });
  };

  return (
    <div>
      <Row gutter={16}>
        <Col span={12}>
          <div style={{ marginBottom: "12px" }}>
            <TextSubtitle text={"ADD CONTACT"} />
          </div>
          <div>
            <Space direction="vertical" style={{ width: "100%" }}>
              <Input
                value={name}
                placeholder="contact name"
                onChange={(e) => setName(e.target.value)}
              />

              <Input
                value={email}
                placeholder="contact email"
                onChange={(e) => setEmail(e.target.value)}
              />

              <Input
                value={phone}
                placeholder="contact phone"
                onChange={(e) => setPhone(e.target.value)}
              />

              <Button type="primary" onClick={addContact}>
                Add
              </Button>
            </Space>
            <br />
            <br />
          </div>
        </Col>
        <Col span={12}>
          <div style={{ marginBottom: "12px" }}>
            <TextSubtitle text={"ADDITIONAL CONTACTS"} />
          </div>
          <div>
            {contacts.length > 0 && (
              <List
                bordered
                dataSource={contacts}
                size="small"
                renderItem={(item) => (
                  <List.Item
                    actions={[
                      <Popconfirm
                        title="Are you sure you want to delete this contact?"
                        onConfirm={() => deleteContact(item)}
                        onCancel={null}
                        okText="Yes"
                        cancelText="No"
                      >
                        <DeleteOutlined />
                      </Popconfirm>,
                    ]}
                  >
                    <Col>
                      <Space size={2} direction="vertical">
                        <span>Name: {item.name}</span>
                        <span>Email: {item.email}</span>
                        <span>Phone: {item.phone}</span>
                      </Space>
                    </Col>
                  </List.Item>
                )}
              />
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AdditionalContacts;
