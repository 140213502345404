import { checkUndefined } from "../../../utils/models/checkers/checkUndefined";
import {
    INVENTORY_COLLECTION,
  } from "../../../utils/models/collections/collectionConstants";
import { formatDateOnRead } from "../../../utils/time/formatTimezoneOffset";

  
  export const MaintenanceQueries = (orgTimezone) => {
   
    async function fetchMaintenanceRecordsByItemId({ firestore }, orgPrefix, itemId) {
      const ref = firestore
        .collection(`${orgPrefix}${INVENTORY_COLLECTION}`)
        .doc(itemId)
        .collection("maintenanceRecords")
        .orderBy("servicedOn", "desc");
      let data = [];
      const snap = await ref.get();
      snap.forEach((s) => {
        data.push(CheckMaintenanceRecord(s, orgTimezone));
      });
      return data;
    }
    return {
      fetchMaintenanceRecordsByItemId    
    };
  };

  /*
  id: string;
  itemId: string;
  servicedOn: Date;
  servicedBy: string;
  costOfService: number;
  costOfLabor: number;
  costOfParts: number;
  notes: string;
  internalFiles: InternalFile[];
  createdBy: string;
  createdOn: Date;
  selectedParts: SelectedPart[] | null;
  status: string; // status => scheduled, complete
  purchaseOrderQueryHook: string[];
  */
 export const CheckMaintenanceRecord = (snap, orgTimezone) => {
  if (!snap) return;
  const data = snap.data();

  return {
    id: checkUndefined(data.id, ""),
    orgId: checkUndefined(data.orgId, ""),
    itemId: checkUndefined(data.itemId, ""),
    itemName: checkUndefined(data.itemName, ""),
    assignedUser: checkUndefined(data.assignedUser, null),
    assignedUserName: checkUndefined(data.assignedUserName, null),
    servicedOn: formatDateOnRead({
      date: checkUndefined(data.servicedOn, null),
      orgTimezone,
    }).toDate(),
    servicedBy: checkUndefined(data.servicedBy, ""),
    costOfService: checkUndefined(data.costOfService, 0),
    costOfParts: checkUndefined(data.costOfParts, 0),
    costOfLabor: checkUndefined(data.costOfLabor, 0),
    notes: checkUndefined(data.notes, ""),
    createdBy: checkUndefined(data.userId, ""),
    createdOn: formatDateOnRead({
        date: checkUndefined(data.createdOn, null),
        orgTimezone,
      }).toDate(),
    internalFiles: checkUndefined(data.internalFiles, []),
    selectedParts: checkUndefined(data.selectedParts, []),
    status: checkUndefined(data.status, 'scheduled'),
    purchaseOrderQueryHook: checkUndefined(data.purchaseOrderQueryHook, []),

  };
};

  