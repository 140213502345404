import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {
  Button,
  Grid,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useFirestore } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { authSelector } from "../auth/authSlice";

// ----------------------------------------------------------------------
interface InputData {
  invoiceCreatedUrl: string;
  customerCreatedUrl: string;
  itemPreppedUrl: string;
  updateStatusUrl: string;
}

export default function HighLevelIntegrations() {
  const { orgData } = useSelector(authSelector);
  const firestore = useFirestore();

  const [inputData, setInputData] = useState<InputData>({
    invoiceCreatedUrl: "",
    customerCreatedUrl: "",
    itemPreppedUrl: "",
    updateStatusUrl: "",
  });

  useEffect(() => {
    if (orgData) {
        setInputData(orgData.highLevelUrls)
    }
  }, [orgData])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInputData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      console.log(inputData);
      firestore.collection('orgs').doc(orgData.id).set({ highLevelUrls: inputData }, { merge: true })
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  return (
    <Container>
      <Typography sx={{ mb: 2 }} variant="h4">
        High Level
      </Typography>

      <div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Invoice Created URL"
              name="invoiceCreatedUrl"
              value={inputData.invoiceCreatedUrl}
              onChange={handleInputChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Customer Created URL"
              name="customerCreatedUrl"
              value={inputData.customerCreatedUrl}
              onChange={handleInputChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Item Prepped URL"
              name="itemPreppedUrl"
              value={inputData.itemPreppedUrl}
              onChange={handleInputChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Update Status URL"
              name="updateStatusUrl"
              value={inputData.updateStatusUrl}
              onChange={handleInputChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
