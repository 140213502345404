import { formatDateOnWrite } from "../../time/formatTimezoneOffset";

export const configureGeneralSettingsUpdate = (
  values,
  updatedById,
  orgTimezone
) => {

  const {
    companyName,
    companyAddress,
    showCompanyAddress,
    companyPhone,
    companyWebsite,
    webTaxName,
    adminEmail,
    useAdminEmailReply,
    webTransactionType,
    qbInvoiceDate,
    companyOpenTime,
    companyCloseTime,
    taxRate,
    qbBidirectionalSync,
    trackInventoryWeight,
    rangeRate,
    baseRate,
  } = values;

  return {
    qbBidirectionalSync,
    useAdminEmailReply,
    companyName: companyName ? companyName : "",
    webTaxName: webTaxName ? webTaxName : "",
    adminEmail: adminEmail ? adminEmail : "",
    webTransactionType: webTransactionType ? webTransactionType : "",
    qbInvoiceDate: qbInvoiceDate ? qbInvoiceDate : "",
    companyAddress: companyAddress ? companyAddress : "",
    showCompanyAddress,
    companyPhone: companyPhone ? companyPhone : "",
    companyWebsite: companyWebsite ? companyWebsite : "",
    companyOpenTime: companyOpenTime
      ? formatDateOnWrite({
        date: companyOpenTime.toDate(),
        orgTimezone,
      })
      : null,
    companyCloseTime: companyCloseTime
      ? formatDateOnWrite({
        date: companyCloseTime.toDate(),
        orgTimezone,
      })
      : null,
    taxRate: taxRate ? taxRate : null,
    updatedBy: updatedById ? updatedById : "",
    trackInventoryWeight,
    updatedOn: new Date(),
    rangeRate: rangeRate * 100,
    baseRate: baseRate * 100,
  };
};

export const configureInvoiceSettingsUpdate = (
  values,
  updatedById
) => {
  const {
    addSubjectLineInvoiceNumber,
    automaticallyConvertEstimate,
    showInvoiceItemImg,
    showItemName,
    showDuration,
    showRate,
    showDescription,
    showTotal,
    deliveryFeeTaxable,
    defaultDepositPercent,
    estimateExpirationLength,
    eventDateEnabled,
    salesInvoiceEnabled,
    allowPayInFull,
  } = values;

  return {
    allowPayInFull,
    addSubjectLineInvoiceNumber,
    automaticallyConvertEstimate,
    showInvoiceItemImg,
    showItemName,
    showDuration,
    showRate,
    showDescription,
    showTotal,
    deliveryFeeTaxable,
    defaultDepositPercent: defaultDepositPercent ? defaultDepositPercent : 100,
    estimateExpirationLength: estimateExpirationLength ? estimateExpirationLength : 0,
    updatedBy: updatedById ? updatedById : "",
    eventDateEnabled,
    salesInvoiceEnabled,
    updatedOn: new Date(),
  };
};


export const configureEmailSettingsUpdate = (
  values,
  updatedById,
  orgTimezone
) => {
  const {
    emailSubject,
    emailFrom,
    emailBody,
    invoiceNotes,
    emailSignature,
    termsConditions,
    invoiceCopyEmails,
  } = values;
  return {
    emailSubject: emailSubject ? emailSubject : "",
    emailFrom: emailFrom ? emailFrom : "",
    emailBody: emailBody ? emailBody : "",
    invoiceNotes: invoiceNotes ? invoiceNotes : "",
    emailSignature: emailSignature ? emailSignature : "",
    termsConditions: termsConditions ? termsConditions : "",
    invoiceCopyEmails: invoiceCopyEmails ? invoiceCopyEmails : [],
    updatedBy: updatedById ? updatedById : "",
    updatedOn: new Date(),
  };
};
