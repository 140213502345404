import React, {
  FC,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  InputRef,
  Switch,
  FormInstance,
} from "antd";
import TextSubtitle from "../../../../app/system-components/typography/text/TextSubtitle";
import ImageUploader from "../ImageUploader";
import { useFirestore } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { authSelector } from "../../../auth/authSlice";
import notificationError from "../../../../app/system-components/toasters/notificationError";
import onlyUnique from "../../../../app/utils/filters/filterOnlyUnique";
import { InventoryCategory } from "../../../../app/interfaces/inventoryCategory";
import { Vendor } from "../../../../app/interfaces/vendor";

const { Option } = Select;
const NewPartDetails: FC<{
  categories: InventoryCategory[];
  vendors: Vendor[];
  form: FormInstance<any>;
  duplicated: boolean;
  saving: boolean;
  onImageUrl: (downloadURL: any, fileName: any) => void;
  removeImg: () => void;
  defaultImg: {
    downloadURL: string;
    fileName: string;
  } | null;

}> = ({
  vendors,
  categories,
  form,
  duplicated,
  onImageUrl,
  removeImg,
  defaultImg,
  saving,
}) => {
  const { fsOrgPrefix, orgData } = useSelector(authSelector);
  const nameInput = useRef<InputRef | null>(null);
  const firestore = useFirestore();

  useEffect(() => {
    if (duplicated) {
      nameInput.current?.focus();
    }
  }, [duplicated]);

  return (
    <div>
      <Row justify="space-between">
        <Col>
          <ImageUploader
            onImageUrl={onImageUrl}
            removeImg={removeImg}
            defaultImg={defaultImg}
          />
        </Col>
      
      </Row>
      <br />
      <Form.Item
        name="name"
        rules={[
          {
            required: true,
            message: "Name required",
          },
        ]}
      >
        <Input placeholder="Name" ref={nameInput} />

      </Form.Item>

      <Form.Item
        name="description"
        rules={[
          {
            required: true,
            message: "Description required",
          },
        ]}
      >
        <Input.TextArea placeholder="Description" rows={1} />
      </Form.Item>

      <Form.Item name="vendorQueryHook">
        <Select
          style={{ width: "100%" }}
          placeholder="Select Vendor"
          filterOption={(input, option) => {
            if (!option || !option.children) return true;
            return (
              option.children
                .toString()
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            );
          }}
          filterSort={(optionA, optionB) => {
            if (!optionA || !optionB || !optionA.children || !optionB.children)
              return 1;
            return optionA.children
              .toString()
              .toLowerCase()
              .localeCompare(optionB.children.toString().toLowerCase());
          }}
        >
          {vendors &&
            vendors.map((v) => (
              <Option key={v.id} value={v.id}>
                {v.companyName}
              </Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item name="categoriesQueryHook">
        <Select
          mode="multiple"
          style={{ width: "100%" }}
          placeholder="Select categories"
          filterOption={(input, option) => {
            if (!option || !option.children) return true;
            return (
              option.children
                .toString()
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            );
          }}
          filterSort={(optionA, optionB) => {
            if (!optionA || !optionB || !optionA.children || !optionB.children)
              return 1;
            return optionA.children
              .toString()
              .toLowerCase()
              .localeCompare(optionB.children.toString().toLowerCase());
          }}
        >
          {categories &&
            categories.map((c) => (
              <Option key={c.id} value={c.id}>
                {c.name}
              </Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item
          label={<TextSubtitle text={"PRICE"} />}
          name={'price'}
        >
          <InputNumber
            placeholder={"0"}
            style={{ width: "120px" }}
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
          />
        </Form.Item>

      <Divider style={{ margin: "16px 0px" }} />
      <Row gutter={24}>
        <Col>
          <Form.Item name="stock" label={<TextSubtitle text={"STOCK"} />}>
            <InputNumber min={0} placeholder={"0"} style={{ width: "120px" }} />
          </Form.Item>
        </Col>
      </Row>

    </div>
  );
};

export default NewPartDetails;
