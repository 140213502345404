import { Card } from '@mui/material';
import React, { useEffect, useRef } from 'react'
import { functionsBaseUrl } from '../../../app/config/firebase';
import { useSelector } from 'react-redux';
import { authSelector } from '../../auth/authSlice';

type Props = {
    paymentId: string
}

export function JustifiPaymentDetail({paymentId}: Props) {

    console.log("paymentId", paymentId);
    
    const { orgData } = useSelector(authSelector);

    const formRef = useRef<HTMLFormElement>(null);
    const [webComponentToken, setWebComponentToken] = React.useState<string | null>(null);

    useEffect(() => {

      if (!orgData) return;

      const getWebcomponentToken = async () => {
        const url = functionsBaseUrl + "/justifi";
        const data = {
          orgId: orgData.id,
          action: "webComponentToken",
          payload: {},
        };

        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });

        const json = await response.json();
        setWebComponentToken(json.access_token);
      };

      getWebcomponentToken();
    }, [orgData]);


    return (
      <Card sx={{ p: 1, m: 1 }}>
        {webComponentToken && (
           <justifi-payment-details
            ref={formRef}
            payment-id={paymentId}
            auth-token={webComponentToken}
           >
         </justifi-payment-details>
        )}
      </Card>
    );
}