import React, { FC } from "react";
import TextBody from "../../../../app/system-components/typography/text/TextBody";
import { useSelector } from "react-redux";
import { authSelector } from "../../../auth/authSlice";
import { AdminPartsContextMenuControl } from "../contextMenu/useAdminPartsContextMenu";

export const PartsContextMenu: FC<{ control: AdminPartsContextMenuControl }> =
  ({ control }) => {

  const { userData } = useSelector(authSelector);

  return (
    <div>
      {control.contextConfig.visible && userData && control.contextConfig.selectedPart && (
        <ul
          className="tableContextMenu"
          style={{
            left: `${control.contextConfig.x}px`,
            top: `${control.contextConfig.y}px`,
            padding: "4px 0",
          }}        >
          <li
            className="contextMenuItem"
            style={{ padding: "4px 8px" }}
            onClick={control.handleEdit}
            >
            <TextBody text={"Edit/View Item"} />
          </li>
          <li
            className="contextMenuItem"
            style={{ padding: "4px 8px" }}
            onClick={control.handleDuplicate}
            >
            <TextBody text={"Duplicate"} />
          </li>
          <li
            className="contextMenuItem"
            style={{ padding: "4px 8px" }}
            onClick={control.handleDelete}
            >
            <TextBody text={"Delete"} />
          </li>
        </ul>
      )}
    </div>
  );
};

export default PartsContextMenu;
