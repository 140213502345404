import React, { useEffect, useState } from 'react';
import { List, Button, Card, Col, DatePicker, Form, Input, InputNumber, Row, Spin, message, Modal, Select, Space, Table } from 'antd';
import NumberFormat from 'react-number-format';
import type { RcFile as OriRcFile, UploadProps as RcUploadProps, UploadRequestOption, UploadRequestError } from 'rc-upload/lib/interface';
import { UploadChangeParam, UploadFile, UploadProps } from 'antd/lib/upload/interface';

import TextSubtitle from '../../../../app/system-components/typography/text/TextSubtitle';
import TextBody from '../../../../app/system-components/typography/text/TextBody';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { firelikeId } from '../../../../app/utils/models/checkers/firelikeid';
import { Spinner } from '../../../../app/ui/spinner';
import { checkUndefinedOrNull } from '../../../../app/utils/models/checkers/checkUndefined';
import Dragger from 'antd/lib/upload/Dragger';
import { Part, SelectedPart, convertPartToSelectedPart } from '../../../../app/interfaces/part';
import { InventoryItem } from '../../../../app/interfaces/inventoryItem';
import { MaintenanceRecord } from '../../../../app/interfaces/maintenanceRecord';
import { InternalFile } from '../../../../app/interfaces/invoice';
import { CloseCircleFilled, DeleteFilled, FilePdfOutlined, InboxOutlined, PlusOutlined } from '@ant-design/icons';
import Upload, { RcFile } from 'antd/lib/upload';
import { useSelector } from 'react-redux';
import { authSelector } from '../../../auth/authSlice';
import { sortBy } from '../../../../app/utils/sorters/sortBy';
import { partsQueries } from '../../../../app/services/firestore/queries/partQueries';
import moment from 'moment';
import { Close, DeleteOutline } from '@mui/icons-material';

const { Option } = Select;

export interface MaintenanceProps {
  item: InventoryItem;
  maintenanceRecord: MaintenanceRecord | null;
  finishEditing?: () => void;
  users: any[];
}

const AddEditMaintenanceRecord: React.FC<MaintenanceProps> = ({ item, maintenanceRecord, finishEditing, users }) => {
  const [maintenanceRecordForm] = Form.useForm();
  const firestore = useFirestore();
  const firebase = useFirebase();

  const [savingRecord, setSavingRecord] = useState(false);
  const [imageFilesList, setimageFilesList] = useState<UploadFile[]>([]);
  const [fileList, setFileList] = useState<any[]>([]);
  const [downloadUrls, setDownloadUrls] = useState<string[]>([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [currentMaintenanceRecord, setMaintenanceRecord] = useState<MaintenanceRecord | null>(null);
  const [editMode, setEditMode] = useState(false);
  const [isComplete, setIsComplete] = useState(false);

  const [salesRep, setSalesRep] = useState<string | null>(null);

  const { userData, orgData, fsOrgPrefix } = useSelector(authSelector);

  const [loadingParts, setLoadingParts] = useState(false);
  const [parts, setParts] = useState<Part[]>([]);
  const [selectedParts, setSelectedParts] = useState<SelectedPart[]>([]);
  const [focusedPart, setFocusedPart] = useState(null);

  // On load, check if this is an edit or add.
  useEffect(() => {
    if (orgData) {
      if (maintenanceRecord) {
        setMaintenanceRecord(maintenanceRecord);
        setEditMode(true);
        setSelectedParts(maintenanceRecord.selectedParts || []);
        setSalesRep(checkUndefinedOrNull(maintenanceRecord.assignedUser));

        if (maintenanceRecord.status === 'complete') {
          setIsComplete(true);
        }

        maintenanceRecordForm.setFieldsValue({
          servicedOn: maintenanceRecord.servicedOn ? moment(maintenanceRecord.servicedOn) : null,
          costOfService: maintenanceRecord.costOfService,
          costOfLabor: maintenanceRecord.costOfLabor,
          costOfParts: maintenanceRecord.costOfParts,
          maintenanceNotes: maintenanceRecord.notes,
          assignedUser: maintenanceRecord.assignedUser,
        });
      } else {
        const starterRecord: MaintenanceRecord = {
          id: firelikeId(),
          orgId: orgData.id,
          itemId: item.id,
          itemName: item.name,
          assignedUser: null,
          assignedUserName: null,
          servicedOn: new Date(),
          servicedBy: '',
          costOfService: 0,
          costOfLabor: 0,
          costOfParts: 0,
          notes: '',
          internalFiles: [],
          createdBy: '',
          createdOn: new Date(),
          selectedParts: null,
          status: 'scheduled', // status => scheduled, complete
          purchaseOrderQueryHook: [],
        };

        setMaintenanceRecord(starterRecord);
      }
    }
  }, [item.id, maintenanceRecord]);

  useEffect(() => {
    // load categories
    fsOrgPrefix && loadParts();
  }, [fsOrgPrefix]);

  const loadParts = async () => {
    setLoadingParts(true);
    const data = await partsQueries({ firestore }, fsOrgPrefix).fetchParts();

    setParts(sortBy(data, 'name'));
    setLoadingParts(false);
  };

  // Define the upload image function
  const handleUploadImage = async (file: RcFile) => {
    try {
      const storageRef = firebase.storage().ref();
      const storagePath = `orgs/${item.orgId}/maintenance/${item.name}-${item.id}/images/${file.name}`;
      const fileRef = storageRef.child(storagePath);
      await fileRef.put(file);

      const downloadUrl = await fileRef.getDownloadURL();
      setDownloadUrls([...downloadUrls, downloadUrl]);
    } catch (error) {
      // Handle error
    }
  };

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload Image</div>
    </div>
  );

  const handleCancel = () => setPreviewOpen(false);
  const handleChange: UploadProps['onChange'] = ({ fileList: newimageFilesList }) => setimageFilesList(newimageFilesList);
  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
  };

  const props: UploadProps = {
    name: 'file',
    accept: '.jpg,.jpeg,.png',
    customRequest: async (options: UploadRequestOption) => {
      const { file, onSuccess, onError } = options;
      try {
        // Perform your upload logic here
        const response = await handleUploadImage(file as RcFile);

        onSuccess && onSuccess(response);
      } catch (error) {
        onError && onError(error as UploadRequestError);
      }
    },
    listType: 'picture-card',
    fileList: imageFilesList,
    onPreview: handlePreview,
    onChange: handleChange,
  };

  const handleUploadFile = async (options: any) => {
    const { file, onSuccess, onError, onProgress } = options;

    try {
      const storageRef = firebase.storage().ref();
      const storagePath = `orgs/${item.orgId}/maintenance/${item.id}/files/${file.name}`;

      const uploadTask = storageRef.child(storagePath).put(file);
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Upload progress
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          onProgress({ percent: progress }, file);
        },
        (error) => {
          // Upload error
          onError(error);
          message.error('File upload failed.');
          console.error('Error uploading file:', error);
        },
        () => {
          // Upload complete
          uploadTask.snapshot.ref
            .getDownloadURL()
            .then(async (downloadUrl) => {
              // Create InvoiceFile object
              const maintenanceFile: InternalFile = {
                name: file.name,
                downloadUrl,
                size: Math.round(file.size / 1024),
                uploadedBy: 'John Doe', // Replace with actual user name
                createdOn: new Date(),
              };

              currentMaintenanceRecord && currentMaintenanceRecord.internalFiles.push(maintenanceFile);

              // Clear the file list
              setFileList([]);
              // Show success message
              message.success('File uploaded successfully.');
              onSuccess(downloadUrl, file);
            })
            .catch((error) => {
              onError(error);
              console.error('Error retrieving download URL:', error);
              message.error('Failed to retrieve download URL.');
            });
        }
      );
    } catch (error) {
      onError(error);
      console.error('Error uploading file:', error);
      message.error('File upload failed.');
    }
  };

  const handleFileClick = (file: InternalFile) => {
    window.open(file.downloadUrl, '_blank');
  };

  const handleAddPart = (value: string | null) => {
    if (!value) {
      return;
    }

    const foundPart = parts.find((part) => part.id === value);
    console.log(foundPart);
    if (foundPart) {
      const newSelectedPart: SelectedPart = convertPartToSelectedPart(foundPart);
      const newParts = [...selectedParts, newSelectedPart];
      setSelectedParts(newParts);
    }

    setFocusedPart(null);
  };

  const handleSaveMaintenanceRecord = async (markComplete: boolean = false) => {
    maintenanceRecordForm
      .validateFields()
      .then(async (values) => {
        // Create a JSON object from the form values

        let copy = currentMaintenanceRecord;

        if (!copy) {
          console.log('No Record: ', currentMaintenanceRecord);
          message.error('No Record');
          setSavingRecord(false);
          return;
        }

        copy.servicedOn = values.servicedOn.format();
        copy.assignedUser = checkUndefinedOrNull(values.assignedUser, null);

        const foundUser = users.find((user) => user.id === copy?.assignedUser);
        if (foundUser) {
          copy.assignedUserName = foundUser.name;
        }
        // copy.servicedBy = values.servicedBy
        copy.costOfService = checkUndefinedOrNull(values.costOfService, 0);
        copy.costOfLabor = checkUndefinedOrNull(values.costOfLabor, 0);
        copy.costOfParts = checkUndefinedOrNull(values.costOfParts, 0);
        copy.notes = checkUndefinedOrNull(values.maintenanceNotes, '');
        copy.createdBy = userData.id;
        copy.createdOn = new Date();
        copy.selectedParts = selectedParts;
        copy.status = 'scheduled';
        copy.purchaseOrderQueryHook = [];

        if (markComplete || isComplete) {
          copy.status = 'complete';
        }

        try {
          await firestore.collection(`orgs/${item?.orgId}/inventory/`).doc(item?.id).collection('maintenanceRecords').doc(copy.id).set(copy);

          setSavingRecord(false);
          setSelectedParts([]);
          setFileList([]);

          // Clear the form fields
          maintenanceRecordForm.resetFields();
          message.success(editMode ? 'Maintenance record updated' : 'Maintenance record added.');
          if (editMode && finishEditing) {
            finishEditing();
          }
        } catch (error) {
          console.log('ERROR: ', error);
          message.error('Adding maintenance record failed.');
        }
      })
      .catch((error) => {
        setSavingRecord(false);
        console.error('Validation error:', error);
      });
  };

  const handleQuantityChange = (value: any, record: SelectedPart) => {
    // Find the index of the record in the selectedParts array
    const index = selectedParts.findIndex((part) => part.id === record.id);

    if (index !== -1) {
      // Clone the selectedParts array to avoid mutating state directly
      const updatedSelectedParts = [...selectedParts];

      // Update the quantity of the corresponding record
      updatedSelectedParts[index].qty = value;

      // Update the state with the new selectedParts array
      setSelectedParts(updatedSelectedParts);
    }
  };

  const handleDelete = (record: SelectedPart) => {
    // Filter out the selected part with the given id
    const updatedSelectedParts = selectedParts.filter((part) => part.id !== record.id);

    // Update the state with the new selectedParts array
    setSelectedParts(updatedSelectedParts);
  };

  const handleAssignedTo = (userId: string) => {
    setSalesRep(userId);
    maintenanceRecordForm.setFieldsValue({
      assignedUser: userId,
    });
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'In Stock',
      dataIndex: 'stock',
      key: 'stock',
    },
    {
      title: 'Required for service',
      dataIndex: 'qty',
      key: 'quantity',
      render: (text: any, record: SelectedPart) => (
        <InputNumber
          disabled={isComplete}
          defaultValue={0}
          placeholder='qty'
          value={text} // Set the value of the input to the qty from the record
          onChange={(value) => handleQuantityChange(value, record)} // Handle quantity change
        />
      ),
    },
    {
      title: 'Delete',
      dataIndex: 'actions',
      key: 'actions',
      render: (_: any, record: SelectedPart) => (
        <Button disabled={isComplete} style={{ color: 'red' }} type='link' icon={<CloseCircleFilled />} onClick={() => handleDelete(record)} />
      ),
    },
  ];

  return (
    <>
      {' '}
      {/* New section for creating maintenance records */}
      <Card
        size='small'
        title={
          <TextBody
            style={{ fontWeight: 'bold' }}
            text={editMode ? 'Review / Edit Maintenance' : 'Schedule New Maintenance (all fields can be edited later)'}
          />
        }
        extra={
          editMode ? (
            <Space>
              {!isComplete && (
                <Button style={{ background: 'green', color: 'white' }} onClick={() => handleSaveMaintenanceRecord(true)}>
                  Mark Complete
                </Button>
              )}

              <Button onClick={() => handleSaveMaintenanceRecord(false)} type='primary'>
                Save
              </Button>
            </Space>
          ) : null
        }
        bordered={false}
      >
        <Spin spinning={savingRecord} indicator={Spinner}>
          <Form form={maintenanceRecordForm}>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item name='servicedOn' label={<TextSubtitle text={'Service On'} />} rules={[{ required: true, message: 'Please select a date' }]}>
                  <DatePicker disabled={isComplete} style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                {users && users.length > 0 && (
                  <Form.Item label={<TextSubtitle text={'Assign to:'} />} name='assignedUser' className='NewInvoiceCustomerForm'>
                    <Select
                      disabled={isComplete}
                      allowClear
                      onClear={() => {
                        setSalesRep(null);
                      }}
                      onSelect={handleAssignedTo}
                      placeholder={'responsible user'}
                      className={'customer-picker'}
                      defaultValue={salesRep} // Set the default value here
                    >
                      {users.map((u) => (
                        <Option value={u.id} key={u.id}>
                          {u.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item label={<TextSubtitle text={'Cost of Parts'} />} name={'costOfParts'}>
                  <InputNumber
                    disabled={isComplete}
                    placeholder={'0'}
                    style={{ width: '120px' }}
                    formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => {
                      return value ? value.replace(/\$\s?|(,*)/g, '') : '';
                    }}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item label={<TextSubtitle text={'Cost of Labor'} />} name={'costOfLabor'}>
                  <InputNumber
                    disabled={isComplete}
                    placeholder={'0'}
                    style={{ width: '120px' }}
                    formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => {
                      return value ? value.replace(/\$\s?|(,*)/g, '') : '';
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <TextSubtitle text={'Notes'} />
                <Form.Item name='maintenanceNotes'>
                  <Input.TextArea rows={3} style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <>
            <Dragger
              fileList={fileList}
              beforeUpload={(file) => {
                const isPdf = file.type === 'application/pdf';
                if (!isPdf) {
                  message.error('You can only upload PDF files.');
                }
                return isPdf;
              }}
              onChange={(info) => {
                const { fileList } = info;
                setFileList(fileList);
              }}
              customRequest={handleUploadFile}
            >
              <p className='ant-upload-drag-icon'>
                <InboxOutlined />
              </p>
              <p className='ant-upload-text'>Click or drag PDF files to this area to upload</p>
            </Dragger>

            {currentMaintenanceRecord && currentMaintenanceRecord.internalFiles.length > 0 && (
              <List
                dataSource={(currentMaintenanceRecord && currentMaintenanceRecord.internalFiles) || []}
                renderItem={(file) => (
                  <List.Item key={file.name} onClick={() => handleFileClick(file)} style={{ cursor: 'pointer' }}>
                    <List.Item.Meta avatar={<FilePdfOutlined style={{ color: 'red' }} />} title={file.name} />
                  </List.Item>
                )}
              />
            )}
          </>

          {/* <>
              <br />
              <Upload {...props}>
                {imageFilesList.length >= 8 ? null : uploadButton}
              </Upload>
              <Modal
                open={previewOpen}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
              >
                <img
                  alt="example"
                  style={{ width: "100%" }}
                  src={previewImage}
                />
              </Modal>
            </>

            <br />
            <br /> */}

          <br />

          <TextBody style={{ fontWeight: 'bold' }} text={'Add any parts required for maintenance.'} />
          <br />
          <br />
          <Select
            disabled={isComplete}
            value={focusedPart}
            style={{ width: '100%' }}
            placeholder='Add part required for maintenance...'
            onSelect={handleAddPart}
            optionLabelProp='label'
          >
            {parts &&
              parts
                .filter((part) => !selectedParts.find((s) => s.id === part.id))
                .map((part) => (
                  <Option value={part.id} label={part.name} key={part.id}>
                    <div>{part.name}</div>
                  </Option>
                ))}
          </Select>

          <br />
          <br />

          {selectedParts.length > 0 && <Table pagination={false} dataSource={selectedParts} columns={columns} rowKey={(record) => record.id} />}

          {!editMode && (
            <Form.Item>
              <Button type='primary' onClick={() => handleSaveMaintenanceRecord(false)}>
                Schedule Maintenance
              </Button>
            </Form.Item>
          )}
        </Spin>
      </Card>
    </>
  );
};

export default AddEditMaintenanceRecord;
